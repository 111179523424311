import React from "react";
import "./Calendar.css";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import dateformat from 'dateformat';
import { apiurl } from "../../App";

import Axios from "axios";
import { Spin } from "antd"
import Moment from 'moment/min/moment-with-locales';

Moment.locale(localStorage.training_center_language == 'ar' ? 'ar' : 'en');
const moment = extendMoment(originalMoment);
const Current_date = (Moment().format(localStorage.training_center_language == 'ar' ? "ddd, MMM DD" : "ddd, MMM DD"))
const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export default class Calendar extends React.Component {
  weekdayshort = Moment.weekdaysShort();

  state = {
    showYearTable: false,
    showMonthTable: false,
    showDateTable: true,
    dateObject: moment(),
    allmonths: moment.months(),
    selectedDay: null,
    fulldate: "",
    rangeSelect: [],
    rangeSelectFirst: [],
    slotSubtract: 1,
    slotAdd: 1,
    TotalslotsAvailable: [],
    spinLoad: false,
  };

  daysInMonth = () => {
    return this.state.dateObject.daysInMonth();
  };
  year = () => {

    return this.state.dateObject.format("Y");
  };
  currentDay = () => {
    return this.state.dateObject.format("D");
  };
  firstDayOfMonth = () => {
    let dateObject = this.state.dateObject;
    let firstDay = moment(dateObject)
      .startOf("month")
      .format("d"); // Day of week 0...1..5...6
    return firstDay;
  };
  month = () => {
    return Moment(this.state.dateObject).format("MMM");
  };
  setMonth = month => {
    let monthNo = this.state.allmonths.indexOf(month);
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("month", monthNo);
    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showDateTable: !this.state.showDateTable
    });
  };
  MonthList = props => {
    let months = [];
    props.data.map(data => {
      months.push(
        <td
          key={data}
          className="calendar-month"
          onClick={e => {
            this.setMonth(data);
          }}
        >
          <span>{data}</span>
        </td>
      );
    });
    let rows = [];
    let cells = [];

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i == 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
    });
    rows.push(cells);
    let monthlist = rows.map((d, i) => {
      return <tr>{d}</tr>;
    });

    return (
      <table className="calendar-month">
        <thead>
          <tr>
            <th colSpan="4">Select a Month</th>
          </tr>
        </thead>
        <tbody>{monthlist}</tbody>
      </table>
    );
  };
  showYearTable = e => {
    this.setState({
      showYearTable: !this.state.showYearTable,
      showDateTable: !this.state.showDateTable
    });
  };

  onPrev = () => {
    this.setState({ spinLoad: false })
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var monthmatch = []
    var yearmatch = []

    for (let i = 0; i < monthNames.length; i++) {
      if (this.month() === monthNames[i]) {
        if (this.month() === "Jan") {
          yearmatch.push(Number(this.year()) - 1)
          monthmatch.push("Dec")
          break;
        } else {
          monthmatch.push(monthNames[i - 1])
          yearmatch.push(this.year())
          break;
        }
      }
    }
    var monthmatchNum = []

    for (let j = 0; j < monthNames.length; j++) {
      if (monthNames[j] === monthmatch[0]) {
        monthmatchNum.push(j + 1)
      }
    }

    var totaldaycount = new Date(Number(yearmatch[0]), Number(monthmatchNum[0]), 0).getDate()
    var fromdate = dateformat(yearmatch[0] + " " + monthmatch[0] + " " + 1, "yyyy-mm-dd")
    var todate = dateformat(yearmatch[0] + " " + monthmatch[0] + " " + totaldaycount, "yyyy-mm-dd")
    this.getAddSlots(fromdate, todate);
    let curr = "";
    if (this.state.showYearTable === true) {
      curr = "year";
    } else {
      curr = "month";
    }

    this.setState({
      dateObject: this.state.dateObject.subtract(1, curr),
    });
  };

  onNext = () => {
    this.setState({ spinLoad: false })
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    var monthmatch = []
    var yearmatch = []

    for (let i = 0; i < monthNames.length; i++) {
      if (this.month() === monthNames[i]) {
        if (this.month() === "Dec") {
          yearmatch.push(Number(this.year()) + 1)
          monthmatch.push("Jan")
          break;
        } else {
          monthmatch.push(monthNames[i + 1])
          yearmatch.push(this.year())
          break;
        }
      }
    }

    var monthmatchNum = []

    for (let j = 0; j < monthNames.length; j++) {
      if (monthNames[j] === monthmatch[0]) {
        monthmatchNum.push(j + 1)
      }
    }

    var totaldaycount = new Date(Number(yearmatch[0]), Number(monthmatchNum[0]), 0).getDate()

    var fromdate = dateformat(yearmatch[0] + " " + monthmatch[0] + " " + 1, "yyyy-mm-dd")
    var todate = dateformat(yearmatch[0] + " " + monthmatch[0] + " " + totaldaycount, "yyyy-mm-dd")
    this.getAddSlots(fromdate, todate);

    let curr = "";
    if (this.state.showYearTable === true) {
      curr = "year";
    } else {
      curr = "month";
    }
    this.setState({
      dateObject: this.state.dateObject.add(1, curr),
    });
  };

  setYear = year => {
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("year", year);
    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showYearTable: !this.state.showYearTable
    });
  };

  onYearChange = e => {
    this.setYear(e.target.value);
  };

  getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY"));
      currentDate = moment(currentDate).add(1, "year");
    }
    return dateArray;
  }

  YearTable = props => {
    let months = [];
    let nextten = moment()
      .set("year", props)
      .add("year", 12)
      .format("Y");

    let tenyear = this.getDates(props, nextten);

    tenyear.map(data => {
      months.push(
        <td
          key={data}
          className="calendar-month"
          onClick={e => {
            this.setYear(data);
          }}
        >
          <span>{data}</span>
        </td>
      );
    });
    let rows = [];
    let cells = [];

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i == 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
    });
    rows.push(cells);
    let yearlist = rows.map((d, i) => {
      return <tr>{d}</tr>;
    });

    return (
      <table className="calendar-month">
        <thead>
          <tr>
            <th colSpan="4">Select a Yeah</th>
          </tr>
        </thead>
        <tbody>{yearlist}</tbody>
      </table>
    );
  };

  onDayClick = (e, d) => {
    var datearr = []
    var rangeSelect = []
    var rangeSelectFirst = []
    var startDatestore = []
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


    if (this.state.fulldate.length === 0) {
      startDatestore.push(new Date(this.month() + "-" + this.year() + "-" + d))
      rangeSelect.push(`selectedclr${d}_${this.month()}_${this.year()}`)

      // send date value to parent
      this.props.getDate({ startdate: new Date(this.month() + "-" + this.year() + "-" + d), enddate: null, TotalslotsAvailable: this.state.TotalslotsAvailable })
    }
    else if (this.state.fulldate.length === 1) {

      var initialstartDate = this.state.startDatestore[0]
      var initialendDate = new Date(new Date(this.month() + "-" + this.year() + "-" + d))

      // send date value to parent
      this.props.getDate({ startdate: initialstartDate, enddate: new Date(this.month() + "-" + this.year() + "-" + d), TotalslotsAvailable: this.state.TotalslotsAvailable })
      var startDate = []
      var endDate = []

      if (initialstartDate > initialendDate) {
        startDate.push(new Date(new Date(this.month() + "-" + this.year() + "-" + d)))
        endDate.push(this.state.startDatestore[0])
      }
      else if (initialstartDate < initialendDate) {
        startDate.push(this.state.startDatestore[0])
        endDate.push(new Date(new Date(this.month() + "-" + this.year() + "-" + d)))
      }
      else {
        startDate.push(this.state.startDatestore[0])
        endDate.push(this.state.startDatestore[0])
      }


      var
        arr = new Array(),
        dt = new Date(startDate[0] - 1);

      while (dt <= endDate[0] - 1) {
        arr.push(new Date(dt));
        dt.setDate(dt.getDate() + 1);
        rangeSelect.push(`selectedclr${dt.getDate()}_${monthNames[dt.getMonth()]}_${moment(new Date(dt)).format("YYYY")}`)
      }
    }
    else if (this.state.fulldate.length === 2) {
      startDatestore.push(new Date(this.month() + "-" + this.year() + "-" + d))
      rangeSelect.push(`selectedclr${d}_${this.month()}_${this.year()}`)

      // send date value to parent
      this.props.getDate({ startdate: new Date(this.month() + "-" + this.year() + "-" + d), enddate: null, TotalslotsAvailable: this.state.TotalslotsAvailable })
    }


    if (this.state.fulldate.length <= 1) {
      datearr.push(...this.state.fulldate, `selectedclr${d}_${this.month()}_${this.year()}`)
    }
    else {
      datearr.push(`selectedclr${d}_${this.month()}_${this.year()}`)
    }

    this.setState(
      {
        selectedDay: d,
        fulldate: datearr,
        selectedMonth: this.month(),
        selectedYear: this.year(),
        rangeSelect: rangeSelect,
        rangeSelectFirst: rangeSelectFirst,
        startDatestore: startDatestore,
      },
    );
  };

  componentDidMount() {
    this.getAddSlots();
  }

  locationChange(islocationchange) {
    if (islocationchange) {
      this.setState({ rangeSelect: [], dateObject: moment() })
    }
    this.getAddSlots()
  }

  getAddSlots = (fromDate, lastDate) => {

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.setState({ spinLoad: true })

    Axios({
      method: 'POST',
      // url: 'doctor/getAdSlots',
      url: 'diet/getAddSlotsDetails',
      data: {

        "fromDate": fromDate ? dateformat(fromDate, "yyyy-mm-dd") : dateformat(firstDay, "yyyy-mm-dd"),
        "toDate": lastDate ? dateformat(lastDate, "yyyy-mm-dd") : dateformat(lastDay, "yyyy-mm-dd"),
        // "locationId": 2,
        "placementLocation": 2,
        "vendor_type_id": 1
      }
    }).then((response) => {
      this.setState({ TotalslotsAvailable: response.data.data, spinLoad: false })
    }).catch((error) => {
      console.log(error);
    });
  }

  render() {

    const { t } = this.props
    let weekdayshortname = this.weekdayshort.map((day, i) => {
      return <th key={day}>{day}</th>;
    });

    let blanks = [];

    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blanks.push(<td className="calendar-day empty"></td>);
    }

    let daysInMonth = [];
    var hidepastdataleft = []


    for (let p = 1; p <= this.daysInMonth(); p++) {

      if (dateformat(this.year() + " " + this.month() + " " + p, "yyyy,mm,dd") === dateformat(new Date(), "yyyy,mm,dd")) {
        hidepastdataleft.push(false)
      }
      else {
        hidepastdataleft.push(true)
      }

      if (new Date() < new Date(dateformat(this.year() + " " + this.month() + " " + p, "yyyy,mm,dd")) || dateformat(this.year() + " " + this.month() + " " + p, "yyyy,mm,dd") === dateformat(new Date(), "yyyy,mm,dd")) {
        var hidepastdata = true
      }
      else {
        var hidepastdata = false
      }
    }
    if (hidepastdata) {
      for (let d = 1; d <= this.daysInMonth(); d++) {
        const startdate = `selectedclr${d}_${this.state.dateObject.format("MMM")}_${this.state.dateObject.format("Y")}`
        let currentDay = d == this.currentDay() ? "today" : "";
        if (this.props.aftertwodays) {

          var textgreyhide = moment(new Date()).add(1, 'days') < new Date(dateformat(this.year() + " " + this.month() + " " + d, "yyyy,mm,dd")) || dateformat(this.year() + " " + this.month() + " " + d, "yyyy,mm,dd") === moment(new Date()).add(1, 'days').format("yyyy,mm,dd")
        } else {
          var textgreyhide = new Date() < new Date(dateformat(this.year() + " " + this.month() + " " + d, "yyyy,mm,dd")) || dateformat(this.year() + " " + this.month() + " " + d, "yyyy,mm,dd") === dateformat(new Date(), "yyyy,mm,dd")
        }
        daysInMonth.push(
          <td key={d}
            className={`calendar-day ${currentDay} ${!textgreyhide && "cursornonehide"} ${this.state.TotalslotsAvailable[d - 1] && this.state.TotalslotsAvailable[d - 1].availableSlots != 1 ? "slotfull" : ''} ${this.state.TotalslotsAvailable[d] && this.state.TotalslotsAvailable[d].availableSlots == 0 && this.state.TotalslotsAvailable[d - 1].availableSlots != 0 ? "next_slotfull" : ''}`}
            onClick={(e) => { ((month[new Date().getMonth()] == Moment(this.state.dateObject).format("MMM")) ? new Date().getDate() <= d : d) && textgreyhide && this.state.TotalslotsAvailable[d - 1].availableSlots != 0 && this.onDayClick(e, d) }}
          // onClick={textgreyhide  && this.state.TotalslotsAvailable[d - 1]  && this.state.TotalslotsAvailable[d - 1].availableSlots == 1 && (e => { this.onDayClick(e, d); })}
          >
            <div className="range_parent w-100">
              <div className="range_child w-25">
              </div>
              <div
                className={`${startdate === this.state.rangeSelect[0] && this.state.TotalslotsAvailable[d - 1].availableSlots == 1 && "advertisement_table_fir_sel" ||
                  startdate === this.state.rangeSelect[this.state.rangeSelect.length - 1] && this.state.TotalslotsAvailable[d - 1] && this.state.TotalslotsAvailable[d - 1].availableSlots == 1 && "advertisement_table_sec_sel" ||
                  startdate === this.state.rangeSelect[this.state.rangeSelect.length - 1] && this.state.TotalslotsAvailable[d - 2] && this.state.TotalslotsAvailable[d - 2].availableSlots == 0 && "advertisement_prev_slotful_table_sec_sel" ||
                  this.state.rangeSelect.includes(startdate) && this.state.TotalslotsAvailable[d-1] && this.state.TotalslotsAvailable[d - 1].availableSlots == 1 && "advertisement_table_inter_sel" ||
                  this.state.rangeSelect.includes(startdate) && this.state.TotalslotsAvailable[d] && this.state.TotalslotsAvailable[d].availableSlots == 0 && this.state.TotalslotsAvailable[d - 1].availableSlots == 1 && "advertisement_prev_slotful_end"
                  }`}
              >
                <span className={`${!textgreyhide && "colornonepast"} table-body`}>
                  {d}
                </span>
              </div>
              <div className="range_btm w-25">
              </div>
            </div>
          </td>
        );
      }
    }
    var totalSlots = [...blanks, ...daysInMonth];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        rows.push(cells);
      }
    });

    let daysinmonth = rows.map((d, i) => {
      return <tr>{d}</tr>;
    });

    return (
      <>

        <div className={`ad_tail-datetime-calendar`}>

          <div className="calendar-navi">
            <div className={``}>{Current_date}</div>
            <div className="move_lft_rgt">
              <ChevronLeftIcon className="date_arrow order_0"

                onClick={() => { month[new Date().getMonth()] != Moment(this.state.dateObject).format("MMM") && this.onPrev() }}
              // onClick={hidepastdataleft.every((val) => val === true) && (e => { this.onPrev(); })}
              />
              {!this.state.showMonthTable && (
                <span className={`order_1 calendar-label`}>
                  {Moment(this.state.dateObject).format("MMM")}
                </span>
              )}
              <span className={`order_2`}>{this.year()}</span>
              <ChevronRightIcon className="date_arrow order_3" onClick={e => { this.onNext(); }} />
            </div>
          </div>

          <div className="calendar-date">
            {this.state.showYearTable && <this.YearTable props={this.year()} />}
            {this.state.showMonthTable && (
              <this.MonthList data={moment.months()} />
            )}
          </div>



          {this.state.showDateTable && (
            // <div className={`${!!this.props.i18n.language=='ar'?"arabic_cls":""} calendar-date`}>
            <div className={`calendar-date`}>
              <Spin className="spinner_align" spinning={this.state.spinLoad}>
                {/* <table className="calendar-day" dir={`${!!this.props.i18n.language=='ar'?"rtl":"ltr"}`}> */}
                <table className="calendar-day" dir={`ltr`}>

                  <thead className="weekday_shortname">
                    <tr>{weekdayshortname}</tr>
                  </thead>
                  <tbody className="table_body">{daysinmonth}</tbody>
                </table>
              </Spin>

              <div className="advertisement_calslots_container" >
                <div className="total_slots_div" style={{color:"#ff0068"}}><p className="cal_avail_slots"></p><span className="total_slots_text">Excluded Dates - Already booked
                </span></div>
                {/* <div className="total_slots_div"><p className="cal_avail_slots1"></p><span className="total_slots_text"> Slots Available </span></div> */}
              </div>

            </div>
          )}
        </div>
      </>
    );
  }
}