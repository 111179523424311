import { useHistory } from "react-router-dom";
import React, { useState, Component, useEffect } from "react";
import Axios from "axios";
import useStateRef from "react-usestateref";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import LinearProgress from "@mui/material/LinearProgress";

import "./Profile.css";
import FooterMaster from "../Dashboard/FooterMaster";

import successModelTickImg from "../../assets/Web_Doctor_Icon_new_theme/Success.svg";
import vector from "../../assets/Doctor_web_Icon/Vector.png";
import EmailIcon from "../../assets/icon/EmailIcon.svg";
import WebSiteIcom from "../../assets/icon/websiteIcon.svg";
import PhoneIcon from "../../assets/icon/PhoneIcon.svg";
import LoactionIcon from "../../assets/icon/LoactionIcon.svg";
import NationalityIcon from "../../assets/icon/Nationality.svg";
import axios from "axios";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CryptoJS from "crypto-js";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
function ProfileComponent() {
  let Var_History = useHistory();

  const [Var_ShowImage, setVar_ShowImage] = React.useState(false);
  const [Var_Profiledata, setVar_Profiledata] = React.useState([]);
  const [, setVar_ProfileImg, Var_ProfileImg_Ref] =
    useStateRef("");
  const [, setVar_ProfileName, Var_ProfileName_Ref] =
    useStateRef("");
  const [, setVar_EmailData, Var_EmailData_Ref] = useStateRef("");
  const [, setVar_WebsiteData, Var_WebsiteData_Ref] =
    useStateRef("");
  const [, setVar_MobileData, Var_MobileData_Ref] =
    useStateRef("");
  const [, setVar_AddressData, Var_AddressData_Ref] =
    useStateRef("");
  const [, setVar_NationalityData, Var_NationalityData_Ref] =
    useStateRef("");
  const [, setVar_CliniclistData, Var_CliniclistData_Ref] =
    useStateRef("");
  const [
    Var_PracticeSinceData,
    setVar_PracticeSinceData,
    Var_PracticeSinceData_Ref,
  ] = useStateRef("");
  const [, setVar_SelfDescription, Var_SelfDescription_Ref] =
    useStateRef("");
  var [, setcontract_eny_data, contract_eny_dataRef] =
    useStateRef({});
  var [contract_orSignature, setcontract_orSignature, contract_orSignatureRef] =
    useStateRef(0);
  const [loading, setLoading] = useState(true);
  const [vendorID, setVendorID] = useState("");
  const [vendorTypeID, setvendorTypeID] = useState("");
  const [Var_ProfileSuccessfully, setVar_ProfileSuccessfully] = useStateRef([]);
  const [Var_ShowResults, setVar_ShowResults] = React.useState(true);
  const [Var_IsShowSuccess, setVar_IsShowSuccess] = React.useState(false);

  // initial function call start
  useEffect(() => {
    getDoctorProfile();
  }, []);

  const handleOnClick = (path) => {
    Var_History.push("/dashboard" + path);
  };

  const isCloseProfileSuccessModel = () => {
    setVar_IsShowSuccess(!Var_IsShowSuccess);
  };

  const uploadImage = () => {
    setVar_ShowImage(!Var_ShowImage);
  };
  const uploadDocument = async (e) => {
    debugger;
    setLoading(true);
    setVar_ProfileName(e.target.files[0].name);
    const formData = new FormData();
    formData.append('module_id', "1");
    formData.append('file', e.target.files[0]);
    formData.append('pathLocation', "PROFILE/");
    Axios({
      method: "POST",
      url: "admin/awsS3FileUpload",
      data: formData,
    })
      .then((response) => {
        setVar_ProfileImg(response.data.filepath.Location);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  //File upload methods end

  //Get Profile Details List start
  const getDoctorProfile = () => {
    setLoading(true);
    let profileGetData = {
      doctorId: localStorage.getItem("Doctor_id"),
    };
    Axios({
      method: "POST",
      url: "doctor/getDoctorDetails",
      data: profileGetData,
    })
      .then((response) => {
        setVar_Profiledata(response.data.data);
        setVar_ProfileImg(response.data.data[0].vendor_profile_path);
        setVar_EmailData(response.data.data[0].email);
        setVar_WebsiteData(response.data.data[0].website);
        setVar_MobileData(response.data.data[0].mobile);
        setVar_AddressData(response.data.data[0].address);
        setVar_NationalityData(response.data.data[0].nationality);
        setVar_CliniclistData(response.data.data[0].clinic_list);
        setVar_PracticeSinceData(response.data.data[0].practiceSince);
        setVar_SelfDescription(response.data.data[0].selfDescription);
        setVendorID(response.data.data[0].doctorId);
        setvendorTypeID(response.data.data[0].vendor);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  //Get Profile Details List end

  //Edit Profile Details List start
  const editDoctorProfile = () => {
    setLoading(true);
    let profileEditData = {
      doctorId: localStorage.getItem("Doctor_id"),
      practiceSince: Var_PracticeSinceData_Ref.current,
      mobile: Var_MobileData_Ref.current,
      email: Var_EmailData_Ref.current,
      address: Var_AddressData_Ref.current,
      file_name: Var_ProfileName_Ref.current,
      file_path: Var_ProfileImg_Ref.current.split('?')[0],
      selfDescription: Var_SelfDescription_Ref.current,
      qualification: this.common.doctorDetails.qualification,
      nationalityId: this.common.doctorDetails.nationality_id,
      website: Var_WebsiteData_Ref.current,
    };
    Axios({
      method: "PUT",
      url: "doctor/editDoctorDetails",
      data: profileEditData,
    })
      .then((response) => {
        setVar_ShowImage(!Var_ShowImage);
        setVar_IsShowSuccess(!Var_IsShowSuccess);
        setVar_ProfileSuccessfully(response.data.msg);
        getDoctorProfile();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  //Edit Profile Details List end
  async function getEncryptedData(id, vendorTypeID) {
    try {
      const response = await axios.post("admin/decryptedContent_data", {
        id: id,
        vendor_type: vendorTypeID,
      });
      setcontract_eny_data(response?.data?.data.UpdateVendor)
      return response;
    } catch (error) {
      if (!error.response) {
      } else {
        // toast(error);
      }
    }
  }
  const Decrypted_Editor_content = (encryptedContent) => {
    const fkey = CryptoJS.enc.Hex.parse(
      "00112233445566778899aabbccddeeff00112233445566778899aabbccddeeff"
    );
    try {
      const fiv = CryptoJS.enc.Hex.parse("00112233445566778899aabbccddeeff");
      // Decrypt content
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedContent, fkey, {
        iv: fiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      const decryptedContent = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return decryptedContent;
    } catch (error) {
      return error;
    }
  };
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0"); // Get day and pad with zero if needed
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()]; // Get month name from array
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year

    return `${day}-${month}-${year}`; // Format the date
  };
  const pdfgenerate = async () => {
    try {
 
      const getEncypted = await getEncryptedData(vendorID, 'Doctor');
      if (Object.keys(getEncypted?.data.data).length == 0 ) {
        return;
      }
      // Convert decrypted content to PDFMake format
      const decryptedContent =
        contract_orSignatureRef.current === 0
          ? getEncypted.data.data.contract_pdf_eData
          : 
            getEncypted.data.data.contract_pdf_eData;
      const content = htmlToPdfmake(Decrypted_Editor_content(decryptedContent));
  
      if (!content) {
        throw new Error("HTML content is null or undefined");
      }
      const documentDefinition = {
        content: [

          ...content,
     
        ],
        styles: {
          header: {
            fontSize: 12,
            bold: true,
            margin: [40, 10, 40, 10],
          },
          footer: {
            fontSize: 10,
            margin: [40, 0, 40, 0],
          },
          main: {
            margin: [40, 20, 40, 20],
          },
 
        },
        header: function (currentPage, pageCount, pageSize) {
          // Only display header on the first page
          if (currentPage != 1) {
            // Header content - a function can be used to dynamically create header content
            const modified_on =
              getEncypted != false && getEncypted?.data.data.modified_on
                ? new Date(getEncypted?.data.data.modified_on)
                : new Date();

            return {
              text: `Published Date :${formatDate(modified_on)}`,
              alignment: "left",
              style: "header",
              color: "#510F30",
              margin: [30, 40, 0, 0],
            };
          } else {
            return{
              text: '',
              margin: [30, 10, 0, 0],
            }
            // return null; // Return null to exclude the header on subsequent pages or when adminCrtRef.current is true
          }
        },
        footer: function (currentPage, pageCount, pageSize) {
          // Footer content - a function can be used to dynamically create footer content
          const imageBlob = getEncypted?.data.data.profile_imageBlob;
          const signature_image = getEncypted != false && getEncypted?.data.data.convertblob;
          const vendorName = contract_eny_dataRef.current;
          const modified_on = getEncypted?.data.data.modified_on
            ? new Date(getEncypted?.data.data.modified_on)
            : new Date();

            return {
              margin: [40, 40, 40, 40],
              columns: [
                {
                  stack: [
                    {
                      text: `Service Provider Signature`,
                      alignment: "left",
                      style: "footer",
                      color: "#510F30",
                    },
                    {
                      table: {
                        body: [
                          [
                            {
                              image: imageBlob,
                              width: 50,
                              height: 50,
                              margin: [0, 10, 10, 0],
                            },
                            {
                              text:  localStorage.getItem("Doctor_name"),
                              alignment: 'left',
                              color: '#510F30',
                        
                              margin: [0, 20, 10, 0],
                            },
                            [
                              {
                                image: signature_image,
                                width: 60,
                                height: 60,
                                alignment: 'left',
                          
                              },
                              {
                                text: formatDate(modified_on),
                                alignment: 'left',
                                color: '#510F30',
                         
                              
                              }
                            ]
  
                          ]
                        ]
                      },
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function (i, node) {
                          return 4;
                        },
                        paddingRight: function (i, node) {
                          return 4;
                        },
                        paddingTop: function (i, node) {
                          return 4;
                        },
                        paddingBottom: function (i, node) {
                          return 4;
                        },
        
                      }
                    },
                    {
                      text: `Page ${currentPage}/${pageCount}`,
                      alignment: "right",
                      color: "#510F30",
                      style: "footerText",
                    },
                  ],
                },
              ],
            };

        },
        styles: {
          footerText: {
            fontSize: 10,
          },
          footer: {
            fontSize: 10,
            bold: true,
          },
        },
        pageMargins: [40, 70, 40, 180], // Page margins (left, top, right, bottom)
      };

 
      pdfMake.createPdf(documentDefinition).open();
       // Create the PDF and get it as a Blob
  
     
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {loading && (
        <LinearProgress
          color="secondary"
          className="progressBar"
          sx={{ zIndex: "9999" }}
        />
      )}
      {/* ================= Profile sub header start ================= */}
      <div className="sub_header">
        <div className="sub_header_body">
          <div className="back_navigation" onClick={() => handleOnClick("/")}>
            <i className="fa fa-angle-left" aria-hidden="true"></i>
            <span>PROFILE</span>
          </div>
          <div className="flex_grow"></div>
          <div className="header_right_content">
            <h5
              onClick={() => setVar_ShowResults(!Var_ShowResults)}
              className="doctor_profile"
            >
              {Var_ShowResults ? (
                <p className="profile_edit" onClick={() => uploadImage()}>
                  Edit
                </p>
              ) : (
                <p className="profile_edit" onClick={() => editDoctorProfile()}>
                  Save
                </p>
              )}
            </h5>
          </div>
        </div>
      </div>
      {/* ================= Profile sub header end ================= */}

      {/* ================= Profile details View  start ================= */}
      <div className="content_view profileBlock">
        {/* ================= Profile details start ================= */}
        <div>
          {Var_Profiledata?.map((responseData, i) => (
            <Grid container key={i}>
              <Grid
                item
                xs={12}
                md={5}
                lg={5}
                className="profile_pic_container"
              >
                <img
                  src={
                    Var_ProfileImg_Ref.current != ""
                      ? Var_ProfileImg_Ref.current
                      : vector
                  }
                  className="profile_pic"
              alt=""  />
                <span hidden={!Var_ShowImage}>
                  <i className="fa fa-plus-circle" aria-hidden="true">
                    <input
                      type="file"
                      className="profile_upload"
                      onChange={(e) => uploadDocument(e)}
                      sx={{ minWidth: "-webkit-fill-available" }}
                    />
                  </i>
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
                lg={7}
                className="profile_fields_container"
              >
                <Grid container>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={11}>
                    <h5 className="profile_doctor_name">
                      {responseData.doctorName}
                    </h5>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={1} className="profile_sub_header">
                    <img src={EmailIcon} className="profile_sub_img" alt=""></img>
                  </Grid>
                  <Grid item xs={5}>
                    <InputLabel className="input_label">Email</InputLabel>
                    <TextField
                      className="profile_input"
                      defaultValue={responseData.email}
                      InputProps={{
                        readOnly: Var_ShowResults,
                      }}
                      variant="standard"
                      onChange={(e) => setVar_EmailData(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel className="input_label">Clinic</InputLabel>
                    <div className="clinic_text">
                      {responseData.clinic_list
                        .split(",")
                        ?.map((clinic, index) => (
                          <p key={index}>{clinic}</p>
                        ))}
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={1} className="profile_sub_header">
                    <img src={WebSiteIcom} className="profile_sub_img" alt=""></img>
                  </Grid>
                  <Grid item xs={5}>
                    <InputLabel className="input_label">Website</InputLabel>
                    <TextField
                      className="profile_input"
                      defaultValue={responseData.website}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="standard"
                      onChange={(e) => setVar_WebsiteData(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel className="input_label">Pharmacy</InputLabel>
                    <div className="pharmacytext">
                      {JSON.parse(responseData.pharma_list)?.map(
                        (pharmacy, index) => (
                          <p key={index}>{pharmacy.clinic}</p>
                        )
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={1} className="profile_sub_header">
                    <img src={PhoneIcon} className="profile_sub_img" alt=""></img>
                  </Grid>
                  <Grid item xs={5}>
                    <InputLabel className="input_label">Mobile</InputLabel>
                    <TextField
                      className="profile_input"
                      defaultValue={responseData.mobile}
                      inputProps={{
                        maxLength: 10,
                        readOnly: true,
                      }}
                      variant="standard"
                      onChange={(e) => setVar_MobileData(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel className="input_label">Specialty</InputLabel>
                    <div className="specialtytext">
                      {JSON.parse(responseData.speciality)?.map(
                        (item, index) => (
                          <p key={index}>{item.speciality}</p>
                        )
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={1} className="profile_sub_header">
                    <img src={LoactionIcon} className="profile_sub_img" alt=""></img>
                  </Grid>
                  <Grid item xs={5}>
                    <InputLabel className="input_label">Address</InputLabel>
                    <TextField
                      className="profile_input"
                      defaultValue={responseData.address}
                      InputProps={{
                        readOnly: Var_ShowResults,
                      }}
                      variant="standard"
                      onChange={(e) => setVar_AddressData(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel className="input_label">
                      Self Description
                    </InputLabel>
                    {/* <TextField
                      style={{ paddingLeft: "0px !important" }}
                      className="profile_input"
                      defaultValue={responseData.selfDescription}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="standard"
                      onChange={(e) => setVar_SelfDescription(e.target.value)}
                    /> */}
                    <label>
                    {responseData.selfDescription}
                    </label>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={1} className="profile_sub_header">
                    <img
                      src={NationalityIcon}
                      className="profile_sub_img"
                      alt=""  ></img>
                  </Grid>
                  <Grid item xs={11}>
                    <InputLabel className="input_label">Nationality</InputLabel>
                    <TextField
                      className="profile_input"
                      defaultValue={responseData.nationality}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="standard"
                      onChange={(e) => setVar_NationalityData(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={11} className="">
                  <div className="">
                    <InputLabel className="input_label terms_Condition" onClick={() => {
                      pdfgenerate();
                    }}
                      style={{ cursor: 'pointer', marginLeft: '68px', color: '#F49C7A', textDecoration: 'underline', }}
                    >Service Provider Terms & Conditions</InputLabel>

                  </div>
                
                </Grid>
                
              </Grid>
            </Grid>
          ))}
        </div>
        {/* ================= Profile details end ================= */}

        {/* ======================== Profile Success Model start======================================= */}
        <Dialog
          fullWidth={true}
          open={Var_IsShowSuccess}
          onClose={setVar_IsShowSuccess}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={successModelTickImg}alt="" />
              </div>
              <div className="title">success!</div>
              <div className="content">{Var_ProfileSuccessfully}</div>
              <div className="segment_center">
                <button
                  className="botton_Cart_Create"
                  onClick={() => isCloseProfileSuccessModel()}
                >
                  OK
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/* ======================== Profile Success Model end======================================= */}
      </div>
      {/* ================= Profile details View  end ================= */}

      {/* ================= Profile footer start ================= */}
      <div className="footer">
        <FooterMaster />
      </div>
      {/* ================= Profile footer end ================= */}
    </div>
  );
}
export default ProfileComponent;
