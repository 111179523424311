import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { requestForToken, onMessageListener } from './firebase';
import logo from "../images/Logo.svg"
// import "./Notification.css";
const Notification = () => {
  debugger;
  const [notification, setNotification] = useState({ title: '', body: '' });
  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      // <div className='notification_container'>
      //   <div className='logo'>
      //     <img src={logo} className='img' />
      //   </div>
      //   <div className='content'>
      //     <p><b>{notification?.title}</b></p>
      //     <p>{notification?.body}</p>
      //   </div>
      // </div>
      <div class="notification_container">
        <div class="notification_img_container">
          <img src={logo} className='notification_img' />
        </div>
        <div class="notification_text_container">
          <p class="notification_text_head"><b>{notification?.title}</b></p>
          <p class="notification_text_body">{notification?.body}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    debugger;
    if (notification?.title) {
      notify()
    }
  }, [notification])

  requestForToken();

  onMessageListener()
    .then((payload) => {
      debugger;
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <Toaster />
  )
}

export default Notification
