import './ReplaySupport.css'

const ReplaySupportComponent = () => {
    return (
        <div>
            <p>ReplaySupportComponent</p>
        </div >
    )
}
export default ReplaySupportComponent;





