import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useStateRef from "react-usestateref";
import moment from "moment";
import LinearProgress from '@mui/material/LinearProgress';


// Mui meterial component import
import { InputLabel, FormControlLabel, Select, MenuItem, Container, Card, Grid, TextField, Button, FormControl, DialogContent, Dialog, Radio } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
// import { Document, Page, pdfjs } from "react-pdf";

import './Report.css';
import FooterMaster from '../Dashboard/FooterMaster'
import xeray from '../../assets/icon/xeray.png'
import vector from '../../assets/Doctor_web_Icon/Vector.png'



const ReportViewComponent = () => {

    let Var_History = useHistory();
    let { Id } = useParams();
    let { Time } = useParams();
    let { items } = useParams();
    var [Var_PatientReport, setVar_PatientReport, Var_PatientReport_Ref] = useStateRef([]);
    var [Var_PatientReportLink, setVar_PatientReportLink, Var_PatientReportLink_Ref] = useStateRef("");
    var [Var_PatientReportName, setVar_PatientReportName, Var_PatientReportName_Ref] = useStateRef("");
    var [Var_PatientReportDate, setVar_PatientReportDate, Var_PatientReportDate_Ref] = useStateRef("");
    const [loading, setLoading] = useState(true);
    var [Var_prescriptionDetails, setVar_prescriptionDetails] = useStateRef([]);
    const [Var_pdfFile, setVar_pdfFile] = useStateRef(null);
    const [Var_viewpdfFile, setVar_viewpdfFile] = useStateRef(null);




    const navigateTo = (path) => { Var_History.push("/dashboard/appointments" + path) };
    // initial function call start
    useEffect(() => {
        typeCheck()
    }, []);
    // initial function call End

    const typeCheck = () => {

        if (items == 3) {
            reportsByClinic()
        } else if (items == 2) {
            patientParticularReport()
        } else if (items == 1) {
            getPrescriptionReport()
        }

    }
    // =====================praveen=====

    const check = () => {

        setVar_viewpdfFile(Var_PatientReportLink_Ref.current)




    }









    // ========================praveen

    // Reports By Clinic Start
    const reportsByClinic = () => {
        debugger;
        setLoading(true);
        var data = {
            frmreq: "doctor",
            patientId: Id,
            doctorId: localStorage.getItem("Doctor_id"),
            clinicId: localStorage.getItem("Clinic_id"),
            dateBasedSorting: true,
            dateBasedSortingOrder: 'DESC',
            limit: 100,
            pageno: 1
        }
        axios
            .post("clinic/getReportsByClinic", data)
            .then((response) => {

                console.log("Reports By Clinic ", response.data.data[0].details)
                // setVar_ReportsByClinic(response.data.data)
                var filtereddata = response.data.data[0].details.filter(x => x.patient_id == Id && x.id == Time);
                console.log("Report View", filtereddata[0])
                setVar_PatientReportLink(filtereddata[0].report_filepath)
                setVar_PatientReportName(filtereddata[0].report_name)
                setVar_PatientReportDate(moment(filtereddata[0].uploaded_date).format("DD-MMM-YY"))
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
        // setisOpenSuccessModel(!isOpenSuccessModel)
    }
    // Reports By Clinic End

    // Get Patient Particular Report start
    const patientParticularReport = () => {

        setLoading(true);
        var data = {
            book_date: localStorage.getItem("Pr_Date"),
            clinic_id: Number(localStorage.getItem("Clinic_id")),
            doctor_id: Number(localStorage.getItem("Doctor_id")),
            patient_id: Number(Id),
        }
        axios
            .post("doctor/getPatientParticularReport", data)
            .then((response) => {

                console.log("Patient Particular Report", response.data.data[0])
                setVar_PatientReport(response.data.data[0].patient_report.filter(x => x.test_time == Time))
                // setVar_PatientInformation(response.data.data[0].patient_info)
                setVar_PatientReportLink(Var_PatientReport_Ref.current[0].test_result)
                setVar_PatientReportDate(moment(Var_PatientReport_Ref.current[0].test_date).format("DD-MMM-YY"))
                setVar_PatientReportName(Var_PatientReport_Ref.current[0].test_name)

                console.log("perticular data", response.data.data[0].patient_report.filter(x => x.test_time == Time))
                console.log("perticular data list", Var_PatientReport_Ref.current[0].test_result)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
        // setisOpenSuccessModel(!isOpenSuccessModel)
    }
    const changeDate = (data) => {
        const inputDate = data;
        const parts = inputDate.split("-");
        const year = parts[0].substring(2);
        const month = new Date(inputDate).toLocaleString('default', { month: 'short' });
        const day = parts[2];
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate
    }
    const getPrescriptionReport = () => {

        setLoading(true);
        var data = {
            doctorId: localStorage.getItem("Doctor_id"),
            patientId: localStorage.getItem("patient_Id")
        }
        axios
            .post("doctor/getReportPrescriptionImage", data)
            .then((response) => {
                setVar_prescriptionDetails(response.data.data)
                var filteredData = response.data.data.filter(x => x.prescript_date == localStorage.getItem("Prescription_Date"))
                console.log("Prescription_Date", filteredData[0])
                setVar_PatientReportName(filteredData[0].prescript_file_name)
                setVar_PatientReportDate(moment(filteredData[0].prescript_date).format("YYYY-MM-DD"))
                setVar_PatientReportDate(changeDate(Var_PatientReportDate_Ref.current))
                setVar_PatientReportLink(filteredData[0].prescript_file_path)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }

    const navigateToReport = (data) => {

        var patientId = localStorage.getItem("patient_Id")
        var bookingId = localStorage.getItem("booking_Id")
        var date = localStorage.getItem("Report_Date")
        navigateTo("/report/" + patientId + "/" + bookingId + "/" + date);
    }
    // Get Patient Particular Report End
    // moment(formattedDate).format("DD-MMM-YY")

    return (
        <div>
            {/* {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            } */}
            {loading &&
                <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
            }
            <div>
                <div>
                    {/* ================= Report sub header start ================= */}
                    <div className="sub_header">
                        <div className="sub_header_body">
                            <div className="back_navigation" onClick={() => navigateToReport()}>
                                <i className="fa fa-angle-left" aria-hidden="true"></i>
                                <span>REPORT</span>
                            </div>
                            <div className="flex_grow"></div>
                        </div>
                    </div>
                    {/* ================= Report sub header End ================= */}

                    <div className='content_view'>

                        <Container>
                            <Grid container className='Historttitle'>
                                <Grid item xs={6} align='end'>
                                    <p className='Reporttitle'>{Var_PatientReportName_Ref.current}</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p className='Reporttime'>{Var_PatientReportDate_Ref.current}</p>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} align='center'>
                                    <div className='report_holder'>
                                        {Var_PatientReportLink_Ref.current.includes('mp4') &&
                                            <video src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} className="Reportimgs" controls autoplay />
                                        }
                                        {Var_PatientReportLink_Ref.current.includes('png') &&
                                            <img src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} className="Reportimgs" />
                                        }
                                        {Var_PatientReportLink_Ref.current.includes ('jpg') &&
                                            <img src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} className="Reportimgs" />
                                        }
                                        {Var_PatientReportLink_Ref.current.includes('jpeg') &&
                                            <img src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} className="Reportimgs" />
                                        }
                                        {Var_PatientReportLink_Ref.current.includes('svg') &&
                                            <img src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} className="Reportimgs" />
                                        }

                                        {Var_PatientReportLink_Ref.current.includes('pdf') &&
                                            // <img src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} className="Reportimgs" />
                                            // <a href={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector}>Visit W3Schools.com!</a>
                                            // <iframe src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} ></iframe>
                                            <embed src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} type="application/pdf" height={800} width={500} />
                                            //    <div className="pdf-container">
                                            //             <Document file={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} >
                                            //                 <Page pageNumber={1} />
                                            //             </Document>
                                            //         </div>
                                        }

                                        {/* <img src={Var_PatientReportLink_Ref.current} className='Reportimgs'></img>
                                        <iframe src={Var_PatientReportLink_Ref.current} className='Reportimgs'></iframe> */}
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </div>
                <div className="layout_footer footer">
                    <FooterMaster />
                </div>
            </div>
        </div>
    )
}
export default ReportViewComponent;