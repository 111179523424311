// AUTHOR : Dinesh
// CR-DATE: 28-June-2023
// MOD-DATE: 19-July-2023
// DESCRIPTION: Reset Password Update work

import React, { Component, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import { notification, Spin } from "antd";
import LinearProgress from '@mui/material/LinearProgress';
import useStateRef from "react-usestateref";

// Mui meterial component import
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Visibility, VisibilityOff } from "@material-ui/icons";


import "./Login.css";

import HomeImage from "../../assets/Images/log_im.svg";
import Eye from "../../images/eye.svg";
import Logo from "../../assets/Doctor_Ave/Avelator_Logo.png";
import tombutton from "../../assets/Images/tom_journey.svg";

function ResetPasswordComponent() {
    let Var_History = useHistory();

    const [Var_Email, setVar_Email] = useState("");
    const [Var_Password, setVar_Password, Var_Password_Ref] = useStateRef("");
    const [Var_UserId, setVar_UserId] = useState("");
    const [Var_UuId, setVar_UuId, Var_UuId_Ref] = useStateRef("");
    const [Var_ConfirmPassword, setVar_ConfirmPassword, Var_ConfirmPassword_Ref] = useStateRef("");
    const [Var_ConfirmPasswordError, setVar_ConfirmPasswordError] = useState(false);
    const [Var_PasswordError, setVar_PasswordError] = useState(false);
    const [Var_PasswordMatchError, setVar_PasswordMatchError] = useState(false);
    const [Var_Hidden, setVar_Hidden] = useState(true);
    const [Var_HiddenPassword, setVar_HiddenPassword] = useState(true);
    const [loading, setLoading] = useState(false);
    const [Uuid_Load, setUuid_Load] = useState(false);

    // initial function call start
    useEffect(() => {
        setVar_UuId(window.location.href.split("?")[1].split("/")[2]);
        if (Var_UuId_Ref.current && Uuid_Load == false) {
            validateUuid();
            setUuid_Load(true);
            console.log("Uuid_Load");
        }
    })
    // initial function call end

    // navigation method start
    const resetPassword = () => {
        Var_History.push("/forgot");
    };
    const navigateLogin = () => {
        Var_History.push("/");
    };
    // navigation method send

    //reset password validation method start
    const toggleshow = () => {
        setVar_Hidden(!Var_Hidden);
    }
    const toggleshowpassword = () => {
        setVar_HiddenPassword(!Var_HiddenPassword);
    }
    const iconStyle = {
        fontSize: '26px', // Change the size
        color: '#707070', // Change the color
      };
    const validateUuid = () => {
        try {
            setLoading(true);
            const host = window.location.host;
            const pathName = window.location.pathname.split("/")[1];
            // const uuId = window.location.href.split("?")[1].split("/")[2];
            const dataObj = {
                uuid: Var_UuId_Ref.current,
                host,
                pathname: pathName,
            };

            Axios({
                method: "POST",
                url: "admin/validate_uuid",
                data: dataObj,
            })
                .then((response) => {
                    if (response.data.status === 1) {
                        setVar_Email(response.data.data[0].email);
                        setVar_UserId(response.data.data[0].user_id);
                        setLoading(false);
                    } else {
                        notification.error({
                            message: response.data.msg,
                        });
                        this.resetPassword();
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (err) {
            setLoading(false);
            console.log("error", err);
        }
    };
    const checkValidation = () => {
        if (!Var_Password_Ref.current) {
            setVar_PasswordError(true);
        } else if (!Var_ConfirmPassword_Ref.current) {
            setVar_PasswordError(false);
            setVar_ConfirmPasswordError(true);
        } else if (Var_Password_Ref.current !== Var_ConfirmPassword_Ref.current) {
            setVar_PasswordError(false);
            setVar_ConfirmPasswordError(false);
            setVar_PasswordMatchError(true);
        } else {
            setVar_PasswordError(false);
            setVar_ConfirmPasswordError(false);
            setVar_PasswordMatchError(false);
            submitPassword();
        }
    }
    //reset password validation method end

    //reset password method start
    const submitPassword = () => {
        try {
            setLoading(true);
            const host = window.location.host;
            const pathName = window.location.pathname.split("/")[1];
            const dataObj = {
                email: Var_Email,
                uuid: Var_UuId_Ref.current,
                password: Var_Password_Ref.current,
                user_id: Var_UserId,
                host,
                pathname: pathName,
            };

            Axios({
                method: "POST",
                url: "admin/check_nd_reset_password",
                data: dataObj,
            })
                .then((response) => {
                    if (response.data.status === 1) {
                        notification.success({
                            message: response.data.msg,
                        });
                        navigateLogin();
                        setLoading(false);
                    } else {
                        notification.error({
                            message: response.data.msg,
                        });
                        resetPassword();
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                });
        } catch (e) {
            console.log("Reset Password Submit Error: ", e);
        }
    }
    //reset password method end

    return (
        <div>
            {/* {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            } */}
            {loading &&
                <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
            }
            {/* ================= Reset Password page start ================= */}
            <div className="pharmacy_login_container">
                <Grid container>
                    {/* ================= Reset Password Doctor Image start ================= */}
                    <Grid item xs={12} md={6} className="pharmacy_image_grid">
            <div className="pharmacy_image_container">
              <div className="pharmacy_image_div">
                <div className="pharmacy_image_login">
                  <div>
                    <div className="pharmacy_image">
                      <div className="lab_login_text">Transformational way in <br />how we connect you with <br />patients & medical professionals!
                      {/* <button className="tom_Journey">
                          <img style={{ height: '15px' }} src={tombutton} ></img> ’S Journey
                        </button> */}
                      </div>
                      {/* <div>
                      
                    </div>
                      <div className="numweb_contee">
                        <img className="login_new_lady_img" src={HomeImage} />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
                    {/* ================= Reset Password Doctor Image end ================= */}


                    {/* ================= Reset Password Input Fields start ================= */}
                    <Grid item xs={12} md={6} className="pharmacy_grid_container">
                        <div className="pharmacy_main_container">
                            <div className="pharmacy_paper_div">
                                <div className="pharmacy_text_container">
                                    <div className="logo_container">
                                        <div className="logo_div">
                                            <img className="logo_image" src={Logo} />
                                        </div>
                                    </div>
                                    <div className="pharmacy_Welcometext-container">
                                        <p className="Welcometext">RESET PASSWORD</p>
                                    </div>

                                    <div className="password_container">
                                        <TextField
                                            type={Var_HiddenPassword ? "password" : "text"}
                                            placeholder="Enter Password"
                                            label="PASSWORD"
                                            name="password"
                                            autoComplete="true"
                                            value={Var_Password}
                                            onChange={(e) => setVar_Password(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment>
                                                        <IconButton>
                                                            {/* <img
                                                                className="logineye_icon"
                                                                alt="eye"
                                                                src={Eye} onClick={() => toggleshow()}
                                                            /> */}
                                                             {Var_HiddenPassword ? <VisibilityOff onClick={toggleshowpassword} style={iconStyle} /> : <Visibility onClick={toggleshowpassword} style={iconStyle} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: Var_PasswordError ? "block" : "none",
                                                color: "red",
                                                textAlign: "left",
                                                marginTop: "5px", fontWeight: "bolder",
                                            }} > Please Enter Password
                                        </div>
                                    </div>
                                    <div className="password_container">
                                        <TextField
                                            type={Var_Hidden ? "password" : "text"}
                                            placeholder="Enter Password"
                                            label="CONFIRM PASSWORD"
                                            name="confirmpassword"
                                            autoComplete="true"
                                            value={Var_ConfirmPassword}
                                            onChange={(e) => setVar_ConfirmPassword(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment>
                                                        <IconButton>
                                                            {/* <img
                                                                className="logineye_icon"
                                                                alt="eye"
                                                                src={Eye} onClick={() => toggleshow()}
                                                            /> */}
                                                             {Var_Hidden ? <VisibilityOff onClick={toggleshow} style={iconStyle} /> : <Visibility onClick={toggleshow} style={iconStyle} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: Var_ConfirmPasswordError ? "block" : "none",
                                                color: "red",
                                                textAlign: "left",
                                                marginTop: "5px", fontWeight: "bolder",
                                            }}>
                                            Please Enter Confirm Password
                                        </div>
                                        {Var_PasswordMatchError && <div
                                            style={{
                                                display: Var_PasswordMatchError ? "block" : "none",
                                                color: "red",
                                                textAlign: "left",
                                                marginTop: "5px", fontWeight: "bolder",
                                            }}>
                                            Invalid Confirm Password
                                        </div>}
                                    </div>
                                    <div className="login_button_container">
                                        <button
                                            className="login"
                                            component={NavLink}
                                            to="/ResetPassword"
                                            onClick={() => checkValidation()}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                    <div className="cancel_container">
                                        <a component={NavLink} href="/" className="cancelbutton">
                                            Cancel
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    {/* ================= Reset Password Input Fields end ================= */}
                </Grid>
            </div>
            {/* ================= Reset Password page end ================= */}
        </div>
    )
}
export default ResetPasswordComponent;