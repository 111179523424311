import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import useStateRef from 'react-usestateref';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Advance from "../../../assets/Web_Doctor_Icon_new_theme/paidadv.svg";
import Fasttrack from "../../../assets/Web_Doctor_Icon_new_theme/fast_track.svg";
import Walkin from "../../../assets/Web_Doctor_Icon_new_theme/Walking.svg";
import NoInsurance from "../../../assets/Web_Doctor_Icon_new_theme/paidClinic.svg";
import FullCoverage from "../../../assets/Web_Doctor_Icon_new_theme/FullCover.svg";
import Partail from "../../../assets/Web_Doctor_Icon_new_theme/partailin.svg";
import Refferal from "../../../assets/Web_Doctor_Icon_new_theme/Refferal.svg";
import "./RevenueCards.css";
import Axios from "axios";
import dateFormat from "dateformat";
import Tomlogo from "../../../assets/Web_Doctor_Icon_new_theme/TOM_logo.svg";
import Datedata from "../mothpicker/Datedata";

export default function MediaCard(props) {
  const { data } = props;
  debugger
  const [Cardvalue, setCardvalue, CardvalueRef] = useStateRef("");
  const [isVendor, setisVendor, isVendor_Ref] = useStateRef(false);
  const [tempdata, settempdata, tempdata_Ref] = useStateRef("");
  const [VendorType, setVendorType, VendorType_Ref] = useStateRef("");
  const [var_startDate, setvar_startDate, var_startDate_ref] = useStateRef("");
  const [var_endDate, setvar_endDate, var_endDate_ref] = useStateRef("");

  const crdactive = (data) => {
    debugger
    if (data) {
      setVendorType(String(data)); tempdata_Ref.current === data ? settempdata("") : settempdata(data);
      if (tempdata_Ref.current === "") {
        setisVendor(false); props.setvendor(false);
      } else { setisVendor(true); props.setvendor(true); fetchdata(data); }
    }
  };

  useEffect(() => {
    fetchdata();
  }, [var_startDate_ref.current]);

  const fetchdata = (data) => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    debugger
    var data = {
      doctorId: localStorage.getItem("Doctor_id"),
      // fromDate: var_startDate_ref.current == ""? dateFormat(firstDay, "yyyy-mm-dd") : var_startDate_ref.current,
      // toDate: var_endDate_ref.current == "" ? dateFormat(date, "yyyy-mm-dd") : var_endDate_ref.current,
      fromDate: props.startDate,
      toDate: props.endDate,
      paymentMode: getId(data)
    }
    Axios({
      method: "POST",
      url: '/patient/getDocPaymentDtlMode',
      data: data
    }).then((response) => {
      debugger
      props.setdata(response.data.data);
    }).catch((err) => {

    })
  }

  const getId = (data) => {
    debugger

    if (data === "Paid Advance") {
      return 1;
    } else if (data === "Fasttrack") {
      return 2;
    } else if (data === "Walkin") {
      return 3;
    } else if (data === "PaidatClinic") {
      return 4;
    } else if (data === "FullCoverage") {
      return 5;
    } else if (data === "PartailCoverage") {
      return 6;
    } else if (data === "RefferalIncentive") {
      return 7;
    } else if (data === "Revenue") {
      return 8;
    } else if (data === "Tomshare") {
      return 9;
    } else if (data === "Deduction") {
      return 10;
    } else if (data === "Income") {
      return 11;
    }
  };



  return (
    <>
      <div className="d-flex flex-wrap " style={{ gap: "15px", padding: '2px 10px' }}>
        <div className="number_crd Paidcrd1"><span className="number_font">1</span>
          <Card className={` Paidcrd  ${isVendor_Ref.current ? (tempdata_Ref.current === "Paid Advance" ? "shadow_Class" : "overlay_Class") : ""}`} onClick={() => crdactive("Paid Advance")}>
            <CardContent className="crd_content">
              <Typography className="Crdhead" gutterBottom component="div">
                {/* Paid Advance <img className="Icons" src={Advance}></img> */}
                <div className="d-flex align-items-center">
                  <span className="titleLetter1">Paid Advance</span>
                  <img className="selectIcons ps-1" src={Advance} />
                </div>
              </Typography>
              <div className="container d-flex justify-content-center Secondary_font">
                <span style={{ color: "#fff", display: 'flex', alignItems: 'center', width: '100%', borderBottom: '1px solid rgba(255, 255, 255, 0.25)' }}><span style={{ display: 'block', width: '100%', marginBottom: '2px' }}>{Datedata.formatMoney(data.onlineAmount)}</span><span className="Amount_value">KWD</span></span>
              </div>
              <div className="container d-flex justify-content-center">
                <span style={{ color: "#fff", display: 'flex', alignItems: 'center', width: '100%' }}><span style={{ display: 'block', width: '100%' }}>{data.onlineCount}</span><span style={{ marginRight: '3px' }} className="Amount_value">TRX</span></span>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="number_crd Paidcrd1"><span className="number_font">2</span>
          <Card className={` Fastcrd   ${isVendor_Ref.current ? (tempdata_Ref.current === "Fasttrack" ? "shadow_Class" : "overlay_Class") : ""}`} onClick={() => crdactive("Fasttrack")}>
            <CardContent className="crd_content">
              <Typography className="Crdhead1" gutterBottom component="div">
                {/* Fast Track <img className="Icons" src={Fasttrack}></img> */}
                <div className="d-flex align-items-center">
                  <span className="titleLetters" style={{ fontSize: '16px' }}>Fast Track</span>
                  <img className="selectIcons ps-1" src={Fasttrack} />
                </div>
              </Typography>
              <div className="container d-flex justify-content-center Secondary_font1">
                <span style={{ color: "#510f30", display: 'flex', alignItems: 'center', width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.24)' }}><span style={{ display: 'block', width: '100%' }}>{Datedata.formatMoney(data.fastTrackAmount)}</span><span className="Amount_value">KWD</span></span>
              </div>
              <div className="container d-flex justify-content-center">
                <span style={{ color: "#510f30", display: 'flex', alignItems: 'center', width: '100%' }}><span style={{ display: 'block', width: '100%' }}>{data.fastTrackCount}</span><span style={{ marginRight: '3px' }} className="Amount_value">TRX</span></span>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="number_crd Paidcrd1"><span className="number_font">3</span>
          <Card className={` Walkcrd  ${isVendor_Ref.current ? (tempdata_Ref.current === "Walkin" ? "shadow_Class" : "overlay_Class") : ""}`} onClick={() => crdactive("Walkin")}>
            <CardContent className="crd_content">
              <Typography className="Crdhead" gutterBottom component="div">
                {/* <img className="Icons" src={Walkin}></img> */}
                <div className="d-flex align-items-center">
                  <span className="titleLetter" style={{ fontSize: '16px' }}>Walk - in</span>
                  <img className="selectIcons ps-1" src={FullCoverage} />
                </div>
              </Typography>
              <div className="container d-flex justify-content-center Secondary_font">
                <span style={{ display: 'flex', alignItems: 'center', width: '100%', borderBottom: '1px solid rgba(255, 255, 255, 0.25)' }}><span style={{ display: 'block', width: '100%', marginBottom: '2px' }}>{Datedata.formatMoney(data.walkInAmount)}</span><span className="Amount_value">KWD</span></span>
              </div>
              <div className="container d-flex justify-content-center">
                <span style={{ color: "#fff", display: 'flex', alignItems: 'center', width: '100%' }}><span style={{ display: 'block', width: '100%' }}>{data.walkInCount}</span><span style={{ marginRight: '3px' }} className="Amount_value">TRX</span></span>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="number_crd Paidcrd1"><span className="number_font">4</span>
          <Card className={` Insurancecrd   ${isVendor_Ref.current ? (tempdata_Ref.current === "PaidatClinic" ? "shadow_Class" : "overlay_Class") : ""}`} onClick={() => crdactive("PaidatClinic")}>
            <CardContent className="crd_content">
              <Typography
                className="Crdhead"
                gutterBottom
                component="div"
                style={{ display: "block", textAlign: "center" }}
              >

                {/* <div style={{ fontSize: "12px" }}>
                  <span style={{ fontSize: '1rem', display: 'flex', justifyContent: 'space-around' }} >Paid at Clinic  </span>
                  <span style={{ display: 'flex', justifyContent: 'center', gap: '5px', marginLeft: '11px', marginTop: '-3px' }}>(No Insurance)<img className="Icons" src={NoInsurance}></img></span>
                </div> */}
                <div className="w-100">
                  <p className="card-content">
                    <span className="supLetter">
                      Paid at Clinic <br />
                      <div className="d-flex align-items-center">
                        <span>(No Insurance)</span>
                        <img className="Icons" src={NoInsurance} />
                      </div>
                    </span>
                  </p>
                </div>
              </Typography>
              <div>
                <div className="container d-flex justify-content-center Secondary_font">
                  <span style={{ display: 'flex', alignItems: 'center', width: '100%', borderBottom: '1px solid rgba(255, 255, 255, 0.25)' }}>
                    <span style={{ display: 'block', width: '100%', marginBottom: '2px' }}>{Datedata.formatMoney(data.paidClinicAmount)}</span>
                    <span className="Amount_value">KWD</span></span>
                </div>
                <div className="container d-flex justify-content-center">
                  <span style={{ color: "#fff", display: 'flex', alignItems: 'center', width: '100%' }}><span style={{ display: 'block', width: '100%' }}>{data.paidClinicCount}</span><span style={{ marginRight: '3px' }} className="Amount_value">TRX</span></span>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="number_crd Paidcrd1 "><span className="number_font">5</span>
          <Card className={` FullCoveragecrd ${isVendor_Ref.current ? (tempdata_Ref.current === "FullCoverage" ? "shadow_Class" : "overlay_Class") : ""}`} onClick={() => crdactive("FullCoverage")}>
            <CardContent className="crd_content card_body">
              <Typography
                className="Crdhead"
                gutterBottom
                component="div"
                style={{ display: "block", textAlign: "center" }}
              >
                {/* <div style={{ fontSize: "12px" }}>
                  <span style={{ fontSize: '1rem' }}>Paid thru Insurance</span>
                  <p class="margin-0 W_color c_title text_center fnt_12" style={{ minHeight: '36px', alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                    <span class="supLetter" style={{ fontWeight: 500 }}>Paid thru Insurance <br /><span>(Partial Coverage)</span>
                      <img className="Icons" src={FullCoverage} />
                    </span>
                  </p>
                </div> */}
                <div className="w-100">
                  <p className="card-content">
                    <span className="supLetter">
                      Paid thru Insurance <br />
                      <div className="d-flex align-items-center">
                        <span>(Full Coverage)</span>
                        <img className="selectIcons" src={FullCoverage} />
                      </div>
                    </span>
                  </p>
                </div>
              </Typography>
              <div className="container d-flex justify-content-center Secondary_font">
                <span style={{ display: 'flex', alignItems: 'center', width: '100%', borderBottom: '1px solid rgba(255, 255, 255, 0.25)' }}><span style={{ display: 'block', width: '100%', marginBottom: '2px' }}>{Datedata.formatMoney(data.paidInsuranceFullAmount)}</span><span className="Amount_value">KWD</span></span>
              </div>
              <div className="container d-flex justify-content-center">
                <span style={{ color: "#fff", display: 'flex', alignItems: 'center', width: '100%' }}><span style={{ display: 'block', width: '100%' }}>{data.paidInsuranceFullCount}</span> <span style={{ marginRight: '3px' }} className="Amount_value">TRX</span></span>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="number_crd Paidcrd1"><span className="number_font">6</span>
          <Card className={` partailcrd  ${isVendor_Ref.current ? (tempdata_Ref.current === "PartailCoverage" ? "shadow_Class" : "overlay_Class") : ""}`} onClick={() => crdactive("PartailCoverage")}>
            <CardContent className="crd_content">
              <Typography
                className="Crdhead"
                gutterBottom
                component="div"
                style={{ display: "block", textAlign: "center" }}
              >
                {/* <div style={{ fontSize: "12px" }}>
                  <span style={{ fontSize: '1rem' }}>Paid thru Insurance</span>
                  <span style={{ display: 'flex', justifyContent: 'start', gap: '4px', marginLeft: '23px', marginTop: '-3px' }}>(Partial Coverage)<img src={Partail}></img></span>
                </div> */}
                <div className="w-100">
                  <p className="card-content">
                    <span className="supLetter">
                      Paid thru Insurance <br />
                      <div className="d-flex align-items-center">
                        <span>(Partial Coverage)</span><img src={Partail} />
                      </div>
                    </span>
                  </p>
                </div>
              </Typography>
              <div className="container d-flex justify-content-center Secondary_font">
                <span style={{ display: 'flex', alignItems: 'center', width: '100%', borderBottom: '1px solid rgba(255, 255, 255, 0.25)' }}><span style={{ display: 'block', width: '100%', marginBottom: '2px' }}>{Datedata.formatMoney(data.paidPartialInsuranceAmount)}</span> <span className="Amount_value">KWD</span></span>
              </div>
              <div className="container d-flex justify-content-center">
                <span style={{ color: "#fff", display: 'flex', alignItems: 'center', width: '100%' }}><span style={{ display: 'block', width: '100%' }}>{data.paidPartialInsuranceCount}</span> <span style={{ marginRight: '3px' }} className="Amount_value">TRX</span></span>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="number_crd Paidcrd1"><span className="number_font">7</span>
          <Card className={` Reffcrd ${isVendor_Ref.current ? (tempdata_Ref.current === "RefferalIncentive" ? "shadow_Class" : "overlay_Class") : ""}`} onClick={() => crdactive("RefferalIncentive")}>
            <CardContent className="crd_content">
              <Typography className="Crdhead" gutterBottom component="div">
                {/* Referral Incentive<img src={Refferal}></img> */}
                <div className="d-flex align-items-center">
                  <span className="titleReferral">Referral Incentive</span>
                  <img className="selectIcons ps-1" src={Refferal} />
                </div>
              </Typography>
              <div className="container d-flex justify-content-center Secondary_font">
                <span style={{ display: 'flex', alignItems: 'center', width: '100%', borderBottom: '1px solid rgba(255, 255, 255, 0.25)' }}><span style={{ display: 'block', width: '100%', marginBottom: '2px' }}>{Datedata.formatMoney(data.incentiveAmount)}</span> <span className="Amount_value">KWD</span></span>
              </div>
              <div className="container d-flex justify-content-center">
                <span style={{ color: "#fff", display: 'flex', alignItems: 'center', width: '100%' }}><span style={{ display: 'block', width: '100%' }}>{data.incentiveCount}</span> <span style={{ marginRight: '3px' }} className="Amount_value">TRX</span></span>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="number_crd Paidcrd1"> <span className="number_font">1 + 2 + 3 + 4 + 5 + 6 + 7</span>
          <Card className={` Revenuecrd  ${isVendor_Ref.current ? (tempdata_Ref.current === "Revenue" ? "shadow_Class" : "overlay_Class") : ""}`}>
            {/* <Card className={` Revenuecrd  ${isVendor_Ref.current ? (tempdata_Ref.current === "Revenue"? "shadow_Class": "overlay_Class"): ""}`}onClick={() => crdactive("Revenue")}> */}
            <CardContent className="crd_content">
              <Typography className="Crdhead1" gutterBottom component="div">
                {/* Revenue */}
                <div className="d-flex align-items-center">
                  <span className="titleLetters">Total Revenue</span>
                  {/* <img className="selectIcons ps-1" src={Fasttrack} /> */}
                </div>
              </Typography>
              <div className="container d-flex justify-content-center Secondary_font1">
                <span style={{ display: 'flex', alignItems: 'center', gap: '9px', width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.24)' }}><span style={{ display: 'block', width: '100%' }}>{Datedata.formatMoney(data.revenueAmount)}</span><span className="Amount_value">KWD</span></span>
              </div>
              <div className="container d-flex justify-content-center">
                <span style={{ color: "#510f30", display: 'flex', alignItems: 'center', width: '100%' }}><span style={{ display: 'block', width: '100%' }}>{data.revenueCount}</span> <span style={{ marginRight: '3px' }} className="Amount_value">TRX</span></span>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="number_crd Paidcrd1"><span className="number_font">8</span>
          <Card className={` Tomsharecrd  ${isVendor_Ref.current ? (tempdata_Ref.current === "Tomshare" ? "shadow_Class" : "overlay_Class") : ""}`}>
            {/* <Card className={` Tomsharecrd  ${isVendor_Ref.current ? (tempdata_Ref.current === "Tomshare"? "shadow_Class": "overlay_Class"): ""}`}onClick={() => crdactive("Tomshare")}> */}
            <CardContent className="crd_content">
              <Typography className="Crdhead11" gutterBottom component="div">
                {/* <img style={{ height: '15px', marginTop: '3px' }} src={Tomlogo} /> Share */}
                <div className="d-flex align-items-center">
                  <img style={{ height: '15px', marginTop: '3px' }} src={Tomlogo} />
                  <span className="titleLetters">Share</span>
                  {/* <img className="selectIcons ps-1" src={Fasttrack} /> */}
                </div>
              </Typography>
              <div className="container d-flex justify-content-center Secondary_font1">
                <span style={{ display: 'flex', alignItems: 'center', width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.24)' }}><span style={{ display: 'block', width: '100%' }}>{Datedata.formatMoney(data.tomShareAmount)}</span> <span className="Amount_value">KWD</span></span>
              </div>
              <div className="container d-flex justify-content-center">
                <span style={{ color: "#510f30", display: 'flex', alignItems: 'center', width: '100%' }}><span style={{ display: 'block', width: '100%' }}>{data.tomShareCount}</span> <span style={{ marginRight: '3px' }} className="Amount_value">TRX</span></span>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="number_crd Paidcrd1"><span className="number_font">9</span>
          <Card className={` Deductioncrd  ${isVendor_Ref.current ? (tempdata_Ref.current === "Deduction" ? "shadow_Class" : "overlay_Class") : ""}`}>
            {/* <Card className={` Deductioncrd  ${isVendor_Ref.current ? (tempdata_Ref.current === "Deduction"? "shadow_Class": "overlay_Class"): ""}`}onClick={() => crdactive("Deduction")}> */}
            <CardContent className="crd_content">
              <Typography
                className="Crdhead1"
                gutterBottom
                component="div"
                style={{
                  display: "block",
                  textAlign: "center",
                  fontSize: "1rem",
                }}
              >
                {/* Insurance Deduction */}
                <div className="d-flex align-items-center justify-content-center">
                  <span className="titleLetters">Insurance Deduction</span>
                  {/* <img className="selectIcons ps-1" src={Fasttrack} /> */}
                </div>
              </Typography>
              <div className="container d-flex justify-content-center Secondary_font1">
                <span style={{ display: 'flex', alignItems: 'center', width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.24)' }}><span style={{ display: 'block', width: '100%' }}>{Datedata.formatMoney(data.insuranceDeducAmount)}</span> <span className="Amount_value">KWD</span></span>
              </div>
              <div className="container d-flex justify-content-center">
                <span style={{ color: "#510f30", display: 'flex', alignItems: 'center', width: '100%' }}><span style={{ display: 'block', width: '100%' }}>{data.insuranceDeducCount}</span> <span style={{ marginRight: '3px' }} className="Amount_value">TRX</span></span>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="number_crd Paidcrd1"> <span className="number_font">1 + 2 + 3 + 4 + 5 + 6 + 7 - 8 - 9</span>
          {/* <Card className={` Fastcrd  ${isVendor_Ref.current ? (tempdata_Ref.current === "Income"? "shadow_Class": "overlay_Class"): ""}`}onClick={() => crdactive("Income")}> */}
          <Card className={` Fastcrd  ${isVendor_Ref.current ? (tempdata_Ref.current === "Income" ? "shadow_Class" : "overlay_Class") : ""}`}>
            <CardContent className="crd_content">
              <Typography className="Crdhead1" gutterBottom component="div">
                {/* Income */}
                <div className="d-flex align-items-center">
                  <span className="titleLetters">Income</span>
                  {/* <img className="selectIcons ps-1" src={Fasttrack} /> */}
                </div>
              </Typography>
              <div className="container d-flex justify-content-center Secondary_font1">
                <span style={{ display: 'flex', alignItems: 'center', gap: '9px', width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.24)' }}><span style={{ display: 'block', width: '100%' }}>{Datedata.formatMoney(data.doctorIncome)}</span> <span className="Amount_value">KWD</span></span>
              </div>
              <div className="container d-flex justify-content-center">
                <span style={{ color: "#510f30", display: 'flex', alignItems: 'center', width: '100%' }}><span style={{ display: 'block', width: '100%' }}>{data.doctorIncomeCount}</span> <span style={{ marginRight: '3px' }} className="Amount_value">TRX</span></span>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}
