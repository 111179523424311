import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useStateRef from "react-usestateref";
import Axios from "axios";
import { notification } from "antd";

// mui imports 
import { MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import "./EditService.css";
import IOSSwitch from "../../helpers/Switch/Switch";
import FooterMaster from '../Dashboard/FooterMaster';

import InputLabel from "@mui/material/InputLabel";
import uploadimage from "../../assets/icon/uploading-archive-1.svg";
import successModelTickImg from '../../assets/Web_Doctor_Icon_new_theme/Success.svg';
import alterImage from '../../../src/images/Vector.png';
import Alert from "../../assets/Web_Doctor_Icon_new_theme/alert.svg";

function EditService() {
    let { serviceId } = useParams();
    let Var_History = useHistory();

    const [, setVar_Speciality, Var_Speciality_Ref] = useStateRef([]);
    const [Var_SpecialityValueId, setVar_SpecialityValueId] = useStateRef("");

    const [, setVar_id, Var_id_Ref] = useStateRef(null);
    const [, setVar_DoctorId, Var_DoctorId_Ref] = useStateRef(null);
    const [Var_Service, setVar_Service, Var_Service_Ref] = useStateRef(null);
    const [Var_Cost, setVar_Cost, Var_Cost_Ref] = useStateRef(null);
    const [, setVar_SlotDuration, Var_SlotDuration_Ref] = useStateRef(null);
    const [Var_Description, setVar_Description, Var_Description_Ref] = useStateRef(null);
    const [, setVar_FilePath, Var_FilePath_Ref] = useStateRef(null);
    const [Var_FileName, setVar_FileName, ] = useStateRef(null);
    const [Var_IsActive, setVar_IsActive, ] = useStateRef(null);
    const [, setVar_SpecId, Var_SpecId_Ref] = useStateRef(null);
    const [, setVar_SpecName, Var_SpecName_Ref] = useStateRef(null);
    const [Var_ServiceImageName, setVar_ServiceImageName, ] = useStateRef(null);
    const [loading, setLoading] = useState(true);
    const [Var_fileprocess, setVar_fileprocess, ] = React.useState(false);
    const [Var_progress, setVar_Progress] = useState(0);
    const [isOpenServiceSuccessModel, setisOpenServiceSuccessModel] = useState(false);
    const [, setVar_PopupImg, setVar_PopupImg_Ref] = useStateRef("");
    const [, setVar_Popup_content, Var_Popup_content_Ref] = useStateRef("");
    const [, setVar_Popup_Message, Var_Popup_Message_Ref] = useStateRef("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDisable, setisisDisable] = useState(false);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    // navigate to back 
    const navigateToBack = (path) => {
        Var_History.push("/dashboard" + path)
    };

    // initial function call start
    useEffect(() => {
        getSpeciality();
        setisisDisable(false);
    }, [])
    // initial function call end


    const getManageServicesById = () => {

        setLoading(true);
        let Var_RequestData = {
            doctor_id: localStorage.getItem("Doctor_id") //serviceId
        };

        Axios({ method: "POST", url: "doctor/getDoctorService", data: Var_RequestData, }).then((response) => {

            let filtereddata = response.data.data.filter(x => x.id == serviceId);

            setVar_id(filtereddata[0].id);
            setVar_DoctorId(filtereddata[0].doctor_id);
            setVar_Service(filtereddata[0].service);
            setVar_Cost(filtereddata[0].cost);
            setVar_SlotDuration(filtereddata[0].slot_duration.slice(3, 5));
            setVar_Description(filtereddata[0].description);
            setVar_FilePath(filtereddata[0].file_path);
            setVar_FileName(filtereddata[0].file_name);
            setVar_ServiceImageName(filtereddata[0].file_name);
            setVar_IsActive(filtereddata[0].is_active);
            setVar_SpecId(filtereddata[0].specid);
            setLoading(false);
            Var_Speciality_Ref.current.map((item) => {
                if (item.specialityId == Var_SpecId_Ref.current) {
                    return (setVar_SpecId(item.specialityId), setVar_SpecName(item.speciality));
                }
            })
            console.log(Var_SpecId_Ref.current, "Sped_Id", Var_SpecName_Ref.current);
        })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    // onchange get form values start

    const getSpeciality = () => {
        setLoading(true);
        let data = {
            doctorId: localStorage.getItem("Doctor_id")
        };
        axios
            .post("doctor/getDoctorDetails", data).then((response) => {
                const doctorSpeciality = JSON.parse(response.data.data[0].speciality)
                setVar_Speciality(doctorSpeciality);
                console.log(doctorSpeciality, "doctorspeciality", Var_Speciality_Ref.current);
                getManageServicesById();
                setLoading(false);

            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    const getSpecialityValue = (event) => {
        setVar_SpecialityValueId(event.target.value);
        setVar_SpecId(event.target.value);
    }

    const getServiceValue = (event) => {
        setVar_Service(event.target.value);
    }

    const getFeeValue = (event) => {
        let numericonly = event.target.value.replace(/[^0-9.]/g, '');
        setVar_Cost(numericonly);
    }
    const getDescriptionValue = (event) => {
        setVar_Description(event.target.value);
    }
    const getServiceImage = (event) => {

        setVar_ServiceImageName(event.target.files[0].name);
        setVar_FileName(event.target.files[0].name);
        fileupload(event)
    }
    const getActiveSwitchValue = (event) => {
        setVar_IsActive(event.target.checked == true ? 1 : 0)
    }


    const fileupload = (e) => {
        setisisDisable(true);
        setVar_FilePath('')
        setVar_fileprocess(true)
        setVar_Progress(0)
        const formData = new FormData();
        formData.append('module_id', "1");
        formData.append('file',e.target.files[0]);
        formData.append('pathLocation', "MANAGE-SERVICE/");
        axios({
            method: "POST",
            url: "admin/awsS3FileUpload",
            data: formData,
            onUploadProgress: (progressEvent) => {
                const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setVar_Progress(percentage);
            },
        })
            .then((response) => {
                setisisDisable(false);
                setVar_FilePath(response.data.filepath.Location);
                setLoading(false);
            })
            .catch((error) => {
                setisisDisable(false);
                console.log(error);
                setLoading(false);
                setVar_fileprocess(false);


            });
    }
    // onchange get form values end

    // Update service API call start
    const UpdateService = () => {
        if (Var_Service_Ref.current != "" && Var_Cost_Ref.current != "" && Var_ServiceImageName != "" && Var_Description != "" && Var_FilePath_Ref.current != "" && Var_SpecId_Ref.current != "") {
            if (Var_SlotDuration_Ref.current > 9 && Var_SlotDuration_Ref.current < 61) {
                if (Var_Cost_Ref.current > 1) {
                    setisisDisable(true);
                    setLoading(true);
                    let data = {
                        id: Var_id_Ref.current,
                        doctor_id: Var_DoctorId_Ref.current,
                        service: Var_Service_Ref.current,
                        specialityId: Var_SpecId_Ref.current,
                        cost: Var_Cost_Ref.current,
                        slot_duration: "00:" + Var_SlotDuration_Ref.current,
                        description: Var_Description,
                        is_active: Var_IsActive,
                        file_name: Var_ServiceImageName,
                        file_path: Var_FilePath_Ref.current.split("?")[0]

                    }
                    axios.post("doctor/addmanageservice", data).then((response) => {
                        setisisDisable(false);
                        if (response.data.data[0].ErrorCode == "9999") {
                            setVar_PopupImg(successModelTickImg);
                            setVar_Popup_content("SUCCESS!");
                            setVar_Popup_Message("Manage Service updated successfully!");
                            setisOpenServiceSuccessModel(!isOpenServiceSuccessModel);
                            setLoading(false);

                        }
                        else if (response.data.data[0].ErrorCode == "9998") {
                            notification.error({ message: response.data.data[0].msg })
                            setLoading(false);

                        } else {
                            setVar_PopupImg(Alert);
                            setVar_Popup_content("FAILED!");
                            setVar_Popup_Message(response.data.data[0].msg + "!");
                            setisOpenServiceSuccessModel(!isOpenServiceSuccessModel);
                            setLoading(false);
                        }

                    })
                        .catch((error) => {
                            setisisDisable(false);
                            console.log(error);
                            setLoading(false);
                        });
                } else {
                    notification.error({ message: "The fee should be above 1 KWD." });
                }
            } else {
                notification.error({ message: "Slot Duration 10 Minu to 60 Minu" });
            }
        } else {
            notification.error({ message: "Please select all fields" });
        }
    }
    // Update service API call end

    const isCloseUpdateServiceSuccessModel = () => {

        setisOpenServiceSuccessModel(!isOpenServiceSuccessModel);
        Var_History.push("/dashboard/manageservice");
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverClick = (value) => {
        console.log(value);
        setVar_SlotDuration(value);
        handleClose();
    };
    const values = Array.from({ length: 50 }, (_, index) => index + 10);

    return (
        <div>
            {loading &&
                <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
            }
            <div className="sub_header">
                <div className="sub_header_body">
                    <div className="back_navigation" onClick={() => navigateToBack("/manageservice")}>
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                        <span>EDIT SERVICE</span>
                    </div>
                    <div className="flex_grow"></div>
                    <div className="header_right_content">
                    </div>
                </div>
            </div>

            <div className="content_view">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <InputLabel id="demo-select-small-label" style={{ color: 'black', fontWeight: '500' }}>Specialty</InputLabel>
                        <TextField
                            select
                            value={Var_SpecId_Ref.current}
                            size="small"
                            sx={{ height: '40px', minWidth: '-webkit-fill-available' }}
                            onChange={(e) => getSpecialityValue(e)}
                        >
                            {Var_Speciality_Ref.current.map((item) => {
                                return (
                                    <MenuItem
                                        key={item.specialityId}
                                        value={item.specialityId}
                                    >{item.speciality}</MenuItem>
                                );
                            })}
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel id="demo-select-small-label">Service Title</InputLabel>
                        <TextField
                            type="text"
                            size="small"
                            value={Var_Service}
                            onChange={(e) => getServiceValue(e)}
                            sx={{ minWidth: '-webkit-fill-available' }} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel id="demo-select-small-label">Service Description</InputLabel>
                        <TextField
                            type="text"
                            size="small"
                            value={Var_Description}
                            onChange={(e) => getDescriptionValue(e)}
                            sx={{ minWidth: '-webkit-fill-available' }} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel id="demo-select-small-label">Fee <span style={{ fontSize: '10px' }}>(KWD)</span></InputLabel>
                        <TextField
                            type="text"
                            size="small"
                            inputProps={{ maxLength: 7 }}
                            value={Var_Cost}
                            onChange={(e) => getFeeValue(e)}
                            sx={{ minWidth: '-webkit-fill-available' }} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel id="demo-select-small-label">Slot Duration <span style={{ fontSize: '10px' }}>(mins)</span></InputLabel>
                        <TextField
                            type="text"
                            size="small"
                            // inputProps={{ maxLength: 2 }}
                            // value={Var_SlotDuration}
                            value={Var_SlotDuration_Ref.current}
                            // onChange={(e) => getSlotDurationValue(e)}
                            onClick={(event) => handleClick(event)}
                            sx={{ minWidth: '-webkit-fill-available' }} />
                        <div >
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <div>
                                    {values.map((value, index) => (
                                        <Typography
                                            key={index}
                                            sx={{ p: 2, cursor: 'pointer' }}
                                            onClick={() => handlePopoverClick(value)}
                                        >
                                            {index + 10}
                                        </Typography>
                                    ))}
                                </div>
                            </Popover>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel id="demo-select-small-label">Service Image</InputLabel>
                        <div className="fileUpload">
                            <input  type="file" className="upload" onChange={(e) => getServiceImage(e)} />
                            <span><img src={uploadimage} alt="Upload Icon" /></span>
                            <p className="fileName" style={{color:"#707070"}}>{Var_FileName}</p>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <span>Active
                            <FormControlLabel className='every_days' control={
                                <IOSSwitch checked={Var_IsActive == 1 ? true : false} onChange={(e) => getActiveSwitchValue(e)} sx={{ m: 1 }} className='toggle' />
                            } />
                        </span>
                    </Grid>
                    <Grid item xs={6} className="fileshow">
                        {Var_fileprocess &&
                            <div>
                                <div className="upload-container">
                                    {/* Your other UI elements */}
                                    <LinearProgress
                                        variant="determinate"
                                        value={Var_progress}
                                        color="secondary"
                                        className="progressBar"
                                        sx={{ zIndex: '1', width: '100%', margin: '0px 3px' }}
                                    />
                                    {/* Display progress percentage */}
                                    <div className="progress-text">{`${Var_progress}%`}</div>
                                </div>
                            </div>
                        }
                    </Grid>


                </Grid>
                <div className="addmanager-post">
                    <Card className="service_card_detials">
                        <Grid container >
                            <Grid item xs={2}>
                                <img className="service_image_display" src={Var_FilePath_Ref.current == "" || null ? alterImage : Var_FilePath_Ref.current} alt="" />

                            </Grid>
                            <Grid item xs={7} className="service_content">
                                <label className="service_title_card">{Var_Service_Ref.current}</label>
                                <p className="sertvice_description">{Var_Description_Ref.current}</p>
                            </Grid>
                            <Grid item xs={3} className="add_button_grid">
                                <Button
                                    variant="contained"
                                    color="success"
                                    className="add_button"
                                    disabled = {isDisable}
                                    onClick={() => UpdateService()}>
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            </div>
            {localStorage.getItem("FooterStatus") == 'A' && <div className="layout_footer footer">
                <FooterMaster />
            </div>}
            {/* ================= Edit Success Model Start ================= */}
            <Dialog
                open={isOpenServiceSuccessModel}
                onClose={isCloseUpdateServiceSuccessModel}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "300px",
                            borderRadius: "20px",
                        },
                    },
                }}
            >
                <DialogContent>
                    <div className="success_model_body">
                        <div className="icons" style={{ textAlign: 'center', marginBottom: '23px' }}>
                            <img src={setVar_PopupImg_Ref.current} alt=""/>
                        </div>
                        <div className="title">{Var_Popup_content_Ref.current}</div>
                        <div className="content">{Var_Popup_Message_Ref.current}</div>
                        <div className="segment_center">
                            <button className="botton_Cart_Create" onClick={() => isCloseUpdateServiceSuccessModel()}>OK</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            {/* ================= Edit Success Model End ================= */}
        </div>
    )
}
export default EditService;