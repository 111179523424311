// AUTHOR : Dinesh
// CR-DATE: 27-June-2023
// MOD-DATE: 19-July-2023
// DESCRIPTION: Forgot Update work

import React, { Component, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import Axios from "axios";
import { notification, Spin } from "antd";
import LinearProgress from '@mui/material/LinearProgress';

// Mui meterial component import
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import "./Forget.css";
import tombutton from "../../assets/Images/tom_journey.svg";
import Logo from "../../assets/Doctor_Ave/Avelator_Logo.png";
import HomeImage from "../../assets/Images/log_im.svg";
import Inbox from "../../images/inbox.svg";


function Forgot() {
  let Var_History = useHistory();

  const [Var_Email, setVar_Email] = useState("");
  const [Var_EmailErr, setVar_EmailErr] = useState("");
  const [Var_IsLoading, setVar_IsLoading] = useState("");
  const [loading, setLoading] = useState(false);


  // navigation method start
  const handleOnClick = (path) => {
    Var_History.push(path)
  };
  // navigation method end


  //forgot method start
  const submitMail = (e) => {
    e.preventDefault();
    try {
      if (Var_Email) {

        setVar_IsLoading(true);
        const host = window.location.host;
        const pathName = window.location.pathname.split("/")[1];
        setLoading(true);

        var obj_data = {
          email: Var_Email,
          host,
          pathname: "Doctormodule",
          module: "Doctor",
        };
        Axios({
          method: "POST",
          url: "admin/forgot_password_check_mail",
          data: obj_data,
        })
          .then((response) => {
            setLoading(false);

            if (response.data.status === 1) {
              setLoading(false);
              notification.success({
                message: response.data.msg,
              });
              handleOnClick("/");
              setLoading(false);
            } else {
              // setLoading(false);
              notification.error({
                message: response.data.msg,
              });
              setLoading(false);
            }
            setVar_IsLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            setVar_IsLoading(false);
            console.log(error);
          });
      } else {
        setVar_EmailErr(true);
      }
    } catch (e) {
      setLoading(false);
      setVar_IsLoading(false);
    }
  };
  //forgot method end

  return (
    <div>
      {/* {loading &&
        <div className="loader_body">
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        </div>
      } */}
      {loading &&
        <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
      }
      {/* ================= Forgot page start ================= */}
      <div className="pharmacy_login_container">
        <Grid container>
          {/* ================= Forgot Doctor Image start ================= */}
          <Grid item xs={12} md={6} className="pharmacy_image_grid">
            <div className="pharmacy_image_container">
              <div className="pharmacy_image_div">
                <div className="pharmacy_image_login">
                  <div>
                    <div className="pharmacy_image">
                      <div className="lab_login_text">Transformational way in <br />how we connect you with <br />patients & medical professionals!
                      {/* <button className="tom_Journey">
                          <img style={{ height: '15px' }} src={tombutton} ></img> ’S Journey
                        </button> */}
                      </div>
                      {/* <div >
                  
                    </div>
                      <div className="numweb_contee">
                        <img className="login_new_lady_img" src={HomeImage} />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          {/* ================= Forgot Doctor Image end ================= */}


          {/* ================= Forgot Input Fields start ================= */}
          <Grid item xs={12} md={6} className="pharmacy_grid_container">
            <div className="pharmacy_main_container">
              <div className="pharmacy_paper_div">
                <div className="pharmacy_text_container">
                  <div className="logo_container">
                    <div className="logo_div">
                      <img className="logo_image" src={Logo} />
                    </div>
                  </div>
                  <div className="pharmacy_Welcometext-container">
                    <p className="Welcometexts">REQUEST NEW PASSWORD</p>
                  </div>
                  <div className="pas_msg">
                    Enter the current email address associated with your THE ONE
                    MOMENT account, then click submit. We'll email you a link to
                    a page where you can easily create a new password.
                  </div>
                  <div className="pharmacy_email_container">
                    <TextField
                      type="email"
                      placeholder="Enter User Name"
                      label="EMAIL"
                      name="email"
                      autoComplete="true"
                      value={Var_Email}
                      onChange={(e) => setVar_Email(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <IconButton>
                              <img
                                className="inbox_icon"
                                alt="inbox"
                                src={Inbox}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: Var_EmailErr ? "block" : "none",
                      color: "#ff0068",
                      textAlign: "center",
                      marginTop: "5px",
                    }}
                  >
                    Please Enter Valid Email
                  </div>
                  <div className="pharmacy_submit_container">
                    <button className="login" component={NavLink} href="/" onClick={(e) => submitMail(e)}>
                      Submit
                    </button>
                  </div>
                  <div className="cancel_container">
                    <a component={NavLink} href="/" className="cancelbutton">
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          {/* ================= Forgot Input Fields end ================= */}
        </Grid>
      </div>
      {/* ================= Forgot page end ================= */}
    </div>
  );
};

export default Forgot;
