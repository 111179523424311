import "./App.css";
import React, { Component } from "react";
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Switch, Redirect } from "react-router-dom";

import Notification from './firebaseNotifications/Notification'

import AppointmentComponent from "./component/Appointments/AppointmentsMaster";
import ReportComponent from "./component/Appointments/Report";
import ReportViewComponent from "./component/Appointments/ReportView";
import PrescriptionComponent from "./component/Appointments/Prescription";
import ReferralComponent from "./component/Appointments/Referral";
import UploadReportComponent from "./component/Appointments/UploadReport";
import QueueComponent from "./component/Queue/QueueMaster";
import CollateralsComponent from "./component/Collaterals/CollateralsMaster";
import AdvertisementBookingComponent from "./component/Advertisement_Booking/AdvertisementBookingMaster";
import AdvertisementBookingListComponent from "./component/Advertisement_Booking/AdvertisementBookingList";
import AdvertisementBookingEditComponent from "./component/Advertisement_Booking/AdvertisementBookingEdit";
import AvailabilityComponent from "./component/Availability/AvailabilityMaster";
import AvailabilitySlotsComponent from "./component/Availability/AvailabilitySlots";
import CancelledAppointmentsComponent from "./component/Cancelled_Appoinments/CancelledAppoinmentsMaster";
import DealComponent from "./component/Deals/DealsMaster";
import ManageServiceComponent from "./component/Manage_Service/ManageServiceMaster";
import ManageAddServiceComponent from "./component/Manage_Service/AddService";
import EditService from "./component/Manage_Service/EditService";
import NotificationComponent from "./component/Notification/Notification";
import ProfileComponent from "./component/Profile/Profile";
import WalkInBookingComponent from "./component/Walkin_Booking/WalkinBookingMaster";
import WorkingHoursComponent from "./component/Working_Hours/WorkingHoursMaster";
import RevenueComponent from "./component/Revenue/RevenueMaster";
import PostComponent from "./component/Post/PostMaster";
import DashboardComponent from "./component/Layout/Dashboard";
import Forgot from "./component/Login/Forget";
import Login from "./component/Login/Login";
import ResetPassword from "./component/Login/ResetPassword";
import LayoutComponent from "./component/Layout/LayoutComponent";
import WalletMasterComponent from "./component/Wallet/WalletMaster";
import SupportMasterComponent from "./component/Support/SupportMaster";
import AddSupportComponent from "./component/Support/AddSupport";
import ReplaySupportComponent from "./component/Support/ReplaySupport";
import ViewWorkingHoursComponent from "./component/Working_Hours/ViewWorkingHours";
import EditWorkingHoursComponent from "./component/Working_Hours/EditWorkingHours";
import ReferralMasterComponent from "./component/Referral/ReferralMaster";
import ViewReferralMasterComponent from "./component/Referral/ViewReferral";
import ReferredMeComponent from "./component/Referral/ReferredMe";
import RevenueListComponent from "./component/Revenue/RevenueList";
import RevenueDashboard from "./component/Revenue_settle/RevenueDashboard";
import RevenueTable from "./component/Revenue_settle/Revenue_Tables/RevenueTable.jsx"
import Duetabel from "./component/Revenue_settle/Revenue_Tables/Revenue_duetabel.jsx";
import SettleMentPage from "./component/Revenue_settle/Revenue_Tables/SettleMent.jsx"
import AdvertisementSuccess from "./component/Advertisement_Booking/AdvertisementSuccess"
import OnlineStatus from "./onlinedecter";

axios.defaults.baseURL = "https://gatewayapi.avelator.in/";
const apiurl = "https://gatewayapi.avelator.in/";

// Local Api 
// axios.defaults.baseURL = "http://134.209.154.64:8000/";
// const apiurl = "http://134.209.154.64:8000/";


//  Mumbai Api
// axios.defaults.baseURL = "https://gateway.t heonemoment.co/";
// const apiurl = "https://gateway.theonemoment.co/";
// export const paymentsuccessurl ="http://localhost:3000/Doctormodule/?/dashboard/advertisementbooking/paymentsuccess"
export const paymentsuccessurl = window.location.origin + "/Doctormodule/?/dashboard/advertisementbooking/paymentsuccess";
//Staging
export const kneturl = "https://knettest.theonemoment.com/SendPerformREQuest.php?";
//Production
//export const kneturl = "https://knet.theonemoment.com/SendPerformREQuest.php?";
//staging
//export const decryptKey = "G252MRU33W7YB5Q1";
export const decryptKey = "NX706H5HECB8W336";
//production
//export const decryptKey = "N5CVV5M85UH6H005";

const ClearPropsAndRedirect = ({ to, ...rest }) => {
  const { Component, ...props } = rest;
  return (<Route {...props} render={() => <Redirect to={to} />} />);
};


function App() {
  return (
    <div>
      <OnlineStatus />
      <Router basename='Doctormodule/?/'>
        <Route exact path="/" component={Login}></Route>
        <Route path="/forgot" component={Forgot}></Route>
        <Route exact path="/ResetPassword/:uuid" component={ResetPassword}></Route>
        <Route path='/' exact={true} component={DashboardComponent} render={props => <DashboardComponent {...props} />} >
          <Redirect to="/" />
        </Route>

        <Route path="/dashboard/" name="Home1" render={props => ("Doctor_id" in window.localStorage) ?
          (<>
            <Route exact path="/dashboard/" component={DashboardComponent}></Route>
            <Route exact path="/dashboard/appointments" component={AppointmentComponent}></Route>
            <Route exact path="/dashboard/appointments/referral" component={ReferralComponent}></Route>
            <Route exact path="/dashboard/appointments/prescription/:patientId/:bookingId/:date/:is_send_to_pharam" component={PrescriptionComponent}></Route>
            <Route exact path="/dashboard/appointments/report/:patientId/:bookingId/:date" component={ReportComponent}></Route>
            <Route exact path="/dashboard/appointments/uploadreport/:patientId/:bookingId/:date" component={UploadReportComponent}></Route>
            <Route exact path="/dashboard/appointments/reportview/:Id/:Time/:items" component={ReportViewComponent}></Route>
            <Route exact path="/dashboard/queue" component={QueueComponent} ></Route>
            <Route exact path="/dashboard/collaterals" component={CollateralsComponent} ></Route>
            <Route exact path="/dashboard/availability" component={AvailabilityComponent} ></Route>
            <Route exact path="/dashboard/availability/slots" component={AvailabilitySlotsComponent} ></Route>
            <Route exact path="/dashboard/workinghours" component={WorkingHoursComponent} ></Route>
            <Route exact path="/dashboard/workinghours/viewworkinghours" component={ViewWorkingHoursComponent} ></Route>
            <Route exact path="/dashboard/workinghours/editworkinghours/:workingHoursId" component={EditWorkingHoursComponent} ></Route>
            <Route exact path="/dashboard/walkinbooking" component={WalkInBookingComponent} ></Route>
            <Route exact path="/dashboard/cancelledappointments" component={CancelledAppointmentsComponent}></Route>
            <Route exact path="/dashboard/post" component={PostComponent}></Route>
            <Route exact path="/dashboard/manageservice" component={ManageServiceComponent} ></Route>
            <Route exact path="/dashboard/manageservice/addservice" component={ManageAddServiceComponent} ></Route>
            <Route exact path="/dashboard/manageservice/editservice/:serviceId" component={EditService} ></Route>

            <Route exact path="/dashboard/advertisementbooking" component={AdvertisementBookingComponent} ></Route>
            <Route exact path="/dashboard/advertisementbooking/advertisementbookinglist" component={AdvertisementBookingListComponent} ></Route>
            <Route exact path="/dashboard/advertisementbooking/advertisementbookingedit/:advertiseId" component={AdvertisementBookingEditComponent} ></Route>
            <Route exact path="/dashboard/advertisementbooking/paymentsuccess" component={AdvertisementSuccess} ></Route>

            <Route exact path="/dashboard/deals" component={DealComponent}></Route>
            {/*<Route exact path="/dashboard/revenue" component={RevenueComponent} ></Route>*/}
            <Route exact path="/dashboard/revenue" component={RevenueDashboard} ></Route>
            <Route exact path="/dashboard/settlement" component={Duetabel} ></Route>
            <Route exact path="/dashboard/notification" component={NotificationComponent}></Route>
            <Route exact path="/dashboard/profile" component={ProfileComponent} ></Route>
            <Route exact path="/dashboard/wallet" component={WalletMasterComponent} ></Route>
            <Route exact path="/dashboard/support" component={SupportMasterComponent} ></Route>
            <Route exact path="/dashboard/support/addsupport" component={AddSupportComponent} ></Route>
            <Route exact path="/dashboard/support/replaysupport" component={ReplaySupportComponent} ></Route>
            <Route exact path="/dashboard/referral" component={ReferralMasterComponent} ></Route>
            <Route exact path="/dashboard/referral/viewreferral/:patientId/:refId/:date/:items" component={ViewReferralMasterComponent} ></Route>
            <Route exact path="/dashboard/referral/referredme" component={ReferredMeComponent} ></Route>
            <Route exact path="/dashboard/revenue/revenuelist/:fromdate/:todate" component={RevenueListComponent} ></Route>
            <Route exact path="/dashboard/revenue/:tableType/:menuType" component={RevenueTable} ></Route>
            {/* <Route exact path="/dashboard/payabledue/:tableType" component={Duetabel} ></Route>
        <Route exact path="/dashboard/SettleMent/:tableType" component={SettleMentPage} ></Route> */}
          </>) : (<ClearPropsAndRedirect to="/" />)} />
      </Router>

      <Notification />
    </div>
  );
}

export default App;
