import moment from "moment";

class DateData {
  
    selectedmonth= moment().format('YYYY-MM');
    item = {startDate: '',endDate:''}

    setdate(stdate,enddate) {     
      this.item.startDate = stdate;
      this.item.endDate = enddate;
      // console.log(this.item,"-----------------");
    }  

    setSelectedDate(date){
      this.selectedmonth=date;
    }

    formatMoney(number) {
      number = parseFloat(number);
      const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
  
      var formattedNumber = number.toLocaleString('en-US', options);
      const parts = formattedNumber.split('.');
      if(formattedNumber == 'NaN'){
        formattedNumber = "0";
      }
      if (parts.length === 1) {
        return `${formattedNumber}.000`;
      } else {
        const decimalPart = parts[1].padEnd(3, '0');
        return `${parts[0]}.${decimalPart}`;
      }
    }
}
export default new DateData();