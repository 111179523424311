import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { notification, Spin } from "antd";
import axios from "axios";
import useStateRef from "react-usestateref";
import moment from "moment";
import LinearProgress from '@mui/material/LinearProgress';



// Mui meterial component import
import { InputLabel, FormControlLabel, Select, MenuItem, Container, Card, Grid, TextField, Button, FormControl, DialogContent, Dialog, Radio } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


import './Report.css';
import Member from '../../assets/Web_Doctor_Icon_new_theme/Member.svg'
import Fasttrack from '../../assets/Web_Doctor_Icon_new_theme/fast_track.svg'
import Online from '../../assets/Web_Doctor_Icon_new_theme/Online.svg'
import Walkin from '../../assets/Web_Doctor_Icon_new_theme/walkin.svg'
import closeicon from '../../assets/Web_Doctor_Icon_new_theme/close.svg';
import successModelTickImg from '../../assets/Web_Doctor_Icon_new_theme/Success.svg'
import FooterMaster from '../Dashboard/FooterMaster';
import Service from '../../helpers/Service'
import vector from '../../assets/Doctor_web_Icon/Vector.png'
import CryptoJS from "crypto-js";
import MaleIcon from "../../assets/Web_Doctor_Icon_new_theme/MaleJpg.jpg";
import FemaleIcon from "../../assets/Web_Doctor_Icon_new_theme/FeMaleJpg.jpg";

const ReportComponent = () => {
    let Var_History = useHistory();
    let { patientId } = useParams();
    let { bookingId } = useParams();
    let { date } = useParams();

    // alert(patientId);
    const [fullWidth, setFullWidth] = useState(true);
    const [loading, setLoading] = useState(true);
    var [Var_DateApi, setVar_DateApi, dateRefapi] = useStateRef("")
    var [decryptionKey, setdecryptionKey, decryptionKey_Ref] = useStateRef("")
    var [Var_AppointmentDetail, setVar_AppointmentDetail] = useState([]);
    var [Var_TotalCuont, setVar_TotalCuont] = useState("");
    var [Var_ReportsByClinic, setVar_ReportsByClinic] = useState([]);
    var [PatientInformation, setVar_PatientInformation, PatientInformation_Ref] = useStateRef([]);
    var [Var_PatientReport, setVar_PatientReport, Var_PatientReport_Ref] = useStateRef([]);
    var [Var_prescriptionDetails, setVar_prescriptionDetails] = useStateRef([]);
    var [Var_gettingComment, set_Var_gettingComment] = useStateRef([]);
    var [Var_addingComment, setVar_addingComment, Var_addingComment_Ref] = useStateRef("");
    var [Var_adding, setVar_adding, Var_adding_Ref] = useStateRef([]);
    var [Var_requriedComment, setVar_requriedComment] = useStateRef("");


    const [isOpenViewCommentModel, setisOpenViewCommentModel] = useState(false);
    var [Var_ViewMoreDetails, setVar_ViewMoreDetails, Var_ViewMoreDetails_Ref] = useStateRef("");
    const [isOpenCommentModel, setisOpenCommentModel] = useState(false);
    const [isOpenSuccessModel, setisOpenSuccessModel] = useState(false);
    const [isOpenvideoModel, setisOpenvideoModel] = useState(false);
    var [videoData, setvideoData] = useState("");
    const [staticVideoUrl, setstaticVideoUrl] = useState([{ "name": "xray", "data": "https://onemoment.voyagerpacs.com/ZeroFP/LN10DxY-1m7GZd2p-iN" }, { "name": "mri", "data": "https://onemoment.voyagerpacs.com/ZeroFP/LN1;kUKUTD8UsPz8fVL" }, { "name": "dna", "data": "https://onemoment.voyagerpacs.com/ZeroFP/LN14yMEIwAmDsm-3Hf2" }])
    // const colors = [" #48BFE3", "#0BA95F", "#E3AC3E"];
    const lightColors = [" #48BFE3", "#0BA95F", "#E3AC3E"];

    const colors = ["#48BFE3", "#0BA95F", "#E3AC3E"];
    // const lightColors = ["lightblue", "lightgreen", "lightyellow"];
    const [isDisable, setisisDisable] = useState(false);
    // initial function call start
    useEffect(() => {
        console.log("refresh check")
        console.log("Service.check", Service.check)
        setVar_DateApi(new Date().toLocaleDateString('fr-CA'));
        reportsByClinic();
        getPrescriptionReport();
        getAllAppointment()
        gettingReportComment();
        // insertReportComment();
        patientParticularReport()
        localStorage.setItem("booking_Id", "")
        localStorage.setItem("patient_Id", "")
        localStorage.setItem("Report_Date", "")
        setisisDisable(false);
    }, []);
    // initial function call End

    // navigattion to back start
    const navigateTo = (path) => {

        Var_History.push("/dashboard/appointments" + path)
    };
    // navigattion to back end

    // =============== Get All Appointment Start ==========================================
    const getAllAppointment = () => {
        // var date = date

        setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("Clinic_id"),
            doctor_id: localStorage.getItem("Doctor_id"),
            patient_type: "",
            period: "day",
            search_date: date, //"2023-08-14",
            search_date_to: date //"2023-08-14",
        }
        axios
            .post("doctor/getAppointmentList", data)
            .then((response) => {
                console.log("Appoinment", response.data.data[0].result)
                var filtereddata = response.data.data[0].result.filter(x => x.patient_id == patientId && x.booking_id == bookingId);
                setVar_AppointmentDetail(filtereddata)
                setVar_TotalCuont(response.data.data[0].result.length)
                console.log("appointment Result", filtereddata)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            })

    }
    // =============== Get All Appointment End ==========================================
    // model controls start
    const okClose = () => {
        if (Var_addingComment == '') {
            setVar_requriedComment("Field Required.")
        }
        else {
            setisOpenCommentModel(!isOpenCommentModel)
            setisOpenSuccessModel(!isOpenSuccessModel)
        }

    }


    const AddComment = () => {
        setisOpenCommentModel(!isOpenCommentModel)
    }
    // model controls end
    // Reports By Clinic Start
    const reportsByClinic = () => {

        setLoading(true);
        var data = {
            frmreq: "doctor",
            patientId: patientId,
            doctorId: localStorage.getItem("Doctor_id"),
            clinicId: localStorage.getItem("Clinic_id"),
            dateBasedSorting: true,
            dateBasedSortingOrder: 'DESC',
            limit: 100,
            pageno: 1
        }
        axios
            .post("clinic/getReportsByClinic", data)
            .then((response) => {
                console.log("Reports By Clinic ", response.data.data[0].details)
                setVar_ReportsByClinic(response.data.data[0].details)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
        // setisOpenSuccessModel(!isOpenSuccessModel)
    }
    // Reports By Clinic End

    // Get Patient Particular Report start
    const patientParticularReport = () => {

        setLoading(true);
        var data = {
            book_date: date,
            clinic_id: Number(localStorage.getItem("Clinic_id")),
            doctor_id: Number(localStorage.getItem("Doctor_id")),
            patient_id: Number(patientId),
        }
        axios
            .post("doctor/getPatientParticularReport", data)
            .then((response) => {
                console.log("Patient Particular Report", response.data.data[0])
                setVar_PatientReport(response.data.data[0].patient_report)
                setVar_PatientInformation(response.data.data[0].patient_info)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
        // setisOpenSuccessModel(!isOpenSuccessModel)
    }
    // Get Patient Particular Report End

    // Time conversion start

    const convertTo12HrFormat = (time24) => {
        var timeArr = time24.split(':');
        var hours = parseInt(timeArr[0]);
        var minutes = parseInt(timeArr[1]);
        var suffix = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12 || 12;
        var time12 = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + suffix;

        return time12;
    }
    const timeFormat = (time) => {
        let datetime = new Date('1970-01-01T' + time + 'Z');
        return datetime.toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' });
    }

    // Time conversion End

    const navigateToLabReportViewPage = (data) => {
        setisOpenvideoModel(true)
        const urlRegex = /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/;
        const decryptedText = urlRegex.test(data.test_result) ? data.test_result : decrypt(data.test_result);
        setvideoData(decryptedText)
        // let arr = staticVideoUrl.filter((obj) => obj.name == data.test_name.toLocaleLowerCase())
        // if (arr.length > 0) {
        //     setvideoData(arr[0]['data'])
        // }
        // else {
        //     setvideoData("")
        // }

        /* data.test_result */
        // setvideoData("https://onemoment.voyagerpacs.com/ZeroFP/LN1KBD4f5MRiAe2M9mL")


        /*  navigateTo("/reportview/" + patientId + "/" + data.test_time + "/" + 2);
          localStorage.setItem("Report_Date", date)
          localStorage.setItem("patient_Id", patientId)
          localStorage.setItem("booking_Id", bookingId)*/
    }


    const decrypt = (encryptedData) => {
        const parts = encryptedData.split(":");
        const iv = CryptoJS.enc.Hex.parse(parts[0]);
        const encrypted = CryptoJS.enc.Hex.parse(parts[1]);
        const cryptoInfo = CryptoJS.AES.encrypt(JSON.stringify(encrypted), decryptionKey);
        const decrypted = CryptoJS.AES.decrypt(
            { ciphertext: encrypted },
            CryptoJS.enc.Utf8.parse(decryptionKey),
            { iv: iv, mode: CryptoJS.mode.CTR, padding: CryptoJS.pad.NoPadding }
        ).toString(CryptoJS.enc.Utf8);

        return decrypted;

    };


    const navigateToClinicReportViewPage = (data) => {

        navigateTo("/reportview/" + data.patient_id + "/" + data.id + "/" + 3);
        localStorage.setItem("Report_Date", date)
        localStorage.setItem("patient_Id", patientId)
        localStorage.setItem("booking_Id", bookingId)
    }

    const navigateToPrescriptionReportViewPage = (data) => {

        navigateTo("/reportview/" + 0 + "/" + 0 + "/" + 1);
        localStorage.setItem("Report_Date", date)
        localStorage.setItem("patient_Id", patientId)
        localStorage.setItem("booking_Id", bookingId)
        localStorage.setItem("Prescription_Date", data.prescript_date)
    }
    // const navigateToPrescriptionReportViewPage = (data) => {
    //     
    //     navigateTo("/reportview/" + 0 + "/" + moment(data.prescript_date).format("YYYY-MM-DD") + "/" + 1);
    //     localStorage.setItem("Report_Date", date)
    //     localStorage.setItem("patient_Id", patientId)
    //     localStorage.setItem("booking_Id", bookingId)
    // }


    const isCloseViewModel = () => {
        setisOpenViewCommentModel(!isOpenViewCommentModel)
    }

    const reportView = (value) => {
        setVar_ViewMoreDetails(value);
        setisOpenViewCommentModel(!isOpenViewCommentModel)
    }


    const isCloseModel = () => {
        setisOpenCommentModel(!isOpenCommentModel)


    }
    const isCloseModelsuccess = () => {
        insertReportComment();
        setisOpenSuccessModel(!isOpenSuccessModel)
        setVar_addingComment("");


    }
    const isCloseVideoModel = () => {
        setisOpenvideoModel(!isOpenvideoModel)


    }
    // ====================get prescription details start==============
    const getPrescriptionReport = () => {

        // setLoading(true);
        var data = {
            doctorId: localStorage.getItem("Doctor_id"),
            patientId: patientId
        }
        axios
            .post("doctor/getReportPrescriptionImage", data)
            .then((response) => {
                setVar_prescriptionDetails(response.data.data)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }

    // ====================get prescription details end==============

    const textConvert = (text) => {

        var textContainer = text

        // Get the full text content of the <p> tag
        const fullText = textContainer;
        const truncatedText = fullText.slice(0, 17) + '...';
        return truncatedText;
        // }
    }


    const textConvertcommand = (text) => {

        var textContainer = text

        // Get the full text content of the <p> tag
        const fullText = textContainer;
        const truncatedText = fullText.slice(0, 30) + '...';
        return truncatedText;
        // }
    }

    // =======================insert comment report==================
    const insertReportComment = () => {
        setisisDisable(true);
        setLoading(true);
        var data = {
            doctor_id: localStorage.getItem("Doctor_id"),
            patient_id: patientId,
            comments: Var_addingComment_Ref.current
        }
        axios
            .post("doctor/addReportComment", data)
            .then((response) => {
                // setVar_adding(response.data.data)
                gettingReportComment();
                setLoading(false);
                setisisDisable(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                setisisDisable(false);
            });
    }
    // =======================insert comment report==================


    // =======================getting comment report start==================
    const gettingReportComment = () => {

        setLoading(true);
        var data = {
            doctor_id: localStorage.getItem("Doctor_id"),
            patient_id: patientId,
        }
        axios
            .post("doctor/getReportComment", data)
            .then((response) => {
                set_Var_gettingComment(response.data.data)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
    // =======================getting comment report end==================

    return (
        <div>
            {/* {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            } */}
            {loading &&
                <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
            }
            <div>
                <div>
                    {/* ================= Report sub header start ================= */}
                    <div className="sub_header">
                        <div className="sub_header_body">
                            <div className="back_navigation" onClick={() => navigateTo("")}>
                                <i className="fa fa-angle-left" aria-hidden="true"></i>
                                <span>REPORT</span>
                            </div>
                            <div className="flex_grow"></div>
                        </div>
                    </div>
                    {/* ================= Report sub header End ================= */}

                    {/* ================= Report list View start ================= */}
                    <div className='content_view'>

                        <div>
                            {/*=======================  Pesenti Profile start ================= */}

                            <div>
                                {Var_AppointmentDetail.map((item) => (

                                    <div className='profile_details_prescription'>
                                        <div className='profile_detail_container'>
                                        <img src={(item.file_name != "" && item.file_name != " " && item.file_name != null)  ? item.file_name : item.gender.toLowerCase() =='male' ? MaleIcon : FemaleIcon} className='profile_Details' style={{ border: '1px solid var(--app-theme)' }} />
                                        <div className='detailsprofile'>
                                                <p className='profile_name'>{item.name}</p>
                                                {/* <p className='profile_number'>{item.mobile_no}</p> */}
                                                <p className='profile_age'>{item.age > 0 ? item.age + " " + "yrs," : ""} <span> {item.gender}</span></p>
                                            </div>
                                        </div>
                                        <div className="flex_grow"></div>
                                        <div className='profile_detail_type'>
                                            <img src={item.appointment_type_id == 1
                                                ? Member
                                                : item.appointment_type_id == 3
                                                    ? Fasttrack
                                                    : item.appointment_type_id == 2
                                                        ? Online
                                                        : item.appointment_type_id == 0
                                                            ? Walkin
                                                            : vector} alt='pro' className='Profoile_Type' />
                                            <p className='profile_time'>{item.appointment_time == null ? 0 : convertTo12HrFormat(item.appointment_time)}</p>
                                            <p className='report_date'>{moment(item.appointment_date).format("DD-MMM-YY")}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/*=======================  Pesenti Profile End ================= */}

                            <div>
                                <div>
                                    <div className="report_container">
                                        {/*=======================  comments container Start ================= */}

                                        <div className="comments_container px-1" align="center">
                                            {/*=======================  Add new comment start ================= */}

                                            <div className="add_comment_container">
                                                <p className="add_comment">Click + to Add New Comment</p>
                                                <div className="plus_comment" onClick={() => AddComment()} >+</div>
                                            </div>
                                            {/*=======================  Add new comment end ================= */}

                                            <div>
                                                <p className="report_title">Comments</p>
                                                <div className="stepper-container">

                                                    {/* {Var_gettingComment.map((data) => (
                                                        <div>
                                                            <div className="stepper-step">
                                                                <div className="stepper-circle"><img className="report_doctor_profile" src={data.vendor_profile_path == " " ? vector : data.vendor_profile_path == "[object Object]" ? vector : data.vendor_profile_path == "" ? vector : data.vendor_profile_path}>
                                                                </img></div>

                                                                <div className="stepper-card_comment">
                                                                    <div style={{ display: "flex" }}>
                                                                        <p className="report_doctor_name">{data.vendor_name}</p>
                                                                        <div className="flex_grow"></div>
                                                                        <p className="report_time">{moment(data.created_on).format("DD-MMM-YY")}</p>
                                                                    </div>
                                                                    <p className="report_name">{data.comments}</p>
                                                                </div>

                                                            </div>
                                                            <div className="stepper-line_comment"></div>
                                                        </div>

                                                    ))} */}
                                                    {Var_gettingComment.length <= 0 &&
                                                        <Grid container>
                                                            <Grid item>
                                                                <p className="nodata_found"> No Comments  available for this patient  </p>
                                                            </Grid>
                                                        </Grid >
                                                    }

                                                    {Var_gettingComment.length > 0 &&
                                                        <div>
                                                            {Var_gettingComment.map((data, index) => (
                                                                <div key={index}>
                                                                    <div className={`stepper-step ${colors[index % colors.length]}`}>
                                                                        <div className="stepper-circle">
                                                                            <img
                                                                                className="report_doctor_profile"
                                                                                src={
                                                                                    data.vendor_profile_path == " " ||
                                                                                        data.vendor_profile_path == "[object Object]" ||
                                                                                        data.vendor_profile_path == ""
                                                                                        ? vector
                                                                                        : data.vendor_profile_path
                                                                                }
                                                                                alt="Profile"
                                                                            />
                                                                        </div>

                                                                        <div className="stepper-card_comment">
                                                                            <div style={{ display: "flex" }}>
                                                                                <p className="report_doctor_name">{data.vendor_name}</p>
                                                                                <div className="flex_grow"></div>
                                                                                <p className="report_time_comment">
                                                                                    {moment(data.created_on).format("DD-MMM-YY")}
                                                                                </p>
                                                                            </div>
                                                                            {/* <p className="report_name_commentstext">{textConvertcommand(data.comments)} </p> */}
                                                                            {data.comments.length > 20 ?
                                                                                <div className="report_view_more">
                                                                                    <p className="report_name_command">{data.comments} </p>
                                                                                    <p className="report_view" onClick={() => reportView(data.comments)}>view more</p>
                                                                                </div> : <p className="report_name_command">{data.comments} </p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {/* Render the stepper-line div only if it's not the last item */}
                                                                    {index !== Var_gettingComment.length - 1 && (
                                                                        <div className={`stepper-line_comment ${lightColors[index % lightColors.length]}`}></div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>
                                        {/*=======================  comments container End ================= */}

                                        {/*=======================  Prscriptions containeer End ================= */}
                                        <div className="Prescriptions_container" align="center">
                                            <div>
                                                <p className="report_title">Prescriptions</p>
                                                {/* {Var_prescriptionDetails.map((value) => (
                                                    <div className="stepper-container">
                                                        <div className="stepper-step">
                                                            <div className="stepper-circle"></div>
                                                            <div className="stepper-card">
                                                                <p className="report_time">{moment(value.prescript_date).format("DD-MMM-YY")}</p>
                                                                <p className="report_name">
                                                                    {value.prescript_file_name == "" ? "No Name" : textConvert(value.prescript_file_name)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="stepper-line"></div>
                                                    </div>
                                                ))} */}
                                                {Var_prescriptionDetails == " " || Var_prescriptionDetails.current == "" || Var_prescriptionDetails.length == 0 &&
                                                    <Grid container>
                                                        <Grid item className="prescription-box">
                                                            <p className="nodata_found">No prescription  available for this patient  </p>
                                                        </Grid>
                                                    </Grid >
                                                }

                                                {Var_prescriptionDetails.map((value, index) => (
                                                    <div key={index} className={`stepper-container`}>
                                                        <div className={`stepper-step ${colors[index % colors.length]}`}>
                                                            <div className="stepper-circle"></div>
                                                            <div className="stepper-card" onClick={() => navigateToPrescriptionReportViewPage(value)}>
                                                                <p className="report_time">
                                                                    {moment(value.prescript_date).format("DD-MMM-YY")}
                                                                </p>
                                                                <p className="report_name">
                                                                    {value.prescript_file_name === "" ? "No Name" : textConvert(value.prescript_file_name)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* Render the stepper-line div only if it's not the last item */}
                                                        {index !== Var_prescriptionDetails.length - 1 && (
                                                            <div className={`stepper-line ${lightColors[index % lightColors.length]}`}></div>
                                                        )}
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                        {/*=======================  Pesenti Profile End ================= */}

                                        {/*=======================  lab container Start ================= */}
                                        <div className="lab_container" align="center">
                                            <div>
                                                <p className="report_title">Lab Report</p>
                                                {/* {Var_PatientReport_Ref.current.map((item) => (
                                                    <div className="stepper-container">
                                                        <div className="stepper-step">
                                                            <div className="stepper-circle"></div>
                                                            <div className="stepper-card" onClick={() => navigateToLabReportViewPage(item)}>
                                                                <p className="report_time">{moment(item.test_date).format("DD-MMM-YY")}</p>
                                                                <p className="report_name">{item.test_name}</p>
                                                            </div>
                                                        </div>
                                                        <div className="stepper-line"></div>
                                                    </div>
                                                ))} */}


                                                {Var_PatientReport_Ref.current.length <= 0 &&
                                                    <Grid container>
                                                        <Grid item className="prescription-box">
                                                            <p className="nodata_found"> No report available for this patient </p>
                                                        </Grid>
                                                    </Grid >
                                                }
                                                {Var_PatientReport_Ref.current.length > 0 &&
                                                    <div className="px-1">
                                                        {Var_PatientReport_Ref.current.map((item, index) => (
                                                            <div className="stepper-container" key={index}>
                                                                <div className="stepper-step">
                                                                    <div className="stepper-circle"></div>
                                                                    <div className="stepper-card" onClick={() => navigateToLabReportViewPage(item)}>
                                                                        <p className="report_time">{moment(item.test_date).format("DD-MMM-YY")}</p>
                                                                        <p className="report_name">{item.test_name}</p>
                                                                    </div>
                                                                </div>
                                                                {/* Render the stepper-line div only if it's not the last item */}
                                                                {index !== Var_PatientReport_Ref.current.length - 1 && (
                                                                    <div className="stepper-line"></div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>}
                                            </div>
                                        </div>
                                        {/*=======================  lab container End ================= */}

                                        {/*=======================  clini container Start ================= */}

                                        <div className="clini_container" align="center">
                                            <div>
                                                <p className="report_title">Clinic Report</p>
                                                {/* {Var_ReportsByClinic.map((item) => (
                                                    <div className="stepper-container">
                                                        <div className="stepper-step">
                                                            <div className="stepper-circle"></div>
                                                            <div className="stepper-card" onClick={() => navigateToClinicReportViewPage(item)}>
                                                                <p className="report_time">{moment(item.uploaded_date).format("DD-MMM-YY")}</p>
                                                                <p className="report_name">{item.report_name == "" ? "0" : item.report_name == " " ? "0" : item.report_name == null ? " " : item.report_name}</p>
                                                            </div>
                                                        </div>
                                                        <div className="stepper-line"></div>
                                                    </div>
                                                ))} */}


                                                {/* {Var_ReportsByClinic.map((item, index) => (
                                                    <div className="stepper-container" key={index}>
                                                        <div className={`stepper-step ${colors[index % colors.length]}`}>
                                                            <div className="stepper-circle"></div>
                                                            <div className="stepper-card" onClick={() => navigateToClinicReportViewPage(item)}>
                                                                <p className="report_time">{moment(item.uploaded_date).format("DD-MMM-YY")}</p>
                                                                <p className="report_name">{item.report_name === "" || item.report_name === null ? "0" : item.report_name}</p>
                                                            </div>
                                                        </div>
                                                        
                                                        {index !== Var_ReportsByClinic.length - 1 && (
                                                            <div className={`stepper-line ${lightColors[index % lightColors.length]}`}></div>
                                                        )}
                                                    </div>
                                                ))} */}
                                                {Var_ReportsByClinic.length <= 0 &&
                                                    <Grid container>
                                                        <Grid item className="prescription-box">
                                                            <p className="nodata_found">No clinic report available for this patient </p>
                                                        </Grid>
                                                    </Grid >
                                                }
                                                {Var_ReportsByClinic.length > 0 &&
                                                    <div >
                                                        {
                                                            Var_ReportsByClinic.map((item, index) => (

                                                                <div className="stepper-container" key={index}>
                                                                    <div className={`stepper-step ${colors[index % colors.length]}`}>
                                                                        <div className="stepper-circle"></div>
                                                                        <div className="stepper-card" onClick={() => navigateToClinicReportViewPage(item)}>
                                                                            <p className="report_time">{moment(item.uploaded_date).format("DD-MMM-YY")}</p>
                                                                            <p className="report_name">{item.report_name === "" || item.report_name === null ? "0" : item.report_name}</p>
                                                                        </div>
                                                                    </div>
                                                                    {/* Render the stepper-line div only if it's not the last item */}
                                                                    {index !== Var_ReportsByClinic.length - 1 && (
                                                                        <div className={`stepper-line ${lightColors[index % lightColors.length]}`}></div>
                                                                    )}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>}

                                            </div>
                                        </div>
                                        {/*=======================  clini container End ================= */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ================= Report list View start ================= */}
                </div>

                <div className="layout_footer footer">
                    <FooterMaster />
                </div>


                {/* ====================== Comment Entery model start ====================== */}
                <Dialog
                    fullWidth={fullWidth}
                    open={isOpenCommentModel}
                    onClose={setisOpenCommentModel}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {

                                width: "100%",
                                maxWidth: "300px",
                                borderRadius: "20px",
                            },
                        },
                    }}
                >
                    <div className="dialog_header">
                        <div className="dialog_title">ADD COMMENTS</div>
                        <div className="flex_grow"></div>
                        <div onClick={() => isCloseModel()}>
                            <img src={closeicon} />
                        </div>
                    </div>
                    <DialogContent>


                        {/* <Card className="card_comment"> */}
                        <div style={{ height: "150px" }}>
                            <TextField
                                placeholder="Comments"
                                value={Var_addingComment}
                                onChange={event => setVar_addingComment(event.target.value)}
                                multiline
                                rows={7}
                                maxRows={4}
                                className='comments_fileds'
                                sx={{ border: "1px solid #fff", padding: '8.5px 14px', height: '40px', minHeight: 15, minWidth: '-webkit-fill-available' }}

                            />
                        </div>
                        {/* </Card> */}
                        <br />
                        <p className="requried_type">{Var_requriedComment}</p>
                        <div className="success_model_body">
                            <div className="segment_center AddButt">
                                <button className="botton_Cart_Create" disabled={isDisable} onClick={() => okClose()}>Add</button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>

                {/* ====================== Comment Entery model End ====================== */}


                <Dialog
                    fullWidth={fullWidth}
                    open={isOpenSuccessModel}
                    onClose={setisOpenSuccessModel}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "300px",
                                borderRadius: "20px",
                            },
                        },
                    }}
                >
                    <DialogContent>
                        <div className="success_model_body">
                            <div className="icon">
                                <img src={successModelTickImg} />
                            </div>
                            <div className="title">success!</div>
                            <div className="content">Comment added successfully!</div>
                            <div className="segment_center">
                                <button className="botton_Cart_Create" onClick={() => isCloseModelsuccess()}>OK</button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>

                {/* Video popup modal start*/}

                {/* <Dialog
                    fullWidth={fullWidth}
                    open={isOpenvideoModel}
                    onClose={setisOpenvideoModel}
                    className="video_popup"
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {

                                width: "100%",
                                borderRadius: "20px",
                            },
                        },
                    }}
                >
                    <div className="dialog_header">

                        <div className="flex_grow"></div>
                        <div onClick={() => isCloseVideoModel()}>
                            <img src={closeicon} />
                        </div>
                    </div>
                    <DialogContent>

                        <div style={{ height: "100%", width: '100%' }} >
                            <iframe src={videoData} className="video_iframe_div">
                            </iframe>
                        </div>

                    </DialogContent>
                </Dialog> */}
                <Dialog
                    fullWidth={fullWidth}
                    open={isOpenvideoModel}
                    onClose={setisOpenvideoModel}
                    className="video_popup"
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                maxWidth: "100%",
                                height: "100%",
                                margin: "15px 0px",
                                width: "100%",
                                borderRadius: "20px",
                            },
                        },
                    }}
                >
                    <div className="dialog_header">

                        <div className="flex_grow"></div>
                        <div onClick={() => isCloseVideoModel()}>
                            <img src={closeicon} />
                        </div>
                    </div>
                    <DialogContent>

                        <div style={{ height: '100%' }}>
                            {videoData.includes(".jpeg", ".png", ".jpg") ?
                                <img
                                    src={videoData}
                                    style={{ height: "100%", width: "100%" }}
                                //sandbox="allow-same-origin allow-scripts"
                                /> :
                                videoData.includes(".pdf") ?
                                    <div className="pdf_view_div" style={{ height: '100%', textAlign: 'center' }}>
                                        <object data={videoData} type="application/pdf" style={{ width: '75%', height: '100%', margin: 'auto' }} >
                                        </object>
                                    </div>
                                    :
                                    <div style={{ height: "100%", width: '100%' }} >
                                        <iframe src={videoData} className="video_iframe_div">
                                        </iframe>
                                    </div>}
                        </div>

                    </DialogContent>
                </Dialog>

                {/* Video popup modal end*/}

                {/* View more modal start*/}
                <Dialog
                    fullWidth={fullWidth}
                    open={isOpenViewCommentModel}
                    onClose={setisOpenViewCommentModel}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {

                                width: "100%",
                                maxWidth: "300px",
                                borderRadius: "20px",
                            },
                        },
                    }}
                >
                    <div className="dialog_header">
                        <div className="dialog_title">VIEW COMMENTS</div>
                        <div className="flex_grow"></div>
                        <div onClick={() => isCloseViewModel()}>
                            <img src={closeicon} />
                        </div>
                    </div>
                    <DialogContent>
                        <div style={{ height: "auto" }}>
                            <p>
                                {Var_ViewMoreDetails_Ref.current}
                            </p>
                        </div>
                    </DialogContent>
                </Dialog>
                {/* View more modal end*/}
            </div>
        </div >
    )
}
export default ReportComponent;