// ====================
import { useHistory } from "react-router-dom";
import dateformat from "dateformat";
import LinearProgress from '@mui/material/LinearProgress';

import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import useStateRef from "react-usestateref";
import Grid from '@mui/material/Grid';
import { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";
import React from "react";
import { MdDone } from "react-icons/md";
import { IoIosCloseCircle } from "react-icons/io";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";

import "./AdvertisementBookingList.css"
import Datedata from '../Revenue_settle/mothpicker/Datedata';
import phoneimagefull from '../../images/Full.png'
import phoneimagehalf from '../../images/Half.png'
import FooterMaster from '../Dashboard/FooterMaster'
import editimage from '../../assets/Web_Doctor_Icon_new_theme/Edit.svg'
import alterImage from '../../../src/images/Vector.png'
import rejecticon from "../../images/rejectIcon.svg"
import Modalcomp from "../../helpers/ModalComp/Modalcomp";



function AdvertisementBookingListComponent(props) {

    let Var_History = useHistory();
    const [Var_date, setVar_date] = useState(new Date());
    const Var_CurrentDate = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
    const [Var_formdate, setVar_formdate, Var_fromdateRef] = useStateRef(new Date(Var_date.getFullYear(), Var_date.getMonth(), 1).toLocaleDateString('fr-CA'));
    const [Var_todate, setVar_settodate, Var_todateRef] = useStateRef(new Date(Var_date.getFullYear(), Var_date.getMonth() + 1, 0).toLocaleDateString('fr-CA'));
    const [Var_currentmonth, setVar_currentmonth] = useStateRef("");
    const [, setVar_AdvertisementBookedData, Var_AdvertisementBookedDataRef] = useStateRef([]);
    const [loading, setLoading] = useState(true);

    const [open, setOpen] = React.useState(false);
    const [rejectReason, setRejectReason] = React.useState(null);

    const { t } = props;

    useEffect(() => {
        setVar_currentmonth(moment(Var_date).format("MMM YY"));
        getAppoinmentBookedList();
    }, [])


    const handleOnClick = (path) => { Var_History.push("/dashboard" + path) };


    const editAdvertisementBookings = (id) => {
        // localStorage.setItem("advertisementid",id)
        handleOnClick("/advertisementbooking/advertisementbookingedit/" + id)
    }

    //   ============================= Get Next Month  start =======================================
    const nextMonth = () => {
        setVar_date(new Date(Var_date.setMonth(Var_date.getMonth() + 1)));
        let monthsegment = moment(Var_date).format("MMM - YY")
        setVar_currentmonth(monthsegment)
        setVar_formdate(new Date(Var_date.getFullYear(), Var_date.getMonth(), 1).toLocaleDateString('fr-CA'));
        setVar_settodate(new Date(Var_date.getFullYear(), Var_date.getMonth() + 1, 0).toLocaleDateString('fr-CA'));

    }
    //=============================== Get Next Month  start ========================================
    // ============================= Get Pervious Month start ==========================================
    const perviousdate = () => {
        setVar_date(new Date(Var_date.setMonth(Var_date.getMonth() - 1)));
        let monthsegment = moment(Var_date).format("MMM - YY")
        setVar_currentmonth(monthsegment)
        setVar_formdate(new Date(Var_date.getFullYear(), Var_date.getMonth(), 1).toLocaleDateString('fr-CA'));
        setVar_settodate(new Date(Var_date.getFullYear(), Var_date.getMonth() - 1, 0).toLocaleDateString('fr-CA'));
    }
    // ============================== Get Pervious Month End ==========================================

    // =====================================Get Advertisement list start  ==============================
    const getAppoinmentBookedList = () => {
        setLoading(true);
        const Var_RequestData = {

            vendor_id: localStorage.getItem("Doctor_id"),
            limit: 100,
            pageno: 1
        };
        Axios({
            method: "POST",
            url: "diet/getAd_Booking",
            data: Var_RequestData,
        }).then((response) => {

            console.log("advertisementList", response)
            setVar_AdvertisementBookedData(response.data.data[0].details)
            setLoading(false);

            var objectToReplace;
            var newObject;

            for (let i = 0; i < Var_AdvertisementBookedDataRef.current.length; i++) {

                var array = [
                    { Label: "Booked", Time: "", Reason: "" },
                    { Label: "Approved", Time: "", Reason: "" },
                    { Label: "Ad Posted", Time: "", Reason: "" },
                ]
                Object.assign(Var_AdvertisementBookedDataRef.current[i], { Var_steps: array })

                if (Var_AdvertisementBookedDataRef.current[i].ad_approve_status == "P") {
                    Object.assign(Var_AdvertisementBookedDataRef.current[i], { activestep: 0 })

                    setLoading(false);
                }
                else if (Var_AdvertisementBookedDataRef.current[i].ad_approve_status == "A") {
                    Object.assign(Var_AdvertisementBookedDataRef.current[i], { activestep: 1 })
                    if (Var_AdvertisementBookedDataRef.current[i].ad_approve_status == "A" && Var_AdvertisementBookedDataRef.current[i].ad_start_date <= Var_CurrentDate.split(" ")[0]) {
                        Var_AdvertisementBookedDataRef.current[i].activestep = 3;
                    }
                }
                else if (Var_AdvertisementBookedDataRef.current[i].ad_approve_status == "R") {
                    Object.assign(Var_AdvertisementBookedDataRef.current[i], { activestep: 1 });

                    objectToReplace = Var_AdvertisementBookedDataRef.current[i].Var_steps.find(x => x.Label == "Approved");
                    if (objectToReplace != undefined) {
                        newObject = { Label: "Rejected", Time: "", Reason: Var_AdvertisementBookedDataRef.current[i].remarks };
                        Object.assign(objectToReplace, newObject);
                        console.log(objectToReplace);
                    }
                }
                else {
                    setLoading(false);
                }
            }

            console.log("active step checking", Var_AdvertisementBookedDataRef.current);
        }).catch((error) => {
            setLoading(false);
            console.log(error);
        });
    }

    function rejectIcon() {
        return (
            <div className="rejectIcon">
                <img src={rejecticon} />
            </div>
        )
    }

    const useColorlibStepIconStyles = makeStyles({
        root: {
            backgroundColor: "#ccc",
            zIndex: 1,
            color: "#fff",
            display: "flex",
            borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center",
            height: "20px",
            width: "20px",
        },
        active: {
            backgroundImage:
                "linear-gradient( 136deg,var(--app-theme) 0%, var(--app-theme) 50%, #510f30 100%);",
            boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
        },
        completed: {
            backgroundImage:
                "linear-gradient( 136deg, #510f30  0%, #510f30  50%, #510f30 100%)",
        },
    });

    function ColorlibStepIcon(props) {
        debugger;
        const classes = useColorlibStepIconStyles();
        const { active, completed } = props;

        const icons = {
            1: (
                <div className="icon_settings">
                    <MdDone />
                </div>
            ),
            2: (
                <div className="icon_settings">
                    {props != "Rejected" &&
                        <MdDone />
                    }
                    {props == "Rejected" &&
                        <IoIosCloseCircle />
                    }
                </div>
            ),
            3: (
                <div className="icon_settings">
                    <MdDone />
                </div>
            ),
        };

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }

    ColorlibStepIcon.propTypes = {
        active: PropTypes.bool,
        completed: PropTypes.bool,
        icon: PropTypes.node,
    };

    function clickToViewReason(data) {
        setOpen(true);
        setRejectReason(data);
    };
    function handleClose() {
        setOpen(false);
    };

    return (

        <div className="body_content">
            {loading &&
                <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
            }
            <div className="sub_header">
                <div className="sub_header_body">
                    <div className="back_navigation" onClick={() => handleOnClick("/advertisementbooking")}>
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                        <span>ADVERTISEMENT</span>
                    </div>
                    <div className="flex_grow"></div>
                    <div className="header_right_content">
                        <div>
                            <div className='page_head_date_advertisement'>
                                <Button className='date_left_button' onClick={() => {
                                    perviousdate();
                                }}>
                                    <i className="fa fa-chevron-left date_left_button_icon" aria-hidden="true" />
                                </Button>
                                <Button className='date_button'>{Var_currentmonth}</Button>
                                <Button className='date_right_button' onClick={() => {
                                    nextMonth();
                                }}>
                                    <i className="fa fa-chevron-right date_right_button_icon" aria-hidden="true" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ==============================AdvertisementListCard======================================= */}
            <div className="content_view">
                {Var_AdvertisementBookedDataRef.current.map((responseData) => (
                    <Card className="advertisement_list_card">
                        <div>
                            <Grid container>
                                <Grid sm={10}>
                                    <Grid container>
                                        <Grid sm={2}>
                                            <h5 className="list_headings">Ad Location</h5>
                                            <label className="list_content">{responseData.ad_location}</label>
                                        </Grid>
                                        <Grid sm={2}>
                                            <h5 className="list_headings">Total Cost  <span style={{ fontSize: '10px' }}> (KWD)</span> </h5>
                                            <label className="list_content">{Datedata.formatMoney(responseData.ad_total_cost)}</label>
                                        </Grid>
                                        <Grid sm={2}>
                                            <h5 className="list_headings">Fee / Day  <span style={{ fontSize: '10px' }}> (KWD)</span></h5>
                                            <label className="list_content">{Datedata.formatMoney(responseData.ad_fee_per_day)}</label>
                                        </Grid>
                                        <Grid sm={2}>
                                            <h5 className="list_headings">Days</h5>
                                            <label className="list_content">{responseData.ad_total_days}</label>
                                        </Grid>
                                        <Grid sm={2}>
                                            <h5 className="list_headings">Start Date</h5>
                                            <label className="list_content_datae">{responseData.ad_start_date != "0000-00-00" ? dateformat(responseData.ad_start_date, "dd-mmm-yy") : " "}</label>
                                        </Grid>
                                        <Grid sm={2}>
                                            <h5 className="list_headings">End Date</h5>
                                            <label className="list_content_datae">{responseData.ad_end_date != "0000-00-00" ? dateformat(responseData.ad_end_date, "dd-mmm-yy") : " "}</label>
                                        </Grid>
                                    </Grid>
                                    <Grid container className="status_content">
                                        <Grid sm={1}></Grid>
                                        <Grid sm={10} className="status_conten_full">
                                            <div>
                                                <ul className="status_result">
                                                    <Box sx={{ width: '100%' }}>
                                                        <div className="advertisement_stepper" >
                                                            <Stepper
                                                                activeStep={responseData.activestep}
                                                                orientation="horizontal" alternativeLabel={true}
                                                                className="ad_stepper">
                                                                {responseData.Var_steps.map((label, i) => (
                                                                    <Step key={i}>
                                                                        <StepLabel
                                                                            StepIconComponent={label.Label != "Rejected" ? ColorlibStepIcon : rejectIcon}
                                                                        >{label.Label != "Rejected" ? label.Label : "Rejected"}</StepLabel>
                                                                        {label.Label == "Booked" &&
                                                                            <div>
                                                                                <p className="status_date">{moment(responseData.created_on).format("DD-MMM-YY")}</p>
                                                                                <p className="status_date" style={{ textTransform: "lowercase"}}>{moment(responseData.created_on).format("h:mm A")}</p>
                                                                            </div>
                                                                        }
                                                                        {(label.Label == "Rejected") &&
                                                                            <div>
                                                                                <div className="stepper_div_reject_reason" onClick={() => clickToViewReason(label.Reason)}> Click to see reason</div>
                                                                                <div>
                                                                                    <p className="status_date" style={{ textTransform: "lowercase", bottom: "80px" }}>{moment(responseData.modified_on).format("DD-MMM-YY")}</p>
                                                                                    <p className="status_date" style={{ textTransform: "lowercase", bottom: "80px" }}>{moment(responseData.modified_on).format("h:mm A")}</p>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {responseData.ad_approve_status == "A" && i == 1 &&
                                                                            <div>
                                                                                <p className="status_date">{moment(responseData.ad_approval_time).format("DD-MMM-YY")}</p>
                                                                                <p className="status_date" style={{ textTransform: "lowercase" }}>{moment(responseData.ad_approval_time).format("h:mm A")}</p>
                                                                            </div>}
                                                                        {(responseData.ad_start_date <= Var_CurrentDate.split(" ")[0]) && i == 2 &&
                                                                            <div>
                                                                                <p className="status_date">{moment(Var_CurrentDate).format("DD-MMM-YY")}</p>
                                                                                <p className="status_date" style={{ textTransform: "lowercase" }}>{moment(Var_CurrentDate).format("h:mm A")}</p>
                                                                            </div>}
                                                                    </Step>
                                                                ))}
                                                            </Stepper>
                                                        </div>
                                                    </Box>
                                                </ul>
                                            </div>
                                        </Grid>
                                        <Grid sm="1"></Grid>
                                    </Grid>
                                </Grid>
                                <Grid sm={2}>
                                    <Grid container>
                                        <Grid sm={6}>
                                            <img className="phone_image" src={responseData.ad_size == 1 ? phoneimagehalf : phoneimagefull}></img>
                                        </Grid>
                                        <Grid sm={6}>
                                            < img className="phone_image" hidden={responseData.ad_approve_status == "A"} src={editimage} onClick={() => { editAdvertisementBookings(responseData.id) }}></img>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <img src={responseData.ad_filename == "" || responseData.ad_filename == null ? alterImage : responseData.ad_filename} className="advertisementimage"></img>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Card>
                ))}
            </div>
            <div className="layout_footer footer">
                <FooterMaster />
            </div>



            <Modalcomp
                xswidth={"md"}
                clrchange="textclr"
                title={"REJECTION REASON"}
                visible={open}
                closemodal={handleClose}
                modelwidthClass='upload_instruction_adv'
            >
                <div className="reject_reason_content">{rejectReason}</div>
                <p style={{fontSize:"16px", color: "#868686"}}>Please check your mail.</p>
            </Modalcomp>



        </div >
    )
}
export default AdvertisementBookingListComponent;