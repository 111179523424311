import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import useStateRef from "react-usestateref";
import { notification } from "antd";
import moment from "moment";
import LinearProgress from '@mui/material/LinearProgress';



// Mui meterial component import
import { InputLabel, FormControlLabel, Select, MenuItem, Container, Card, Grid, TextField, Button, FormControl, DialogContent, Dialog, Radio } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import './UploadReport.css';
import profile from '../../assets/Images/TOMLogo.jpg';
import Report from '../../assets/icon/Group 19092.svg';
import Success from '../../assets/Doctor_web_Icon/success.png'
import FooterMaster from '../Dashboard/FooterMaster'
import Member from '../../assets/Web_Doctor_Icon_new_theme/Member.svg'
import Fasttrack from '../../assets/Web_Doctor_Icon_new_theme/fast_track.svg'
import Online from '../../assets/Web_Doctor_Icon_new_theme/Online.svg'
import Walkin from '../../assets/Web_Doctor_Icon_new_theme/walkin.svg'
import closeicon from '../../assets/Web_Doctor_Icon_new_theme/close.svg';
import successModelTickImg from '../../assets/Web_Doctor_Icon_new_theme/Success.svg'
// import uploadimage from "../../assets/icon/uploading-archive-1.svg";
import uploadimage from "../../assets/Doctor_web_Icon/Path 38241.svg";
import vector from '../../assets/Doctor_web_Icon/Vector.png'
import uploadimagerep from "../../assets/icon/uploading-archive-1.svg";
import MaleIcon from "../../assets/Web_Doctor_Icon_new_theme/MaleJpg.jpg";
import FemaleIcon from "../../assets/Web_Doctor_Icon_new_theme/FeMaleJpg.jpg";





const UploadReportComponent = () => {
    let Var_History = useHistory();
    let { patientId } = useParams();
    let { bookingId } = useParams();
    let { date } = useParams();

    const [fullWidth, setFullWidth] = useState(true);
    const [Var_FileName, setVar_FileName, Var_FileName_Ref] = useStateRef("Select file to upload");
    const [Var_File, setVar_File, Var_File_Ref] = useStateRef("");
    const [Var_ReportName, setVar_ReportName, Var_ReportName_Ref] = useStateRef("");
    var [Var_DateApi, setVar_DateApi, dateRefapi] = useStateRef("")
    var [Var_AppointmentDetail, setVar_AppointmentDetail] = useState([]);
    var [Var_TotalCuont, setVar_TotalCuont] = useState("");
    const [Var_SuccessModelText, setVar_SuccessModelText] = useState("");
    const [Var_ReportNamePlaceHolder, setVar_ReportNamePlaceHolder, Var_ReportNamePlaceHolder_Ref] = useStateRef("Enter Report Name");
    const [loading, setLoading] = useState(true);
    const [Var_progress, setVar_Progress] = useState(0);
    const [Var_fileprocess, setVar_fileprocess, Var_fileprocess_Ref] = React.useState(false);
    const [Var_buttoncolor, setVar_buttoncolor] = React.useState("#510F30");
    const [isDisable, setisisDisable] = useState(false);

    // const [Var_FileName, setVar_Var_FileName, Var_FileName_Ref] = useStateRef("");


    const [Var_IsshowSuccess, setVar_IsshowSuccess] = useState(false);

    // initial function call start
    useEffect(() => {
        setVar_DateApi(new Date().toLocaleDateString('fr-CA'));
        getAllAppointment()
        setisisDisable(false);
    }, []);
    // initial function call End


    // navigattion to back start
    const navigateTo = (path) => {
        Var_History.push("/dashboard" + path)
    };

    const okClose = () => {
        setVar_IsshowSuccess(!Var_IsshowSuccess)
        navigateTo("/appointments")

    }

    // File Upload Report Start

    // const uploadDocumentDoctor = async (e) => {
    //     debugger;
    //     setLoading(true);
    //     setVar_FileName(e.target.files[0].name);
    //     const formData = new FormData();
    //     formData.append('Bucket', "docterweb-appointment-upload-reports");
    //     formData.append('file', e.target.files[0]);

    //     axios.post("admin/awsS3FileUpload", formData).then((response) => {
    //         debugger;
    //         setVar_File(response.data.filepath.Location);
    //         setLoading(false);
    //     })
    //         .catch((error) => {
    //             setLoading(false);
    //             debugger;

    //         });
    // };
    const uploadDocumentDoctor = async (e) => {
        setisisDisable(true);
        var filetype = e.target.files[0].name.split('.')

        if (filetype[1] == "jpg" || filetype[1] == "jpeg" || filetype[1] == "png") {
            debugger;
            // setLoading(true);
            setVar_fileprocess(true)
            setVar_Progress(0)
            setVar_buttoncolor("#D4C3CC")
            setVar_FileName(e.target.files[0].name);
            const formData = new FormData();
            formData.append('module_id', "1");
            formData.append('file', e.target.files[0]);
            formData.append('pathLocation', "CLINICREPORT/")

            axios({
                method: "POST",
                url: "admin/awsS3FileUpload",
                data: formData,
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    console.log("progress", percentage)
                    setVar_Progress(percentage);
                },
            }).then((response) => {
                debugger;
                setVar_File(response.data.filepath.Location);
                setisisDisable(false);
                // setLoading(false);
                // setVar_fileprocess(false);
                setVar_buttoncolor("#510F30")
            })
                .catch((error) => {
                    // setLoading(false);
                    // setLoading(false);
                    setVar_fileprocess(false);
                    setisisDisable(false);
                    setVar_buttoncolor("#510F30");

                });
        }
        else {
            debugger;
            // setLoading(true); 
            setVar_fileprocess(true)
            setVar_Progress(0)
            setVar_buttoncolor("#D4C3CC")
            setVar_FileName(e.target.files[0].name);
            const formData = new FormData();
            formData.append('module_id', "1");
            formData.append('file', e.target.files[0]);
            formData.append('pathLocation', "CLINICREPORT/")

            axios({
                method: "POST",
                url: "admin/awsS3FileUpload",
                data: formData,
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    console.log("progress", percentage)
                    setVar_Progress(percentage);
                },
            }).then((response) => {
                debugger;
                setisisDisable(false);
                setVar_File(response.data.filepath.Location);
                // setLoading(false);
                // setVar_fileprocess(false);
                setVar_buttoncolor("#510F30")

            })
                .catch((error) => {
                    // setLoading(false);
                    // setLoading(false);
                    setisisDisable(false);
                    setVar_fileprocess(false);
                    setVar_buttoncolor("#510F30");
                });
        }
    };
    // const uploadDocumentDoctor = async (e) => {
    //     var filetype = e.target.files[0].name.split('.')

    //     if (filetype[1] == "jpg" || filetype[1] == "jpeg" || filetype[1] == "png" || filetype[1] == "pdf") {
    //         debugger;
    //         // setLoading(true);
    //         setVar_fileprocess(true)
    //         setVar_Progress(0)
    //         setVar_FileName(e.target.files[0].name);
    //         const formData = new FormData();
    //         formData.append('Bucket', "docterweb-appointment-upload-reports");
    //         formData.append('file', e.target.files[0]);
    //         axios({
    //             method: "POST",
    //             url: "admin/awsS3FileUpload",
    //             data: formData,
    //             onUploadProgress: (progressEvent) => {
    //                 const percentage = Math.round(
    //                     (progressEvent.loaded * 100) / progressEvent.total
    //                 );
    //                 setVar_Progress(percentage);
    //             },
    //         })
    //             // axios
    //             //     .post("admin/awsS3FileUploadpdfview", formData)
    //             //     onUploadProgress: (progressEvent) => {
    //             //         const percentage = Math.round(
    //             //           (progressEvent.loaded * 100) / progressEvent.total
    //             //         );
    //             //         setVar_Progress(percentage);
    //             //       },
    //             .then((response) => {
    //                 debugger;

    //                 setVar_File(response.data.filepath.Location);
    //                 // setLoading(false);
    //                 // setVar_fileprocess(false)
    //             })
    //             .catch((error) => {
    //                 // setLoading(false);
    //                 setVar_fileprocess(false)
    //                 debugger;

    //             });
    //     }
    //     else {

    //     }
    // };
    // File Upload Report End

    const sendReport = () => {
        setisisDisable(true);
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        console.log(formattedDate);
        debugger
        setLoading(true);
        var data = {

            patientId: patientId,
            doctorId: localStorage.getItem("Doctor_id"),
            bookingId: bookingId,
            clinicId: localStorage.getItem("Clinic_id"),
            reportName: Var_ReportName_Ref.current,
            reportFile: Var_File_Ref.current.split("?")[0],
            dateValue: formattedDate,
            is_walkin: 0

        }
        debugger;
        if (Var_ReportName_Ref.current != "" && Var_File_Ref.current != "" && formattedDate != "") {
            axios
                .post("clinic/reportByClinic", data)
                .then((response) => {
                    setisisDisable(false);
                    if (response.data.status == 1) {
                        setVar_FileName("")
                        setVar_IsshowSuccess(!Var_IsshowSuccess)
                        setVar_ReportNamePlaceHolder("Enter Report Name")
                        setVar_SuccessModelText("Report uploaded successfully")
                        setLoading(false);
                        setVar_File('')
                        setVar_Progress(0)
                    } else if (response.data.status == 0) {

                        notification.error({ message: response.data.msg });
                        setLoading(false);
                        setVar_File('')
                        setVar_Progress(0)
                    }
                    getAllAppointment()
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    setisisDisable(false);
                });
        } else {
            notification.error({ message: "Please fill all the data" })
            setLoading(false);
            setisisDisable(false);
        }

    }
    // =============== Get All Appointment Start ==========================================
    const getAllAppointment = () => {
        // var date = dateRefapi.current
        debugger
        setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("Clinic_id"),
            doctor_id: localStorage.getItem("Doctor_id"),
            patient_type: "",
            period: "day",
            search_date: date,//"2023-08-14",
            search_date_to: date //"2023-08-14",
        }
        axios
            .post("doctor/getAppointmentList", data)
            .then((response) => {
                console.log("Appoinment", response.data.data[0].result)
                var filtereddata = response.data.data[0].result.filter(x => x.patient_id == patientId && x.booking_id == bookingId);
                setVar_AppointmentDetail(filtereddata)
                setVar_TotalCuont(response.data.data[0].result.length)
                console.log("appointment Result", filtereddata)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            })

    }
    // Time conversion start

    const convertTo12HrFormat = (time24) => {
        var timeArr = time24.split(':');
        var hours = parseInt(timeArr[0]);
        var minutes = parseInt(timeArr[1]);
        var suffix = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12 || 12;
        var time12 = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + suffix;

        return time12;
    }
    const timeFormat = (time) => {
        let datetime = new Date('1970-01-01T' + time + 'Z');
        return datetime.toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' });
    }

    // Time conversion End
    // =============== Get All Appointment End ==========================================

    return (

        <div>
            {/* {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            } */}
            {loading &&
                <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
            }
            <div>
                <div>
                    <div className="sub_header">
                        <div className="sub_header_body">
                            <div className="back_navigation" onClick={() => navigateTo("/appointments")}>
                                <i className="fa fa-angle-left" aria-hidden="true"></i>
                                <span>REPORT</span>
                            </div>
                            <div className="flex_grow"></div>
                        </div>
                    </div>
                    <div className='content_view'>

                        <div>
                            <div>
                                {Var_AppointmentDetail.map((item) => (

                                    <div className='profile_details_prescription'>
                                        <div className='profile_detail_container'>
                                        <img src={(item.file_name != "" && item.file_name != " " && item.file_name != null)  ? item.file_name : item.gender.toLowerCase() =='male' ? MaleIcon : FemaleIcon} className='profile_Details' style={{ border: '1px solid var(--app-theme)' }} />
                                        <div className='detailsprofile'>
                                                <p className='profile_name'>{item.name}</p>
                                                {/* <p className='profile_number'>{item.mobile_no}</p> */}
                                                <p className='profile_age'>{item.age > 0 ? item.age + " " + "yrs," : ""} <span> {item.gender}</span></p>
                                            </div>
                                        </div>
                                        <div className="flex_grow"></div>
                                        <div className='profile_detail_type'>
                                            <img src={item.appointment_type_id == 1
                                                ? Member
                                                : item.appointment_type_id == 3
                                                    ? Fasttrack
                                                    : item.appointment_type_id == 2
                                                        ? Online
                                                        : item.appointment_type_id == 0
                                                            ? Walkin
                                                            : vector} alt='pro' className='Profoile_Type' />
                                            <p className='profile_time'>{item.appointment_time == null ? 0 : convertTo12HrFormat(item.appointment_time)}</p>
                                            <p className='report_date'>{moment(item.appointment_date).format("DD-MMM-YY")}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div>

                            </div>
                            <div className='Reportcontainer'>
                                <div className='EnterDetalis'>
                                    <Grid container>
                                        <Grid item align='start'>
                                            <label> Report Name</label>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item align='center'>
                                            <input className="text_filed" type='text' placeholder={Var_ReportNamePlaceHolder} onChange={(e) => setVar_ReportName(e.target.value)} />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item align='start'>
                                            <label> Select Report</label>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item align='center'>
                                            <div className="fileUpload fileUpload_report">
                                                <input type="file" className="upload" onChange={(e) => uploadDocumentDoctor(e)}onClick={(e) => (e.target.value = null)} placeholder={Var_FileName}
                                                />
                                                <span><img src={uploadimagerep} alt="Upload Icon" /></span>
                                                <p className="fileName">{Var_FileName}</p>
                                            </div>
                                            {Var_fileprocess &&
                                                <div>
                                                    {/* <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999999' }} /> */}
                                                    <div className="upload-container" style={{ paddingTop: '15px' }}>
                                                        {/* Your other UI elements */}
                                                        <LinearProgress
                                                            variant="determinate"
                                                            value={Var_progress}
                                                            color="secondary"
                                                            className="progressBar"
                                                            sx={{ zIndex: '1', width: '100%', margin: '0px 3px' }}
                                                        />
                                                        {/* Display progress percentage */}
                                                        <div className="progress-text">{`${Var_progress}%`}</div>
                                                    </div>
                                                </div>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>

                            <div className="segment_center">
                                <Grid container className='success_button_row'>
                                    <Grid item align='center'>
                                        <div className="segment_center">
                                            <div className="uploadbutton">
                                                <button className="botton_Cart_Create" disabled={isDisable} onClick={() => sendReport()}>Upload</button>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    {/* ====================== Upload Success=============================== */}



                    <Dialog
                        fullWidth={fullWidth}
                        open={Var_IsshowSuccess}
                        onClose={setVar_IsshowSuccess}
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "300px",
                                    borderRadius: "20px",
                                },
                            },
                        }}
                    >
                        <DialogContent>
                            <div className="success_model_body">
                                <div className="icon">
                                    <img src={successModelTickImg} />
                                </div>
                                <div className="title">success!</div>
                                <div className="content">Report uploaded successfully!</div>
                                <div className="segment_center">
                                    <button className="botton_Cart_Create" onClick={() => okClose()}>OK</button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
                <div className="layout_footer footer">
                    <FooterMaster />
                </div>
            </div>

        </div>
    )
}
export default UploadReportComponent;