import DateRangeSelect from "../../../helpers/DateRange/DateRange";
import PDFicon from "../../../assets/Web_Doctor_Icon_new_theme/pdf.svg";
import EXCEL from "../../../assets/Web_Doctor_Icon_new_theme/excel.svg";
import PRINT from "../../../assets/Web_Doctor_Icon_new_theme/print.svg";
import moment from "moment";
import Monthpicker from "../mothpicker/Monthpicker";
import "./RevenueSidemenu.css";
import dateFormat from "dateformat";
import useStateRef from "react-usestateref";
import { useHistory, useParams } from "react-router-dom";

const Header = (props) => {
  let Var_History = useHistory();
  let { fromdate } = useParams();
  let { todate } = useParams();
  const [var_startDate, setvar_startDate, var_startDate_ref] = useStateRef("");
  const [var_endDate, setvar_endDate, var_endDate_ref] = useStateRef("");
  const[title,settitle,title_ref]=useStateRef([]);
  const[excelD,setexcelD,exceD_ref]=useStateRef([]);
  const[excelData,setExcelData,exceldata_ref]=useStateRef([]);
  // var [Var_fromDate, SetVar_fromDate, var_startDate_ref] = useStateRef(fromdate);
  // var [Var_toDate, SetVar_toDate, var_startDate_ref] = useStateRef(todate);

  const getRangeDate = (item) => {
    debugger;
    var fromDate = dateFormat(item.startDate, "yyyy-mm-dd");
    var toDate = dateFormat(item.endDate, "yyyy-mm-dd");
    setvar_startDate(fromDate);
    setvar_endDate(toDate);
    props.startDateFn(fromDate);
    props.endDateFn(toDate);
    // getRevenueList()
  };
  const handleChange = (arg) => {
    settitle('')
    setexcelD([])
    setExcelData([])
  }
  return (
    <div className="header_settle">
      <div className="header_settle_body">
        <div className="back_navigation">
          <span className="header_value">{props.title}</span>
        </div>
        <div className="d-flex">
          {/* <DateRangeSelect
            dynalign={"dynalign"}
            rangeDate={(item) => getRangeDate(item)}
            startDate={new Date(props.startDate)}
            endDate={new Date(props.endDate)}
            
            /> */}
            
             <Monthpicker
                  s_month={props.idControl==1 ?  moment(props.StartDate).add('year').format('YYYY-MM') : ''}
                  rangeDate={(item) => getRangeDate(item)}
                />        
           

          {props.title !== "Payable & Receivable Due" &&
            props.title !== "Settlement" && (
              <div className="d-flex" >
                <div className="header_img_down">
                  <img
                    className="iconhead"
                    src={PDFicon}
                    onClick={props.triggerPDF}
                    alt="PDF icon"
                  />
                </div>
                <div className="header_img_down">
                  <img
                    className="iconhead"
                    src={EXCEL}
                    alt="Excel icon"
                    onClick={props.triggerExcle}
                  />
                </div>
                <div className="header_img_down">
                  <img
                    className="iconhead"
                    src={PRINT}
                    onClick={props.triggerPRINT}
                    alt="Print icon"
                  />
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
export default Header;
