import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import React, { useEffect, useState } from "react";
import Axios from "axios";
import SuccessTick from '../../../src/images/success_tick.png';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { decryptKey } from '../../App'


function AdvertisementSuccess() {
  const [isSuccess, setIsSuccess] = useState(true);
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
        const urlWithoutFragment = window.location.href.split('#')[0];
       
        let resultValue = urlWithoutFragment.split("=").length>0 ? urlWithoutFragment.split("=")[1] : "" 

        if (resultValue && resultValue!="")
        {
            resultValue = resultValue.replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            let response_data= decrypt(resultValue, decryptKey);
            if(response_data !="")
            {
                response_data = response_data.split("&")
                  // Creating a dictionary to store key-value pairs
                const dataObj = {};

                // Populating the dictionary with key-value pairs
                response_data.forEach(pair => {
                  const [key, value] = pair.split('=');
                  dataObj[key] = value;
                });

                if(dataObj.result=="CAPTURED")
                {
                  let requestdata = {
                    paymentid: dataObj['paymentid'],
                    result: dataObj['result'],
                    auth: dataObj['auth'],
                    avr: dataObj['avr'],
                    ref: dataObj['ref'],
                    tranid: dataObj['tranid'],
                    postdate: dataObj['postdate'],
                    trackid: dataObj['trackid'],
                    udf1: dataObj['udf1'],
                    udf2: dataObj['udf2'],
                    udf3: dataObj['udf3'],
                    udf4: dataObj['udf4'],
                    udf5: dataObj['udf5'],
                    amt: dataObj['amt']
                  }

                  Axios({
                    method: "POST",
                    url: "Patient/knetPaymentTransactions",
                    data: requestdata,
                  })
                    .then((response) => {
                      localStorage.setItem('paymentstatus','completed');
                      localStorage.setItem('resultindicator', dataObj['paymentid']);
                      localStorage.setItem('referenceid',dataObj['tranid']);
                      localStorage.setItem('paymentGatewayName',"KNET");
                      localStorage.setItem('paymentObject', JSON.stringify(dataObj));
                      setIsSuccess(true)
                      startTimer()
                    })
                    .catch((err) => {
                      localStorage.setItem('paymentstatus','failed');
                      setIsSuccess(false)
                      startTimer()
                    });

                  
                  
                // window.location.href= window.location.origin +"/trainingcenter/?/Home/advertise";
                }
                else
                {
                  localStorage.setItem('paymentstatus','failed');
                  localStorage.setItem('resultindicator', dataObj['paymentid']);
                  localStorage.setItem('referenceid',dataObj['tranid']);
                  localStorage.setItem('paymentGatewayName',"KNET");
                  localStorage.setItem('paymentObject', JSON.stringify(dataObj));
                  setIsSuccess(false)
                  startTimer()
                }

                
            }
            else
            {         
              startTimer()
            }
        }
        else
        {
          startTimer()
        }

    })

    const startTimer = () => {
      const intervalId = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1); // Use functional update to update state based on previous state
      }, 1000);
      return () => clearInterval(intervalId);
    
   }

    useEffect(() => {

      if (seconds <= 1) {
        setSeconds(0)
        window.location.href = '/Doctormodule/?/dashboard/advertisementbooking'; // Redirect using window.location.href
      }
    }, [seconds]);

    const decrypt = (data,termResourceKey) =>
    {

      // const decrypt_final = data.toString().replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=');
      // const decData1 = decryptData(decrypt_final);
      // console.log("Decrypted Data Final = ", decData1);
      const decodedData = atob(data);
      let result = '';
      const keyLength = termResourceKey.length;
      for (let i = 0; i < decodedData.length; i++) {
          const char = decodedData[i];
          const keyChar = termResourceKey[i % keyLength];
          result += String.fromCharCode(char.charCodeAt(0) - keyChar.charCodeAt(0));
      }
      return result;
    }

    return (
      <div style={{ width: '75%', marginLeft: 'auto', marginRight: 'auto', height: 'calc(100vh)', marginTop: '4rem' }}>
          <div style={{ display: (isSuccess ? "block" : "none") }}>
              <div style={{ textAlign: 'center' }}>
                  <img src={SuccessTick} className="tick_mark" />
              </div>
              <div style={{ textAlign: 'center', marginTop: '1rem', fontSize: '5rem', fontWeight: 'bold' }}>
                  Your payment was successful
              </div>
              <div style={{ textAlign: 'center', marginTop: '1rem', fontSize: '2rem' }}>
                  You will be redirected to Doctor Management System site within {seconds} seconds
              </div>
          </div>
          <div style={{ display: (isSuccess ? "none" : "block") }}>
              <div style={{ textAlign: 'center' }}>
                  <CancelOutlinedIcon className="cross_mark"></CancelOutlinedIcon>
              </div>
              <div style={{ textAlign: 'center', marginTop: '1rem', fontSize: '5rem', fontWeight: 'bold' }}>
                  Your payment failed
              </div>
              <div style={{ textAlign: 'center', marginTop: '1rem', fontSize: '2rem' }}>
                  You will be redirected to Doctor Management System site within {seconds} seconds
              </div>
          </div>
      </div>
    )
}
export default AdvertisementSuccess;