import React from "react";
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
// import Button from "@material-ui/core/Button";
// import dateformat from 'dateformat';
// import "./Monthpicker.css";
// import Labelbox from "../../../helpers/labelbox/labelbox";
import moment from "moment";
import './Monthpicker.css';
import Datedata from "./Datedata";
// import Moment from 'moment/min/moment-with-locales';

export default class Monthpicker extends React.Component {

    state = {
        // item: [{
        //     startDate: this.props.startDate? this.props.startDate: '',
        //     endDate: this.props.endDate? this.props.endDate:'',
        // }],
        
        // selectedMonth: moment().format('YYYY-MM'),
        openDateRange: false,
        SelectRange:''
    }
    
handleMonthChange = (event) => {
     debugger
     const selectedMonthEvent = event.target.value;
     if(event !==''){
     const selectedMonth1 = moment(selectedMonthEvent);
     const startOfMonth = selectedMonth1.startOf('month').format('YYYY-MM-DD');
     const endOfMonth = selectedMonth1.endOf('month').format('YYYY-MM-DD');
     Datedata.setSelectedDate(selectedMonthEvent);
    //  this.setState({selectedMonth:selectedMonthEvent});     
            //  item:[{
            //     startDate:startOfMonth,
            //     endDate:endOfMonth
            //  }] },()=>this.props.rangeDate(Datedata.item)   
    Datedata.setdate(startOfMonth, endOfMonth);
    this.props.rangeDate(Datedata.item);
    }
 }
    render() {
        return (
            <div style={{zIndex:"1"}}>
                <div className="rangePicker_Master">
                    <div className="rangePicker_Label">
                        {"Select Month"} :
            </div>
                    <div className="rangePicker_InpputIconAlign" style={{padding:'25px 0px'}}>   
                    <input type="month" className="selectmonth_input" id="start" name="start" min="2021-03" value={Datedata.selectedmonth}
                    onChange={this.handleMonthChange} />
                    </div>
                </div>
                {this.state.openDateRange && 
                <input type="month" className="selectmonth_input" id="start" name="start" min="2021-03" value="2018-05" />
                }
            </div>
        )
    }
}