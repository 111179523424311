import Axios from "axios";
import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import React, { useState, useEffect } from "react";

// Mui material component import
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";

import "./ManageServiceMaster.css";
import "bootstrap/dist/css/bootstrap.min.css";
import FooterMaster from "../Dashboard/FooterMaster";
import Datedata from '../Revenue_settle/mothpicker/Datedata';
import Success from "../../assets/Web_Doctor_Icon_new_theme/Success.svg";
import deleteIcon from "../../assets/Web_Doctor_Icon_new_theme/Delete.svg";
import editIcon from "../../assets/Web_Doctor_Icon_new_theme/Edit.svg";
import Alert from "../../assets/Web_Doctor_Icon_new_theme/alert.svg";
import vector from "../../assets/Doctor_web_Icon/Vector.png";
import closeicon from "../../assets/Web_Doctor_Icon_new_theme/close.svg";

function ManageServiceComponent() {
  let Var_History = useHistory();
  let interval;
  const [, setVar_PopupImg, setVar_PopupImg_Ref] = useStateRef("");
  const [, setVar_Popup_content, Var_Popup_content_Ref] = useStateRef("");
  const [, setVar_Popup_Message, Var_Popup_Message_Ref] = useStateRef("");
  const [Var_ManageServicesdata, setVar_ManageServicesdata] = useStateRef([]);
  const [loading, setLoading] = useState(true);

  const [,
    setVar_ManageServiceDeleteId,
    Var_ManageServiceDeleteId_Ref,
  ] = useStateRef("");
  const [, setVarimagepopup, Varimagepopup_Ref] = useStateRef("");

  const [
    Var_IsShowDeleteManageServiceModel,
    setVar_IsShowDeleteManageServiceModel,
  ] = useStateRef(false);
  const [Var_IsShowSuccess, setVar_IsShowSuccess] = useState(false);
  const [isOpenimageModel, setIsOpenimageModel] = React.useState(false);
  const [verification, setVerification] =
    useState(Var_History.location.state ? (Var_History.location.state.verification ? Var_History.location.state.verification : false) : false);
  // initial function call start
  useEffect(() => {
    getManageServicesList();
    return () => {
      clearInterval(interval); // Cleanup the interval on component unmount
    };
  }, []);
  // initial function call end

  // navigattion to back start
  const navigateToBack = (path) => {
    Var_History.push("/dashboard" + path);
  };
  // navigattion to back end

  // ========================================== Get Manage Services List Start =========================================
  const getManageServicesList = () => {
    setLoading(true);
    let Var_RequestData = {
      doctor_id: localStorage.getItem("Doctor_id"),
    };

    Axios({
      method: "POST",
      url: "doctor/getDoctorService",
      data: Var_RequestData,
    })
      .then((response) => {
        setVar_ManageServicesdata(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

      clearInterval(interval);
      interval = setInterval(() => {
        getManageServicesList();
      }, 120000); // 1000ms interval
  };
  // =========================================== Get Manage Services List End =================================================

  const isOpenAddDeal = (value) => {
    setVarimagepopup(value);
    setIsOpenimageModel(true);
  };

  // ====================================== Add ManageService Start  ====================================
  const addService = () => {
    navigateToBack("/manageservice/addservice");
  };
  // ====================================== Add ManageService End ====================================

  // ====================================== EditManageServiceStart ====================================
  const editManageService = (data, name, bname) => {
    navigateToBack("/manageservice/editservice/" + data.id);
  };
  // ====================================== EditManageServiceEnd ====================================

  const isCloseimageModel = () => {
    setIsOpenimageModel(false);
  };

  //  =============================== Delete Manage Service  start ======================================
  const deleteManageServiceList = () => {
    setLoading(true);
    setVar_IsShowDeleteManageServiceModel(!Var_IsShowDeleteManageServiceModel);

    let Var_RequestData = {
      id: Var_ManageServiceDeleteId_Ref.current,
    };

    Axios({
      method: "DELETE",
      url: "doctor/delete_mas_doctor_service_type",
      data: Var_RequestData,
    })
      .then((response) => {
        if (response.data.status == 1) {
          setVar_PopupImg(Success);
          setVar_Popup_content("SUCCESS!");
          setVar_Popup_Message("Manage Service deleted successfully!");
          deleteServiceModels();
          getManageServicesList();
          setLoading(false);
        }
        else {
          setVar_PopupImg(Alert);
          setVar_Popup_content("FAILED!");
          setVar_Popup_Message(response.data.msg + "!");
          deleteServiceModels();
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  // ================================== Delete Manage Service End ===============================

  const deleteManageService = (data) => {
    setVar_IsShowDeleteManageServiceModel(!Var_IsShowDeleteManageServiceModel);
    setVar_ManageServiceDeleteId(data);
  };
  const deleteServiceModels = () => {
    setVar_IsShowSuccess(!Var_IsShowSuccess);
    getManageServicesList();
  };
  const oKModalClose = () => {
    setVar_IsShowSuccess(!Var_IsShowSuccess);
    if (!verification) {
      Var_History.push("/dashboard/manageservice");
    } else {
      Var_History.push("/dashboard");
    }
  };

  return (
    <div>
      {loading && (
        <LinearProgress
          color="secondary"
          className="progressBar"
          sx={{ zIndex: "9999" }}
        />
      )}
      {/* ======================= SubHeader Start ======================================= */}
      <div className="sub_header">
        <div className="sub_header_body">
          <div className="back_navigation" onClick={() => navigateToBack("/")}>
            <i class="fa fa-angle-left" aria-hidden="true"></i>
            <span>MANAGE SERVICES</span>
          </div>
          <div className="flex_grow"></div>
          <div className="header_right_content">
            <div>
              <button
                className="add_btn_post"
                onClick={() => addService()}
                style={{ border: "none" }}
              >
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                <span>Add Service</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ======================= SubHeader End ======================================= */}

      {/* ======================= ManageService body  Content  Start ======================================= */}
      <div className="content_view tableset-width post-content-view">
        <Grid container className="content_row_manage">
          <Grid
            sm={12}
            md={3}
            lg={2}
            align="start"
            className="manage_service_data"
          >
            <h6 className="profile_head_service">
              <b>Service Title</b>
            </h6>
          </Grid>
          <Grid sm={12} md={2} lg={3} className="manage_service_data">
            <h6 className="profile_head_service">
              <b>Service Description</b>
            </h6>
          </Grid>
          <Grid sm={12} md={2} lg={1.5} className="manage_service_data">
            <h6 className="profile_head_service">
              <b>Duration</b> <span style={{ fontSize: "10px", color: "#000", fontWeight: "bold" }}> (mins)</span>
            </h6>
          </Grid>
          <Grid sm={12} md={2} lg={1.5} className="manage_service_data">
            <h6 className="profile_head_service">
              <b>
                Fee <span style={{ fontSize: "10px" }}>(KWD)</span>
              </b>
            </h6>
          </Grid>
          <Grid sm={12} md={1} lg={1} align="center">
            <h6 className="profile_head_services"><b>Thumbnail</b></h6>
          </Grid>
          <Grid sm={12} md={2} lg={2} align="center">
            <h6 className="profile_head_service">
              <b>Action</b>
            </h6>
          </Grid>
          <Grid sm={12} md={2} lg={1} align="center" style={{ paddingLeft: '10px' }}>
            <h6 >
              <b>Status</b>
            </h6>
          </Grid>
        </Grid>

        <div>
          {Var_ManageServicesdata.map((responseData, i) => (
            <div className="card_content" key={i}>
              <Grid container className="content_row_manage">
                <Grid
                  sm={12}
                  md={3}
                  lg={2}
                  align="start"
                  className="manage_service_data"
                >
                  <h6 className="profile_head_service">
                    {responseData.service}
                  </h6>
                </Grid>
                <Grid sm={12} md={2} lg={3} className="manage_service_data">
                  <h6 className="profile_head_service" style={{marginRight:"30px"}} >
                    {responseData.description}
                  </h6>
                </Grid>
                <Grid sm={12} md={2} lg={1.5} className="manage_service_data">
                  <h6 className="profile_head_service Durationslight">
                    {responseData.slot_duration}
                  </h6>
                </Grid>
                <Grid sm={12} md={2} lg={1.5} className="manage_service_data">
                  <h6 className="profile_head_service Durationslight">
                    {Datedata.formatMoney(responseData.cost)}
                  </h6>
                </Grid>
                <Grid sm={12} md={1} lg={1} align="center">
                  <img
                    src={
                      responseData.file_path != ""
                        ? responseData.file_path
                        : vector
                    }
                    className="card_row_img_manage"
                    onClick={() => isOpenAddDeal(responseData.file_path)}
                    alt=""
                  />
                </Grid>
                <Grid sm={12} md={2} lg={2} className="manage_service_active">
                  <div>
                    <img src={editIcon} className="edit_icon_service1" onClick={() => editManageService(responseData, "Edit", "Update")} alt=""></img>
                    <img src={deleteIcon} className="edit_icon_service" onClick={() => deleteManageService(responseData.id)}alt="" />
                  </div>
                </Grid>
                <Grid sm={12} md={2} lg={1} className="manage_service_data active-inactive">
                  <span className="manage_active">{responseData.is_active == 1 ? "Active" : "In-Active"}</span>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      </div>
      {/* =======================ManageService body  Content  End======================================= */}
      <div >
        {/* ======================Delete Post Confirm Model Start======================================= */}
        <Dialog
          className="delete_model"
          open={Var_IsShowDeleteManageServiceModel}
          onClose={setVar_IsShowDeleteManageServiceModel}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div>
              <Grid container>
                <Grid item xs={12} align="center">
                  <img alt="" className="image_body_sizes" style={{ position: 'relative', top: '15px' }} src={Alert} />
                </Grid>
                <Grid item xs={12} align="center">
                  <div>
                    <div className="success_body_size_alert">ALERT!</div>
                  </div>
                </Grid>
                <Grid item xs={12} align="center">
                  <p className="success_msg"> Do you want to delete this service?</p>
                </Grid>
                <Grid item xs={12} align="center">
                  <Button
                    className="alert_no_btn"
                    onClick={() => deleteManageService()}
                  >
                    No
                  </Button>
                  <Button
                    className="alert_yes_btn"
                    onClick={() => deleteManageServiceList()}
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
        {/* ====================== Delete Post Confirm Model End======================================= */}

        {/* ======================DeleteModealSuccess start ==================================== */}
        <Dialog
          className="delete_model"
          open={Var_IsShowSuccess}
          onClose={setVar_IsShowSuccess}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px", 
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div>
              <Grid container>
                {Var_Popup_content_Ref.current == 'SUCCESS!' && <Grid item xs={12} align="center" className="success_card">
                  <img className="image_body_size" src={setVar_PopupImg_Ref.current} alt=""/>
                </Grid>}
                {Var_Popup_content_Ref.current == 'FAILED!' && <Grid item xs={12} align="center" className="success_card">
                  <img className="image_body_sizes" src={setVar_PopupImg_Ref.current} alt=""/>
                </Grid>}
                {Var_Popup_content_Ref.current == 'SUCCESS!' && <Grid item xs={12} align="center" className="success_card">
                  <p className="success_body_size_manage">{Var_Popup_content_Ref.current}</p>
                </Grid>}
                {Var_Popup_content_Ref.current == 'FAILED!' && <Grid item xs={12} align="center" className="success_card">
                  <p className="success_body_size_manage" style={{ color: '#ff0068' }}>{Var_Popup_content_Ref.current}</p>
                </Grid>}
                <Grid item xs={12} align="center" className="success_card">
                  <p className="success_msg">
                    {Var_Popup_Message_Ref.current}
                  </p>
                </Grid>
                <Grid item xs={12} align="center" className="success_card">
                  <Button
                    className="success_ok_btn"
                    onClick={() => oKModalClose()}
                  >
                    Ok
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
        {/* ======================DeleteModealSuccess end ==================================== */}

        <Dialog
                fullWidth={true}
                open={isOpenimageModel}
                onClose={isCloseimageModel}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                          width: "100%",
                          maxWidth: "340px",
                          borderRadius: "20px",
                          height: "51%"
                        },
                    },
                }}
            >

                <div className="dialog_header">
                    <div className="dialog_title">VIEW DOCUMENT</div>
                    <div className="flex_grow"></div>
                    <div onClick={() => isCloseimageModel()}>
                        <img src={closeicon} alt=""/>
                    </div>
                </div>
                <DialogContent>
                <img
                className="image_content_manage_servive"
                src={
                  Varimagepopup_Ref.current !== ""
                    ? Varimagepopup_Ref.current
                    : vector
                }
                alt="" ></img>
                </DialogContent>
            </Dialog>





        {/* ================================jhgfds=========================== */}
      </div>
      {/* ======================Footer start==================================== */}
      {
        localStorage.getItem("FooterStatus") == 'A' && <div className="layout_footer footer">
          <FooterMaster />
        </div>
      }
      {/* ======================Footer End==================================== */}
    </div >
  );
}
export default ManageServiceComponent;
