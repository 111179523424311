import Sidemenu from "./Revenue_Sidemenu/RevenueSidemenu";
import MediaCard from "./Revenue_Charts/RevenueCards";
import WeekChart from "./Revenue_Charts/DashboardChart";
import DateRangeSelect from "../../helpers/DateRange/DateRange";
import RevenueTable from "./Revenue_Tables/RevenueTable";
import "./RevenueDashboard.css";
import Container from "react-bootstrap/Container";
import moment from "moment";
import Monthpicker from "./mothpicker/Monthpicker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import jsonData from "./local.json";
import React, { useState, useEffect } from "react";
import useStateRef from "react-usestateref";
import Axios from "axios";
import FooterMaster from "../Dashboard/FooterMaster";
import dateFormat from "dateformat";
import { useHistory } from "react-router-dom";
import Datedata from "./mothpicker/Datedata";

const RevenueDashboard = () => {
  const href = useHistory();
  const dashboard = jsonData.Dashboards;
  const [responseValue, setresponseValue, responseValue_Ref] = useStateRef({});
  const [weekdata, setweekdata, weekdata_ref] = useStateRef([]);
  const [selectedcard, setselectedcard, selectedcard_ref] = useStateRef("");
  const [var_startDate, setvar_startDate, var_startDate_ref] = useStateRef("");
  const [var_endDate, setvar_endDate, var_endDate_ref] = useStateRef("");
  const [isvendor, setisvendor, isvendor_ref] = useStateRef(false);
  // const [isvendortype, setvendortype, isvendortype_ref] = useStateRef('');
  // var [var_startDate, SetVar_fromDate, Var_fromDate_Ref] = useStateRef();
  // var [var_endDate, SetVar_toDate, Var_toDate_Ref] = useStateRef();
  const [isRevenue, setisRevenue, isRevenue_ref] = useStateRef('switch');
  const [isSettlement, setSettlement, isSettlement_ref] = useStateRef('');

  useEffect(() => {
    getRevenueList();
    debugger
   
  }, []);

  // const MonthDate = () => {
  //   var date = new Date();
  //   var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  //   var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  //   console.log(firstDay,lastDay," 8181");
  // };

  const getRevenueList = () => {
    debugger
    setvar_startDate(Datedata.item.startDate);
    setvar_endDate(Datedata.item.endDate);
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var data = {
      doctorId: localStorage.getItem("Doctor_id"),
      //doctorId: localStorage,
      fromDate:
        var_startDate_ref.current == ""
          ? dateFormat(firstDay, "yyyy-mm-dd")
          : var_startDate_ref.current,
      toDate:
        var_endDate_ref.current == ""
          ? dateFormat(lastDay, "yyyy-mm-dd")
          : var_endDate_ref.current,
    };
    const fetchData = async () => {
      try {
        const response = await Axios.post(
          "/patient/getDoctorRevenueCardDetls",
          data
        );
        if (response.data.data.length > 0) {
          setresponseValue(response.data.data[0]);
        }
        console.log(response.data.data[0]);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  };
  const getRangeDate = (item) => {
    debugger;
    var fromDate = dateFormat(item.startDate, "yyyy-mm-dd");
    var toDate = dateFormat(item.endDate, "yyyy-mm-dd");
    setvar_startDate(fromDate);
    setvar_endDate(toDate);
    // props.startDate(fromDate);
    // props.endDate(toDate);
    getRevenueList();
  };

  // async function apicall() {
  //   var data = {
  //     doctorId: "152",
  //     fromDate: "2023-12-01",
  //     toDate: "2023-12-31",
  //   };
  //   await Axios({
  //     method: "POST",
  //     url: "/patient/getDoctorRevenueCardDetls",
  //     data: data,
  //   })
  //     .then((response) => {
  //       debugger;
  //       if (response.data.data.length > 0) {
  //         setresponseValue(response.data.data[0]);
  //       }
  //       console.log(response.data.data[0]);
  //     })
  //     .catch((err) => {});
  // }

  const handleRadioChange = (event) => {
    
    const toggle = event.target ? event.target.value : event;

    if (toggle == "revenue") {
      setSettlement('');
      setisRevenue('switch');
      href.push(`/dashboard/revenue`);
    } else if (toggle === "settlement") {
      setSettlement('switch');
      setisRevenue('');
      href.push(`/dashboard/settlement`);
    }
  };

  return (
    <div className="Scrool_bar_dash">
      <Row>
        <Col xs={12} className="d-flex">
          <Col sm={2} md={2} xs={2} className="menucol">
            <Sidemenu></Sidemenu>
          </Col>
          <Col
            sm={10}
            md={10}
            xs={10}
            // style={{ height: "850px", overflow: "auto" }}
          >
            <Row 
            // style={{ height: "100px" }}
            >
              <div className="header_settle">
                <div className="header_settle_body">
                  <div className="back_navigation">
                    <span className="header_value">REVENUE & INCOME </span>
                  </div>
                  <div>

                  <Monthpicker
                  // s_month={moment().format('YYYY-MM')}
                  rangeDate={(item) => getRangeDate(item)}
                  />
                    {/* <DateRangeSelect
                      dynalign={"dynalign"}
                      rangeDate={(item) => getRangeDate(item)}
                      startDate={
                        new Date(
                          var_startDate_ref.current == ""
                            ? dateFormat(
                                new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth(),
                                  1
                                ),
                                "yyyy-mm-dd"
                              )
                            : var_startDate_ref.current
                        )
                      }
                      endDate={
                        new Date(
                          var_endDate_ref.current == ""
                            ? dateFormat(
                                new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth() + 1,
                                  0
                                ),
                                "yyyy-mm-dd"
                              )
                            : var_endDate_ref.current
                        )
                      }
                    /> */}
                  </div>
                </div>
              </div>
            </Row>

            <Row>
            <div className="RS_toggler">
              <div className="switches-container">
              <input type="radio" id="totalrec" name="switchPlan" value="revenue" checked="checked" onChange={handleRadioChange}/>
              <label htmlFor="totalrec" className={isRevenue_ref.current}>Revenue</label>
              <input type="radio" id="recsof" name="switchPlan" value="settlement"  onChange={handleRadioChange}/>
              <label htmlFor="recsof" className={isSettlement_ref.current}>Settlement</label>
              </div>
            </div>
            </Row>

            <Row>
              <div class="container flex-wrap">
                {" "}
                <MediaCard
                  data={responseValue_Ref.current}
                  setdata={setweekdata}
                  setvendor={setisvendor}
                  startDate={
                    var_startDate_ref.current == ""
                      ? dateFormat(
                          new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            1
                          ),
                          "yyyy-mm-dd"
                        )
                      : var_startDate_ref.current
                  }
                  endDate={
                    var_endDate_ref.current == ""
                      ? dateFormat(
                          new Date(
                            new Date().getFullYear(),
                            new Date().getMonth() + 1,
                            0
                          ),
                          "yyyy-mm-dd"
                        )
                      : var_endDate_ref.current
                  }
                ></MediaCard>
              </div>
            </Row>

            <Row className="chartbox">
              <div className="container">
                {" "}
                {!isvendor_ref.current &&
                  Object.keys(responseValue_Ref.current).length > 0 && (
                    <WeekChart
                      data={responseValue_Ref.current}
                      type={"module"}
                      fromDate={
                        var_startDate_ref.current == ""
                          ? dateFormat(
                              new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                1
                              ),
                              "yyyy-mm-dd"
                            )
                          : var_startDate_ref.current
                      }
                      toDate={
                        var_endDate_ref.current == ""
                          ? dateFormat(
                              new Date(
                                new Date().getFullYear(),
                                new Date().getMonth() + 1,
                                0
                              ),
                              "yyyy-mm-dd"
                            )
                          : var_endDate_ref.current
                      }
                    ></WeekChart>
                  )}
                {isvendor_ref.current && weekdata_ref.current.length > 0 && (
                  <WeekChart
                    data={weekdata_ref.current}
                    type={"week"}
                    fromDate={
                      var_startDate_ref.current == ""
                        ? dateFormat(
                            new Date(
                              new Date().getFullYear(),
                              new Date().getMonth(),
                              1
                            ),
                            "yyyy-mm-dd"
                          )
                        : var_startDate_ref.current
                    }
                    toDate={
                      var_endDate_ref.current == ""
                        ? dateFormat(
                            new Date(
                              new Date().getFullYear(),
                              new Date().getMonth() + 1,
                              0
                            ),
                            "yyyy-mm-dd"
                          )
                        : var_endDate_ref.current
                    }
                  ></WeekChart>
                )}
              </div>
            </Row>
          </Col>
        </Col>
        <div className="layout_footer footer">
          <FooterMaster />
        </div>
      </Row>
    </div>
  );
};
export default RevenueDashboard;
