
// AUTHOR : PRAVEEN S
// CR-DATE: 
// MOD-DATE: 05-08-2023
// DESCRIPTION: menu active

import { useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import useStateRef from "react-usestateref";
import { message, notification, Spin } from "antd";
// import LinearProgress from '@mui/material/LinearProgress';


// Mui meterial component import
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

// Footer css Start
import './FooterMaster.css';
// Footer css end

// Default Images start
import Default_HomeImage from "../../assets/Web_Doctor_Icon_new_theme/home.svg";
import Active_HomeImage from "../../assets/Doctor_web_Icon/active_home_icon.svg";

import Default_Advertisement_Image from "../../assets/Web_Doctor_Icon_new_theme/advertisement.svg";
import Active_Advertisement_Image from "../../assets/Doctor_web_Icon/active_advertisement_icon.svg";

import Default_Deal_Image from "../../assets/Web_Doctor_Icon_new_theme/Deal.svg";
import Active_Deal_Image from "../../assets/Doctor_web_Icon/active_deal_icon.svg";

import Default_Collateral_Image from "../../assets/Web_Doctor_Icon_new_theme/Collaterals.svg";
import Active_Collateral_Image from "../../assets/Doctor_web_Icon/active_collatera_icon.png";

import Default_Revenue_Image from "../../assets/Web_Doctor_Icon_new_theme/Revenue.svg";
import Active_Revenue_Image from "../../assets/Doctor_web_Icon/active_revenue_icon.svg";


// Default  Images end

function FooterMasterComponent(props) {

    // Navigation 
    let Var_History = useHistory();
    const Var_location = useLocation();
    const Var_queryParams = new URLSearchParams(Var_location.pathname);
    const Var_Urlvalue = Var_queryParams.get('key'); // Retrieve the value of a specific query parameter
    console.log(Var_location.pathname, 1010)
    // variables
    const [Var_TabPermissionById, setVar_TabPermissionById, Var_TabPermissionById_Ref] = useStateRef(null);
    const [Var_Active_menu, setVar_Active_menu, Var_Active_menu_Ref] = useStateRef("");
    const [loading, setLoading] = useState(true);
    const [Var_PermissionObject, setVar_PermissionObject, Var_PermissionObject_Ref] = useStateRef(null);
    // Initial load method start
    useEffect(() => {
        debugger
        // clinicTabPermissionById();
        setActiveLink(sessionStorage.getItem("url"))
        if (localStorage.getItem("activeMenu") == "") {
            setVar_Active_menu("");
            setLoading(false)
        }
        else {
            setVar_Active_menu(localStorage.getItem("activeMenu"));
            setLoading(false)
        }
        // clinicTabPermissionById();
    }, []);

    const clinicTabPermissionById = () => {
        //setLoading(true);
        var data = {
            doctorId: localStorage.getItem("Doctor_id"),
            clinicId: localStorage.getItem("Clinic_id")
        };
        axios
            .post("doctor/clinicTabPermissionbyId", data)
            .then((response) => {
                debugger
                setVar_PermissionObject(response.data.data[0]);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
    const [activeLink, setActiveLink] = useState("/dashboard/");
    // console.log(activeLink,10, Var_location.pathname, 11111)
    // Initial load method end

    // Menu permission get api start
    // const clinicTabPermissionById = () => {
    //     
    //     setLoading(true);
    //     var data = {
    //         doctorId: localStorage.getItem("doctor_id"),
    //         clinicId: localStorage.getItem("clinic_id")
    //     };
    //     axios
    //         .post("doctor/clinicTabPermissionbyId", data)
    //         .then((response) => {
    //             if (response != null) {
    //                 setVar_TabPermissionById(response.data.data[0]);
    //                 setLoading(false);
    //             }
    //             else {
    //                 notification.warning({ message: "No Data Found" })
    //                 setLoading(false);
    //             }

    //         })
    //         .catch((error) => {
    //             setLoading(false);
    //             console.log(error);
    //         });
    // }
    // Menu permission get api end

    // Menu permission Error Message start
    const noPermission = (type) => {
        if (type == "deals") {
            notification.error({ message: "No permission" })
        } else if (type == "revenue") {
            notification.error({ message: "No permission" })

        } else if (type == "availability") {
            notification.error({ message: "No permission" })

        } else if (type == "advertisementbooking") {
            notification.error({ message: "No permission" })
        }
    }
    // Menu permission Error Message end

    // menu navigation start
    const menuNavigation = (url) => {
        sessionStorage.setItem("url", url);
        setActiveLink(url);
        console.log(url);
        Var_History.push(url);
    }
    // menu navigation end

    // menu Permission start
    const menuPermission = (activestate, permission) => {

        if (activestate == "home" && permission == 1) {
            localStorage.setItem("activeMenu", "");
        }
        else if (activestate == "advetisement") {
            if (permission == 0) {
                notification.error({ message: "No Permission For Advertisement Menu" })
            }
            else {
                localStorage.setItem("activeMenu", "advetisement");
            }
        }
        else if (activestate == "deal") {
            if (permission == 0) {
                notification.error({ message: "No Permission For Deal Menu" })
            } else {
                localStorage.setItem("activeMenu", "deal");
            }
        }
        else if (activestate == "revenue") {
            if (permission == 0) {
                notification.error({ message: "No Permission For Revenue Menu" })
            } else {
                localStorage.setItem("activeMenu", "revenue");
            }
        }
        else if (activestate == "collateral") {
            if (permission == 0) {
                notification.error({ message: "No Permission For Collateral Menu" })
            } else {
                localStorage.setItem("activeMenu", "collateral");
            }
        }
    }
    // menu Permission start

    const emptyclick = () => {
        notification.error({ message: "No Permission For This Menu " })
    }
    return (
        <div>
            {/* {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            } */}
            {loading &&
                <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999', display: "none" }} />
            }
            {/* ================= Footer Page start ================= */}
            <Grid container className="footer_icon_row">
                <Grid item xs={12} className='footer_column'>

                    {/* <div onClick={() => { menuNavigation('/dashboard') }}>
                        <img src={Default_HomeImage} alt="icon" className="icon_footer" />
                        <p className={'menu_active'} >Home</p>
                    </div>
                    <div onClick={() => { menuNavigation('/dashboard/advertisementbooking') }} style={{cursor:'pointer'}}>
                        <img src={Default_Advertisement_Image} alt="icon" className="icon_footer" />
                        <p className={'menu_active'}>Advertise</p>
                    </div>
                    <div onClick={() => { menuNavigation('/dashboard/deals') }} style={{cursor:'pointer'}}>
                        <img src={Default_Deal_Image} alt="icon" className="icon_footer" />
                        <p className={'menu_active'}>Deal</p>
                    </div>
                    <div onClick={() => { menuNavigation('/dashboard/collaterals') }} style={{cursor:'pointer'}}>
                        <img src={Default_Collateral_Image} alt="icon" className="icon_footer" />
                        <p className={'menu_active'}>Tutorials</p>
                    </div>
                    <div onClick={() => { menuNavigation('/dashboard/revenue') }} style={{cursor:'pointer'}}>
                        <img src={Default_Revenue_Image} alt="icon" className="icon_footer" />
                        <p className={'menu_active'}>Revenue</p>
                    </div> */}
                    <div onClick={() => menuNavigation('/dashboard/')} className={`${(activeLink === "/dashboard/" && activeLink === Var_location.pathname) || Var_location.pathname === '/dashboard' || Var_location.pathname === '/dashboard/' ? 'active-link' : 'unactive-link'}`}>
                        <img src={Default_HomeImage} alt="icon" className="icon_footer" />
                        <p className={'menu_unactive'} >Home</p>
                    </div>
                    <div onClick={() => menuNavigation('/dashboard/advertisementbooking')} className={`${Var_location.pathname === '/dashboard/advertisementbooking' ? 'active-link' : 'unactive-link'}`}>
                        <img src={Default_Advertisement_Image} alt="icon" className="icon_footer" />
                        <p className={'menu_unactive'}>Advertise</p>
                    </div>
                    <div onClick={() => menuNavigation('/dashboard/deals')} className={` ${Var_location.pathname === '/dashboard/deals' ? 'active-link' : 'unactive-link'}`}>
                        <img src={Default_Deal_Image} alt="icon" className="icon_footer" />
                        <p className={'menu_unactive'}>Deal</p>
                    </div>
                    <div onClick={() => menuNavigation('/dashboard/collaterals')} className={` ${Var_location.pathname === '/dashboard/collaterals' ? 'active-link' : 'unactive-link'}`}>
                        <img src={Default_Collateral_Image} alt="icon" className="icon_footer" />
                        <p className={'menu_unactive'}>Tutorials </p>
                    </div>
                    <div onClick={() => menuNavigation('/dashboard/revenue')} className={` ${Var_location.pathname === '/dashboard/revenue' ? 'active-link' : 'unactive-link'}`}>
                        <img src={Default_Revenue_Image} alt="icon" className="icon_footer" />
                        <p className={'menu_unactive'}>Revenue</p>
                    </div>

                    {/* <div onClick={() => { menuNavigation('/dashboard'); menuPermission("home", 1) }}>
                        <img src={Var_Active_menu_Ref.current == "" ? Active_HomeImage : Default_HomeImage} alt="icon" className="icon_footer" />
                        <p className={Var_Active_menu_Ref.current == "" ? 'menu_active' : 'menu_unactive'} >Home</p>
                    </div>
                    <div onClick={Var_TabPermissionById?.ad_access == 1 ? () => { menuNavigation('/dashboard/advertisementbooking'); menuPermission("advetisement", Var_TabPermissionById.ad_access) } : () => emptyclick()}>
                        <img src={Var_Active_menu_Ref.current == "advetisement" ? Active_Advertisement_Image : Default_Advertisement_Image} alt="icon" className="icon_footer" />
                        <p className={Var_Active_menu_Ref.current == "advetisement" ? 'menu_active' : 'menu_unactive'}>Advertise</p>
                    </div>
                    <div onClick={Var_TabPermissionById?.deal_access == 1 ? () => { menuNavigation('/dashboard/deals'); menuPermission("deal", Var_TabPermissionById.deal_access) } : () => emptyclick()}>
                        <img src={Var_Active_menu_Ref.current == "deal" ? Active_Deal_Image : Default_Deal_Image} alt="icon" className="icon_footer" />
                        <p className={Var_Active_menu_Ref.current == "deal" ? 'menu_active' : 'menu_unactive'}>Deals</p>
                    </div>
                    <div onClick={() => { menuNavigation('/dashboard/collaterals'); menuPermission("collateral", 1) }}>
                        <img src={Var_Active_menu_Ref.current == "collateral" ? Active_Collateral_Image : Default_Collateral_Image} alt="icon" className="icon_footer" />
                        <p className={Var_Active_menu_Ref.current == "collateral" ? 'menu_active' : 'menu_unactive'}>Collaterals</p>
                    </div>
                    <div onClick={Var_TabPermissionById?.revenue_access ? () => { menuNavigation('/dashboard/revenue'); menuPermission("revenue", Var_TabPermissionById.revenue_access) } : () => emptyclick()}>
                        <img src={Var_Active_menu_Ref.current == "revenue" ? Active_Revenue_Image : Default_Revenue_Image} alt="icon" className="icon_footer" />
                        <p className={Var_Active_menu_Ref.current == "revenue" ? 'menu_active' : 'menu_unactive'}>Revenue</p>
                    </div> */}
                </Grid>
            </Grid>
            {/* ================= Footer Page end ================= */}
        </div>
    )
}
export default FooterMasterComponent;