import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import { notification } from "antd";

// Mui material imports

import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import { MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import "./AddService.css";
import "bootstrap/dist/css/bootstrap.min.css";
import IOSSwitch from "../../helpers/Switch/Switch";
import FooterMaster from '../Dashboard/FooterMaster';

import uploadimage from "../../assets/icon/uploading-archive-1.svg";
import successModelTickImg from '../../assets/Web_Doctor_Icon_new_theme/Success.svg';
import alterImage from '../../../src/images/Vector.png';

function ManageAddServiceComponent() {

  let Var_History = useHistory();

  const [Var_Speciality, setVar_Speciality] = useState([]);
  const [Var_SpecialityValueId, setVar_SpecialityValueId] = useStateRef("");

  const [Var_ServiceValue, setVar_ServiceValue, Var_ServiceValue_Ref] = useStateRef("");
  const [Var_FeeKWDValue, setFeeKWDValue] = useStateRef("");
  const [Var_SlotsValue, setSlotsValue, Var_SlotsValue_Ref] = useStateRef("");
  const [Var_SlotsValueData, setSlotsValueData] = useStateRef("");
  const [Var_SlotsValue_Minus, setSlotsValue_Minus] = useStateRef("");
  const [Var_DescriptionValue, setDescriptionValue, Var_DescriptionValue_Ref] = useStateRef("");
  const [, setServiceImage, Var_ServiceImage_Ref] = useStateRef("");
  const [Var_ServiceImageName, setServiceImageName, Var_ServiceImageName_Ref] = useStateRef("Upload Image");
  const [, setServiceActiveSwitchValue, Var_ServiceActiveSwitchValue_Ref] = useStateRef(true);
  const [loading, setLoading] = useState(true);
  const [Var_fileprocess, setVar_fileprocess, ] = React.useState(false);
  const [Var_progress, setVar_Progress] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [isOpenServiceSuccessModel, setisOpenServiceSuccessModel] = useState(false);
  // Check for 3 step verification
  const [verification, setVerification] = useState(Var_History.location.state ? (Var_History.location.state.verification ? Var_History.location.state.verification : false) : false);
  const [isDisable, setisisDisable] = useState(false);



  // ======================== Edit Service Values start==========================

  // initial function call start
  useEffect(() => {
    getSpeciality();
    setServiceActiveSwitchValue(true);
    setisisDisable(false);
  }, []);
  // initial function call End

  // navigattion to back start
  const navigateToBack = (path) => {

    Var_History.push("/dashboard" + path);
  };
  // navigattion to back end

  // get speciality api call start
  const getSpeciality = () => {
    setLoading(true);
    let data = {
      doctorId: localStorage.getItem("Doctor_id")
    };
    axios
      .post("doctor/getDoctorDetails", data).then((response) => {
        const doctorSpeciality = JSON.parse(response.data.data[0].speciality)
        setVar_Speciality(doctorSpeciality);
        setLoading(false);

      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }
  // get speciality api call end

  // form change get values

  const getSpecialityValue = (event) => {
    setVar_SpecialityValueId(event.target.value);
  }
  const getServiceValue = (event) => {

    setVar_ServiceValue(event.target.value);
  }
  const getFeeKWDValue = (event) => {
    let numericonly = event.target.value.replace(/[^0-9.]/g, '');
    setFeeKWDValue(numericonly);
  }
  const getDescriptionValue = (event) => {

    setDescriptionValue(event.target.value);
  }
  const getServiceImage = (event) => {

    addServiceFileUpload(event.target.files[0]);
    setServiceImageName(event.target.files[0].name);
  }
  const getServiceActiveSwitchValue = (event) => {

    setServiceActiveSwitchValue(event.target.checked);
  }

  // add service s3 file upload start
  const addServiceFileUpload = (file) => {
    setisisDisable(true);
    setVar_fileprocess(true)
    setVar_Progress(0)
    setServiceImage('')
    const formData = new FormData();
    formData.append('module_id', "1");
    formData.append('file',file);
    formData.append('pathLocation', "MANAGE-SERVICE/")
    axios({
      method: "POST",
      url: "admin/awsS3FileUpload",
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setVar_Progress(percentage);
      },
    })
      .then((response) => {
        setisisDisable(false);
        setServiceImage(response.data.filepath.Location);
        setLoading(false);
      })
      .catch((error) => {
        setisisDisable(false);
        console.log(error);
        setLoading(false);
        setVar_fileprocess(false);


      });
  }
  // add service s3 file upload end 

  // add manage service final submit start
  const addManaeServiceFinalSubmit = () => {

    if (Var_ServiceValue != "" && Var_SpecialityValueId != "" && Var_FeeKWDValue != "" && Var_DescriptionValue != "" && Var_ServiceImageName != "" && Var_ServiceImage_Ref.current != "") {
      if (Var_SlotsValueData > 9 && Var_SlotsValueData < 61) {
        if (Var_FeeKWDValue > 1) {
          setisisDisable(true);
          setLoading(true);
          let data = {
            id: 0,
            doctor_id: localStorage.getItem("Doctor_id"),
            service: Var_ServiceValue,
            specialityId: Var_SpecialityValueId,
            cost: Var_FeeKWDValue,
            slot_duration: Var_SlotsValue,
            description: Var_DescriptionValue,
            is_active: Var_ServiceActiveSwitchValue_Ref.current == true ? 1 : 0,
            file_name: Var_ServiceImageName,
            file_path: Var_ServiceImage_Ref.current.split("?")[0]
          }
          axios.post("doctor/addmanageservice", data).then((response) => {
            setisisDisable(false);
            if (response.data.data[0].ErrorCode == '9999') {
              setisOpenServiceSuccessModel(!isOpenServiceSuccessModel)
              setLoading(false);

            }
            else if (response.data.data[0].ErrorCode == '9998') {
              notification.error({ message: response.data.data[0].msg });
              setLoading(false);
            }
          })
            .catch((error) => {
              setisisDisable(false);
              notification.error({ message: error });
              setLoading(false);
            });
        } else {
          notification.error({ message: "The fee should be above 1 KWD." });
        }
      } else {
        notification.error({ message: "Slot Duration 10 Minu to 60 Minu" });
      }
    } else {
      notification.error({ message: "Please select all fields" });
    }
  }
  // add manage service final submit end

  // modelcontroles start

  const isCloseServiceSuccessModel = () => {
    setisOpenServiceSuccessModel(false)
    if (!verification) {
      Var_History.push("/dashboard/manageservice");
    }
    else {
      Var_History.push("/dashboard");
    }
  }
  // modelcontroles end

  // ==========================TimePopOver ==================================
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverClick = (value) => {
    setSlotsValueData(value);
    setSlotsValue('00:' + value);
    setSlotsValue_Minus(value);
    handleClose();
    console.log(Var_SlotsValue_Ref.current);
  };
  const values = Array.from({ length: 50 }, (_, index) => index + 10);


  return (
    <div>
      {/* =============================Sub Header Start============================== */}
      {loading &&
        <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
      }
      <div className="sub_header">
        <div className="sub_header_body">
          <div className="back_navigation" onClick={() => { navigateToBack("/manageservice") }}>
            <i class="fa fa-angle-left" aria-hidden="true"></i>
            <span>ADD SERVICE</span>
          </div>
          <div className="flex_grow"></div>
          <div className="header_right_content">
          </div>
        </div>
      </div>
      {/* =============================Sub Header End ============================== */}

      {/* ===============================Add Service Content Start=========================== */}
      <div className="content_view content-height">

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputLabel id="demo-select-small-label" style={{ color: 'black', fontWeight: '500' }}>Specialty</InputLabel>
            <TextField
              select
              // fullWidth
              size="small"
              sx={{ height: '40px', minWidth: '-webkit-fill-available' }}
              onChange={(e) => getSpecialityValue(e)}
            >
              {Var_Speciality.map((item) => {
                return (
                  <MenuItem
                    key={item.specialityId}
                    value={item.specialityId}
                  >{item.speciality}</MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="demo-select-small-label" style={{ color: 'black', fontWeight: '500' }}>Service Title</InputLabel>
            <TextField
              type="text"
              placeholder="Enter service description"
              size="small"
              sx={{ minWidth: '-webkit-fill-available' }}
              onChange={(e) => getServiceValue(e)} />
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="demo-select-small-label" style={{ color: 'black', fontWeight: '500' }}>Fee <span style={{ fontSize: '10px' }}>(KWD)</span></InputLabel>
            <TextField
              type="text"
              placeholder="Enter Fee"
              size="small"
              inputProps={{ maxLength: 7 }}
              sx={{ minWidth: '-webkit-fill-available' }}
              value={Var_FeeKWDValue}
              onChange={(e) => getFeeKWDValue(e)} />
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="demo-select-small-label" style={{ color: 'black', fontWeight: '500' }}>Slot Duration <span style={{ fontSize: '10px' }}>(mins)</span></InputLabel>
            <TextField
              type="text"
              placeholder="00"
              size="small"
              inputProps={{ maxLength: 5 }}
              sx={{ minWidth: '-webkit-fill-available' }}
              value={Var_SlotsValue_Minus}
              onClick={(event) => handleClick(event)}
            />
            <div >
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div>
                  {values.map((value, index) => (
                    <Typography
                      key={index}
                      sx={{ p: 2, cursor: 'pointer' }}
                      onClick={() => handlePopoverClick(value)}
                    >
                      {index + 10}
                    </Typography>
                  ))}
                </div>
              </Popover>
            </div>
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="demo-select-small-label" style={{ color: 'black', fontWeight: '500' }}>Description</InputLabel>

            <TextField
              type="text"
              placeholder="Description"
              size="small"
              sx={{ minWidth: '-webkit-fill-available' }}
              onChange={(e) => getDescriptionValue(e)} />
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="demo-select-small-label" style={{ color: 'black', fontWeight: '500' }}>Service Image</InputLabel>
            <div className="fileUpload">
              <p>{Var_ServiceImageName_Ref.current}</p>
              <input  type="file" className="upload" onChange={(e) => getServiceImage(e)} />
              <span><img src={uploadimage} alt="Upload Icon" /></span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="demo-select-small-label" style={{ color: 'black', fontWeight: '500' }}>Active</InputLabel>
            <FormControlLabel className='every_days_service' control={
              <IOSSwitch sx={{ m: 1 }} className='toggle' checked={Var_ServiceActiveSwitchValue_Ref.current == true ? true : false} onClick={(e) => getServiceActiveSwitchValue(e)} />
            } />
          </Grid>
          <Grid item xs={6} className="fileshow">
            {Var_fileprocess &&
              <div>
                <div className="upload-container">
                  {/* Your other UI elements */}
                  <LinearProgress
                    variant="determinate"
                    value={Var_progress}
                    color="secondary"
                    className="progressBar"
                    sx={{ zIndex: '1', width: '100%', margin: '0px 3px' }}
                  />
                  {/* Display progress percentage */}
                  <div className="progress-text">{`${Var_progress}%`}</div>
                </div>
              </div>
            }
          </Grid>
        </Grid>
        <div className="addmanager-post">
          <Card className="service_card_detials">
            <Grid container >
              <Grid item xs={2}>
                <img className="service_image_display" src={Var_ServiceImage_Ref.current == "" || null ? alterImage : Var_ServiceImage_Ref.current} alt="" />

              </Grid>
              <Grid item xs={7} className="service_content">
                <label className="service_title_card">{Var_ServiceValue_Ref.current}</label>
                <p className="sertvice_description">{Var_DescriptionValue_Ref.current}</p>
              </Grid>
              <Grid item xs={3} className="add_button_grid">
                <Button
                  variant="contained"
                  color="success"
                  className="add_button"
                  disabled = {isDisable}
                  onClick={() => addManaeServiceFinalSubmit()}>
                  Add
                </Button>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
      {/* ===============================Add Service Content End=========================== */}

      {
        localStorage.getItem("FooterStatus") == 'A' && <div className="layout_footer footer">
          <FooterMaster />
        </div>
      }
      {/* ======================== AddService Success Model======================================= */}

      {/* ================= Add deal Success Model Start ================= */}
      <Dialog
        // fullWidth={fullWidth}
        open={isOpenServiceSuccessModel}
        onClose={isCloseServiceSuccessModel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "300px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogContent>
          <div className="success_model_body">
            <div className="icon" style={{ textAlign: 'center', marginBottom: '23px' }}>
              <img src={successModelTickImg} alt="" />
            </div>
            <div className="title">success!</div>
            <div className="content">Manage Service added successfully!</div>
            <div className="segment_center">
              <button className="botton_Cart_Create" onClick={() => isCloseServiceSuccessModel()}>OK</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* ================= Add deal Success Model End ================= */}


    </div >
  );
};
export default ManageAddServiceComponent;
