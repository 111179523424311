import { useHistory } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import useStateRef from "react-usestateref";
import { notification, Spin } from "antd";
// import LinearProgress from '@mui/material/LinearProgress';


// Mui meterial component import
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import './DashboardMaster.css'

import Appoinmentsimg from '../../assets/icon/appointment.svg'
import Queueimg from '../../assets/icon/queue.svg'
import Availabilityimg from '../../assets/icon/manageSchedule.svg'
import Workinghoursimg from '../../assets/icon/workhours.svg'
import Walkinimg from '../../assets/icon/referral.svg'
import Cancelledimg from '../../assets/icon/cancelled.svg'
import Postimg from '../../assets/icon/post.svg'
import Manageserviceimg from '../../assets/icon/manageService.svg'



function DashboardMasterComponent(props) {
    let Var_History = useHistory();
    console.log(props.menupermission);
    const [Var_ClinicID, setVar_ClinicID, Var_ClinicID_Ref] = useStateRef(null)
    const [Var_getClinicWiseDoctor, setVar_getClinicWiseDoctor] = useState([]);
    var [Var_doctorSpecialitySelect, setVar_doctorSpecialitySelect] = useState("");
    const [Var_TabPermissionById, setVar_TabPermissionById, Var_TabPermissionById_Ref] = useStateRef(null)
    const [Var_Availability_access, setVar_Availability_access, Var_Availability_access_Ref] = useStateRef(null)
    const [Var_Workinghrs_access, setVar_Workinghrs_access, Var_Workinghrs_access_Ref] = useStateRef(null)
    const [Var_Post_acccess, setVar_Post_acccess, Var_Post_acccess_Ref] = useStateRef(null)
    const [Var_Service_access, setVar_Service_access, Var_Service_access_Ref] = useStateRef(null)
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        // getClinicWiseDoctor()
        sendToken();
        changeLanguage();
    }, []);
    // navigation to back start
    const handleOnClick = (path) => {
        Var_History.push("/dashboard/" + path)
    };
    // navigation to back end

    const getClinicWiseDoctor = () => {

        // setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("Clinic_id")
        };
        axios
            .post("clinic/getClinicWiseDoctor", data)
            .then((response) => {
                // setVar_getClinicWiseDoctor(response.data.data);
                // setVar_doctorSpecialitySelect(response.data.data[0].specialityid);
                // localStorage.setItem("Doctor_id", response.data.data[0].doctor_id)
                // localStorage.setItem("speciality_Id", response.data.data[0].specialityid);
                setLoading(false);

            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }

    const noPermission = (type) => {
        if (type == "appointments") {
            notification.error({ message: "No permission" })
        } else if (type == "queue") {
            notification.error({ message: "No permission" })

        } else if (type == "availability") {
            notification.error({ message: "No permission" })

        } else if (type == "workinghours") {
            notification.error({ message: "No permission" })
        }
        else if (type == "walkinbooking") {
            notification.error({ message: "No permission" })

        } else if (type == "cancelledappointments") {
            notification.error({ message: "No permission" })

        } else if (type == "post") {
            notification.error({ message: "No permission" })

        } else if (type == "manageservice") {
            notification.error({ message: "No permission" })
        }
    }
    const sendToken = () => {

        var data = {
            "vendor_id": localStorage.getItem("Doctor_id"),
            "token": localStorage.getItem("TokenFCM")
        };
        axios
            .post("admin/insertvendorwebtoken", data)
            .then((response) => {
                console.log("update token response", response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const changeLanguage = () => {

        var data = {
            "vendor_id": localStorage.getItem("Doctor_id"),
            "language": "en"
        };
        axios
            .post("admin/insertvendorweblanguage", data)
            .then((response) => {
                console.log("update token response", response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div>
            {/* {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                       
                    </Box>
                </div>
            } */}
            {loading &&
                <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
            }
            {/* ====================Dashboard Module Image Start=================== */}
            <div className='main_card_image'>
                <Grid container className='first_row_card_image'>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content mr_t_2rem'
                        >
                            <div className="image_div">
                                <img className='card_image card_image_appointment '   onClick={() => handleOnClick("appointments")} src={Appoinmentsimg} alt="Appoinmentsimg" />
                            </div>
                            <p className='home_card_title'>Appointments</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content mr_t_2rem'
                            
                        >
                            <div className="image_div">
                                <img className='card_image' onClick={() => handleOnClick("queue")} src={Queueimg} alt="Queueimg" />
                            </div>
                            <p className='home_card_title'>Queue</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content mr_t_2rem'
                            
                        >
                            <div className="image_div">
                                <img className='card_image' onClick={() => handleOnClick("availability")} src={Availabilityimg} alt="Availabilityimg" />
                            </div>
                            <p className='home_card_title'>Manage Schedule</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content mr_t_2rem'
                          
                        >
                            <div className="image_div">
                                <img className='card_image'   onClick={() => handleOnClick("workinghours")} src={Workinghoursimg} alt="Workinghoursimg" />
                            </div>
                            <p className='home_card_title'>Working Hours</p>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className='first_row_div_image'>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content'>
                            <div className="image_div" >
                                <img className='card_image card_image_Walkin'  onClick={() => handleOnClick("referral")} src={Walkinimg} alt="Walkinimg" />
                            </div>
                            <p className='home_card_title'>Referral</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content'
                           
                        >
                            <div className="image_div">
                                <img className='card_image'  onClick={() => handleOnClick("cancelledappointments")}  src={Cancelledimg} alt="Cancelledimg" />
                            </div>
                            <p className='home_card_title'>Canceled</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content'
                          
                        >
                            <div className="image_div">
                                <img className='card_image'   onClick={() => handleOnClick("post")} src={Postimg} alt="Postimg" />
                            </div>
                            <p className='home_card_title'>Post</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content'
                           
                        >
                            <div className="image_div">
                                <img className='card_image'   onClick={() => handleOnClick("manageservice")} src={Manageserviceimg} alt="Manageserviceimg" />
                            </div>
                            <p className='home_card_title'>Manage Services</p>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/* ====================Dashboard Module Image End=================== */}
        </div >
    )
}

export default DashboardMasterComponent;