// AUTHOR : SABARIESH
// CR-DATE: 17-july-2023
// MOD-DATE: 19-july-2023
// DESCRIPTION: Deal Update work

import axios from "axios";
import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import React, { useState, useEffect } from "react";
import { notification, Spin } from "antd";

// Mui meterial component import
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import "./DealsMaster.css";
import IOSSwitch from "../../helpers/Switch/Switch";
import FooterMaster from "../Dashboard/FooterMaster";
import Datedata from '../Revenue_settle/mothpicker/Datedata';
import profile from "../../assets/Images/TOMLogo.jpg";
import Success from "../../assets/Doctor_web_Icon/success.png";
import EditIcon from "../../assets/Web_Doctor_Icon_new_theme/Edit.svg";
import DealOfferImage from "../../assets/Web_Doctor_Icon_new_theme/Deal_offer.svg";
import closeicon from "../../assets/Web_Doctor_Icon_new_theme/close.svg";
import successModelTickImg from "../../assets/Web_Doctor_Icon_new_theme/Success.svg";
import Alert from "../../assets/Web_Doctor_Icon_new_theme/alert.svg";
import faildIcon from "../../assets/Images/failedIcon.svg";

function DealComponent() {
  const currentDate = new Date();
  let Var_History = useHistory();
  const [Var_GetDealsDetails, setVar_GetDealsDetails] = useStateRef([]);
  const [Var_DealActiveSwitch, setVar_DealActiveSwitch] = useState(true);
  const [Var_DealSpeciality, setVar_DealSpeciality, Var_DealSpeciality_Ref] = useStateRef([]);
  const [Var_DealServiceType, setVar_DealServiceType] = useState([]);

  const [Var_DealSpecialityValue, setVar_DealSpecialityValue] = useStateRef("");
  const [Var_DealSpecialityValueId, setVar_DealSpecialityValueId, Var_DealSpecialityValueId_Ref] =
    useStateRef("");
  const [Var_GetServiceTypeValue, setVar_GetServiceTypeValue] = useStateRef("");
  const [Var_GetServiceTypeCost, setVar_GetServiceTypeCost, Var_GetServiceTypeCost_Ref] = useStateRef("");
  const [Var_GetDealTitleValue, setVar_GetDealTitleValue] = useStateRef("");
  const [Var_GetValidFromValue, setVar_GetValidFromValue] = useStateRef("");
  const [Var_GetValidToValue, setVar_GetValidToValue] = useStateRef("");
  const [Var_GetDealOptionRadioValue, setVar_GetDealOptionRadioValue, Var_GetDealOptionRadioValue_Ref] =
    useStateRef("");
  const [
    Var_GetDealOptionAmountTextBoxValue,
    setVar_GetDealOptionAmountTextBoxValue, Var_GetDealOptionAmountTextBoxValue_Ref
  ] = useStateRef("");
  const [
    Var_GetDealOptionPercentageTextBoxValue,
    setVar_GetDealOptionPercentageTextBoxValue, Var_GetDealOptionPercentageTextBoxValue_Ref
  ] = useStateRef("");
  const [Var_DealUpdateOldData, setVar_DealUpdateOldData] = useStateRef(null);
  const [
    Var_DoctorDetailShow,
    setVar_DoctorDetailShow,
    Var_DoctorDetailShow_Ref,
  ] = useStateRef(null);
  const [loading, setLoading] = useState(true);

  const [isOpenAddDealModel, setIsOpenAddDealModel] = React.useState(false);
  const [isOpenAddDealSuccessModel, setIsOpenAddDealSuccessModel] =
    React.useState(false);

  const [isOpenEditDealModel, setIsOpenEditDealModel] = React.useState(false);
  const [isOpenEditDealSuccessModel, setIsOpenEditDealSuccessModel] =
    React.useState(false);

  const [fullWidth, setFullWidth] = React.useState(true);
  const [addDialogueWidth, setaddDialogueWidth] = React.useState("200px");
  const [Var_IsShowSuccess, setVar_IsShowSuccess] = React.useState(false);

  var [DateValFrom, setDateValFrom, DateValFrom_Ref] = useStateRef('dd-mm-yy');
  var [DateValTo, setDateValTo, DateValTo_Ref] = useStateRef('dd-mm-yy');
  const [errorMessage, setErrorMessage] = useState("");
  const [Var_AdvertisementMsg, setVar_AdvertisementMsg, Var_AdvertisementMsg_Ref] = useStateRef("");
  const [Var_AdvertisementMsgHead, setVar_AdvertisementMsgHead, Var_AdvertisementMsgHead_Ref] = useStateRef("");
  const [Var_PopupModelIcon, setVar_PopupModelIcon, Var_PopupModelIcon_Ref] = useStateRef("");
  const currentDateFormated = moment(new Date()).format("YYYY-MM-DD");
  const [Var_DealAmount, setVar_DealAmount, Var_DealAmount_Ref] = useStateRef("");
  const [Var_BillingAmount, setVar_BillingAmount, Var_BillingAmount_Ref] = useStateRef("");
  const [Var_ServiceTitle, setVar_ServiceTitle, Var_ServiceTitle_Ref] = useStateRef("");
  const [isDisable, setisisDisable] = useState(false);
  // initial function call start
  useEffect(() => {
    getDealsDetails();
    // getClinicWiseDoctor();
    getSpeciality();
    // getServiceType();
    setDateValFrom('dd-mm-yy');
    setDateValTo('dd-mm-yy');
    setisisDisable(false);
  }, []);
  // initial function call end

  // navigattion to back start
  const navigateToBack = () => {
    Var_History.push("/dashboard");
  };
  // navigattion to back end
  const oKClose = () => {
    setVar_IsShowSuccess(!Var_IsShowSuccess);
  };
  // get deal list api call start
  const getDealsDetails = () => {
    setLoading(true);
    var data = {
      doctorid: localStorage.getItem("Doctor_id"),
      limit: 1000,
      pageno: 1,
    };
    axios
      .post("doctor/getsingleDeals", data)
      .then((response) => {
        {
          setVar_GetDealsDetails(response.data.data[0].details);
          setLoading(false);
          response.data.data[0].details.forEach((element) => {
            element.deal_valid_from = element.deal_valid_from.split("T")[0];
            element.deal_valid_to = element.deal_valid_to.split("T")[0];
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  // get deal list api call end

  // get speciality api call start
  const getSpeciality = () => {
    setLoading(true);
    var data = {
      doctorId: localStorage.getItem("Doctor_id"),
    };
    axios
      .post("doctor/getDoctorDetails", data)
      .then((response) => {
        debugger;
        const speciality = JSON.parse(response.data.data[0].speciality);
        setVar_DealSpeciality(speciality);
        console.log(JSON.parse(response.data.data[0].speciality));
        setVar_DealSpecialityValue({ selectedvalue: speciality[0].speciality });
        setVar_DealSpecialityValueId(speciality[0].specialityId);
        console.log("speciality.speciality", speciality[0].speciality);
        console.log("speciality.specialityId", speciality[0].specialityId);
        setLoading(false);
        getServiceType();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  // get speciality api call end

  const getServiceType = () => {
    setLoading(true);
    var data = {
      doctor_id: localStorage.getItem("Doctor_id"),
      speciality_id: Var_DealSpecialityValueId_Ref.current,
    };
    axios
      .post("doctor/getDoctorServiceBySpecilaity", data)
      .then((response) => {
        setVar_DealServiceType(response.data.data.filter(x => x.is_active == 1));
        setLoading(false);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // model controls start
  const isOpenAddDeal = () => {
    setDateValFrom('dd-mm-yy');
    setDateValTo('dd-mm-yy');
    setVar_GetDealOptionRadioValue("Amount");
    setVar_GetServiceTypeValue("");
    setVar_GetDealTitleValue("");
    setVar_GetValidFromValue("");
    setVar_GetValidToValue("");
    getDealOptionAmountTextBoxValue("");
    getDealOptionPercentageTextBoxValue("");
    setVar_DealAmount("");
    setVar_BillingAmount("");
    setVar_GetServiceTypeCost("");
    setLoading(true);
    setIsOpenAddDealModel(true);
    setLoading(false);
  };
  const isCloseAddDealModel = () => {
    setErrorMessage("");
    setLoading(true);
    setIsOpenAddDealModel(false);
    setLoading(false);
  };
  const isclosedmodal = () => {
    setLoading(true);
    setVar_IsShowSuccess(false);
    setLoading(false);
  };
  const isCloseAddDealSuccessModel = () => {
    setLoading(true);
    setIsOpenAddDealSuccessModel(false);
    setLoading(false);
  };

  const isOpenEditDeal = (data) => {
    setLoading(true);
    setIsOpenEditDealModel(true);
    setVar_DealUpdateOldData(data);
    setVar_GetDealOptionRadioValue(data.deal_options);
    setVar_GetServiceTypeValue(data.deal_service_type_id);
    setVar_ServiceTitle(data.deal_service_type);
    setVar_GetDealTitleValue(data.deal_title);
    setVar_GetValidFromValue(data.deal_valid_from);
    setVar_GetValidToValue(data.deal_valid_to);
    setDateValFrom(data.deal_valid_from);
    setDateValTo(data.deal_valid_to);
    setVar_GetServiceTypeCost(data.service_cost);
    getDealOptionAmountTextBoxValue(data.deal_options == "Amount" ? data.deal_amount : "");
    getDealOptionPercentageTextBoxValue(data.deal_options == "Percentage" ? data.deal_amount : "");
    setVar_DealActiveSwitch(data.deal_active ==1 ? true : false);
    setLoading(false);
  };

  const isCloseEditDealModel = () => {
    setErrorMessage("");
    setIsOpenEditDealModel(false);
  };
  const isCloseEditDealSuccessModel = () => {
    setIsOpenEditDealSuccessModel(false);
  };
  // model controls end

  // onchange get vaues from form start

  const getServiceTypeValue = (event) => {
    setVar_GetServiceTypeValue(event.target.value);
    var ServiceCose = Var_DealServiceType.filter(
      (e) => e.id == event.target.value
    );
    setVar_GetServiceTypeCost(ServiceCose[0].cost);
  };
  const getDealTitleValue = (event) => {
    setVar_GetDealTitleValue(event.target.value);
  };
  const getValidFromValue = (event) => {
    if (Var_GetValidToValue == "") {
      setDateValFrom(new Date(event.target.value).toLocaleDateString('en-GB', { year: '2-digit', month: 'short', day: '2-digit' }).replaceAll(' ', '-'));
      setVar_GetValidFromValue(event.target.value);
    }
    else if (Var_GetValidToValue != "" && Var_GetValidToValue >= event.target.value) {
      setDateValFrom(new Date(event.target.value).toLocaleDateString('en-GB', { year: '2-digit', month: 'short', day: '2-digit' }).replaceAll(' ', '-'));
      setVar_GetValidFromValue(event.target.value);
    }
    else {

    }
  };

  const getValidToValue = (event) => {
    if (Var_GetValidFromValue <= event.target.value) {
      setDateValTo(new Date(event.target.value).toLocaleDateString('en-GB', { year: '2-digit', month: 'short', day: '2-digit' }).replaceAll(' ', '-'));
      setVar_GetValidToValue(event.target.value);
    }
    else {

    }
  };
  const getDealOptionRadioValue = (event) => {
    setVar_DealAmount("");
    setVar_BillingAmount("");
    setVar_GetDealOptionAmountTextBoxValue("");
    setVar_GetDealOptionPercentageTextBoxValue("");
    setVar_GetDealOptionRadioValue(event.target.value);
  };

  const getDealOptionAmountTextBoxValue = (value) => {
    setVar_GetDealOptionAmountTextBoxValue(value);
    getDealValue();
  }
  const getDealOptionPercentageTextBoxValue = (value) => {
    setVar_GetDealOptionPercentageTextBoxValue(value);
    getDealValue();
  }
  const getDealValue = () => {
    debugger
    if (Var_GetDealOptionRadioValue_Ref.current == "Amount") {
      const billingAmount = Var_GetServiceTypeCost_Ref.current - Var_GetDealOptionAmountTextBoxValue_Ref.current;
      if (billingAmount >= 2) {
        setVar_DealAmount(Var_GetDealOptionAmountTextBoxValue_Ref.current);
        setVar_BillingAmount(billingAmount);
      }
      else {
        setVar_DealAmount("");
        setVar_BillingAmount("");
        setVar_GetDealOptionAmountTextBoxValue("");
      }
    } else {
      const dealAmount = ((Var_GetServiceTypeCost_Ref.current / 100) * Number(Var_GetDealOptionPercentageTextBoxValue_Ref.current));
      const billingAmount = Var_GetServiceTypeCost_Ref.current - dealAmount;
      if (billingAmount >= 2) {
        setVar_DealAmount(dealAmount);
        setVar_BillingAmount(billingAmount);
      }
      else {
        setVar_DealAmount("");
        setVar_BillingAmount("");
        setVar_GetDealOptionPercentageTextBoxValue("");
      }
    }
  }
  const dealActiveSwitch = () => {
    setVar_DealActiveSwitch(!Var_DealActiveSwitch);
  };

  // onchange get vaues from form end

  // const validation = () => {
  //   if (Var_GetDealTitleValue == "") {
  //     notification.error({ message: "Deal Title Is Required" });
  //     return false;
  //   } else if (Var_GetValidFromValue == "") {
  //     notification.error({ message: "Valid From Is Required" });
  //     return false;
  //   } else if (Var_GetValidToValue == "") {
  //     notification.error({ message: "Valid From Is Required" });
  //     return false;
  //   } else if (Var_GetDealOptionRadioValue == "") {
  //     notification.error({ message: "Deal option Is Required" });
  //     return false;
  //   } else if (Var_GetServiceTypeValue == "") {
  //     notification.error({ message: "Service Type Is Required" });
  //     return false;
  //   } else if (Var_DealAmount_Ref.current == "") {
  //     notification.error({ message: "Deal Amount Is Required" });
  //     return false;
  //   }
  //   else if (Var_DealAmount_Ref.current < 2) {
  //     notification.error({ message: "The Deal Amt should be above 1 KWD." });
  //     return false;
  //   }
  //   else if (Var_BillingAmount_Ref.current == "") {
  //     notification.error({ message: "Billing Amount Is Required" });
  //     return false;
  //   }
  //   else {
  //     return true;
  //   }
  // };
  const validation = () => {
    debugger
    if (Var_GetDealTitleValue == "") {
      setErrorMessage("Field required"); // Set error message if validation fails
      return false;
    }
    else if (Var_GetValidFromValue == "") {
      setErrorMessage("Field required"); // Set error message if validation fails
      return false;
    }
    else if (Var_DealServiceType == "") {
      setErrorMessage("Field required"); // Set error message if validation fails
      return false;
    }
    else if (Var_GetValidToValue == "") {
      setErrorMessage("Field required"); // Set error message if validation fails
      // notification.error({ message: "Valid To Is Required" });
      return false;
    }

    else if (Var_BillingAmount_Ref.current <= 1) {
      setErrorMessage("Field required"); // Set error message if validation fails
      // notification.error({ message: "Billing Amount Is Required" });
      return false;
    }
    else if (Var_DealAmount_Ref.current <= 1) {
      setErrorMessage("The Deal Amt should be above 1 KWD.");
      // notification.error({ message: "The Deal Amt should be above 1 KWD." });
      return false;
    }
    else if (Var_GetDealOptionPercentageTextBoxValue == "" && Var_GetDealOptionAmountTextBoxValue == "") {
      notification.error({ message: "Deal option Is Required" });
      return false;
    }
    else if (Var_GetServiceTypeValue == "") {
      notification.error({ message: "Service Type Is Required" });
      return false;
    }
    else if (Var_DealAmount_Ref.current == "") {
      notification.error({ message: "Deal Amount Is Required" });
      return false;
    }

    else {
      return true;
    }
  }
  const addDealFinalSubmit = () => {
    let Var_Validate = validation();
    if (Var_Validate) {
      setLoading(true);
      setisisDisable(true);
      var data = {
        dealtitle: Var_GetDealTitleValue,
        dealvalidfrom: Var_GetValidFromValue,
        dealvalidto: Var_GetValidToValue,
        dealoptions: Var_GetDealOptionRadioValue,
        dealamount:
          Var_GetDealOptionRadioValue == "Amount"
            ? Var_GetDealOptionAmountTextBoxValue
            : Var_GetDealOptionPercentageTextBoxValue,
        dealactive: Var_DealActiveSwitch == true ? 1 : 0,
        dealservicetypeId: Var_GetServiceTypeValue,
        dealvendorId: localStorage.getItem("Doctor_id"),
        userId: localStorage.getItem("Clinic_id"),
        activeflag: 1,
        createdby: localStorage.getItem("Clinic_id"),
        createdon: new Date()
          .toISOString()
          .replace(/T/, " ")
          .replace(/\..+/, ""),
        modifiedby: localStorage.getItem("Clinic_id"),
        modifiedon: null,
        ipaddress: null,
        specialityId: Var_DealSpecialityValueId,
        module_name: "Doctor",
      };

      axios
        .post("doctor/insertDeals", data)
        .then((response) => {
          setisisDisable(false);
          setLoading(false);
          if (response.data.status == 1) {
            setVar_PopupModelIcon(successModelTickImg);
            setVar_AdvertisementMsgHead("success!");
            setVar_AdvertisementMsg("Deal added successfully!");
            // notification.success({ message: response.data.msg });
            setIsOpenAddDealModel(false);
            setIsOpenAddDealSuccessModel(true);
            // To call All Deals
            getDealsDetails();
            setLoading(false);
          } else if (response.data.status == -1) {
            // notification.error({ message: response.data.msg });
            setVar_PopupModelIcon(faildIcon);
            setVar_AdvertisementMsgHead("failed!");
            setVar_AdvertisementMsg(response.data.msg + "!");
            setIsOpenAddDealSuccessModel(true);
            // notification.error({ message: response.data.msg });
            setLoading(false);
          } else if (response.data.status == 0) {
            // notification.error({ message: response.data.msg });
            // setVar_IsShowSuccess(!Var_IsShowSuccess);
            setVar_PopupModelIcon(faildIcon);
            setVar_AdvertisementMsgHead("failed!");
            setVar_AdvertisementMsg(response.data.msg + "!");
            setIsOpenAddDealSuccessModel(true);
            // setVar_IsShowSuccess(!Var_IsShowSuccess);
            setLoading(false);
          }
        })
        .catch((error) => {
          notification.error({ message: error });
          setLoading(false);
          setisisDisable(false);
        });
    }
  };

  const getClinicWiseDoctor = () => {
    setLoading(true);
    var data = {
      clinic_id: localStorage.getItem("Clinic_id"),
    };
    axios
      .post("clinic/getClinicWiseDoctor", data)
      .then((response) => {
        var filteredData = response.data.data.filter(
          (x) =>
            x.specialityid == localStorage.getItem("speciality_Id") &&
            x.doctor_id == localStorage.getItem("Doctor_id")
        );
        setVar_DoctorDetailShow(
          filteredData[0].doctorName + " " + filteredData[0].specialityname
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const editDealFinalSubmit = () => {
    let Var_Validate = validation();
    if (Var_Validate) {
      setisisDisable(true);
      setLoading(true);
      var data = {
        dealtitle: Var_GetDealTitleValue,
        dealvalidfrom: Var_GetValidFromValue,
        dealvalidto: Var_GetValidToValue,
        dealoptions: Var_GetDealOptionRadioValue,
        dealamount:
          Var_GetDealOptionRadioValue == "Amount"
            ? Var_GetDealOptionAmountTextBoxValue
            : Var_GetDealOptionPercentageTextBoxValue,
        dealactive: Var_DealActiveSwitch == true ? 1 : 0,
        dealservicetypeId: Var_GetServiceTypeValue,
        dealvendorId: localStorage.getItem("Doctor_id"),
        userId: localStorage.getItem("Clinic_id"),
        activeflag: 1,
        createdby: localStorage.getItem("Clinic_id"),
        createdon: Var_DealUpdateOldData.created_on,
        modifiedby: localStorage.getItem("Clinic_id"),
        modifiedon: new Date()
          .toISOString()
          .replace(/T/, " ")
          .replace(/\..+/, ""),
        ipaddress: null,
        specialityId: Var_DealSpecialityValueId,
        id: Var_DealUpdateOldData.id,
      };

      axios
        .put("doctor/editDeals", data)
        .then((response) => {
          setisisDisable(false);
          // notification.success({ message: response.data.msg });
          if (response.data.status == 1) {
            setVar_PopupModelIcon(successModelTickImg);
            setVar_AdvertisementMsgHead("success!");
            setVar_AdvertisementMsg("Deal updated successfully!");
            // notification.success({ message: response.data.msg });
            setIsOpenEditDealSuccessModel(true);
            setIsOpenEditDealModel(false);
            // To call All Deals 
            getDealsDetails();
            setLoading(false);
          }
          else {
            setVar_PopupModelIcon(faildIcon);
            setVar_AdvertisementMsgHead("success!");
            setVar_AdvertisementMsg(response.data.msg + "!");
            setIsOpenEditDealSuccessModel(true);
            setIsOpenEditDealModel(false);
          }
        })
        .catch((error) => {
          notification.error({ message: error });
          setLoading(false);
          setisisDisable(false);
        });
    }
  };

  const getSpecialitychangevalue = (e) => {
    setVar_DealSpecialityValueId(e.target.value.specialityId);
    setVar_DealSpecialityValue({ selectedvalue: e.target.value.speciality, });
    getServiceType();
  }

  return (
    <div>
      {/* {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%' }}>
                        <LinearProgress sx={{ animationDuration: '4s' }} />
                    </Box>
                </div>
            } */}
      {loading && (
        <LinearProgress
          color="secondary"
          className="progressBar"
          sx={{ zIndex: "9999" }}
        />
      )}
      {/* ================= Deal sub header start ================= */}
      <div className="sub_header">
        <div className="sub_header_body">
          <div className="back_navigation" onClick={() => navigateToBack()}>
            <i class="fa fa-angle-left" aria-hidden="true"></i>
            <span>DEAL</span>
          </div>
          <div className="flex_grow"></div>
          <div className="header_right_content">
            {/* <div>
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    SelectProps={{
                                        renderValue: (p) => p
                                    }}
                                    value={Var_DoctorDetailShow_Ref.current}
                                    readOnly={true}
                                    renderValue={(p) => p}

                                >
                                </Select>
                            </FormControl>
                        </div> */}
            <div>
              <button className="add_btn_post" onClick={() => isOpenAddDeal()}>
                <i class="fa fa-plus-square" aria-hidden="true"></i>
                <span>Add Deal</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ================= Deal sub header end ================= */}

      {/* ================= Deal list View start ================= */}
      <div className="content_view">
        {Var_GetDealsDetails.map((item, i) => {
          return (
            <div className="deal_card" key={i}>
              <Grid container spacing={2}>
                <Grid item xs={1.5}>
                  <div className="deal_header">Speciality</div>
                  <div className="deal_data">{item.speciality_name}</div>
                </Grid>
                <Grid item xs={2}>
                  <div className="deal_header">Service Type</div>
                  <div className="deal_data">{item.deal_service_type}</div>
                </Grid>
                <Grid item xs={2}>
                  <div className="deal_header">Title</div>
                  <div className="deal_data">{item.deal_title}</div>
                </Grid>
                <Grid item xs={1.5}>
                  <div className="deal_header">Start Date</div>
                  <div className="deal_data">{moment(item.deal_valid_from).format("DD-MMM-YY")}</div>
                </Grid>
                <Grid item xs={1.5}>
                  <div className="deal_header">End Date</div>
                  <div className="deal_data">{moment(item.deal_valid_to).format("DD-MMM-YY")}</div>
                </Grid>
                <Grid item xs={3.5}>
                  <div className="pos_rel">
                    <div className="deal_off_img">
                      <img src={DealOfferImage} width="80px" height="80px" />
                      {item.deal_options == 'Percentage' &&
                        <div>
                          {item.deal_amount} %<br />
                          <span>Off</span>
                        </div>}
                      {item.deal_options != 'Percentage' &&
                        <div>
                          {Datedata.formatMoney(item.deal_amount)} <span style={{ fontSize: "9px" }}> KWD</span> <br />
                          <span>Off</span>
                        </div>
                      }
                    </div>
                    <div className="deal_strick">
                      {/* <span style={{ color: "#ff0068", textDecoration: 'line-through' }}>
                                                <span style={{ color: "#000000" }}>{item.service_cost} KWD</span>
                                            </span> */}
                      {item.deal_options != 'Percentage' ? Datedata.formatMoney(item.deal_amount) : item.deal_amount}{item.deal_options == 'Percentage' ? " %" : <span style={{ fontSize: "13px" }}> KWD</span>} <span>OFF</span>&nbsp;<span style={{ fontSize: "14px" }}>on</span>&nbsp;
                      <span style={{ color: "#ff0068", textDecoration: 'line-through' }}>
                        <span style={{ color: "#000000" }}>{Datedata.formatMoney(item.service_cost)}</span>
                      </span>&nbsp;
                      {item.deal_options == "Amount" ? Datedata.formatMoney(item.service_cost - item.deal_amount) : Datedata.formatMoney(item.service_cost - ((item.service_cost / 100) * item.deal_amount))} <span style={{ fontSize: "13px" }}> KWD</span>
                    </div>
                    {item.deal_valid_from > moment(currentDate).format("YYYY-MM-DD") ?
                      <div className="deal_edit" onClick={() => isOpenEditDeal(item)}>
                        <img src={EditIcon} alt="" />
                      </div>
                      : ""}
                  </div>
                </Grid>
              </Grid>
            </div>
          );
        })}
      </div>
      {/* ================= Deal list View end ================= */}

      {/* ================= add deal model start ================= */}
      <div style={{ borderRadius: "25px" }}>
        <Dialog
          fullWidth={fullWidth}
          open={isOpenAddDealModel}
          // onClose={isCloseAddDealModel}
          className="add_modol"
          maxWidth={"xs"}
          style={{ borderRadius: "30px" }}
        >
          <div className="dialog_header">
            <div className="dialog_title">ADD DEAL</div>
            <div className="flex_grow"></div>
            <div onClick={() => isCloseAddDealModel()}>
              <img src={closeicon} />
            </div>
          </div>
          <DialogContent>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel id="demo-select-small-label">
                    Specialty
                  </InputLabel>
                  <TextField
                    SelectProps={{
                      renderValue: (p) => p,
                    }}
                    value={Var_DealSpecialityValue.selectedvalue}
                    select
                    fullWidth
                    size="small"
                    renderValue={(p) => p}
                    sx={{ height: "40px", minWidth: "-webkit-fill-available" }}
                    // InputProps={{
                    //     readOnly: false
                    // }}
                    onChange={(e) => {
                      getSpecialitychangevalue(e)
                    }}
                  >
                    {Var_DealSpeciality.map((item) => {
                      return (
                        <MenuItem key={item.specialityId} value={item}>
                          {item.speciality}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel id="demo-select-small-label">
                    Service Type
                  </InputLabel>
                  <Select
                    onChange={(e) => getServiceTypeValue(e)}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    sx={{
                      padding: "8.5px 14px",
                      height: "40px",
                      minHeight: 15,
                      minWidth: "-webkit-fill-available",
                    }}
                  >

                    {Var_DealServiceType.map((item, i) => {
                      return (
                        <MenuItem value={item.id} name={item.cost}>
                          <img
                            src={item.file_path}
                            className="service_type_img"
                          />
                          {item.service}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errorMessage && Var_GetServiceTypeValue?.length <= 0 && (
                    <div className="error_text">{errorMessage}</div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel id="demo-select-small-label">
                    Deal Title
                  </InputLabel>
                  <TextField
                    onChange={(e) => getDealTitleValue(e)}
                    type="text"
                    size="small"
                    sx={{ minWidth: "-webkit-fill-available" }}
                  />
                  {errorMessage && Var_GetDealTitleValue?.length <= 0 && (
                    <div className="error_text">{errorMessage}</div>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel id="demo-select-small-label">
                    Valid From
                  </InputLabel>
                  {/* <TextField
                    onChange={(e) => getValidFromValue(e)}
                    value={Var_GetValidFromValue}
                    label=""
                    type="date"
                    size="small"
                    sx={{ minWidth: "-webkit-fill-available" }}
                  /> */}
                  <div className="Date_Picker" style={{ position: "relative" }}>
                    <TextField
                      label=""
                      htmlFor='date-input'
                      type="text"
                      size="small"
                      value={DateValFrom_Ref.current}
                      readOnly
                      sx={{ minWidth: '-webkit-fill-available' }} />
                    <TextField
                      onChange={(e) => getValidFromValue(e)}
                      label=""
                      type="date"
                      id="date-input"
                      size="small"
                      className='input121'
                      style={{ marginLeft: '74%' }}
                      InputProps={{ inputProps: { min: currentDateFormated } }}
                      sx={{ minWidth: '-webkit-fill-available' }} />
                    {errorMessage && Var_GetValidFromValue?.length <= 0 && (
                      <div className="error_text">{errorMessage}</div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel id="demo-select-small-label">Valid To</InputLabel>
                  <div className="Date_Picker" style={{ position: "relative" }}>
                    <TextField
                      label=""
                      htmlFor='date-input'
                      type="text"
                      size="small"
                      value={DateValTo_Ref.current}
                      readOnly
                      sx={{ minWidth: '-webkit-fill-available' }} />
                    <TextField
                      onChange={(e) => getValidToValue(e)}
                      label=""
                      type="date"
                      id="date-input"
                      size="small"
                      className='input121'
                      style={{ marginLeft: '74%' }}
                      InputProps={{ inputProps: { min: currentDateFormated } }}
                      sx={{ minWidth: '-webkit-fill-available' }} />
                    {errorMessage && Var_GetValidToValue?.length <= 0 && (
                      <div className="error_text">{errorMessage}</div>
                    )}
                  </div>
                  {/* <TextField
                    onChange={(e) => getValidToValue(e)}
                    label=""
                    type="date"
                    size="small"
                    sx={{ minWidth: "-webkit-fill-available" }}
                  /> */}
                </Grid>
                <Grid item xs={8} style={{ position: 'relative', top: '8px' }}  >
                  <InputLabel id="demo-select-small-label">
                    Deal Options
                  </InputLabel>

                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="KWD"
                    name="radio-buttons-group"
                  >
                    <div className="deal_option_group">
                      <FormControlLabel
                        value="Amount"
                        control={
                          <Radio checked={Var_GetDealOptionRadioValue == "Amount" ? true : false} onChange={(e) => getDealOptionRadioValue(e)} />
                        }
                        label={
                          <span style={{ display: 'flex' }}>
                            Amount <span style={{ fontSize: '10px', position: 'relative', top: '5px', left: '3px' }}>(KWD)</span>
                          </span>
                        }
                      />

                      <div className="flex_grow"></div>

                      <OutlinedInput
                        onChange={(event) => getDealOptionAmountTextBoxValue(event.target.value)}
                        value={Var_GetDealOptionAmountTextBoxValue}
                        id="outlined-adornment-weight"
                        disabled={
                          Var_GetDealOptionRadioValue == "Percentage"
                            ? true
                            : false
                        }
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{ m: 1, fontSize: "10px" }}
                          ></InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="KWD"
                        sx={{ m: 1, height: "25px", width: "125px" }}
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </div>

                    {!Var_GetDealOptionRadioValue || Var_GetDealOptionRadioValue !== "Percentage" ? (
                      <>
                        {errorMessage && Var_GetDealOptionPercentageTextBoxValue?.length <= 0
                          && Var_GetDealOptionAmountTextBoxValue?.length <= 0 && (
                            <div className="error_text">{errorMessage}</div>
                          )}
                        {errorMessage && Var_DealAmount_Ref.current <= 1 && !(Var_GetDealOptionPercentageTextBoxValue?.length <= 0
                          && Var_GetDealOptionAmountTextBoxValue?.length <= 0) && (
                            <div className="error_text">The Deal Amt should be above 1 KWD.</div>
                          )}
                      </>

                    ) : ""

                    }
                    <div className="deal_option_group">
                      <FormControlLabel
                        value="Percentage"
                        control={
                          <Radio onChange={(e) => getDealOptionRadioValue(e)} />
                        }
                        label="Percentage"
                      />
                      {/* <div className="flex_grow"></div> */}

                      <OutlinedInput
                        onChange={(event) => getDealOptionPercentageTextBoxValue(event.target.value)}
                        value={Var_GetDealOptionPercentageTextBoxValue}
                        id="outlined-adornment-weight"
                        disabled={
                          Var_GetDealOptionRadioValue == "Amount" ? true : false
                        }
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{ m: 1, fontSize: "10px" }}
                          ></InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="%"
                        sx={{ m: 1, height: "25px", width: "109px" }}
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </div>
                    {!Var_GetDealOptionRadioValue || Var_GetDealOptionRadioValue !== "Amount" ? (
                      <>
                        {errorMessage && Var_GetDealOptionPercentageTextBoxValue?.length <= 0
                          && Var_GetDealOptionAmountTextBoxValue?.length <= 0 && (
                            <div className="error_text">{errorMessage}</div>
                          )}
                        {errorMessage && Var_DealAmount_Ref.current <= 1 && !(Var_GetDealOptionPercentageTextBoxValue?.length <= 0
                          && Var_GetDealOptionAmountTextBoxValue?.length <= 0) && (
                            <div className="error_text">The Deal Amt should be above 1 KWD.</div>
                          )}
                      </>

                    ) : ''
                    }
                  </RadioGroup>
                </Grid>
                <Grid item xs={4}>
                  <div className="segment_center">
                    {/* <InputLabel id="demo-select-small-label">
                      Deal Active
                    </InputLabel> */}
                    <label className="deal_active_label">Deal Active</label>

                    <FormControlLabel
                      className="every_days"
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={Var_DealActiveSwitch}
                          className="toggle"
                          onClick={() => dealActiveSwitch()}
                        />
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {/* <p
                    className={
                      Var_GetDealOptionRadioValue == "Amount"
                        ? (100 * Var_GetDealOptionAmountTextBoxValue) /
                          Var_GetServiceTypeCost <=
                          70
                          ? ""
                          : "warning_helper_txt"
                        : ""
                    }
                  >
                    {Var_GetDealOptionRadioValue == "Amount"
                      ? (100 * Var_GetDealOptionAmountTextBoxValue) /
                        Var_GetServiceTypeCost <=
                        70
                        ? ""
                        : "Deal Amount Is too High"
                      : ""}
                  </p>
                  <p
                    className={
                      Var_GetDealOptionRadioValue == "Percentage"
                        ? Var_GetDealOptionPercentageTextBoxValue > 70
                          ? "warning_helper_txt"
                          : ""
                        : ""
                    }
                  >
                    {Var_GetDealOptionRadioValue == "Percentage" &&
                      Var_GetDealOptionPercentageTextBoxValue > 70 &&
                      "Deal Percentage Is too High"}
                  </p> */}
                  <p className={Var_GetDealOptionRadioValue === "Amount" && (100 * Var_GetDealOptionAmountTextBoxValue) / Var_GetServiceTypeCost > 70 ? "warning_helper_txt" : ""}>
                    {Var_GetDealOptionRadioValue === "Amount" && (100 * Var_GetDealOptionAmountTextBoxValue) / Var_GetServiceTypeCost > 70 ? "Deal Amount Is too High" : ""}
                  </p>
                  {/* <p className={Var_GetDealOptionRadioValue == "Amount" ? ((100 * Var_GetDealOptionAmountTextBoxValue) / Var_GetServiceTypeCost) <= 70 ? "" : "warning_helper_txt" : ""}>{Var_GetDealOptionRadioValue == "Amount" ? ((100 * Var_GetDealOptionAmountTextBoxValue) / Var_GetServiceTypeCost) <= 70 ? "" : "Deal Amount Is too High" : ""}</p> */}
                  <p className={Var_GetDealOptionRadioValue == "Percentage" ? Var_GetDealOptionPercentageTextBoxValue > 70 ? "warning_helper_txt" : "" : ""}>
                    {Var_GetDealOptionRadioValue == "Percentage" &&
                      Var_GetDealOptionPercentageTextBoxValue > 70 && "Deal Percentage Is too High"
                    }
                  </p>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel id="demo-select-small-label">
                    Service Fee
                    <p>{Datedata.formatMoney(Var_GetServiceTypeCost)}</p>
                  </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel id="demo-select-small-label">
                    Deal Amt
                    {/* <p>
                      {Var_GetDealOptionRadioValue == "Amount"
                        ? Var_GetDealOptionAmountTextBoxValue
                        : Var_GetDealOptionPercentageTextBoxValue}
                    </p> */}
                    <p>{Datedata.formatMoney(Var_DealAmount_Ref.current)}</p>
                  </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel id="demo-select-small-label">
                    Billing Amt
                    <p>{Datedata.formatMoney(Var_BillingAmount_Ref.current)}</p>
                    {/* <p>
                      {Var_GetDealOptionRadioValue == "Amount"
                        ? (100 * Var_GetDealOptionAmountTextBoxValue) /
                          Var_GetServiceTypeCost <=
                          70
                          ? Var_GetServiceTypeCost -
                          Var_GetDealOptionAmountTextBoxValue
                          : ""
                        : Var_GetServiceTypeCost -
                        (Var_GetServiceTypeCost / 100) *
                        Var_GetDealOptionPercentageTextBoxValue}
                    </p> */}
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <div className="segment_center">
                    <button
                      className="botton_Cart_Create"
                      disabled ={isDisable}
                      onClick={() => addDealFinalSubmit()}
                    >
                      Add
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
      </div>

      {/* ================= Add deal model end =================  */}

      {/* ================= Add deal Success Model Start ================= */}
      <Dialog
        fullWidth={fullWidth}
        open={isOpenAddDealSuccessModel}
        onClose={isCloseAddDealSuccessModel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "300px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogContent>
          <div className="success_model_body">
            <div className="icon">
              <img src={Var_PopupModelIcon_Ref.current} />
            </div>
            <div className="title">{Var_AdvertisementMsgHead_Ref.current}</div>
            <div className="content">{Var_AdvertisementMsg_Ref.current}</div>
            <div className="segment_center">
              <button
                className="botton_Cart_Create"
                onClick={() => isCloseAddDealSuccessModel()}
              >
                OK
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* ================= Add deal Success Model End ================= */}

      {/* ================= Updated deal Success Model Start ================= */}
      <Dialog
        fullWidth={fullWidth}
        open={isOpenEditDealSuccessModel}
        onClose={isCloseEditDealSuccessModel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "300px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogContent>
          <div className="success_model_body">
            <div className="icon">
              <img src={successModelTickImg} />
            </div>
            <div className="title">success!</div>
            <div className="content">Deal updated successfully!</div>
            <div className="segment_center">
              <button
                className="botton_Cart_Create"
                onClick={() => isCloseEditDealSuccessModel()}
              >
                OK
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* ================= Updated deal Success Model End ================= */}

      {/* ================= Edit deal model start ================= */}
      <Dialog
        fullWidth={fullWidth}
        open={isOpenEditDealModel}
        onClose={isCloseEditDealModel}
        maxWidth={"xs"}
      >
        <div className="dialog_header">
          <div className="dialog_title">EDIT DEAL</div>
          <div className="flex_grow"></div>
          <div onClick={() => isCloseEditDealModel()}>
            <img src={closeicon} />
          </div>
        </div>
        <DialogContent>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel id="demo-select-small-label">Specialty</InputLabel>
                <TextField
                  SelectProps={{
                    renderValue: (p) => p,
                  }}
                  value={Var_DealSpecialityValue.selectedvalue}
                  // select
                  fullWidth
                  size="small"
                  renderValue={(p) => p}
                  sx={{ height: "40px", minWidth: "-webkit-fill-available" }}
                  InputProps={{
                    readOnly: true
                  }}
                  onChange={(e) => {
                    setVar_DealSpecialityValueId(e.target.value.specialityId);
                    setVar_DealSpecialityValue({
                      selectedvalue: e.target.value.speciality,
                    });
                  }}
                >
                  {Var_DealSpeciality.map((item) => {
                    return (
                      <MenuItem key={item.specialityId} value={item}>
                        {item.speciality}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="demo-select-small-label">
                  Service Type
                </InputLabel>
                <TextField
                  SelectProps={{
                    renderValue: (p) => p
                  }}
                  value={Var_ServiceTitle_Ref.current}
                  select
                  fullWidth
                  size="small"
                  renderValue={(p) => p}
                  sx={{ height: '40px', minWidth: '-webkit-fill-available' }}
                  InputProps={{
                    readOnly: true
                  }}
                >
                  {Var_DealServiceType.map((item, i) => {
                    return <MenuItem value={item.id}>{item.service}</MenuItem>;
                  })}
                </TextField>
                {errorMessage && Var_ServiceTitle_Ref?.length <= 0 && (
                  <div className="error_text">{errorMessage}</div>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="demo-select-small-label">Deal Title</InputLabel>
                <TextField
                  onChange={(e) => getDealTitleValue(e)}
                  type="text"
                  size="small"
                  defaultValue={Var_GetDealTitleValue}
                  sx={{ minWidth: "-webkit-fill-available" }}
                />
                {errorMessage && Var_GetDealTitleValue?.length <= 0 && (
                  <div className="error_text">{errorMessage}</div>
                )}
              </Grid>
              <Grid item xs={6}>
                <InputLabel id="demo-select-small-label">Valid From</InputLabel>
                <div className="Date_Picker" style={{ position: "relative" }}>
                  <TextField
                    label=""
                    htmlFor='date-input'
                    type="text"
                    size="small"
                    value={moment(DateValFrom_Ref.current).format("DD-MMM-YY")}
                    readOnly
                    sx={{ minWidth: '-webkit-fill-available' }} />
                  <TextField
                    onChange={(e) => getValidFromValue(e)}
                    label=""
                    type="date"
                    id="date-input"
                    size="small"
                    className='input121'
                    InputProps={{ inputProps: { min: currentDateFormated } }}
                    style={{ marginLeft: '74%' }}
                    sx={{ minWidth: '-webkit-fill-available' }} />
                </div>
                {/* <TextField
                  onChange={(e) => getValidFromValue(e)}
                  label=""
                  type="date"
                  size="small"
                  defaultValue={Var_GetValidFromValue}
                  sx={{ minWidth: "-webkit-fill-available" }}
                /> */}
              </Grid>
              <Grid item xs={6}>
                <InputLabel id="demo-select-small-label">Valid To</InputLabel>
                <div className="Date_Picker" style={{ position: "relative" }}>
                  <TextField
                    label=""
                    htmlFor='date-input'
                    type="text"
                    size="small"
                    value={moment(DateValTo_Ref.current).format("DD-MMM-YY")}
                    readOnly
                    sx={{ minWidth: '-webkit-fill-available' }} />
                  <TextField
                    onChange={(e) => getValidToValue(e)}
                    label=""
                    type="date"
                    id="date-input"
                    size="small"
                    className='input121'
                    InputProps={{ inputProps: { min: currentDateFormated } }}
                    style={{ marginLeft: '74%' }}
                    sx={{ minWidth: '-webkit-fill-available' }} />
                </div>
                {/* <TextField
                  onChange={(e) => getValidToValue(e)}
                  label=""
                  type="date"
                  size="small"
                  defaultValue={Var_GetValidToValue}
                  sx={{ minWidth: "-webkit-fill-available" }}
                /> */}
              </Grid>
              <Grid item xs={8} style={{ position: 'relative', top: '8px' }} >
                <InputLabel id="demo-select-small-label">
                  Deal Options
                </InputLabel>

                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="KWD"
                  name="radio-buttons-group"
                >
                  <div className="deal_option_group">
                    <FormControlLabel
                      value="Amount"
                      control={
                        <Radio
                          checked={
                            Var_GetDealOptionRadioValue == "Amount"
                              ? true
                              : false
                          }
                          onChange={(e) => getDealOptionRadioValue(e)}
                        />
                      }
                      label={
                        <span style={{ display: 'flex' }}>
                          Amount <span style={{ fontSize: '10px', position: 'relative', top: '5px', left: '3px' }}>(KWD)</span>
                        </span>
                      }
                    />

                    <div className="flex_grow"></div>

                    <OutlinedInput
                      onChange={(event) => getDealOptionAmountTextBoxValue(event.target.value)}
                      value={Datedata.formatMoney(Var_GetDealOptionAmountTextBoxValue)}
                      disabled={Var_GetDealOptionRadioValue == "Percentage" ? true : false}
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{ m: 1, fontSize: "10px" }}
                        >
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      sx={{ m: 1, height: "25px", width: "100px" }}
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    // defaultValue={
                    //   Var_GetDealOptionRadioValue == "Amount"
                    //     ? Var_GetDealOptionAmountTextBoxValue
                    //     : ""
                    // }
                    />
                  </div>
                  {!Var_GetDealOptionRadioValue || Var_GetDealOptionRadioValue !== "Percentage" ? (
                    <>
                      {errorMessage && Var_GetDealOptionPercentageTextBoxValue?.length <= 0
                        && Var_GetDealOptionAmountTextBoxValue?.length <= 0 && (
                          <div className="error_text">{errorMessage}</div>
                        )}
                      {errorMessage && Var_DealAmount_Ref.current <= 1 && !(Var_GetDealOptionPercentageTextBoxValue?.length <= 0
                        && Var_GetDealOptionAmountTextBoxValue?.length <= 0) && (
                          <div className="error_text">The Deal Amt should be above 1 KWD.</div>
                        )}
                    </>

                  ) : ""

                  }
                  <div className="deal_option_group">
                    <FormControlLabel
                      value="Percentage"
                      defaultValue={Var_DealUpdateOldData?.deal_options}
                      control={
                        <Radio
                          checked={
                            Var_GetDealOptionRadioValue == "Percentage"
                              ? true
                              : false
                          }
                          onChange={(e) => getDealOptionRadioValue(e)}
                        />
                      }
                      label={
                        <span style={{ display: 'flex' }}>
                          Percentage <span style={{ fontSize: '10px', position: 'relative', top: '5px', left: '3px' }}>(%)</span>
                        </span>
                      }
                    />
                    <div className="flex_grow"></div>

                    <OutlinedInput
                      onChange={(event) => getDealOptionPercentageTextBoxValue(event.target.value)}
                      value={Var_GetDealOptionPercentageTextBoxValue}
                      disabled={Var_GetDealOptionRadioValue == "Amount" ? true : false}
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{ m: 1, fontSize: "10px" }}
                        >
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      sx={{ m: 1, height: "25px", width: "100px" }}
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    // defaultValue={
                    //   Var_GetDealOptionRadioValue == "Percentage"
                    //     ? Var_GetDealOptionAmountTextBoxValue
                    //     : ""
                    // }
                    />
                  </div>
                  {!Var_GetDealOptionRadioValue || Var_GetDealOptionRadioValue !== "Amount" ? (
                    <>
                      {errorMessage && Var_GetDealOptionPercentageTextBoxValue?.length <= 0
                        && Var_GetDealOptionAmountTextBoxValue?.length <= 0 && (
                          <div className="error_text">{errorMessage}</div>
                        )}
                      {errorMessage && Var_DealAmount_Ref.current <= 1 && !(Var_GetDealOptionPercentageTextBoxValue?.length <= 0
                        && Var_GetDealOptionAmountTextBoxValue?.length <= 0) && (
                          <div className="error_text">The Deal Amt should be above 1 KWD.</div>
                        )}
                    </>

                  ) : ''
                  }
                </RadioGroup>
              </Grid>
              <Grid item xs={4}>
                <div className="segment_center">
                  {/* <InputLabel id="demo-select-small-label">
                    Deal Active
                  </InputLabel> */}
                  <label className="deal_active_label">Deal Active</label>
                  <FormControlLabel
                    className="every_days"
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={Var_DealActiveSwitch == true ? true : false}
                        className="toggle"
                        onClick={() => dealActiveSwitch()}
                      />
                    }
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                {/* <p
                  className={
                    Var_GetDealOptionRadioValue == "Amount"
                      ? (100 * Var_GetDealOptionAmountTextBoxValue) /
                        Var_GetServiceTypeCost <=
                        70
                        ? ""
                        : "warning_helper_txt"
                      : ""
                  }
                >
                  {Var_GetDealOptionRadioValue == "Amount"
                    ? (100 * Var_GetDealOptionAmountTextBoxValue) /
                      Var_GetServiceTypeCost <=
                      70
                      ? ""
                      : "Deal Amount Is too High"
                    : ""}
                </p>
                <p
                  className={
                    Var_GetDealOptionRadioValue == "Percentage"
                      ? Var_GetDealOptionPercentageTextBoxValue > 70
                        ? "warning_helper_txt"
                        : ""
                      : ""
                  }
                >
                  {Var_GetDealOptionRadioValue == "Percentage" &&
                    Var_GetDealOptionPercentageTextBoxValue > 70 &&
                    "Deal Percentage Is too High"}
                </p> */}
                <p className={Var_GetDealOptionRadioValue === "Amount" && (100 * Var_GetDealOptionAmountTextBoxValue) / Var_GetServiceTypeCost > 70 ? "warning_helper_txt" : ""}>
                  {Var_GetDealOptionRadioValue === "Amount" && (100 * Var_GetDealOptionAmountTextBoxValue) / Var_GetServiceTypeCost > 70 ? "Deal Amount Is too High" : ""}
                </p>
                {/* <p className={Var_GetDealOptionRadioValue == "Amount" ? ((100 * Var_GetDealOptionAmountTextBoxValue) / Var_GetServiceTypeCost) <= 70 ? "" : "warning_helper_txt" : ""}>{Var_GetDealOptionRadioValue == "Amount" ? ((100 * Var_GetDealOptionAmountTextBoxValue) / Var_GetServiceTypeCost) <= 70 ? "" : "Deal Amount Is too High" : ""}</p> */}
                <p className={Var_GetDealOptionRadioValue == "Percentage" ? Var_GetDealOptionPercentageTextBoxValue > 70 ? "warning_helper_txt" : "" : ""}>
                  {Var_GetDealOptionRadioValue == "Percentage" &&
                    Var_GetDealOptionPercentageTextBoxValue > 70 && "Deal Percentage Is too High"
                  }
                </p>
              </Grid>
              <Grid item xs={4}>
                <InputLabel id="demo-select-small-label">
                  Service Cost
                  <p>{Datedata.formatMoney(Var_GetServiceTypeCost)}</p>
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <InputLabel id="demo-select-small-label">
                  Deal Amt
                  {/* <p>
                    {Var_GetDealOptionRadioValue == "Amount"
                      ? Var_GetDealOptionAmountTextBoxValue
                      : Var_GetDealOptionPercentageTextBoxValue}
                  </p> */}
                  <p>{Datedata.formatMoney(Var_DealAmount_Ref.current)}</p>
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <InputLabel id="demo-select-small-label">
                  Billing Amt
                  {/* <p>
                    {Var_GetDealOptionRadioValue == "Amount"
                      ? (100 * Var_GetDealOptionAmountTextBoxValue) /
                        Var_GetServiceTypeCost <=
                        50
                        ? Var_GetServiceTypeCost -
                        Var_GetDealOptionAmountTextBoxValue
                        : ""
                      : Var_GetServiceTypeCost -
                      (Var_GetServiceTypeCost / 100) *
                      Var_GetDealOptionPercentageTextBoxValue}
                  </p> */}
                  <p>{Datedata.formatMoney(Var_BillingAmount_Ref.current)}</p>
                </InputLabel>
              </Grid>

              <Grid item xs={12}>
                <div className="segment_center">
                  <button
                    className="botton_Cart_Create"
                    disabled={isDisable}
                    onClick={() => editDealFinalSubmit()}
                  >
                    Update
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
      {/* ================= Edit deal model end =================  */}

      {/* <Dialog
                fullWidth={fullWidth}
                open={isOpenAddDealModel}
                onClose={isCloseAddDealModel}
                maxWidth={"xs"}
            >
                <div className="dialog_header">
                    <div className="dialog_title">ADD DEAL</div>
                    <div className="flex_grow"></div>
                    <div onClick={() => isCloseAddDealModel()}>
                        <img src={closeicon} />
                    </div>
                </div>
                <DialogContent> */}

      <Dialog
        fullWidth={fullWidth}
        open={Var_IsShowSuccess}
        onClose={setVar_IsShowSuccess}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "300px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogContent>
          <div className="success_model_body">
            {/* <Grid container> */}
            <div item xs={12} align="center">
              <img alt="" className="image_body_sizes" src={Alert} />
            </div>
            <div item xs={12} align="center">
              <p className="success_body_size">ALERT!</p>
            </div>
            {/* </Grid> */}
            <div className="title"></div>
            <div className="content">
              working hours not available for this service!
            </div>
            <div className="segment_center">
              <button className="botton_Cart_Create" onClick={() => oKClose()}>
                OK
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <div className="footer">
        <FooterMaster />
      </div>
    </div>
  );
}
export default DealComponent;
