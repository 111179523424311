// AUTHOR : PRAVEEN S
// CR-DATE: 
// MOD-DATE: 05-08-2023
// DESCRIPTION: doctor select droupdown doctor select doctor id issue done

import React, { Component, useEffect, useState } from "react";
import useStateRef from "react-usestateref";
import { Card, Row, Col, Container, Dropdown, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
// import LinearProgress from '@mui/material/LinearProgress';

// dashboard css atsrt
import './Dashboard.css'
// dashboard css end

// mui css import start
import { MenuItem, DialogContent, Dialog } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
// mui css import end

// images start
import Logo from '../../assets/Doctor_Ave/Avelator_Logo.png'
// import Notification from '../../assets/Doctor_web_Icon/Group 19086.svg'
import support from '../../assets/Web_Doctor_Icon_new_theme/Support.svg'
import Notification from '../../assets/Web_Doctor_Icon_new_theme/notification_active.svg'
import emptyNotification from '../../assets/Web_Doctor_Icon_new_theme/notification_inactive.svg'
// images end

// component imnporting start
import Service from '../../helpers/Service'
import DashboardMasterComponent from "../Dashboard/DashboardMaster";
import FooterMaster from '../Dashboard/FooterMaster'
// component imnporting end
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import Postimg from '../../assets/Web_Doctor_Icon_new_theme/post.svg';
import Manageserviceimg from '../../assets/Web_Doctor_Icon_new_theme/Manage Service.svg';
import Workinghoursimg from '../../assets/Web_Doctor_Icon_new_theme/workinghours.svg';
import CollateralPlayIcon from '../../assets/Web_Doctor_Icon_new_theme/Collaterals_video.svg';
import closeicon from '../../assets/Web_Doctor_Icon_new_theme/close.svg';
import Supportimg from '../../assets/Doctor_web_Icon/Group 21105.png';
import handIcon from '../../assets/Doctor_web_Icon/Path 38456.svg';
import RejectIcon from "../../images/rejectIcon.svg"
function DashboardComponent() {


    // Navigation 
    let Var_History = useHistory();
    // Navigation 

    // Route Navigation start
    const routeNavigation = (path) => { Var_History.push("/dashboard" + path) };

    const routeNavigationByVerification = (path) => {
        Var_History.push({
            pathname: "/dashboard" + path, state: {  // location state
                verification: true,
            }
        })
    };
    // Route Navigation end

    // variable declaration start
    const [Var_Profiledata, setVar_Profiledata] = React.useState([]);
    const [Var_getClinicWiseDoctor, setVar_getClinicWiseDoctor] = React.useState([]);
    const [Var_doctorSpecialitySelect, setVar_doctorSpecialitySelect, Var_doctorSpecialitySelect_Ref] = useStateRef(null);
    const [Var_PermissionObject, setVar_PermissionObject, Var_PermissionObject_Ref] = useStateRef(null);
    const [Var_DoctorClinicSelect, setVar_DoctorClinicSelect, Var_DoctorClinicSelect_Ref] = useStateRef([]);
    const [Var_DoctorCurrentClinic, setVar_DoctorCurrentClinic, Var_DoctorCurrentClinic_Ref] = useStateRef(null);
    const [loading, setLoading] = useState(true);
    const [verificationPage, setVerificationPage] = useState(false);
    const [Var_NotificationData, setVar_NotificationData] = React.useState([]);
    const [Varnotificationcount, setVarnotificationcount, Varnotificationcount_Ref] = useStateRef([]);
    var [activeStep, setActiveStep] = useState(0);
    var [stepList, setStepList] = useState([
        { "label": "Reviewed & Approved", "dateTime": "23-Jun-23 10:00AM" },
        { "label": "Reviewed & Approved", "dateTime": "24-Jun-23 11:00AM" },
        { "label": "Reviewed & Approved", "dateTime": "25-Jun-23 12:00PM" },
        { "label": "You are ready", "dateTime": "to go live" }]);
    var [isOpenVideoPopup, setisOpenVideoPopup] = useState(false);
    var [videoPopupTitle, setVideoPopupTitle] = useState("");
    var [videoListbyMenu, setVideoListbyMenu] = useState([]);
    var [menuVideo, setMenuVideo] = useState([]);
    var [currentVideo, setCurrentVideo] = useState("");
    var [currentSubmenuid, setCurrentSubmenuid] = useState("");
    var [approved, setApproved] = useState("P");
    var [approvedLive, setApprovedLive] = useState("");
    var [approvedStatus, setApprovedStatus] = useState("");
    var [declineStatus, setDeclinestatus] = useState("");

    // variable declaration end

    // Initial load method start
    useEffect(() => {
        // getClinicWiseDoctor();
        getDoctorProfile();
        getMenulist()
        getNotifications();
        getClinicDoctor();
        // clinicTabPermissionById();
    }, []);
    // Initial load method end
    const getClinicDoctor = () => {
        setLoading(true);

        var data = {
            doctorId: localStorage.getItem("Doctor_id")
        };
        axios
            .post("doctor/getDoctorClinics", data)
            .then((response) => {
                debugger
                // localStorage.setItem("Clinic_id", response.data.data[0].clinicId);
                // setVar_getClinicWiseDoctor(response.data.data);
                // setVar_doctorClinic(response.data.data[0].clinicId)
                console.log("response.data.data[0].clinicId", response.data.data[0].clinicId);
                localStorage.setItem("Clinic_id", response.data.data[0].clinicId);
                setVar_DoctorCurrentClinic(response.data.data[0]);
                setVar_DoctorClinicSelect(response.data.data);
                console.log(Var_DoctorCurrentClinic_Ref.current);
                // localStorage.setItem("speciality_Id", response.data.data[0].specialityid);
                // clinicTabPermissionById()
                // checkThreeStepVerification(response.data.data[0].doctor_id)
                setLoading(false);

            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
    // get doctor profile api call start
    function getDoctorProfile() {
        debugger;
        setLoading(true);
        var data = {
            doctorId: localStorage.getItem("Doctor_id")
            // doctorId: "23"
        };
        axios
            .post("doctor/getDoctorDetails", data)
            .then((response) => {
                debugger;
                setVar_Profiledata(response.data.data);
                localStorage.setItem("speciality_Id", JSON.parse(response.data.data[0].speciality)[0].specialityId);
                setLoading(false);
                // getClinicWiseDoctor();
                checkThreeStepVerification();
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
    // get doctor profile api call end

    // get clinic wise doctor api call start
    function getClinicWiseDoctor() {
        // setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("Clinic_id")
        };
        axios
            .post("clinic/getClinicWiseDoctor", data)
            .then((response) => {
                localStorage.setItem("Doctor_id", response.data.data[0].doctor_id)
                setVar_getClinicWiseDoctor(response.data.data);
                setVar_doctorSpecialitySelect(response.data.data[0]);
                localStorage.setItem("speciality_Id", response.data.data[0].specialityid);
                Service.doctorDetail = (response.data.data[0])
                // clinicTabPermissionById()
                checkThreeStepVerification(response.data.data[0].doctor_id)
                setLoading(false);

            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
    // get clinic wise doctor api call end

    const doctorClinicSelect = (e) => {
        debugger
        // localStorage.setItem("speciality_Id", e.target.value.specialityid);
        // localStorage.setItem("Doctor_id", e.target.value.doctor_id);
        setVar_DoctorCurrentClinic(e.target.value);
        localStorage.setItem("Clinic_id", e.target.value.clinicId);
        console.log(Var_DoctorCurrentClinic_Ref.current);
        // clinicTabPermissionById();
        // checkThreeStepVerification(e.target.value.doctor_id)
        // Var_History.push("/dashboard/");


    }

    const clinicTabPermissionById = () => {
        //setLoading(true);
        var data = {
            doctorId: localStorage.getItem("Doctor_id"),
            clinicId: localStorage.getItem("Clinic_id")
        };
        axios
            .post("doctor/clinicTabPermissionbyId", data)
            .then((response) => {
                setVar_PermissionObject(response.data.data[0]);
            })
            .catch((error) => {
                // setLoading(false);
                console.log(error);
            });
    }

    const checkThreeStepVerification = (doctor_id,) => {
        getNotifications()
        var data = {
            "doctor_id": localStorage.getItem("Doctor_id"),
            // "clinic_id": localStorage.getItem("Clinic_id")
        }

        axios
            .post("doctor/doctorThreeStepVerification", data)
            .then((response) => {
                debugger
                if (response.status == 200) {
                    let findex = response.data.data.findIndex((obj) => obj.id == 0);
                    var filteredData = response.data.data.filter(x => x.id == "A" || x.id == "R");
                    var filteredData1 = response.data.data.filter(x => x.menu == "Go Live");
                    var rejectres = response.data.data.filter(
                        (x) => x.menu == "Reject Reason by Salesadmin"
                    );
                    setDeclinestatus(rejectres[0]?.id ? rejectres[0].id : '')
                    // console.log(rejectres[0].menu, '111');
                    // if (findex == -1) {
                    //     setVerificationPage(false)
                    //     setActiveStep(0)
                    // }
                    // else {
                    //     setVerificationPage(true)
                    //     setActiveStep(findex)
                    // }
                    if (filteredData.length != 0) {
                        setApproved(filteredData[0].id);
                        if (filteredData[0].id == 'A') {
                            // setVerificationPage(false)
                            // setActiveStep(0)
                            setApproved('P');
                            setApprovedLive('A');
                            setVerificationPage(true)
                            setActiveStep(findex)
                        } else if (filteredData[0].id == 'R') {
                            setApproved('R');
                            setApprovedLive('P');
                            localStorage.setItem("FooterStatus", "P");
                            setVerificationPage(true)
                            setActiveStep(findex)
                            console.log(findex, "findex========");
                            return
                        }
                        if (filteredData1[0].id != '0' && filteredData1[0].id != '' && filteredData1[0].id != null) {
                            // setApprovedStatus(filteredData1[0].id);
                            setApproved('A');
                            localStorage.setItem("FooterStatus", "A");
                            setVerificationPage(false);
                            setActiveStep(0);
                        } else {
                            setApprovedStatus(filteredData1[0].id);
                        }
                    } else {
                        setApproved('P');
                        setApprovedLive('P');
                        localStorage.setItem("FooterStatus", "P");
                        setVerificationPage(true)
                        setActiveStep(findex)
                        console.log(findex, "findex========");
                    }
                    console.log(approved, "approved=====");
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
    const updategoLiveStatus = () => {
        // if (approved == 'A') {
        //     setVerificationPage(false)
        //     setActiveStep(0)
        // }
        debugger;
        var data = {
            // "vendor_id": localStorage.getItem("clinic_id") ? localStorage.getItem("clinic_id") : ""
            "vendor_id": localStorage.getItem("Doctor_id") ? localStorage.getItem("Doctor_id") : ""
        }
        axios
            .post("admin/updateGoLiveStatus", data)
            .then((response) => {
                debugger;

                if (response.data.status == 1) {
                    setApproved('A');
                    setVerificationPage(false);
                    setActiveStep(0);
                    // setApprovedStatus(1);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getNotifications = () => {
        setLoading(true);
        debugger
        var data = {
            // doctor_id: localStorage.getItem("doctor_id"),
            // clinic_id: localStorage.getItem("clinic_id"),
            // speciality_id: localStorage.getItem("speciality_Id")
            clinic_id: 0,
            doctor_id: localStorage.getItem("Doctor_id"),
            patient_type: "",
            period: "day",
            search_date: (moment(new Date().toLocaleDateString('fr-CA')).format("YYYY-MM-DD")),
            search_date_to: (moment(new Date().toLocaleDateString('fr-CA')).format("YYYY-MM-DD"))
        };
        axios
            .post("doctor/getAppointmentList", data)
            .then((response) => {
                debugger;
                setVar_NotificationData(response.data.data);
                setVarnotificationcount(response.data.data[0].result.length);
                // checkThreeStepVerification(localStorage.getItem("Doctor_id"))
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const currentDate = new Date();

    // Get the year, month, and day
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because January is 0
    const day = ('0' + currentDate.getDate()).slice(-2);

    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    // console.log("praveen", formattedDate)




    const getMenulist = () => {

        axios({
            method: "POST",
            url: "admin/getCollateralVideosByVendor",
            data: {
                "vendorId": 1
            },
        })
            .then((response) => {
                if (response.data.status == 200) {
                    setVideoListbyMenu(response.data.data)
                }
                else {
                    setVideoListbyMenu([])
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleMenuCardOnClick = (data) => {
        debugger;
        if (videoListbyMenu.length != 0 && data != '') {
            var video = videoListbyMenu.filter((obj) => { return obj.menu_name.toLowerCase() == data.toLowerCase() })
            if (video.length > 0) {
                setMenuVideo(video)
                setisOpenVideoPopup(true)
                setCurrentVideo(video[0].video_path ? video[0].video_path : '')
                setCurrentSubmenuid(video[0].sub_menu_id)
                setVideoPopupTitle(video[0].menu_name)
            }
            else {
                setMenuVideo([])
            }

        }
        else {
            setMenuVideo([])
        }
    }

    const navClick = (data) => {
        setCurrentVideo(data.video_path ? data.video_path : '')
        setCurrentSubmenuid(data.sub_menu_id)
        setVideoPopupTitle(data.menu_name)
    }
    function rejectIcon() {
        return (
            <div className="rejectimage">
                <img src={RejectIcon} />
            </div>
        );
    }
    return (
        <div className="layout_Total_body">
            <div>
                {/* {loading &&
                    <div className="loader_body">
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <CircularProgress />
                        </Box>
                    </div>
                } */}
                {loading &&
                    <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999', width: '100%', top: '0', position: 'fixed' }} />
                }
                <div>
                    {/* ===========================================Page Header Start================================================== */}
                    {!verificationPage && approved == 'A' && <div className="layout_header">
                        <div className="home_header">
                            {/* <div className="header_row">
                                <Row className="row_content_">
                                    <Col xs="1">
                                        <div className="header_column1">
                                            <img src={Logo} alt="log" className="logo_images" />
                                        </div>
                                    </Col>
                                    <Col xs='2'>
                                        <div className='fix_dropdown'>
                                            <div className='drop dropdown_button'>
                                                <FormControl fullWidth>
                                                    <Select size="small" className="selectoption" sx={{ m: 1, minHeight: 1, minWidth: 100 }}
                                                        id="demo-simple-select"
                                                        value={Var_DoctorCurrentClinic_Ref.current}
                                                        onChange={(e) => {
                                                            doctorClinicSelect(e)
                                                        }}  >
                                                        {Var_DoctorClinicSelect_Ref.current.map((item) => (
                                                            <MenuItem
                                                                key={item.clinicName}
                                                                value={item}>
                                                                {item.clinicName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs='6'>
                                    </Col>
                                    <Col xs='3' className="header_right_part">
                                        <div className="header_icons">
                                            {Varnotificationcount_Ref.current > 0 &&
                                                <img src={Notification} alt="log" className="notification_images"
                                                    onClick={() => routeNavigation("/notification")}
                                                />
                                            }
                                            {Varnotificationcount_Ref.current == 0 &&
                                                <img className="notification_not_icon" src={emptyNotification} alt="log"
                                                    onClick={() => routeNavigation("/notification")}
                                                />
                                            }

                                            <img src={support} alt="log" className=" support_images"
                                                onClick={() => routeNavigation("/support")}
                                            />
                                        </div>
                                        <div>
                                            {Var_Profiledata?.map((item, i) => (
                                                <div className="profile_text">
                                                    <p className="profile_name_header">Welcome</p>
                                                    <p className="profile_name_header username">{item.doctorName}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            {Var_Profiledata?.map((item, i) => (
                                                <img src={item.vendor_profile_path == null ? Logo : item.vendor_profile_path == "" ? Logo : item.vendor_profile_path == " " ? Logo : item.vendor_profile_path} className="profile_img"
                                                    onClick={() => routeNavigation("/profile")}
                                                />
                                            ))}
                                        </div>

                                    </Col>
                                </Row>
                            </div> */}
                            <div className="header_row">
                                <div className="row_content_aprove">
                                    <div style={{ display: "flex" }}>
                                        <div className="header_column1">
                                            <img src={Logo} alt="log" className="logo_images_aprove" />
                                        </div>
                                        <div className='fix_dropdown_aprove'>
                                            <div className='drop dropdown_button'>
                                                <FormControl fullWidth>
                                                    <Select size="small" className="selectoption" sx={{ m: 1, minHeight: 1, minWidth: 100 }}
                                                        id="demo-simple-select"
                                                        value={Var_DoctorCurrentClinic_Ref.current}
                                                        onChange={(e) => {
                                                            doctorClinicSelect(e)
                                                        }}  >
                                                        {Var_DoctorClinicSelect_Ref.current.map((item) => (
                                                            <MenuItem
                                                                key={item.clinicName}
                                                                value={item}>
                                                                {item.clinicName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ flexGrow: "1" }}>
                                    </div>
                                    <div className="header_right_part">
                                        <div className="header_icons">
                                            {Varnotificationcount_Ref.current > 0 &&
                                                <img src={Notification} alt="log" className="notification_images"
                                                    onClick={() => routeNavigation("/notification")}
                                                />
                                            }
                                            {Varnotificationcount_Ref.current == 0 &&
                                                <img className="notification_not_icon" src={emptyNotification} alt="log"
                                                    onClick={() => routeNavigation("/notification")}
                                                />
                                            }

                                            <img src={support} alt="log" className=" support_images"
                                                onClick={() => routeNavigation("/support")}
                                            />
                                        </div>
                                        <div>
                                            {Var_Profiledata?.map((item, i) => (
                                                <div className="profile_text_aprove">
                                                    <p className="profile_name_header">Welcome</p>
                                                    <p className="profile_name_header username">{item.doctorName}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            {Var_Profiledata?.map((item, i) => (
                                                <img src={item.vendor_profile_path == null ? Logo : item.vendor_profile_path == "" ? Logo : item.vendor_profile_path == " " ? Logo : item.vendor_profile_path} className="profile_img_aprove"
                                                    onClick={() => routeNavigation("/profile")}
                                                />
                                            ))}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {verificationPage && (approved == 'P' || approved == 'R') && <div className="layout_header">
                        <div className="home_header">
                            <div className="header_row">
                                <div className="row_content_aprove">
                                    <div>
                                        <div className="header_column1">
                                            <img src={Logo} alt="log" className="logo_images" />
                                        </div>
                                    </div>
                                    {/* <div>
                                        <div className='fix_dropdown'>
                                            {/* <div className='drop dropdown_button'>
                                                <FormControl fullWidth>
                                                    <Select size="small" className="selectoption" sx={{ m: 1, minHeight: 1, minWidth: 100 }}
                                                        id="demo-simple-select"
                                                        value={Var_doctorSpecialitySelect}
                                                        onChange={(e) => {
                                                            doctorSpecialityId(e)
                                                        }}  >
                                                        {Var_getClinicWiseDoctor.map((item) => (
                                                            <MenuItem
                                                                key={item.specialityname}
                                                                value={item}>
                                                                {item.doctorName},{item.specialityname}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div> 
                                        </div>
                                    </div> */}
                                    <div style={{ flexGrow: "1" }}>
                                    </div>
                                    <div className="header_right_part">
                                        <div className="header_icons">
                                            {/* {Varnotificationcount_Ref.current > 0 &&
                                                <img src={Notification} alt="log" className="notification_images"
                                                    onClick={() => routeNavigation("/notification")}
                                                />
                                            }
                                            {Varnotificationcount_Ref.current == 0 &&
                                                <img className="notification_not_icon" src={emptyNotification} alt="log"
                                                    onClick={() => routeNavigation("/notification")}
                                                />
                                            } */}

                                            <img src={support} alt="log" className=" support_images"
                                                onClick={() => routeNavigation("/support")}
                                            />
                                        </div>
                                        <div>
                                            {Var_Profiledata?.map((item, i) => (
                                                <div className="profile_text">
                                                    <p className="profile_name_header">Welcome</p>
                                                    <p className="profile_name_header username">{item.doctorName}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            {Var_Profiledata?.map((item, i) => (
                                                <img src={item.vendor_profile_path == null ? Logo : item.vendor_profile_path == "" ? Logo : item.vendor_profile_path == " " ? Logo : item.vendor_profile_path} className="profile_img"
                                                // onClick={() => routeNavigation("/profile")}
                                                />
                                            ))}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {/* ===========================================Page Header End================================================== */}
                    {/* ===========================================Page Body Start================================================== */}

                    {!verificationPage && approved == 'A' && <div className="layout_body">
                        <DashboardMasterComponent menupermission={Var_PermissionObject} />
                    </div>}
                    {verificationPage && (approved == 'P' || approved == 'R') && <div className="verification_main_div">
                        <div className="three_step_head"> <h1 className="three_head">3 Step Registration to go Live</h1></div>
                        <div>
                            <Grid container>
                                <Grid sm={12} md={12} className="ml-5 mr-5">
                                    <Grid container>
                                        <Grid sm={3} md={3} className={activeStep >= 0 ? 'active_card' : 'disable_card'}>
                                            {/* <div style={{ fontSize: '22px' }}> Step 1</div> */}
                                            <div style={{ position: "relative" }} >
                                                <img className='step_head_img' src={Manageserviceimg} alt="Manageserviceimg" onClick={() => { if (activeStep >= 0) routeNavigationByVerification("/manageservice") }} />
                                                <img src={CollateralPlayIcon} className="play_img" onClick={() => { handleMenuCardOnClick('Manage Service') }} />
                                            </div>
                                            {/* <div style={{ textAlign: 'right' }}> </div> */}
                                            <div className="mt-2"> Manage Services</div>
                                        </Grid>
                                        <Grid sm={3} md={3} className={(activeStep >= 1 ? 'active_card' : 'disable_card')}>
                                            {/* <div style={{ fontSize: '22px' }}> Step 2</div> */}
                                            <div style={{ position: "relative" }} >
                                                <img className='step_head_img' src={Workinghoursimg} alt="Workinghoursimg" onClick={() => { if (activeStep >= 1) routeNavigationByVerification("/workinghours") }} />
                                                <img src={CollateralPlayIcon} className="play_img" onClick={() => { handleMenuCardOnClick('Working Hours') }} />
                                            </div>
                                            <div className="mt-2"> Working Hours</div>
                                        </Grid>
                                        <Grid sm={3} md={3} className={(activeStep >= 2 ? 'active_card' : 'disable_card')}>
                                            {/* <div style={{ fontSize: '22px' }}> Step 3</div> */}
                                            <div style={{ position: "relative" }} >
                                                <img className='step_head_img' src={Postimg} alt="Postimg" onClick={() => { if (activeStep >= 2) routeNavigationByVerification("/post") }} />
                                                <img src={CollateralPlayIcon} className="play_img" onClick={() => { handleMenuCardOnClick('post') }} />
                                            </div>
                                            <div className="mt-2"> Post</div>
                                        </Grid>
                                        <Grid sm={3} md={3} className={(activeStep >= 3 ? 'active_card' : 'disable_card')}>
                                            {/* <div style={{ fontSize: '22px' }}> Step 4</div> */}
                                            {activeStep == 4 && <div>
                                                {approvedStatus == 0 && approvedLive != 'A' ?
                                                    approved == 'R' ? (<div className="d-flex justify-content-center"><div className="decline_msg_box" >{declineStatus}</div></div>) : (<div><img className='step_head_img' src={Supportimg} alt="Supportimg" /></div>)

                                                    :
                                                    <div>
                                                        <img className='step_head_img' src={Supportimg} alt="Supportimg" onClick={() => { if (activeStep >= 3) updategoLiveStatus() }} />
                                                        {/* <div className="mt-2" onClick={() => { if (activeStep >= 3) updategoLiveStatus() }}>Congrats <br /> You are Live!</div> */}
                                                    </div>
                                                }
                                            </div>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <div className="stepper_line_show w-100">
                                    <Grid sm={12} md={12} className="ml-5 mr-5 mt-5">
                                        <Stepper activeStep={activeStep} orientation="horizontal" alternativeLabel={true}
                                            className="dash_stepper">
                                            {stepList.map((label, i) => (
                                                <Step key={label}>

                                                    <StepLabel StepIconComponent={(i == 3 && approved == 'R') && rejectIcon}>
                                                    </StepLabel>
                                                    {/* StepIconComponent={approvedStatus == 0 && approved == 'R' ? rejectIcon : ""} */}
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </Grid>
                                </div>
                                <div className="w-100">
                                    <div className="w-100"></div>
                                    {activeStep == 4 && <div>
                                        {approvedStatus == 0 && approvedLive != 'A' ?
                                            approved == 'R' ?
                                                (<div className="mt-2 waiting_class1 " style={{ fontWeight: '600', color: '#ff0068' }}>Declined</div>) :
                                                (<div className="mt-2 waiting_class " style={{ fontWeight: '600' }}>Waiting for <br /><span> verification</span></div>) :
                                            <div className="mt-2 congrats_text" style={{ fontWeight: '600' }}>
                                                Congratulations. <br /> <span style={{ marginRight: '15px' }}>You are Live!</span>
                                                {/* <div className="click_div">
                                                    <p>click to start</p>
                                                    <img className="hand_icon" src={handIcon}></img>
                                                </div></div> */}
                                                <div className="click_div">
                                                    <button
                                                        className="start_button"
                                                        onClick={() => { if (activeStep >= 3) updategoLiveStatus() }}
                                                    >Start</button>
                                                </div>
                                            </div>

                                        }
                                    </div>
                                    }
                                </div>
                            </Grid>
                        </div>
                    </div>}
                    {/* ===========================================Page Body End================================================== */}

                </div>

                {!verificationPage && approved == 'A' && <div className="footer">
                    <FooterMaster menupermission={Var_PermissionObject} />
                </div>}

                <Dialog
                    fullWidth={true}
                    alignment="center"
                    size="md"
                    open={isOpenVideoPopup}
                    onClose={setisOpenVideoPopup}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "950px", minWidth: "950px", borderRadius: "20px", height: '580px'
                            },
                        },
                    }}

                >
                    <div className="dialog_header">
                        <div className="dialog_title" style={{
                            textTransform: 'uppercase'
                        }}>{videoPopupTitle}</div>
                        <div className="flex_grow"></div>
                        <div onClick={() => setisOpenVideoPopup(false)}>
                            <img src={closeicon} />
                        </div>
                    </div>
                    <DialogContent>
                        <div>
                            <div style={{ textAlign: 'center' }}>
                                <video src={currentVideo} style={{ width: '100%', height: '450px' }} controls></video>
                                <div>
                                    <ul className="collaterals_video_ul">
                                        {
                                            menuVideo.map((obj) => (
                                                <li className={currentSubmenuid == obj['sub_menu_id'] ? "active" : ""} onClick={() => { navClick(obj) }}> {obj['sub_menu_name']} </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>

            </div>
        </div>
    );
}
export default DashboardComponent;
