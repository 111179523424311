import React, { useEffect, useRef } from "react";
import Axios from "axios";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import useStateRef from "react-usestateref";
import LinearProgress from '@mui/material/LinearProgress';

import { FormControl, Grid, InputLabel, MenuItem, TextField, Container } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Select from "@mui/material/Select";
import dateFormat from "dateformat";
import { Card } from "antd";
import { notification, Spin } from "antd";

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import { FileSaver } from 'file-saver';
import XLSX from 'sheetjs-style';

import eyeImage from "../../assets/Images/Action.png";
import pdficon from "../../assets/Doctor_web_Icon/pdf (1).svg";
import excelicon from "../../assets/Doctor_web_Icon/excel.svg";
import printericon from "../../assets/Doctor_web_Icon/print.svg";
import filtericon from "../../assets/Doctor_web_Icon/Icon awesome-filter.svg";
import appoinmentTypeIcon from "../../assets/Doctor_web_Icon/Icons/Group 16761.svg";
import closeicon from "../../assets/icon/Icon ionic-ios-close.png";
import downloadIcon from "../../assets/Doctor_web_Icon/Path 38309.svg";
import Visit from '../../assets/Doctor_web_Icon/Visit.svg';
import Referal from '../../assets/Doctor_web_Icon/Group 22856.svg';
import IncentiveIcon from '../../assets/Doctor_web_Icon/Group 22906.svg';
import aeroImage from '../../assets/Doctor_web_Icon/Polygon 4.svg';
import headerImg from "../../assets/Doctor_web_Icon/Vector.png";


import DateRangeSelect from "../../helpers/DateRange/DateRange";
import './RevenueList.css';
import FooterMaster from '../Dashboard/FooterMaster';
const RevenueListComponent = () => {

    let Var_History = useHistory();
    let { fromdate } = useParams();
    let { todate } = useParams();

    const [fullWidth, setFullWidth] = React.useState(true);
    var [Var_RevenueToggle, setVar_RevenueToggle, Var_RevenueToggle_Ref] = useStateRef("Revenue");
    var [Var_AppoinmentDetials, SetVar_AppoinmentDetials, Var_AppoinmentDetials_Ref] = useStateRef("");
    var [Var_PatientRevenueList, SetVar_PatientRevenueList, Var_PatientRevenueList_Ref] = useStateRef([]);
    var [Var_PatientRevenueListId, SetVar_PatientRevenueListId, Var_PatientRevenueListId_Ref] = useStateRef("");
    var [Var_RevenueBackgroundColor, setVar_RevenueBackgroundColor] = useStateRef("#82AE3F");
    var [Var_RevenueColor, setVar_RevenueColor] = useStateRef("#FFFFFF");
    var [Var_SettlementBackgroundColor, setVar_SettlementBackgroundColor] = useStateRef("#FFFFFF");
    var [Var_SettlementColor, setVar_SettlementColor] = useStateRef("#707070");
    var [memberList, setmemberList, memberList_Ref] = useStateRef([{ '1': 'thr' }, { '2': 'hjk' }, { '3': "jkl" }]);
    var [totalEarnings, settotalEarnings, totalEarnings_Ref] = useStateRef(0);
    var [viewmodal, setviewmodal, viewmodal_Ref] = useStateRef(false);
    var [Var_IsOpenFilter, setVar_IsOpenFilter] = useStateRef(false);
    var [Var_IsOpenDownloadStatement, setVar_IsOpenDownloadStatement] = useStateRef(false);
    var [Var_isOpenViewReceiptModal, SetVar_isOpenViewReceiptModal] = useStateRef(false);
    var [Var_ThirtyDaysCount, SetVar_ThirtyDaysCount, Var_ThirtyDaysCount_Ref] = useStateRef("");
    var [Var_SixtyDaysCount, SetVar_SixtyDaysCount, Var_SixtyDaysCount_ref] = useStateRef("");
    var [Var_SearchContent, setVar_SearchContent, Var_SearchContent_Ref] = useStateRef("");
    var [Var_TomIncome, setVar_TomIncome, Var_TomIncome_Ref] = useStateRef("");
    var [Var_PayableThDays, setVar_PayableThDays, Var_PayableThDays_Ref] = useStateRef("");
    var [Var_PayableSixDays, setVar_PayableSixDays, Var_PayableSixDays_Ref] = useStateRef("");
    var [Var_ReceivableThDays, setVar_ReceivableThDays, Var_ReceivableThDays_Ref] = useStateRef("");
    var [Var_TotalFee, setVar_TotalFee, Var_TotalFee_Ref] = useStateRef("");
    var [Var_viewInsuranceDetials, SetVar_viewInsuranceDetials, Var_viewInsuranceDetials_Ref] = useStateRef(false);
    var [Var_viewInsuranceId, SetVar_viewInsuranceId, Var_viewInsuranceId_Ref] = useStateRef("");
    var [Var_viewIncentiveId, SetVar_viewIncentiveId, Var_viewIncentiveId_Ref] = useStateRef("");
    var [Var_isOpenIncentive, SetVar_isOpenIncentive, Var_isOpenIncentive_Ref] = useStateRef(false);
    var [Var_fromDate, SetVar_fromDate, Var_fromDate_Ref] = useStateRef(fromdate);
    var [Var_toDate, SetVar_toDate, Var_toDate_Ref] = useStateRef(todate);
    const pdfRef = useRef();
    const printRef = useRef();



    const navigateToBack = (path) => {
        Var_History.push("/dashboard" + path)
    };

    useEffect(() => {
        getRevenueList();
        getDaysCalculate();
        const selectionRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
    }, []);

    const viewAppointment = (item) => {
        SetVar_AppoinmentDetials(item)
        setviewmodal(true);
    }
    const isOpenFilterModel = () => {
        setVar_IsOpenFilter(!Var_IsOpenFilter);
    }
    const isOpenDownloadModel = () => {
        setVar_IsOpenDownloadStatement(!Var_IsOpenDownloadStatement);
    }
    const isOpenViewReceiptModal = () => {
        SetVar_isOpenViewReceiptModal(!Var_isOpenViewReceiptModal);
    }
    const isSearchContent = (event) => {
        setVar_SearchContent(event);
        getRevenueList();
    }

    const toggleChange = (name) => {
        if (name == "Revenue") {
            setVar_RevenueBackgroundColor("#82AE3F");
            setVar_RevenueColor("#FFFFFF");
            setVar_SettlementBackgroundColor("#FFFFFF");
            setVar_SettlementColor("#707070");
            setVar_RevenueToggle(name);
        }
        else if (name == "Settlement") {
            setVar_RevenueBackgroundColor("#FFFFFF");
            setVar_RevenueColor("#707070");
            setVar_SettlementBackgroundColor("#82AE3F");
            setVar_SettlementColor("#FFFFFF");
            setVar_RevenueToggle(name);
        }
    }

    const viewInsuranceDetials = (data) => {
        SetVar_viewInsuranceId(data)
        SetVar_viewInsuranceDetials(true)
    }

    const isOpenIncentive = (data) => {
        SetVar_viewIncentiveId(data)
        SetVar_isOpenIncentive(true)
    }

    const getDaysCalculate = () => {
        var Var_CurrentDate = new Date();
        // Var_CurrentDate.setDate(Var_CurrentDate.getDate() + 5);
        // Var_CurrentDate.setDate(Var_CurrentDate.getMonth()+1);
        // var firstDay = new Date(Var_CurrentDate.getFullYear(), Var_CurrentDate.getMonth() , 1);
        // var Var_ThirtyDays = new Date(Var_CurrentDate.getFullYear(), Var_CurrentDate.getMonth(), 30);
        // var Var_ThirtyDays = 30 - (moment(Var_CurrentDate).format("DD"));

        // var Var_DaysLeft = Math.abs(Var_ThirtyDays - Var_CurrentDate)

        // var Var_DaysLeftCalc = Var_DaysLeft / (1000 * 3600 * 24)
        // var Var_ThirtyDaysLeft = Math.round(Var_DaysLeftCalc);

        // var Var_SixtyDays = new Date(Var_CurrentDate.getFullYear(), Var_CurrentDate.getMonth(), 60);
        // var Var_DaysLeftDiff = Math.abs(Var_SixtyDays - Var_CurrentDate)
        // var Var_SixtyDaysLeftCalc = Var_DaysLeftDiff / (1000 * 3600 * 24)
        // var Var_SixtyDaysLeft = Math.round(Var_SixtyDaysLeftCalc);
        // SetVar_ThirtyDaysCount(Var_ThirtyDaysLeft)
        // SetVar_SixtyDaysCount(Var_SixtyDaysLeft)
        // var monthCount = moment(new Date).format("DD-MM_YYYY") - moment(fromdate).format("DD-MM_YYYY")
        // var one_day = 1000 * 60 * 60 * 24;
        // let FromDateMonth = moment(fromdate).format("MM")
        // let FromDateDays = moment(fromdate).format("DD")
        // var today = new Date();
        // var cmas = new Date(today.getFullYear(), (FromDateMonth), 1);
        // alert(Math.ceil(((cmas.getTime() - today.getTime())) / (one_day)))

        if (moment(fromdate).format("MM") >= moment(new Date).format("MM")) {
            SetVar_ThirtyDaysCount("")
            SetVar_SixtyDaysCount("")
        }
        else {
            var monthCount = moment(new Date).format("MM") - moment(fromdate).format("MM")
            if (monthCount == 1) {
                SetVar_ThirtyDaysCount(30 - Number(moment(Var_CurrentDate).format("DD")))
                SetVar_SixtyDaysCount(60 - (moment(Var_CurrentDate).format("DD")))
            }
            // else if (monthCount == 2) {
            // alert(((-monthCount) * 30))
            // alert((moment(Var_CurrentDate).format("DD")))
            else {
                SetVar_ThirtyDaysCount(Number((-monthCount) * 30) + Number(moment(Var_CurrentDate).format("DD")))
                SetVar_SixtyDaysCount(Number((-monthCount) * 30) + Number((moment(Var_CurrentDate).format("DD"))) + 30)
                // SetVar_SixtyDaysCount(60 - (moment(Var_CurrentDate).format("DD")))
            }
            // }
            // else if (monthCount == 3) {
            //     SetVar_ThirtyDaysCount(-moment(new Date).format("DD"))
            //     SetVar_SixtyDaysCount((Var_SixtyDaysLeft + 1) - 30)
            // }
            // else {
            //     SetVar_ThirtyDaysCount((monthCount - 1) * (-moment(new Date).format("DD")))
            //     SetVar_SixtyDaysCount(-((monthCount - 2)) * ((Var_SixtyDaysLeft - (monthCount * 1))))
            // }
        }
        // alert(moment(fromdate).format("MM"));
        // alert(moment(new Date).format("MM"));
        // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        // alert(firstDay)
        // alert(Var_ThirtyDays)
        // alert(Var_SixtyDays)
        //     var d = new Date(); // today!
        //     var x = 5; // go back 5 days!
        //   d.setDate(d.getDate() - x);
        // alert(d)
        // alert(lastDay)
        // var daysInCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0).getDate()
        // alert(daysInCurrentMonth);
    }

    const getRevenueList = () => {
        debugger
        var data = {
            doctorId: localStorage.getItem("doctor_id"),
            fromDate: Var_fromDate_Ref.current,
            toDate: Var_toDate_Ref.current,
            searchContent: Var_SearchContent_Ref.current,
        };
        Axios({
            method: "POST",
            url: "patient/getDoctorRevenueList",
            data: data,
        }).then((response) => {
            response.data.data.forEach((element, i) => {
                element["sNo"] = i + 1;
            });
            SetVar_PatientRevenueList(response.data.data);
            let total = 0;
            let tomIncome = 0;
            let payableTDays = 0;
            let payableSDays = 0;
            let receivableThDays = 0;
            let totalFee = 0;
            response.data.data.forEach(element => {
                // SetVar_PatientRevenueListId(element.id)

                tomIncome = tomIncome + element.tomIncome;
                payableTDays = payableTDays + Number(element.payableThirtyDays);
                payableSDays = payableSDays + Number(element.payableSixtyDays);
                receivableThDays = receivableThDays + Number(element.receivableThirtyDays);
                totalFee = totalFee + Number(element.book_amount);
                element.doctorAmount = Number(element.doctorAmount) - Number(element.insuranceDeduction)
                element.doctorAmount = element.doctorIncentive == null && element.labIncentive == null ? element.doctorAmount : element.doctorIncentive + element.labIncentive + element.doctorAmount;
                total = total + element.doctorAmount;
            });
            setVar_TomIncome(tomIncome);
            setVar_ReceivableThDays(receivableThDays);
            settotalEarnings(total);
            setVar_PayableThDays(payableTDays ? payableTDays : 0);
            setVar_PayableSixDays(payableSDays ? payableSDays : 0);
            setVar_TotalFee(totalFee);
        }).catch((error) => {
            console.log(error);
        });
    }

    const getRangeDate = (item) => {
        var fromDate = dateFormat(item.startDate, "yyyy-mm-dd");
        var toDate = dateFormat(item.endDate, "yyyy-mm-dd");
        SetVar_fromDate(fromDate)
        SetVar_toDate(toDate)
        getRevenueList()
    };



    // =========================Pdf Download======================================
    const downloadPdf = () => {
        if (Var_PatientRevenueList_Ref.current.length > 0) {
            const input = pdfRef.current;
            html2canvas(input).then((canvas) => {
                const imageData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4', true);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
                const imgWidth = canvas.width;
                const imgHeight = canvas.height;
                const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
                const imgx = (pdfWidth - imgWidth * ratio) / 2;
                const imgy = 30;
                pdf.addImage(imageData, 'PNG', imgx, imgy, imgWidth * ratio, imgHeight * ratio);
                pdf.save('revenue.pdf');
            });
        }
        else {
            notification.error({ message: "No Data Found" });
        }
    }

    // ======================================Print===============================
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });


    return (
        <div>
            {/* ================= Revenue sub header start ================= */}
            <div className="sub_header">
                <div className="sub_header_body walkin_header_body">
                    <div className="back_navigation" onClick={() => { navigateToBack("/revenue") }}>
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                        <span>Revenue</span>
                    </div>
                </div>
            </div>
            {/* ================= Revenue sub header End ================= */}
            {/* ===============Body part start============== */}
            <div className='content_view'>
                {/* ===============Table design start=============== */}
                <div>
                    <div>
                        <Card className="revenuelist_subhead_card">
                            <Grid container >
                                <Grid item sm="5">
                                    <Grid container>
                                        <Grid item sm="2" className="revenue_date">{moment(fromdate).format("MMM  YY")}</Grid>
                                        <Grid item sm="3" className="revenue_toggle_head">
                                            <label className="revenue_toggle" style={{ color: Var_RevenueColor, background: Var_RevenueBackgroundColor }} onClick={() => { toggleChange("Revenue") }}>Revenue </label>
                                            <label className="settlement_toggle" style={{ color: Var_SettlementColor, background: Var_SettlementBackgroundColor }} onClick={() => { toggleChange("Settlement") }}>Settlement </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm="7" >
                                    <Grid container className="revenue_header_icons">
                                        <Grid sm="6.5" className="revenue_select_range">
                                            {/* <label className="select_range_revenue">Select Range :</label>
                                            <TextField
                                                type="text"
                                                size="small"
                                            > */}
                                            {/* </TextField> */}
                                            <DateRangeSelect
                                                dynalign={"dynalign"}
                                                rangeDate={(item) => getRangeDate(item)}
                                            // t={t}
                                            // i18n={this.props.i18n}
                                            />
                                            {/* <DateRangePicker/> */}
                                            {/* <input type="date"></input> */}
                                            {/* <img className="calendar_revenue_icon" src={calendaricon}></img> */}
                                        </Grid>
                                        <Grid sm="1" align="center" onClick={() => { isOpenFilterModel() }}><img src={filtericon}></img></Grid>
                                        <Grid sm="3" className="search_revenue">
                                            {/* <TextField
                                                type="text"
                                                size="small"
                                                onChange={(e) => { isSearchContent(e.target.value) }}></TextField> */}
                                            <Paper
                                                component="form"
                                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 200, height: 35, border: '1px solid #D8D8D8' }}
                                            >
                                                <InputBase
                                                    sx={{ ml: 0.5, flex: 1 }}
                                                    placeholder="Search"
                                                    onChange={(e) => { isSearchContent(e.target.value) }}
                                                />
                                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                                    <SearchIcon />
                                                </IconButton>
                                            </Paper>
                                        </Grid>
                                        <Grid sm="0.5">
                                            <img onClick={() => { isOpenDownloadModel() }} src={pdficon}></img>
                                        </Grid>
                                        <Grid sm="0.5"><img src={excelicon}></img></Grid>
                                        <Grid sm="0.5">
                                            <img src={printericon} onClick={() => handlePrint()}></img></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>

                    </div>
                    <div ref={printRef} >
                        <div hidden={Var_RevenueToggle_Ref.current == "Settlement"}>
                            <div className="revenue_label_top" >
                                <img src={IncentiveIcon}></img>
                                <label style={{ marginBottom: "5px" }} className="revenue_table_header">Incentive</label>
                            </div>
                            <div className="revenue_card_heading">
                                <Card className="revenue_sub_heading_title" >
                                    <Grid container className="revenue_grid">
                                        {/* <Grid item sm="10"> */}
                                        <Grid container >
                                            <Grid item sm="1" align="center" className="revenue_table_header">S.No</Grid>
                                            <Grid item sm="1" align="left" className="revenue_table_header">Date</Grid>
                                            <Grid item sm="1" align="left" className="revenue_table_header">Member</Grid>
                                            <Grid item sm="1" align="left" className="revenue_table_header">Appointment<br></br> Type</Grid>
                                            <Grid item sm="1" align="center" className="revenue_table_header">Fee <span style={{ fontSize: "10px" }}>(KWD) </span></Grid>
                                            <Grid item sm="1" align="center" className="revenue_table_header">Payment<br></br> Method</Grid>
                                            <Grid item sm="1" align="left" className="revenue_table_header">Advance <br></br>thru Tom <span style={{ fontSize: "10px" }}>(KWD) </span> </Grid>
                                            <Grid item sm="1" align="left" className="revenue_table_header">Collected<br></br> by Clinic <span style={{ fontSize: "10px" }}>(KWD) </span></Grid>
                                            <Grid item sm="1" align="left" className="revenue_table_header">Insurance <br></br>Claim <span style={{ fontSize: "10px" }}>(KWD) </span></Grid>
                                            {/* <Grid item sm="1" align="left" className="revenue_table_header">Insurance Deduction %</Grid> */}
                                            <Grid item sm="1" align="left" className="revenue_table_header" onClick={() => { viewInsuranceDetials() }}>Reimbursed by Insurance <span style={{ fontSize: "10px" }}>(KWD) </span></Grid>
                                            <Grid item sm="1" align="center" className="revenue_table_header">TOM <br></br>Income<span style={{ fontSize: "10px" }}>(KWD) </span></Grid>
                                            <Grid item sm="1" align="center" className="revenue_table_header">Dr.Saud<br></br> Income <span style={{ fontSize: "10px" }}>(KWD) </span></Grid>
                                        </Grid>
                                        {/* </Grid> */}
                                        {/* <Grid item sm="2"> */}
                                        {/* <Grid container> */}
                                        {/* <Grid item sm="3" align="left" className="revenue_table_header">TOM Share<br></br> % </Grid> */}
                                        {/* <Grid item sm="3" align="left" className="revenue_table_header">Dr.Saud Share<br></br> % </Grid> */}
                                        {/* </Grid> */}
                                        {/* </Grid> */}
                                    </Grid>
                                </Card>
                            </div>
                            <div className="revenue_ans_div">
                                {Var_PatientRevenueList_Ref.current.map((item, i) => (
                                    <div className="revenue_detials">
                                        <Card className="revenue_content" style={{ backgroundColor: i % 2 !== 0 ? '#7070701A' : 'white' }}>
                                            <Grid container className="revenue_grid">
                                                {/* <Grid item sm="10"> */}
                                                <Grid container >
                                                    <Grid item sm="1" align="center" className="revenue_table_content">{item.sNo}</Grid>
                                                    <Grid item sm="1" align="left" className="revenue_table_content"> {moment(item.book_date).format('DD-MMM-YY')}</Grid>
                                                    <Grid item sm="1" align="left" className="revenue_table_content">{item.patientName}<img src={eyeImage} onClick={() => { viewAppointment(item) }} className="revenue_eye_img" alt="No image"></img> </Grid>
                                                    <Grid item sm="1" align="left" className="revenue_table_content"><img src={item.is_referral == 1 ? Referal : item.appointmentType == 1 ? appoinmentTypeIcon : item.appointmentType == 2 ? Visit : item.appointmentType == 3 ? Visit : item.appointmentType == 4 ? Visit : item.appointmentType == 5 ? Visit : Referal} className="revenue_payment_type_img" alt="No image" style={item.is_referral == 1 ? { height: "40px", width: "40px", marginLeft: "15px" } : {}}></img></Grid>
                                                    <Grid item sm="1" align="center" className="revenue_table_content">{item.book_amount == "" || item.book_amount == null ? "---" : item.book_amount}</Grid>
                                                    <Grid item sm="1" align="center" className="revenue_table_content">{item.appointmentType == '1' ? 'TOM' : item.appointmentType == '2' ? 'Clinic' : item.appointmentType == '3' ? "Clinic + insurance" : "insurance"}</Grid>
                                                    <Grid item sm="1" align="center" className="revenue_table_content">{item.collectedByTom == "" || item.collectedByTom == null ? "---" : item.collectedByTom}</Grid>
                                                    <Grid item sm="1" align="center" className="revenue_table_content">{item.paidByClinic == "" || item.paidByClinic == null ? "---" : item.paidByClinic}</Grid>
                                                    <Grid item sm="1" align="center" className="revenue_table_content">{item.insurance_amount == "" || item.insurance_amount == null ? "---" : item.insurance_amount}</Grid>
                                                    {/* <Grid item sm="1" align="center" className="revenue_table_header">{item.insurancePercent == "" || item.insurancePercent == null ? "---" : item.insurancePercent}</Grid> */}
                                                    {/* <Grid item sm="1" align="center" className="revenue_table_header">{item.insuranceDeduction == "" || item.insuranceDeduction == null ? "---" : item.insuranceDeduction}</Grid> */}
                                                    {/* <Grid item sm="1" align="center" className="revenue_table_header">{item.insuranceDay == "" || item.insuranceDay == null ? "---" : item.insuranceDay}</Grid> */}
                                                    <Grid item sm="1" align="center" className="revenue_table_content">{item.reimbursedInsurance == "" || item.reimbursedInsurance == null ? "---" : item.reimbursedInsurance}{item.reimbursedInsurance == "" || item.reimbursedInsurance == null ? " " : <img src={eyeImage} onClick={() => { viewInsuranceDetials(item.sNo) }} className="revenue_eye_img"></img>}</Grid>
                                                    <Grid item sm="1" align="center" className="revenue_table_content">{item.tomIncome == "" || item.tomIncome == null ? "---" : item.tomIncome}</Grid>
                                                    <Grid item sm="1" align="center" className="revenue_table_content" >{item.doctorAmount == "" || item.doctorAmount == null ? "---" : item.doctorAmount}{item.doctorIncentive == null && item.labIncentive == null || item.doctorIncentive == "" && item.labIncentive == "" ? "" : item.doctorIncentive == null || item.labIncentive == null ? <img src={IncentiveIcon} onClick={() => { isOpenIncentive(item.sNo) }}></img> : <img src={IncentiveIcon} onClick={() => { isOpenIncentive(item.sNo) }}></img>}</Grid>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item sm="2"> */}
                                            {/* <Grid container> */}
                                            {/* <Grid item sm="3" align="left" className="revenue_table_header">{item.tomCommission} %</Grid> */}
                                            {/* <Grid item sm="3" align="left" className="revenue_table_header">{item.doctorCommissionPercentage} %</Grid> */}
                                            {/* </Grid> */}
                                            {/* </Grid> */}
                                            {/* </Grid> */}
                                        </Card>
                                        <div>
                                            <div >
                                                {Var_viewInsuranceDetials_Ref.current && Var_viewInsuranceId_Ref.current == item.sNo && <Grid container>
                                                    <Grid item sm="2"></Grid>
                                                    <Grid item sm="8" style={{ padding: "0px  50px 0px 0px" }}>
                                                        <div className="insurance_details_revenue">
                                                            <Card className="insurance_details_revenue_card">
                                                                <div style={{ display: "flex", justifyContent: "end" }}>
                                                                    <img src={aeroImage} className="insurance_detials"></img>
                                                                    <img src={closeicon} style={{ height: "15px" }} onClick={() => { SetVar_viewInsuranceDetials(false) }}></img>
                                                                </div>
                                                                <Grid container>
                                                                    <Grid item sm="3" align="center" className="revenue_table_header">Insurance<br></br> Claim<span style={{ fontSize: "10px" }}>(KWD) </span></Grid>
                                                                    <Grid item sm="3" align="center" className="revenue_table_header">Insurance<br></br> Deduction (%)</Grid>
                                                                    <Grid item sm="3" align="center" className="revenue_table_header">Insurance<br></br> Deduction<span style={{ fontSize: "10px" }}>(KWD) </span></Grid>
                                                                    <Grid item sm="3" align="center" className="revenue_table_header">Insurance<br></br> Receivable  <br></br>(Days Left)</Grid>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Grid item sm="3" align="center" className="revenue_table_content">{item.insurance_amount == "" || item.insurance_amount == null ? "---" : item.insurance_amount}</Grid>
                                                                    <Grid item sm="3" align="center" className="revenue_table_content">{item.insurancePercent == "" || item.insurancePercent == null ? "---" : item.insurancePercent}</Grid>
                                                                    <Grid item sm="3" align="center" className="revenue_table_content">{item.insuranceDeduction == "" || item.insuranceDeduction == null ? "---" : item.insuranceDeduction}</Grid>
                                                                    <Grid item sm="3" align="center" className="revenue_table_content">{item.insuranceDay == "" || item.insuranceDay == null ? "---" : item.insuranceDay}</Grid>
                                                                </Grid>
                                                            </Card>
                                                        </div>
                                                    </Grid>
                                                    <Grid item sm="2">
                                                    </Grid>
                                                    {/* {Var_isOpenIncentive_Ref.current && item.sNo == Var_viewIncentiveId_Ref.current && <Grid item sm="2">
                                                    <div className="insurance_incentive">
                                                        <Card className="insurance_details_revenue_insentive">
                                                            <Grid container>
                                                                <div style={{ display: "flex", justifyContent: "end" }}>
                                                                    <img src={closeicon} style={{ height: "15px" }} onClick={() => { SetVar_isOpenIncentive(false) }}></img>
                                                                </div>
                                                            </Grid>
                                                            <Grid container>
                                                                <div>
                                                                    <p>Lab Incentive     5<span>KWD</span></p>
                                                                </div>
                                                            </Grid>
                                                    </Card>
                                                </div>
                                                </Grid>} */}
                                                </Grid>}
                                                {Var_isOpenIncentive_Ref.current && item.sNo == Var_viewIncentiveId_Ref.current && <Grid container>
                                                    <Grid item sm="2"></Grid>
                                                    <Grid item sm="8"> </Grid>
                                                    <Grid item sm="2">
                                                        <div className="insurance_incentive">
                                                            <Card className="insurance_details_revenue_insentive">
                                                                <div style={{ display: "flex", justifyContent: "end" }}>
                                                                    <img src={closeicon} style={{ height: "10px" }} onClick={() => { SetVar_isOpenIncentive(false) }}></img>
                                                                </div>
                                                                <div>
                                                                    <Grid container className="revenue_table_content">Lab Incentive &emsp; {item.labIncentive}<span style={{ fontSize: "10px", display: "contents" }}>KWD</span></Grid>
                                                                    <Grid container className="revenue_table_content">Doctor  Incentive &emsp; {item.doctorIncentive}<span style={{ fontSize: "10px", display: "contents" }}>KWD</span></Grid>
                                                                    {/* <p>Lab Incentive &emsp; 5<span style={{ fontSize: "10px" }}>KWD</span></p> */}
                                                                </div>
                                                            </Card>
                                                        </div>
                                                    </Grid>
                                                </Grid>}
                                            </div>

                                            {/* <Grid container className="revenue_table_body">
                                <Grid item sm="1" align='left'>1</Grid>
                                <Grid item sm="2" align='left'>01-Mar-23</Grid>
                                <Grid item sm="2" align='left'>Mohamad <img src={eyeImage} onClick={() => { viewAppointment() }} className="revenue_eye_img" alt="No image"></img></Grid>
                                <Grid item sm="2" align='left'><img src={headerImg} className="revenue_payment_type_img" alt="No image"></img></Grid>
                                <Grid item sm="1" align='left'>Root X-Ray</Grid>
                                <Grid item sm="1" align='center'>50</Grid>
                                <Grid item sm="2" align='center'>TOM <img src={headerImg} className="revenue_eye_img" alt="No image"></img></Grid>
                                <Grid item sm="1" align='left'>42.5 <img src={eyeImage} onClick={() => { viewDtlList(i) }} className="revenue_eye_img" alt="No image"></img></Grid>
                            </Grid> */}
                                            {/* <div style={{ display: { "memberList_Ref.current[i].showDtl" } }}>
                                <Grid container className="revenue_dtl_grid">
                                    <Grid item sm="3">
                                        <div>Payment Method</div>
                                        <div>Insurance Claim</div>
                                        <div>200</div>
                                    </Grid>
                                </Grid>
                            </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="total_income">
                                <label className="revenue_total_revenue">Total Income : {totalEarnings} <span>KWD</span></label></div>
                        </div>
                        <div hidden={Var_RevenueToggle_Ref.current == "Revenue"}>
                            <div className="revenue_card_heading">
                                <Card className="revenue_sub_heading_title_settlement">
                                    <Grid container className="revenue_grid">
                                        {/* <Grid item sm="12">
                                        <Grid container > */}
                                        <Grid item sm="0.5" align="left" className="revenue_table_header">S.No</Grid>
                                        <Grid item sm="1.5" align="center" className="revenue_table_header">Date</Grid>
                                        <Grid item sm="1.5" align="center" className="revenue_table_header">Member</Grid>
                                        <Grid item sm="1.5" align="center" className="revenue_table_header">Fee(KWD)</Grid>
                                        <Grid item sm="1" align="center" className="revenue_table_header">Payment Method</Grid>
                                        <Grid item sm="1.5" align="center" className="revenue_table_header">Receivable<br></br>(30 Days)<br></br><div hidden={moment(new Date).format("MM") <= moment(fromdate).format("MM")}>{Var_ThirtyDaysCount_Ref.current} Days Left</div></Grid>
                                        <Grid item sm="1.5" align="center" className="revenue_table_header">Receivable<br></br>(60 Days)<br></br><div hidden={moment(new Date).format("MM") <= moment(fromdate).format("MM")}>{Var_SixtyDaysCount_ref.current} Days Left</div></Grid>
                                        <Grid item sm="1.5" align="center" className="revenue_table_header">Payable<br></br> (30 Days)<br></br><div hidden={moment(new Date).format("MM") <= moment(fromdate).format("MM")}>{Var_ThirtyDaysCount_Ref.current} Days Lef</div></Grid>
                                        <Grid item sm="1.5" align="center" className="revenue_table_header">Payable<br></br> (60 Days)<br></br><div hidden={moment(new Date).format("MM") <= moment(fromdate).format("MM")}>{Var_SixtyDaysCount_ref.current} Days Left</div></Grid>
                                        {/* </Grid>
                                    </Grid> */}
                                        {/* <Grid item sm="3">
                                        <Grid container>
                                            <Grid item sm="3" align="center" className="revenue_table_header">TOM <br></br>Share<br></br> % </Grid>
                                            <Grid item sm="3" align="center" className="revenue_table_header">TOM <br></br>Income </Grid>
                                            <Grid item sm="3" align="center" className="revenue_table_header">Dr.Saud<br></br> Share <br></br>% </Grid>
                                            <Grid item sm="3" align="center" className="revenue_table_header">Dr.Saud Income </Grid>
                                        </Grid>
                                    </Grid> */}
                                    </Grid>
                                </Card>
                            </div>
                            <div className="revenue_ans_div">
                                <div hidden={moment(new Date).format("MM") <= moment(fromdate).format("MM")}>
                                    <Card>
                                        <Grid container>
                                            {/* <Grid item sm="9">
                                            <Grid container > */}
                                            <Grid item sm="0.5"></Grid>
                                            <Grid item sm="1.5"></Grid>
                                            <Grid item sm="1.5"></Grid>
                                            <Grid item sm="1.5" align="center" ><label className="settlement_income_sub_total">Total Fee : {Var_TotalFee_Ref.current == "" || Var_TotalFee_Ref.current == null ? "---" : Var_TotalFee_Ref.current}<span>KWD</span></label></Grid>
                                            <Grid item sm="1"></Grid>
                                            <Grid item sm="1.5" align="center" onClick={() => { isOpenViewReceiptModal() }} ><label className="settlement_income_sub_total">Receivable : {Var_ReceivableThDays_Ref.current == "" || Var_ReceivableThDays_Ref.current == null ? "---" : Var_ReceivableThDays_Ref.current}  <span>KWD</span></label></Grid>
                                            <Grid item sm="1.5" align="center" ><label className="settlement_income_sub_total">Receivable :  0 <span>KWD</span></label></Grid>
                                            <Grid item sm="1.5" align="center" ><label className="settlement_income_sub_total">Payable : {Var_PayableThDays_Ref.current == "" || Var_PayableThDays_Ref.current == null ? "---" : Var_PayableThDays_Ref.current} <span>KWD</span></label></Grid>
                                            <Grid item sm="1.5" align="center"  ><label className="settlement_income_sub_total">Payable : {Var_PayableSixDays_Ref.current == "" || Var_PayableSixDays_Ref.current == null ? "---" : Var_PayableSixDays_Ref.current} <span>KWD</span></label></Grid>
                                            {/* </Grid>
                                        </Grid> */}
                                            {/* <Grid item sm="3">
                                            <Grid container >
                                                <Grid item sm="3"></Grid>
                                                <Grid item sm="2" align="center" ><label className="settlement_income_sub_total">TOM Income : {Var_TomIncome_Ref.current == "" || Var_TomIncome_Ref.current == null ? "---" : Var_TomIncome_Ref.current} <span>KWD</span></label></Grid>
                                                <Grid item sm="2"></Grid>
                                                <Grid item sm="2"></Grid>
                                                <Grid item sm="3" align="end" className="settlement_income_total"><label>Income : {totalEarnings_Ref.current == "" || totalEarnings_Ref.current == null ? "---" : totalEarnings_Ref.current} <span>KWD</span></label></Grid>
                                            </Grid>
                                        </Grid> */}
                                        </Grid>
                                    </Card>
                                </div>

                                {Var_PatientRevenueList_Ref.current.map((item, i) => (
                                    <div className="revenue_detials">
                                        <Card className="settlement_content" style={{ backgroundColor: i % 2 !== 0 ? '#7070701A' : 'white' }}>
                                            <Grid container className="revenue_grid">
                                                {/* <Grid item sm="9">
                                                <Grid container > */}
                                                <Grid item sm="0.5" align="left" className="revenue_table_header">{item.sNo}</Grid>
                                                <Grid item sm="1.5" align="center" className="revenue_table_header">{moment(item.book_date).format('DD-MMM-YY')}</Grid>
                                                <Grid item sm="1.5" align="center" className="revenue_table_header">{item.patientName} <img src={eyeImage} onClick={() => { viewAppointment(item) }} className="revenue_eye_img" alt="No image"></img> </Grid>
                                                <Grid item sm="1.5" align="center" className="revenue_table_header">{item.book_amount == "" || item.book_amount == null ? "---" : item.book_amount}</Grid>
                                                <Grid item sm="1" align="center" className="revenue_table_header">{item.appointmentType == '1' ? 'TOM' : item.appointmentType == '2' ? 'Clinic' : item.appointmentType == '3' ? "Clinic + insurance" : "insurance"}</Grid>
                                                <Grid item sm="1.5" align="center" className="revenue_table_header">{item.receivableThirtyDays == "" || item.receivableThirtyDays == null ? "---" : item.receivableThirtyDays}</Grid>
                                                <Grid item sm="1.5" align="center" className="revenue_table_header">---</Grid>
                                                <Grid item sm="1.5" align="center" className="revenue_table_header">{item.payableThirtyDays == "" || item.payableThirtyDays == null ? "---" : item.payableThirtyDays}</Grid>
                                                <Grid item sm="1.5" align="center" className="revenue_table_header">{item.payableSixtyDays == "" || item.payableSixtyDays == null ? "---" : item.payableSixtyDays}</Grid>
                                                {/* </Grid>
                                            </Grid> */}
                                                {/* <Grid item sm="3">
                                                <Grid container>
                                                    <Grid item sm="3" align="center" className="revenue_table_header">{item.tomCommission == "" || item.tomCommission == null ? "---" : item.tomCommission + "%"}</Grid>
                                                    <Grid item sm="3" align="center" className="revenue_table_header">{item.tomIncome == "" || item.tomIncome == null ? "---" : item.tomIncome}</Grid>
                                                    <Grid item sm="3" align="center" className="revenue_table_header">{item.doctorCommissionPercentage == "" || item.doctorCommissionPercentage == null ? "---" : item.doctorCommissionPercentage + "%"}</Grid>
                                                    <Grid item sm="3" align="center" className="revenue_table_header">{item.doctorAmount == "" || item.doctorAmount == null ? "---" : item.doctorAmount}</Grid>
                                                </Grid>
                                            </Grid> */}
                                            </Grid>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            {/* ============================= Table design end=============================================== */}
            {/* ==============================View appointment start ========================================*/}
            <Dialog
                fullWidth={fullWidth}
                open={viewmodal_Ref.current}
                onClose={() => setviewmodal(false)}
                maxWidth={"xs"}
            >
                <div className="dialog_header">
                    <div className="dialog_title">VIEW APPOINTMENT</div>
                    <div className="flex_grow"></div>
                    <div onClick={() => setviewmodal()}>
                        <img src={closeicon} />
                    </div>
                </div>
                <DialogContent>
                    <div className="revenue_modal_img">
                        <img src={Var_AppoinmentDetials_Ref.current.patientImage} onError={() => {
                            let tempArray = [];
                            tempArray.push(Var_AppoinmentDetials_Ref.current);
                            tempArray[0].patientImage = headerImg;
                            SetVar_AppoinmentDetials(tempArray[0]);
                        }}></img>
                        <div>{Var_AppoinmentDetials_Ref.current.patientName}</div>
                    </div>
                    <hr className="revenue_hr_line"></hr>
                    <div className="revenue_grid_container">
                        <Grid container className="revenue_modal_bodyGrid">
                            <Grid item sm="6">
                                <div className="revenue_modal_ttl">Name</div>
                                <div className="revenue_modal_ttlDtl">{Var_AppoinmentDetials_Ref.current.patientName}</div>
                            </Grid>
                            <Grid item sm="6">
                                <div className="revenue_modal_ttl">Appointment Type</div>
                                <div className="revenue_modal_ttlDtl">Online <img src={appoinmentTypeIcon}></img></div>
                            </Grid>
                        </Grid>
                        <Grid container className="revenue_modal_bodyGrid">
                            <Grid item sm="6">
                                <div className="revenue_modal_ttl">Specialty</div>
                                <div className="revenue_modal_ttlDtl">{Var_AppoinmentDetials_Ref.current.speciality_name}</div>
                            </Grid>
                            <Grid item sm="6">
                                <div className="revenue_modal_ttl">Service</div>
                                <div className="revenue_modal_ttlDtl">{Var_AppoinmentDetials_Ref.current.serviceName}</div>
                            </Grid>
                        </Grid>
                        <Grid container className="revenue_modal_bodyGrid">
                            <Grid item sm="6">
                                <div className="revenue_modal_ttl">Date</div>
                                <div className="revenue_modal_ttlDtl">{moment(Var_AppoinmentDetials_Ref.current.book_date).format('DD-MMM-YY')}</div>
                            </Grid>
                            <Grid item sm="6">
                                <div className="revenue_modal_ttl">Time</div>
                                <div className="revenue_modal_ttlDtl">{Var_AppoinmentDetials_Ref.current.appointmentTime}</div>
                            </Grid>
                        </Grid>
                        <Grid container className="revenue_modal_bodyGrid">
                            <Grid item sm="6">
                                <div className="revenue_modal_ttl">Clinic</div>
                                <div className="revenue_modal_ttlDtl">{Var_AppoinmentDetials_Ref.current.clinicName}</div>
                            </Grid>
                            <Grid item sm="6">
                                <div className="revenue_modal_ttl">Location</div>
                                <div className="revenue_modal_ttlDtl">{Var_AppoinmentDetials_Ref.current.clinicAddress}  <span>view more</span></div>
                            </Grid>
                        </Grid>
                        <Grid container className="revenue_modal_bodyGrid">
                            <Grid item sm="4">
                                <div className="revenue_modal_ttl"><span style={{fontSize:'10px'}}>(KWD)</span></div>
                                <div className="revenue_modal_ttlDtl">{Var_AppoinmentDetials_Ref.current.book_amount}</div>
                            </Grid>
                            <Grid item sm="4">
                                <div className="revenue_modal_ttl">Deal (KWD)</div>
                                <div className="revenue_modal_ttlDtl">10</div>
                            </Grid>
                            <Grid item sm="4">
                                <div className="revenue_modal_ttl">Billed Amount (KWD)</div>
                                <div className="revenue_modal_ttlDtl">{Var_AppoinmentDetials_Ref.current.doctorAmount}</div>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
            {/* ==============================View appointment end======================================== */}
            {/* ==============================View filtter start =============================================*/}
            <Dialog
                fullWidth={true}
                alignment="center"
                size="md"
                open={Var_IsOpenFilter}
                onClose={() => setVar_IsOpenFilter(false)}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%", maxWidth: "380px", borderRadius: "15px"
                        },
                    },
                }}
            >
                <div className="dialog_revenue_header">
                    <div className="dialog_revenue_title"> ADVANCE FILTER</div>
                    <div className="flex_grow"></div>
                    <div onClick={() => setVar_IsOpenFilter(false)}>
                        <img src={closeicon} alt="Close" />
                    </div>
                </div>
                <DialogContent>
                    <div>
                        <div className="revenue_select_filter">
                            <label>Appointment Type</label>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 339 }}>
                                <InputLabel id="demo-simple-select-standard-label">Age</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    // value={age}
                                    // onChange={handleChange}
                                    label="Age"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="revenue_select_filter">
                            <label>Payment Mode</label>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 339 }}>
                                <InputLabel id="demo-simple-select-standard-label">Age</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    // value={age}
                                    // onChange={handleChange}
                                    label="Age"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <Grid container style={{ display: "flex", justifyContent: "center" }}><label className="filter_submit" >Submit</label></Grid>
                    </div>
                </DialogContent>
            </Dialog>
            {/* ==============================View filtter end =============================================*/}
            {/* ============================== pdf Download start =============================================*/}
            <Dialog
                fullWidth={fullWidth}
                open={Var_IsOpenDownloadStatement}
                onClose={() => setVar_IsOpenDownloadStatement(false)}
                // maxWidth={"lg"}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            maxWidth: "lg", borderRadius: "45px"
                        },
                    },
                }}
            >
                <div className="dialog_revenue_header">
                    <div className="dialog_revenue_title">DOWNLOAD STATEMENT</div>
                    <div className="flex_grow"></div>
                    <div onClick={() => setVar_IsOpenDownloadStatement()}>
                        <img src={closeicon} />
                    </div>
                </div>
                <DialogContent>
                    <div className="revenue_ans_div">
                        <div ref={pdfRef}   >
                            <Card>
                                <Grid container>
                                    {/* <Grid item sm="9">
                                    <Grid container > */}
                                    <Grid item sm="0.5"></Grid>
                                    <Grid item sm="1.5"></Grid>
                                    <Grid item sm="1.5"></Grid>
                                    <Grid item sm="1.5" align="left" ><label className="settlement_income_sub_total">Total Fee : {Var_TotalFee_Ref.current == "" || Var_TotalFee_Ref.current == null ? "---" : Var_TotalFee_Ref.current} <span style={{ fontSize: "10px" }}>KWD</span></label></Grid>
                                    <Grid item sm="1"></Grid>
                                    <Grid item sm="1.5" align="center" onClick={() => { isOpenViewReceiptModal() }} ><label className="settlement_income_sub_total">Receivable : {Var_ReceivableThDays_Ref.current == "" || Var_ReceivableThDays_Ref.current == null ? "---" : Var_ReceivableThDays_Ref.current}<span style={{ fontSize: "10px" }}>KWD</span></label></Grid>
                                    <Grid item sm="1.5" align="center" ><label className="settlement_income_sub_total">Receivable : ---  <span style={{ fontSize: "10px" }}>KWD</span></label></Grid>
                                    <Grid item sm="1.5" align="center" ><label className="settlement_income_sub_total">Payable : {Var_PayableThDays_Ref.current == "" || Var_PayableThDays_Ref.current == null ? "---" : Var_PayableThDays_Ref.current}<span style={{ fontSize: "10px" }}>KWD</span></label></Grid>
                                    <Grid item sm="1.5" align="center"  ><label className="settlement_income_sub_total">Payable : {Var_PayableSixDays_Ref.current == "" || Var_PayableSixDays_Ref.current == null ? "---" : Var_PayableSixDays_Ref.current}<span style={{ fontSize: "10px" }}>KWD</span></label></Grid>
                                    {/* </Grid>
                                </Grid> */}
                                    {/* <Grid item sm="3">
                                    <Grid container >
                                        <Grid item sm="3"></Grid>
                                        <Grid item sm="2" align="center" ><label className="settlement_income_sub_total">TOM Income : 185 <span>KWD</span></label></Grid>
                                        <Grid item sm="2"></Grid>
                                        <Grid item sm="2"></Grid>
                                        <Grid item sm="3" align="end" className="settlement_income_total"><label>Income : 592.5 <span>KWD</span></label></Grid>
                                    </Grid>
                                </Grid> */}
                                </Grid>
                            </Card>
                            {Var_PatientRevenueList_Ref.current.map((item, i) => (
                                <div className="revenue_detials">
                                    <Card className="settlement_content" style={{ background: i % 2 !== 0 ? '#7070701A' : 'white' }}>
                                        <Grid container className="revenue_grid">
                                            {/* <Grid item sm="9">
                                            <Grid container > */}
                                            <Grid item sm="0.5" align="left" className="revenue_table_header">{item.sNo}</Grid>
                                            <Grid item sm="1.5" align="left" className="revenue_table_header">{moment(item.book_date).format('DD-MMM-YY')}</Grid>
                                            <Grid item sm="1.5" align="center" className="revenue_table_header">{item.patientName} <img src={eyeImage} onClick={() => { viewAppointment(item) }} className="revenue_eye_img" alt="No image"></img> </Grid>
                                            <Grid item sm="1.5" align="center" className="revenue_table_header">{item.book_amount}</Grid>
                                            <Grid item sm="1" align="center" className="revenue_table_header">{item.payment_type == '1' ? 'TOM' : 'Online'}</Grid>
                                            <Grid item sm="1.5" align="center" className="revenue_table_header"></Grid>
                                            <Grid item sm="1.5" align="center" className="revenue_table_header"></Grid>
                                            <Grid item sm="1.5" align="left" className="revenue_table_header"></Grid>
                                            <Grid item sm="1.5" align="left" className="revenue_table_header"></Grid>
                                            {/* </Grid>
                                        </Grid> */}
                                            {/* <Grid item sm="3">
                                            <Grid container>
                                                <Grid item sm="3" align="center" className="revenue_table_header">{item.tomCommission} %</Grid>
                                                <Grid item sm="3" align="center" className="revenue_table_header">{item.tomIncome}</Grid>
                                                <Grid item sm="3" align="center" className="revenue_table_header">{item.doctorCommissionPercentage} %</Grid>
                                                <Grid item sm="3" align="center" className="revenue_table_header">{item.doctorAmount}</Grid>
                                            </Grid>
                                        </Grid> */}
                                        </Grid>
                                    </Card>
                                </div>
                            ))}
                            {/* <Card>
                            <Grid container>
                                <Grid item sm="9">
                                    <Grid container >
                                        <Grid item sm="0.5"></Grid>
                                        <Grid item sm="1"></Grid>
                                        <Grid item sm="1.5"></Grid>
                                        <Grid item sm="1" align="left" ><label className="settlement_income_sub_total_fees">Total Fee : 750<span>KWD</span></label></Grid>
                                        <Grid item sm="1"></Grid>
                                        <Grid item sm="2" align="center" onClick={() => { isOpenViewReceiptModal() }} ><label className="settlement_income_sub_total">Receivable : 205  <span>KWD</span></label></Grid>
                                        <Grid item sm="2" align="center" ><label className="settlement_income_sub_total">Receivable : 20  <span>KWD</span></label></Grid>
                                        <Grid item sm="1.5" align="center" ><label className="settlement_income_sub_total">Payable : 22.5 <span>KWD</span></label></Grid>
                                        <Grid item sm="1.5" align="center"  ><label className="settlement_income_sub_total">Payable : 60 <span>KWD</span></label></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm="3">
                                    <Grid container >
                                        <Grid item sm="3"></Grid>
                                        <Grid item sm="2" align="center" ><label className="settlement_income_sub_total">TOM Income : 185 <span>KWD</span></label></Grid>
                                        <Grid item sm="2"></Grid>
                                        <Grid item sm="2"></Grid>
                                        <Grid item sm="3" align="end" className="settlement_income_total"><label>Income : 592.5 <span>KWD</span></label></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card> */}
                        </div>
                        <Grid container className="download_button">
                            <Grid
                                align="end" className="settlement_income_total" onClick={() => { downloadPdf() }}><label>Download PDF</label>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
            {/* ============================== pdf Download end =============================================*/}
            {/* ============================== pdf Receipt start =============================================*/}
            <Dialog
                fullWidth={true}
                alignment="center"
                size="md"
                open={Var_isOpenViewReceiptModal}
                onClose={() => SetVar_isOpenViewReceiptModal(false)}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%", maxWidth: "787px", borderRadius: "58px", height: "901px"
                        },
                    },
                }}
            >
                <div className="dialog_revenue_header">
                    <div className="dialog_revenue_title"> VIEW RECEIPT</div>
                    <div className="flex_grow"></div>
                    <div onClick={() => SetVar_isOpenViewReceiptModal(false)}>
                        <img src={closeicon} alt="Close" />
                    </div>
                </div>
                <DialogContent>
                    <div className="download_icon_recepit">
                        <img src={downloadIcon}></img>
                    </div>
                </DialogContent>
            </Dialog>
            {/* ============================== pdf Receipt end =============================================*/}
            {/* ===============Body part end=============== */}
            {/*===============Footer part start===============  */}
            <div className="layout_footer footer">
                <FooterMaster />
            </div>
            {/*===============Footer part end===============  */}
        </div >
    )
}

export default RevenueListComponent;
