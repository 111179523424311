import { useHistory, useParams } from "react-router-dom";
import useStateRef from "react-usestateref";
import React, { useEffect, useState } from "react";
import Axios from "axios";
import dateformat from "dateformat";
import { notification } from "antd";
import LinearProgress from "@mui/material/LinearProgress";


// mui material imports
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FooterMaster from "../Dashboard/FooterMaster";
import "./AdvertisementBookingMaster.css";
import Calendar from "./Calendar";
import Datedata from '../Revenue_settle/mothpicker/Datedata';
import DialogTitle from '@mui/material/DialogTitle';

import "bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";

import full from "../../../src/images/Full.png";
import half from "../../../src/images/Half.png";
import uploadalert from "../../assets/Web_Doctor_Icon_new_theme/info.svg";
import uploadIcon1 from "../../assets/Web_Doctor_Icon_new_theme/uploading-archive-1.svg";
import successModelTickImg from "../../assets/Web_Doctor_Icon_new_theme/Success.svg";
import faildIcon from "../../assets/Images/failedIcon.svg";
import closeicon from "../../assets/icon/Icon ionic-ios-close.png";
let startDate = moment();
let startdate = moment(startDate).add(2, "days").format("YYYY-MM-DD");

var enddate = moment(startdate);

function AdvertisementBookingEditComponent() {
  let { advertiseId } = useParams();
  let Var_History = useHistory();

  const [
    ,
    setVar_PlacementLocationId,
    Var_PlacementLocationId_Ref,
  ] = useStateRef("");
  const [, setVar_StartDate, Var_StartDate_Ref] =
    useStateRef(startdate);
  const [, setVar_EndDate, Var_EndDate_Ref] = useStateRef(enddate);
  const [, setVar_ExcludedDays, Var_ExcludedDays_Ref] =
    useStateRef([]);
  const [, setVar_ADImgUpload, Var_ADImgUpload_Ref] =
    useStateRef(null);
  const [, setVar_ADImgName, Var_ADImgName_Ref] =
    useStateRef(null);
  const [, setVar_ADImgNameSplit, Var_ADImgNameSplit_Ref] =
    useStateRef(null);
  const [, setVar_ImageSize, Var_ImageSize_Ref] = useStateRef("");
  const [Var_FeeKWD, setVar_FeeKWD, Var_FeeKWD_Ref] = useStateRef(null);
  const [Var_ToTalCost, setVar_ToTalCost, Var_ToTalCost_Ref] = useStateRef(0);
  const [, setVar_Advetisedays, Var_AdvetisedaysRef] =
    useStateRef("");
  const [
    isOpenAddedAdvertisementSuccessModel,
    setisOpenAddedAdvertisementSuccessModel,
  ] = useState(false);
  const [loading, setLoading] = useState(true);
  const [Var_fileprocess, setVar_fileprocess, ] =
    React.useState(false);
  const [Var_progress, setVar_Progress] = useState(0);
  const [, setVar_AdvertisementMsg, Var_AdvertisementMsg_Ref] = useStateRef("");
  const [, setVar_AdvertisementMsgHead, Var_AdvertisementMsgHead_Ref] = useStateRef("");
  const [, setVar_PopupModelIcon, Var_PopupModelIcon_Ref] = useStateRef("");
  const [, setVar_ClassName, Var_ClassName_Ref] = useStateRef("");
  const [, setVar_File_Detials, Var_File_Detials_Ref] = useStateRef("");
    const [Var_IsShowFailed, setVar_IsShowFailed] = React.useState(false);
    const [isDisable, setisisDisable] = useState(false);
    const [isOpenUploadInstructions, setisOpenUploadInstructions] = useState(false);
  // initial function call start
  useEffect(() => {
    getAdvertisementList();
    getFileSize();
    setisisDisable(false);
  }, []);
  // initial function call end

  // naviagte back start
  const navigateToBack = (path) => {
    Var_History.push("/dashboard" + path);
  };
  // naviagte back end
  const openInstruction = () => {
    setisOpenUploadInstructions(true);
}
const closeInstructionModel = () => {
    setisOpenUploadInstructions(false);
}
  const isClosePostfailedModel = () => {
    setVar_IsShowFailed(!Var_IsShowFailed);
  }
  // ==================Get Advertisememt list Start =================================
  const getAdvertisementList = () => {
    setLoading(true);
    const Var_RequestData = {
      doctorid: localStorage.getItem("Doctor_id"),
      limit: 50,
      pageno: 1,
    };
    Axios({
      method: "POST",
      url: "doctor/getAdBooking",
      data: Var_RequestData,
    })
      .then((response) => {
        let editAdvertiseValue = response.data.data[0].details.filter(
          (x) => x.id == advertiseId
        );
        setVar_StartDate(editAdvertiseValue[0].ad_start_date);
        setVar_EndDate(editAdvertiseValue[0].ad_end_date);
        setVar_Advetisedays(editAdvertiseValue[0].ad_total_days);
        setVar_ImageSize(editAdvertiseValue[0].ad_size == 1 ? "Half" : "Full");
        setVar_PlacementLocationId(editAdvertiseValue[0].ad_location_id);
        setVar_FeeKWD(editAdvertiseValue[0].ad_fee_per_day);
        setVar_ToTalCost(editAdvertiseValue[0].ad_total_cost);
        setVar_ADImgNameSplit(
          editAdvertiseValue[0].ad_path_name.split("//")[1]
        );
        setVar_ADImgName(getFileNameFromUrl(editAdvertiseValue[0].ad_path_name));
        setVar_ADImgUpload(editAdvertiseValue[0].ad_path_name.split("?")[0]);
        setVar_ExcludedDays(editAdvertiseValue[0].excluded_dates.split(","));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };


  const getFileNameFromUrl = (url) => {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    return pathname.substring(pathname.lastIndexOf('/') + 1);
  };
  // ==================Get Advertisememt list End  =================================
  const checkHours = () => {
    let startDate = moment(Var_EndDate_Ref.current).format("DD");
    let endDate = moment(Var_StartDate_Ref.current).format("DD");
    let fromMonth = moment(Var_StartDate_Ref.current).format("MM");
    let toMonth = moment(Var_EndDate_Ref.current).format("MM");
    let current_year = moment().year();
    let to_year = moment(Var_EndDate_Ref.current).year();

    if (parseInt(fromMonth) < parseInt(toMonth)) {
      var monthDiff =
        moment(Var_EndDate_Ref.current).format("MM") -
        moment(Var_StartDate_Ref.current).format("MM");
    } else if (parseInt(toMonth) < parseInt(fromMonth)) {
      var monthabs =
        moment(Var_StartDate_Ref.current).format("MM") -
        moment(Var_EndDate_Ref.current).format("MM") -
        12 * (to_year - current_year);

      var monthDiff = Math.abs(monthabs);
    } else if (parseInt(fromMonth) == parseInt(toMonth)) {
      var monthDiff = 0;
    }

    var daysInMonth = 0;
    for (let i = 0; i < monthDiff; i++) {
      var filteredMonth = parseInt(fromMonth) + parseInt(i);
      daysInMonth += new Date(current_year, filteredMonth, 0).getDate();
    }

    if (parseInt(endDate) < parseInt(startDate)) {
      var totalDays =
        moment(Var_EndDate_Ref.current).format("DD") -
        moment(Var_StartDate_Ref.current).format("DD") +
        daysInMonth +
        1;
      setVar_Advetisedays(totalDays);
    } else {
      var totalDays =
        daysInMonth +
        1 -
        (moment(Var_StartDate_Ref.current).format("DD") -
          moment(Var_EndDate_Ref.current).format("DD"));
      setVar_Advetisedays(totalDays);
      // this.setState({ totalDays });
    }
    let totalcost =
      (Var_AdvetisedaysRef.current - Var_ExcludedDays_Ref.current.length) *
      Var_FeeKWD_Ref.current;
    setVar_ToTalCost(totalcost);
    // this.setState({ adtotalcost: totalcost });
  };

  const isCloseAdvertisementSuccessModel = () => {
    setisOpenAddedAdvertisementSuccessModel(false);
    navigateToBack("/advertisementbooking/advertisementbookinglist");
  };

  // ======================== Advertisement s3 file Upload start ========================

  const addvertisementFileUpload = (event) => {
    var fileSize = event.target.files[0].size;
    if (fileSize >= Number(Var_File_Detials_Ref.current.size_in_byts)) {
      setVar_IsShowFailed(!Var_IsShowFailed);
    }
else {
  setisisDisable(true);
    setVar_fileprocess(true);
    setVar_Progress(0);
    setVar_ADImgName(event.target.files[0].name);
    const formData = new FormData();
    formData.append('module_id', "1");
    formData.append('file', event.target.files[0]);
    formData.append('pathLocation', "ADV/");
    Axios({
      method: "POST",
      url: "admin/awsS3FileUpload",
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setVar_Progress(percentage);
      },
    })
      .then((response) => {
        setVar_ADImgUpload(response.data.filepath.privatePathUrl);
        setLoading(false);
        setisisDisable(false);
      })
      .catch((error) => {
        setLoading(false);
        setVar_fileprocess(false);
        setisisDisable(false);
        console.log(error);
      });
    }
  };
  // ======================== Advertisement s3 file Upload end ========================
//Dynamic file size changes
const getFileSize = () =>{
    const payloaddata = {
        "contenttype": "Advertisement",
        "module_id": 1
    }

    Axios({
      method:"POST",
      url : "admin/getFileSizeByModule",
      data : payloaddata
    }).then((response)=>{
  setVar_File_Detials(response.data.data[0]);
  })
    .catch((error)=>{
      console.log(error);
    })
  }
  // ==================================Update Advertisement Start ================================
  const updateAddvertisement = () => {
    if (
      moment(Var_StartDate_Ref.current).format("YYYY-MM-DD") >=
      moment().add(2, "days").format("YYYY-MM-DD") &&
      Var_AdvetisedaysRef.current != " " &&
      Var_FeeKWD_Ref.current != null &&
      Var_ToTalCost_Ref.current != " " &&
      Var_ADImgName_Ref.current != null &&
      Var_ADImgUpload_Ref.current != null
    ) {
      setLoading(true);
      setisisDisable(true);
      const payload = {
        ad_id: advertiseId,
        imageArray: Var_ADImgUpload_Ref.current,
        startdate: moment(Var_StartDate_Ref.current).format("YYYY-MM-DD"),
        enddate: moment(Var_EndDate_Ref.current).format("YYYY-MM-DD"),
      }
      console.log(payload);
      Axios({ method: "POST", url: "Diet/editAdBooking", data: payload })
        .then((response) => {
          setisisDisable(false);
          if (response.data.status == 1) {
            setVar_ClassName("title");
            setVar_PopupModelIcon(successModelTickImg);
            setVar_AdvertisementMsgHead("success!");
            setVar_AdvertisementMsg("Advertisement updated successfully!");
            setisOpenAddedAdvertisementSuccessModel(true);
            setLoading(false);
          } else if (response.data.status == 2) {
            setVar_ClassName("failedtxt");
            setVar_PopupModelIcon(faildIcon);
            setVar_AdvertisementMsgHead("failed!");
            setVar_AdvertisementMsg("Advertisement Already Booked for Selected Date!");
            setisOpenAddedAdvertisementSuccessModel(true);
            setLoading(false);
          } else {
            setVar_PopupModelIcon(faildIcon);
            setVar_ClassName("failedtxt");
            setVar_AdvertisementMsgHead("failed!");
            setVar_AdvertisementMsg("Advertisement updated failed!");
            setisOpenAddedAdvertisementSuccessModel(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          setisisDisable(false);
        });
    } else {
      notification.error({ message: " Please Choose all important fields" });
    }
  };
  // ==================================Update Advertisement End ================================
  return (
    <div>
      {loading && (
        <LinearProgress
          color="secondary"
          className="progressBar"
          sx={{ zIndex: "9999" }}
        />
      )}

      <div className="sub_header">
        <div className="sub_header_body">
          <div
            className="back_navigation"
            onClick={() =>
              navigateToBack("/advertisementbooking/advertisementbookinglist")
            }
          >
            <i className="fa fa-angle-left" aria-hidden="true"></i>
            <span>ADVERTISEMENT</span>
          </div>
          <div className="flex_grow"></div>
          <div className="header_right_content">
            <div>
              <div>
                <button class="add_btn_post" onClick={() => navigateToBack("/advertisementbooking/advertisementbookinglist")}>
                  <span>View AD</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content_view">
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="calendar_full">
                <Calendar
                  useRef={"calendar"}
                  getDate={(data) => { }}
                  aftertwodays={true}
                  showSlots={true}
                  clinicId={null}
                ></Calendar>
                <div style={{ margin: '0px 0px 0px 20px ', color: '#ff0068' }}>Note : Booking can be done 2 days from the current date only</div>

              </div>
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={6}>
                  <InputLabel id="demo-select-small-label">
                    Start Date
                  </InputLabel>
                  <TextField
                    type="text"
                    size="small"
                    value={
                      Var_StartDate_Ref.current != "0000-00-00"
                        ? dateformat(Var_StartDate_Ref.current, "dd-mmm-yy")
                        : " "
                    }
                    sx={{ minWidth: "-webkit-fill-available" }}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <InputLabel id="demo-select-small-label">End Date</InputLabel>
                  <TextField
                    type="text"
                    size="small"
                    value={
                      Var_EndDate_Ref.current != "0000-00-00"
                        ? dateformat(Var_EndDate_Ref.current, "dd-mmm-yy")
                        : " "
                    }
                    sx={{ minWidth: "-webkit-fill-available" }}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <InputLabel id="demo-select-small-label">
                    Excluded Dates :
                    {Var_ExcludedDays_Ref.current.length > 0 && Var_ExcludedDays_Ref.current?.map((data, i, array) => (
                      <label>{data != "" &&
                        moment(data).format("DD-MMM-YY")}{i < array.length - 1 ? "," : " "}&nbsp;</label>
                    ))}
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="upload_title">
                    <span>Upload Advertisement</span>
                    <img onClick={() => openInstruction()} src={uploadalert} alt=""></img>
                  </div>
                  <div class="fileUpload">
                    <input
                      type="file"
                      class="upload"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => addvertisementFileUpload(e)}
                      onClick={(e) => (e.target.value = null)}
                    />
                    <span>
                      <img src={uploadIcon1} alt="Upload Icon" />
                    </span>
                    <p>{Var_ADImgName_Ref.current}</p>
                  </div>

                  {Var_fileprocess && (
                    <div>
                      <div>
                        <div className="upload-container" style={{ paddingTop: '15px' }}>

                          <LinearProgress
                            variant="determinate"
                            value={Var_progress}
                            color="secondary"
                            className="progressBar"
                            sx={{ zIndex: "1", width: '100%', margin: '0px 3px' }}
                          />
                          <div className="progress-text">
                            {`${Var_progress}%`}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Grid container spacing={2}>
                    <Grid item sm={3} className="checkbox_full">
                      <Checkbox
                        className="post_active_btn_ad"
                        checked={
                          Var_ImageSize_Ref.current == "Half" ? true : false
                        }
                      />
                      <label className="fess_cost">Half</label>
                      <img src={half} className="select_Icon" alt=""></img>
                    </Grid>
                    <Grid item sm={3} className="checkbox_full">
                      <Checkbox
                        className="post_active_btn_ad"
                        checked={
                          Var_ImageSize_Ref.current == "Full" ? true : false
                        }
                      />
                      <label className="fess_cost">Full</label>
                      <img src={full} className="select_Icon" alt=""></img>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <p className="content_SubHeadings">
                    Fee / Day <span style={{ fontSize: "10px" }}> (KWD)</span>
                  </p>
                  <p style={{ color: '#aaaaaa' }}>{Datedata.formatMoney(Var_FeeKWD)}</p>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <p className="content_SubHeadings">
                    Total Cost <span style={{ fontSize: "10px" }}> (KWD)</span>
                  </p>
                  <p style={{ color: '#aaaaaa' }}>{Datedata.formatMoney(Var_ToTalCost)}</p>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="segment_center">
                    <button
                      className="submit_Button"
                      disabled={isDisable}
                      onClick={() => updateAddvertisement()}
                    >
                      Update
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="layout_footer footer">
        <FooterMaster />
      </div>
      {/* ================= Add Advertisement  Success Model Start ================= */}
      <Dialog
        open={isOpenAddedAdvertisementSuccessModel}
        onClose={isCloseAdvertisementSuccessModel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "300px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogContent>
          <div className="success_model_body">
            <div className="icon">
              <img className="image_body_sizes" src={Var_PopupModelIcon_Ref.current} alt=""/>
            </div>
            <div className={Var_ClassName_Ref.current}>{Var_AdvertisementMsgHead_Ref.current}</div>
            <div className="content">{Var_AdvertisementMsg_Ref.current}</div>
            <div className="segment_center">
              <button
                className="botton_Cart_Create"
                onClick={() => isCloseAdvertisementSuccessModel()}
              >
                OK
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
{/* ============================== */}
      <Dialog fullWidth= {true} open={Var_IsShowFailed} onClose={setVar_IsShowFailed}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={faildIcon} alt=""/>
              </div>
              <div className="failed">alert!</div>
              <div className="content">File size should be less than {Var_File_Detials_Ref.current.size_in_mb} MB</div>
              <div className="segment_center">
                <button className="botton_Cart_Create" onClick={() => isClosePostfailedModel()}>OK</button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
            {/* ==========================================Upload Instructions ======================= */}
            <Dialog
                open={isOpenUploadInstructions}
                onClose={closeInstructionModel}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "400px",
                            borderRadius: "20px",
                        },
                    },
                }}
            >
                <DialogTitle>
                    <div className="upload_instruction_full">
                        <h4 className="uploadins_head">UPLOAD INSTRUCTIONS</h4>
                        <img className="upload_ins_close" onClick={() => closeInstructionModel()} src={closeicon} alt=""></img>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div className="uploaddIns_div">
                            <label className="upldinsicon"></label><label className="ins_content">Please upload image in JPG or PNG format</label>
                        </div>
                        <div className="uploaddIns_div">
                            <label className="upldinsicon"></label><label className="ins_content">Image size should be 1080 px X 566 px </label>
                        </div>
                        <div className="uploaddIns_div">
                            <label className="upldinsicon"></label><label className="ins_content">Image size should be less than {Var_File_Detials_Ref.current.size_in_mb} MB</label>
                        </div>
                        {/* <div className="uploaddIns_div">
                            <label className="upldinsicon"></label><label className="ins_content">Video size should be less than {Var_File_Detials_Ref.current.size_in_mb} MB.</label>
                        </div> */}
                    </div>
                </DialogContent>
            </Dialog>

    </div>
  );
}

export default AdvertisementBookingEditComponent;
