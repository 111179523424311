import { Container, Row, Col, Dropdown, Card } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";

import './ReferredMe.css';
import referral from "../../assets/icon/referral.svg"
import FooterMaster from '../Dashboard/FooterMaster'

const ReferredMeComponent = () => {
    let Var_History = useHistory();
    const handleOnClick = (path) => { Var_History.push("/dashboard" + path) };
    return (
        <div>
            
        </div>
        // <div>
        //     <div className="layout_header">
        //         <div className="home_header">
        //             <div className="header_row">
        //                 <Row className="row_content_">
        //                     <Col xs="3" align='start' clas>
        //                         <div className="header_column">
        //                             <i className="fa fa-angle-left header_back_button " aria-hidden="true"
        //                                 onClick={() => handleOnClick("/referral/viewreferral")}
        //                             />
        //                             <p className='page_head'>Who Referred me</p>
        //                         </div>
        //                     </Col>
        //                     <Col xs='3'>
        //                     </Col>
        //                     <Col xs='2' align="center ">
        //                         <div className='fix_dropdown'>
        //                             <div className='drop dropdown_button'>
        //                                 <Row className='Select_Label Select_Label_work Header_Select_Label'>
        //                                     <Col xs='10' align="start">
        //                                         Saud, Dentistry
        //                                     </Col>
        //                                     <Col xs='1' className='ref_lab' align="start">
        //                                         <i class="fa fa-caret-down" aria-hidden="true"></i>
        //                                     </Col>
        //                                 </Row>
        //                             </div>
        //                         </div>
        //                     </Col>

        //                     <Col xs='3' align="center">
        //                         <div className='page_head_date'>
        //                             <Button className='date_left_button'>
        //                                 <i className="fa fa-chevron-left date_left_button_icon" aria-hidden="true" />
        //                             </Button>
        //                             <Button className='date_button'>09-May-23</Button>
        //                             <Button className='date_right_button'>
        //                                 <i className="fa fa-chevron-right date_right_button_icon" aria-hidden="true" />
        //                             </Button>
        //                         </div>
        //                     </Col>
        //                 </Row>
        //             </div>
        //         </div>

        //     </div>
        //     <Container>
        //         <Row style={{ margin: '0px' }}>
        //             <Col>
        //                 <Card style={{ width: '100%', height: '100px', padding: '18px', marginTop: '50px' }}>
        //                     <Row>
        //                         <Col xs="6" align="center" className='d-flex' style={{ borderRight: 'black 2px dotted' }}>
        //                             <div >
        //                                 <img src={referral} alt='ref' className='Profoile_img' />
        //                             </div>
        //                             <div style={{ marginLeft: '30px' }}>
        //                                 <p className='referral_details1'>Dr. Fahad Fazil</p>
        //                                 <p className='referral_details2'>28 Years / Male</p>
        //                                 <p className='referral_details'>Dentistry</p>
        //                             </div>

        //                         </Col>
        //                         <Col xs="6" align="center" className='d-flex'>
        //                             <div >
        //                                 <img src={referral} alt='ref' className='Profoile_img' />
        //                             </div>
        //                             <div style={{ marginLeft: '30px' }}>
        //                                 <p className='referral_details1'>Wissam Ahmadi</p>
        //                                 <p className='referral_details2'>28 Years / Male</p>
        //                                 <p className='referral_details'>Kuwait city, Kuwait</p>
        //                             </div>

        //                         </Col>
        //                     </Row>
        //                 </Card>
        //             </Col>
        //         </Row>


        //     </Container>
        //     <div className="layout_footer footer">
        //         <FooterMaster />
        //     </div>
        // </div>
    )
}
export default ReferredMeComponent;
