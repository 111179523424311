import { useHistory } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { CRow, CCol, CModal, CModalBody, CModalHeader, CModalTitle, CButton, } from "@coreui/react";
import React from "react";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Badge from '@mui/material/Badge';
import "bootstrap/dist/css/bootstrap.min.css";


import "./WalletMaster.css";
import TOMLogo from "../../assets/Images/TOMLogo.jpg";
import Prices from "../../assets/Images/Price.png";
import Success from "../../assets/Doctor_web_Icon/success.png";
import FooterMaster from '../Dashboard/FooterMaster'

const WalletMasterComponent = () => {
  let Var_History = useHistory();
  const handleOnClick = (path) => { Var_History.push("/dashboard" + path) };
  const [Var_IsShowAddPostModel, setVar_IsShowAddPostModel] =
    React.useState(false);
  const [Var_IsShowSuccess, setVar_IsShowSuccess] = React.useState(false);

  function oKClose() {
    setVar_IsShowSuccess(!Var_IsShowSuccess);
  }
  function uploadBankDetailsModel() {
    setVar_IsShowAddPostModel(!Var_IsShowAddPostModel);
    setVar_IsShowSuccess(!Var_IsShowSuccess);
  }
  function addBankDetailsModel() {
    setVar_IsShowAddPostModel(!Var_IsShowAddPostModel);
  }
  return (
    <div>
      <div className="home_header">
        <Row className="home_header_row">
          <Col sm="12" md="3" lg="3" className="d-flex">
            <Button className="header_back" onClick={() => handleOnClick(" ")}>
              <i className="fa fa-angle-left header_back_button " aria-hidden="true"></i>
            </Button>
            <h5 className="header_name page_head">Wallet</h5>
          </Col>
          <Col sm="12" md="5" lg="5"></Col>
          <Col sm="12" md="2" lg="2">
            <TextField
              className="header_input"
              placeholder="Saud,Density"
              variant="outlined"
            />
          </Col>
          <Col sm="12" md="2" lg="2"></Col>
        </Row>
      </div>
      <div className="credit_point_row_two">
        <Container>
          <Row>
            <Col sm="12" md="5" lg="5">
              <Card className="credit_point_card_one">
                <img src={TOMLogo} alt="a" className="credit_point_img_logo" />
                <div className="credit_point_excel">
                  <h5 className="credit_point_name">Excel polyclinic</h5>
                  <p className="credit_point_wallet">Wallet Balance - 20</p>
                </div>
                <Button
                  className="credit_point_transfer"
                  onClick={() => addBankDetailsModel()}
                >
                  Transfer to Bank
                </Button>
              </Card>
            </Col>
            <Col sm="12" md="3" lg="3"></Col>
            <Col sm="12" md="4" lg="4">
              <Card className="credit_point_card_three">
                <img src={Prices} alt="a" className="credit_point_imgs" />
                <p className="credit_point_kwd">
                  <span>110</span> <span style={{fontSize:'10px'}}> KWD</span>
                </p>
                <p className="credit_point_earned">Earned So Far</p>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="credit_point_row_three">
        <Container>
          <Card className="credit_point_card_five">
            <Row>
              <Col sm="12" md="1" lg="1">
                <img src={TOMLogo} alt="a" className="credit_point_images" />
              </Col>
              <Col sm="12" md="6" lg="6">
                <h6 className="credit_point_card_name">
                  Congrats Polyclinic! Dina now is a TOM member
                </h6>
              </Col>
              <Col sm="12" md="2" lg="2"></Col>
              <Col sm="12" md="1" lg="1" className="credit_point_mobile">
                <Badge badgeContent={4} color="success">
                  <i className="fa fa-mobile" aria-hidden="true"></i>
                </Badge>
              </Col>
              <Col sm="12" md="1" lg="1"></Col>
              <Col sm="12" md="1" lg="1">
                <p className="credit_point_date">03-Apr-23</p>
              </Col>
            </Row>
          </Card>
        </Container>
      </div>
      <div className="credit_point_row_three">
        <Container>
          <Card className="credit_point_card_five">
            <Row>
              <Col sm="12" md="1" lg="1">
                <img src={TOMLogo} alt="a" className="credit_point_images" />
              </Col>
              <Col sm="12" md="6" lg="6">
                <h6 className="credit_point_card_name">
                  Congrats Polyclinic! Lina now is a TOM member
                </h6>
              </Col>
              <Col sm="12" md="2" lg="2"></Col>
              <Col sm="12" md="1" lg="1" className="credit_point_mobile">
                <Badge badgeContent={4} color="success">
                  <i className="fa fa-mobile" aria-hidden="true"></i>
                </Badge>
              </Col>
              <Col sm="12" md="1" lg="1"></Col>
              <Col sm="12" md="1" lg="1">
                <p className="credit_point_date">04-Apr-23</p>
              </Col>
            </Row>
          </Card>
        </Container>
      </div>
      <div className="layout_footer footer">
        <FooterMaster />
      </div>
      {/* ======================== Add Transfer Bank Details Model======================================= */}

      <CModal
        alignment="center"
        size="sm"
        width={"200px"}
        visible={Var_IsShowAddPostModel}
        onClose={setVar_IsShowAddPostModel}
        style={{ margin: "", borderRadius: "10px", minHeight: "350px" }}
      >
        <CModalHeader closeButton>
          <CModalTitle className="success_body_size">ENTER BANK DETAILS</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>
            <span className="input_span_wallet">Beneficiary Name</span>
            <TextField
              className="input_field_wallet"
              defaultValue="Abdullah"
              variant="standard"
            />
          </div>
          <div>
            <span className="input_span_wallet">Beneficiary Address</span>
            <TextField
              className="input_field_wallet"
              defaultValue="5,Jabriya, facing the 4th Ring Road,Block 3A Hawally,Jabriya 32002,Kuwait"
              variant="standard"
            />
          </div>
          <div>
            <span className="input_span_wallet">Bank Name</span>
            <TextField
              className="input_field_wallet"
              defaultValue="National Bank of Kuwait"
              variant="standard"
            />
          </div>
          <div>
            <span className="input_span_wallet">Account Number</span>
            <TextField
              className="input_field_wallet"
              defaultValue="123456789"
              variant="standard"
            />
          </div>
          <div>
            <span className="input_span_wallet">IBAN</span>
            <TextField
              className="input_field_wallet"
              defaultValue="1234ABC"
              variant="standard"
            />
          </div>
          <div>
            <span className="input_span_wallet">Beneficiary Mobile Number</span>
            <TextField
              className="input_field_wallet"
              defaultValue="12345678"
              variant="standard"
            />
          </div>
          <div className="text-center">
            <Button
              variant="contained"
              color="success"
              className="add_btn"
              onClick={() => uploadBankDetailsModel()}
            >
              Submit
            </Button>
          </div>
        </CModalBody>
      </CModal>

      {/* ======================== Add Wallet Success Model======================================= */}

      <CModal
        alignment="center"
        size="sm"
        width={"300px"}
        height={"500px"}
        visible={Var_IsShowSuccess}
        onClose={setVar_IsShowSuccess}
        style={{ margin: "13rem auto", borderRadius: "30px" }}
      >
        <CModalBody>
          <CRow>
            <CCol sm="12" style={{ textAlign: "center" }}>
              <img className="image_body_size" src={Success} />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" align="center">
              <p className="success_body_size">SUCCESS!</p>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" align="center">
              <p className="success_msg">
                Amount will be transferred to <br /> your account within 7
                business days
              </p>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" style={{ textAlign: "center", marginTop: "10px" }}>
              <CButton className="success_ok_btn" onClick={() => oKClose()}>
                Ok
              </CButton>
            </CCol>
          </CRow>
        </CModalBody>
      </CModal>
    </div>
  );
};
export default WalletMasterComponent;
