import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import React, { useState, useEffect, useRef } from "react";
import "./DashboardChart.css";
import { useHistory } from "react-router-dom";




const WeekChart = (props) => {
  const { data,type }= props
  const navigate = useHistory();
  const [tb_data, setTbData] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const root = useRef(null);

  useEffect(() => {
    if(type==="week"){
      if(data.length > 0)fetchData();
    }
    else{
      if(Object.keys(data).length!==0)fetchData();
    }
  }, [props.data]);

  const fetchData =  () => {
    debugger
    let converted_data=[];
     if(type==='week'){
      converted_data = data.map(item => ({
        labelValue: item.labelValue,
        amount: Number(item.transactionAmount),
        trx: item.transactionCount
    }));

     }else{
      converted_data = [
          {   
           labelValue: "Paid Advance",
           amount: data.onlineAmount ? data.onlineAmount :0, 
           trx:   data.onlineCount ? data.onlineCount : 0,   
           },
           {   
            labelValue: "Fast Track",
            amount:  data.fastTrackAmount ? data.fastTrackAmount : 0,
            trx:    data.fastTrackCount ? data.fastTrackCount : 0,
           },
           {   
           labelValue: "Walk - in",
           amount: data.walkInAmount ? data.walkInAmount : 0,
            trx:   data.walkInCount? data.walkInCount : 0,
          },
           {   
           labelValue: "Paid at Clinic",
           amount: data.paidClinicAmount ? data.paidClinicAmount : 0,
           trx:   data.paidClinicCount ? data.paidClinicCount : 0,
           },
           {   
            labelValue: "(Full Coverage)",
            amount: data.paidInsuranceFullAmount ? data.paidInsuranceFullAmount : 0,
            trx:   data.paidInsuranceFullCount ? data.paidInsuranceFullCount : 0,
           },
           {   
             labelValue: "(Partial Coverage)",
             amount: data.paidPartialInsuranceAmount ? data.paidPartialInsuranceAmount : 0,
             trx:   data.paidPartialInsuranceCount ? data.paidPartialInsuranceCount : 0,
            },
           {   
             labelValue: "Referral Incentive",
             amount: data.incentiveAmount ? data.incentiveAmount : 0,
             trx:   data.incentiveCount ? data.incentiveCount : 0,
            },
            // {   
            //  labelValue: "Revenue",
            //  amount: data.revenueAmount ? data.revenueAmount : 0,
            //  trx:  data.revenueCount? data.revenueCount: 0,
            // },
            // {   
            //  labelValue: "Tom Share",
            //  amount: data.tomShareAmount ? data.tomShareAmount : 0,
            //  trx:   data.tomShareCount ? data.tomShareCount : 0,
            // },
            // {   
            //  labelValue: "Insurance Deduction",
            //  amount: data.insuranceDeducAmount ? data.insuranceDeducAmount : 0,
            //  trx:   data.insuranceDeducCount ? data.insuranceDeducCount : 0,
            // },
            // {   
            //   labelValue: "Income",
            //   amount: data.doctorIncome ? data.doctorIncome : 0,
            //   trx:   data.doctorIncomeCount ? data.doctorIncomeCount : 0,
            // },
       ]
     }
    // if(converted_data.length>0){
      if(root.current) {
        root.current.dispose();
        root.current = null;
      }
    chart_am(converted_data);
    // }
  };
  

  const chart_am = (data) => {
    debugger
    console.log(data, "data");
    if (data) {
      if (!root.current) {
        root.current = am5.Root.new("1chartdiv");
        root.current?._logo?.dispose();
        
        // Create chart
        var chart = root.current.container.children.push(
          am5xy.XYChart.new(root.current, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.current.verticalLayout,
          })
        );
     


        setTbData(data);
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root.current, {}));
        cursor.lineY.set("visible", false);
        //  console.log(cursor);

        // Create X-Axis
        var xAxis = chart.xAxes.push(
          am5xy.CategoryAxis.new(root.current, {
            renderer: am5xy.AxisRendererX.new(root.current, {
              cellStartLocation: 0.2,
              cellEndLocation: 0.8,
              minGridDistance: 60,
            }),
            tooltip: am5.Tooltip.new(root.current, {
              themeTags: ["axis"],
              animationDuration: 100,
            }),
            categoryField: "labelValue",
          })
        );
        xAxis.get("renderer").labels.template.setAll({
          oversizedBehavior: "truncate",
          textAlign: "center",
          maxWidth: 90,
        });
        var xRenderer = xAxis.get("renderer");
        xRenderer.grid.template.set("visible", false);

        xAxis.data.setAll(data);

        let yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root.current, {
            extraMax:0.1,
            renderer: am5xy.AxisRendererY.new(root.current, {
              strokeOpacity: 0.1,
            }),
          })
        );
        var paretoAxisRenderer = am5xy.AxisRendererY.new(root.current, {
          opposite: true,
        });
        paretoAxisRenderer.grid.template.set("forceHidden", true);
        var yAxis1 = chart.yAxes.push(
          am5xy.ValueAxis.new(root.current, {
            renderer: paretoAxisRenderer,
          })
        );

        //   chart.set("scrollbarX", am5.Scrollbar.new(root.current, {
        //     orientation: "horizontal"
        //   }));

        var xRenderer = yAxis.get("renderer");
        xRenderer.grid.template.set("visible", false);
         
        // let start_value = Math.max(...data.map((item) => Math.max(item.amount)));
        // yAxis.set("min", 0);
        // yAxis.set("max", start_value + start_value * 0.2);

        var mycolor = [
          "rgba(81, 15, 48, 1)",
          "rgba(249, 225, 217, 1)",
          "rgba(255, 25, 119, 1)",
          "rgba(0, 164, 154, 1)",
          "rgba(244, 127, 81, 1)",
          "rgba(0, 164, 154, 1)",        
          "rgba(255, 0, 104, 1)",
          "rgba(7, 254, 231, 1)", 
          "rgba(255, 25, 119, 1)",
          "rgba(244, 127, 81, 1)",
          "rgba(249, 225, 217, 1)",
          "rgba(0, 164, 154, 1)",
          "rgba(249, 225, 217, 1)",
        ];
        var series1 = chart.series.push(
          am5xy.ColumnSeries.new(root.current, {
            name: "",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "amount",
            categoryXField: "labelValue",
          })
        );

        series1.bullets.push(function () {
          return am5.Bullet.new(root.current, {
            locationX: 0.5,
            locationY: 1,
            sprite: am5.Label.new(root.current, {
              centerY: am5.p100,
              centerX: am5.p50,
              text: "{valueY}",
              fontSize: 11,
              populateText: true,
              // oversizedBehavior: "hide",
            }),
          });
        });

        series1.data.setAll(data);

        series1.columns.template.setAll({
          tooltipText: "Transaction:{trx}",
          tooltipY: am5.percent(10),
          width: 50,
          // cornerRadiusTL: 5,
          // cornerRadiusTR: 5,
          strokeOpacity: 0,
          // fill: am5.color("#c0504e"),
        });

        series1.columns.template.adapters.add("fill", function (fill, target) {
          var columnsindex = series1.columns.indexOf(target);
          return mycolor[columnsindex];
          // return chart.get("colors").getIndex(series1.columns.indexOf(target));
        });

        series1.columns.template.events.on("click", (ev) => {
          let clicked_value =
            ev.target._dataItem.dataContext.labelValue.toLowerCase();
          // setTitle(String(props.vendor_type));
          data.map((res) => {
            console.log(
              res.labelValue.toLowerCase(),
              clicked_value.toLowerCase(),
              "week route"
            );
            // if (res.labelValue.toLowerCase() === clicked_value.toLowerCase()) {
            //   debugger
            //    if (clicked_value.toLowerCase() === "paid advance") {
            //     navigate.push(`/dashboard/revenue/paid advance/1R`);
            //   } else if (clicked_value.toLowerCase() === "fast track") {
            //     navigate.push(`/dashboard/revenue/Fast Track/1R`);
            //   } else if (clicked_value.toLowerCase() === "walk - in") {
            //     navigate.push(`/dashboard/revenue/Walk In/1R`);
            //   } else if (clicked_value.toLowerCase() === "paid at clinic") {
            //     navigate.push(`/dashboard/revenue/Paid at Clinic (No Insurance)/1R`);
            //   } else if (clicked_value.toLowerCase() === "(full coverage)") {
            //     navigate.push(`/dashboard/revenue/Paid through Insurance (full Coverage)/1R`);
            //   } else if (clicked_value.toLowerCase() === "(partail coverage)") {
            //     navigate.push(`/dashboard/revenue/Paid through Insurance (Partial Coverage)/1R`);
            //   } else if (clicked_value.toLowerCase() === "referral incentive") {
            //     navigate.push(`/dashboard/revenue/Referral Incentive/2I`);
            //   } 
            // }
          });
          // console.log(ev.target);
          // console.log(clicked_value);
        });

      } else {
        root.current.dispose();
      }
    }
  };



  
  return (
    <>
      <div className="chart_container">
        <div className="text-end">
          <p
            style={{
              fontSize: "15px",
              color: "black",
              fontWeight: "500",
              margin: "0px",
              padding: "15px",
              color:'#ff0060',
              cursor:'default'
            }}
          >
            All amount in KWD
          </p>
        </div>
        <div id="1chartdiv" style={{ width: "100%", height: "40vh",cursor:'pointer' }}></div>
      </div>
    </>
  );
};
export default WeekChart;
