import React, { useState, useEffect, useRef } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import logo from "../../assets/icon/bannerone.png";
import tomimg from "../../assets/icon/tomImagewhite.png";
import moment from "moment";
import AmiriRegular from "../../assets/font/J7aRnpd8CGxBHqUpvrIw74NL.ttf";
import RobotoRegular from "../../assets/font/ROBOTO/Roboto-Regular.ttf";
// Register the Amiri font
// Font.register({ family: 'Amiri', src:AmiriRegular });
// Font.register({ family: 'Amiri', src: 'https://fonts.gstatic.com/s/amiri/v14/J7aRnpd8CGxBHqUpvrIw74NL.ttf' });
// Register the Roboto-Regular font
//  Font.register({ family: 'Roboto-Regular', src: RobotoRegular });

// Register the Amiri font
Font.register({ family: "Amiri", src: AmiriRegular });

// Register the Roboto-Regular font
Font.register({ family: "Roboto-Regular", src: RobotoRegular });
const PdfDownload = React.forwardRef((props, ref) => {
  console.log(props);
  debugger;
  useEffect(() => {
    //
  }, [props]);

  var arraywidth = [];
  var propsHeader = props.heading;
  var widthAdjustDt = "";
  var sm_table_align = 0;
  var title_change=0;
  var subtitle_change=0
  if (window.location.href.includes("paid%20advance")) {
    arraywidth = [30, 100, 300, 100, 100, 100];
  } else if (window.location.href.includes("Fast%20Track")) {
    arraywidth = [30, 100, 300, 100, 100, 100, 100];
  } else if (window.location.href.includes("Walk%20In")) {
    arraywidth = [30, 100, 300, 100, 100, 100, 100];
  } else if (
    window.location.href.includes("Paid%20at%20Clinic%20(No%20Insurance)")
  ) {
    arraywidth = [30, 100, 300, 100,100, 100, 100, 100];
  } else if (
    window.location.href.includes(
      "Paid%20through%20Insurance%20(full%20Coverage)"
    )
  ) {
    arraywidth = [40, 100, 290,100, 100, 100, 100, 100, 100, 100, 100];
    widthAdjustDt = "A";
  } else if (
    window.location.href.includes(
      "Paid%20through%20Insurance%20(Partial%20Coverage)"
    )
  ) {
    arraywidth = [40, 100, 280, 100,100, 100, 100, 100, 100, 110, 100, 100];
    widthAdjustDt = "A";
  } else if (window.location.href.includes("Referral%20Incentive")) {
    arraywidth = [30, 100, 300, 100, 100];
    sm_table_align=60
    subtitle_change=30
  } else if (window.location.href.includes("settlement")) {
    arraywidth = [50, 200, 100, 100];
    sm_table_align = 120;
    title_change=1
    subtitle_change=150
  }

  const styles = StyleSheet.create({
    // fontFamily: {
    //   // fontFamily: 'Roboto-Regular'
    // },
    arabicFont: {
      // fontFamily: 'Amiri'
    },
    page: {
      // fontFamily: 'Amiri',
      flexDirection: "column",
      backgroundColor: "#ffffff",
    },
    title: {
      // fontFamily: 'Amiri',
      textAlign: "center",
      // fontSize: "15px",
    },
    section: {
      margin: 10,
      // padding: 10,
      flexGrow: 1,
    },
    subtitle: {
      // fontFamily: 'Amiri',
      textAlign: "right",
      fontSize: 10,
      marginRight: subtitle_change == 0 ? 0 : subtitle_change,
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderTopWidth: 0,
      marginLeft: sm_table_align == 0 ? 0 : sm_table_align,
    },
    tableRow: { display: "flex", flexDirection: "row" },
    footer: {
      position: "absolute",
      bottom: 30,
      // left: 0,
      right: 50,
      textAlign: "center",
      fontSize: 10,
      marginTop: 10,
      // borderTop: '1px solid #AAAAAA',
      paddingTop: 5,
    },
  });
  const formatMoney = (number) => {
    let num = Number(number);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };

    var formattedNumber = num.toLocaleString("en-US", options); // Change 'en-US' to 'en-IN'
    const parts = formattedNumber.split(".");
    if (formattedNumber == "NaN") {
      formattedNumber = "0";
    }
    if (parts.length === 1) {
      return `${formattedNumber}.00`;
    } else {
      const decimalPart = parts[1].padEnd(2, "0");
      // const integerPart = parts[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
      return `${parts[0]}.${decimalPart}`;
    }
  };
  const isValidEnglishLetter = (word) => {
    if (word) {
      console.log(
        /^[a-zA-Z0-9\s\/\\\-.,]+$/i.test(word),
        word,
        "checking data"
      );
      // Check if the word consists only of English letters, numbers, spaces, '/', '-', '.', and ','
      return /^[a-zA-Z0-9\s\/\\\-.,]+$/i.test(word);
    }
    return false;
  };
  return (
    <>
      {props.type === "pdf" && (
        <Document>
          {props.rowdata.map((rowData, rowIndex) => {
            const startIdx = rowIndex * 30;
            const endIdx = startIdx + 30;
            if (startIdx <= endIdx && startIdx <= props.rowdata.length - 1) {
              return (
                <Page size="A3" style={styles.page}>
                  <View style={{ margin: "50px" }}>
                    <View style={{ width: "100%" }}>
                      <Image
                        style={{
                          width: "100px",
                          height: "60px",
                          margin: "0px 0px 10px 0px",
                        }}
                        src={logo}
                      />
                    </View>
                    <View style={styles.title}>
                      <Text>{`${
                        title_change==0
                          ? props.title?.props?.children[0]
                          : props.title?.props?.children
                      }`}</Text>
                    </View>
                    <View style={styles.section}>
                      <View style={styles.subtitle}>
                        <Text>{"All values in KWD"}</Text>
                      </View>
                      <View style={styles.table}>
                        <View style={styles.tableRow}>
                          {props.heading.map((header, index) => {
                            if (header.label.includes("Tom")) {
                              const parts = header.label.split("Tom");
                              return (
                                <View
                                  style={{ width: `${arraywidth[index]}'%'` }}
                                >
                                  <View
                                    style={{
                                      display:
                                        widthAdjustDt == "A" ? "block" : "flex",
                                      flexDirection:
                                        widthAdjustDt == "A" ? "" : "row",
                                      //  width: `${(header.label.length) * (props?.widthValue ? props.widthValue : 10)}%`,
                                      backgroundColor: "#510F30",
                                      alignItems: "center",
                                      border: "1px solid #000000",
                                      borderRight: "1px solid #000000",
                                      borderLeft: "none",
                                      height:
                                        props.heading.length >= 11
                                          ? 40
                                          : props.heading.length >= 8
                                          ? 30
                                          : 20,
                                      paddingLeft:
                                        widthAdjustDt == "A" ? 0 : 30,
                                      paddingTop:
                                        props.heading.length >= 11 &&
                                        widthAdjustDt == "A"
                                          ? 10
                                          : widthAdjustDt == "A"
                                          ? 5
                                          : 0,
                                      overflow: "break-word", // Correct property name
                                      fontSize: "10px",
                                    }}
                                  >
                                    <Image
                                      src={tomimg}
                                      style={{
                                        height: 8,
                                        width: 12,
                                        objectFit: "contain",
                                      }} // Adjust width, height, and margin as needed
                                    />
                                    <Text
                                      style={{
                                        fontSize: "10px",
                                        color: "white",
                                        overflow: "break-word",
                                      }}
                                    >
                                      {parts[1]}
                                    </Text>
                                  </View>
                                </View>
                              );
                            } else if (header.label.includes("(KWD)")) {
                              const parts = header.label?.split("(");
                              return (
                                <View
                                  style={{ width: `${arraywidth[index]}'%'` }}
                                >
                                  <Text
                                    key={index}
                                    style={{
                                      // width: `${(header.label.length) * 10}'%'`,
                                      fontSize: "10px",
                                      backgroundColor: "#510F30",
                                      border: "1px solid #000000",
                                      borderRight: "1px solid #000000",
                                      borderLeft: "none",
                                      height:
                                        props.heading.length >= 11
                                          ? 40
                                          : props.heading.length >= 8
                                          ? 30
                                          : 20,
                                      color: "#ffffff",
                                      padding: 4,
                                      verticalAlign: "middle",
                                      // display: 'flex',
                                      // alignItems: 'center',
                                      // textAlign: "center",
                                      // justifyContent:'center',
                                      paddingLeft: 10,
                                      Overflow: "break-word",
                                    }}
                                  >
                                    {parts[0]}
                                    <Text
                                      style={{
                                        fontSize: "5px",
                                        backgroundColor: "#510F30",
                                        color: "#ffffff",
                                        display: "flex",
                                        alignItems: "center",
                                        textAlign: "center",
                                        Overflow: "break-word",
                                      }}
                                    >
                                      {" (" + parts[1]}
                                    </Text>
                                  </Text>
                                </View>
                              );
                            } else {
                              var parts = header.label?.split(" ");
                              return (
                                <View
                                  style={{ width: `${arraywidth[index]}'%'` }}
                                >
                                  <View
                                    key={index}
                                    style={{
                                      // width: `${(header.label.length) * (props?.widthValue? props.widthValue:10)}'%'`,
                                      display:
                                        widthAdjustDt == "A" ? "block" : "flex",
                                      flexDirection:
                                        widthAdjustDt == "A" ? "column" : "row",
                                      //  width: `${(header.label.length) * (props?.widthValue ? props.widthValue : 10)}%`,
                                      backgroundColor: "#510F30",
                                      alignItems:
                                        widthAdjustDt == "A" ? "" : "center",
                                      border: "1px solid #000000",
                                      borderRight: "1px solid #000000",
                                      borderLeft: "none",
                                      height:
                                        props.heading.length >= 11
                                          ? 40
                                          : props.heading.length >= 8
                                          ? 30
                                          : 20,
                                      //  paddingLeft:widthAdjustDt=='A'?0:30,
                                      //  paddingTop:widthAdjustDt=='A'?30:0,
                                      overflow: "break-word", // Correct property name
                                      fontSize: "10px",

                                      // paddingLeft:14
                                      // verticalAlign:'middle',
                                      // display: 'flex',
                                      // alignItems: 'center',
                                      justifyContent: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    {!header.label.includes("Name") &&
                                    widthAdjustDt == "A" &&
                                    parts.length == 2 ? (
                                      <View>
                                        <Text
                                          style={{
                                            textAlign: "center",
                                            fontSize: 10,
                                            color: "white",
                                            overflow: "break-word",
                                          }}
                                        >
                                          {parts[0]}
                                        </Text>
                                        <Text
                                          style={{
                                            textAlign: "center",
                                            fontSize: 10,
                                            color: "white",
                                            overflow: "break-word",
                                          }}
                                        >
                                          {parts[1]}
                                        </Text>
                                      </View>
                                    ) : (
                                      <Text
                                        style={{
                                          fontSize: "10px",
                                          color: "white",
                                          overflow: "break-word",
                                        }}
                                      >
                                        {header.label}
                                      </Text>
                                    )}
                                  </View>
                                </View>
                              );
                            }
                          })}
                        </View>
                        {props?.totalcal && rowIndex + 1 == 1 && (
                          <View key={rowIndex} style={styles.tableRow}>
                            {props?.totalcal.map((headerd, colIndex) => {
                              return (
                                <Text
                                  key={colIndex}
                                  style={{
                                    width: `${arraywidth[colIndex]}'%'`,
                                    fontSize:
                                      headerd.income == "Total"
                                        ? "10px"
                                        : "10px",
                                    fontWeight: "bold",
                                    borderBottom: "1px solid #000000",
                                    borderRight:
                                      headerd.income == ""
                                        ? "none"
                                        : "1px solid #000000",
                                    // borderLeft:headerd.income==''?'none':"1px solid #000000",
                                    backgroundColor:
                                      headerd.income == ""
                                        ? "white"
                                        : "#f8e0d8",
                                    color: "#510F30",
                                    padding: 4,
                                    textAlign:
                                      headerd.income == "Total"
                                        ? "center"
                                        : "right",
                                    Overflow: "break-word",
                                  }}
                                >
                                  {headerd.income == "Total" ||
                                  headerd.income == ""
                                    ? headerd.income
                                    : formatMoney(headerd.income)}
                                </Text>
                              );
                            })}
                          </View>
                        )}
                        {props.rowdata
                          .slice([startIdx], [endIdx])
                          .map((rowData, rowIndex) => (
                            <View key={rowIndex} style={styles.tableRow}>
                              {props.heading.map((header, colIndex) => {
                                return (
                                  <Text
                                    key={colIndex}
                                    style={{
                                      fontFamily: `${
                                        isValidEnglishLetter(rowData[header.id])
                                          ? ""
                                          : "Amiri"
                                      }`,

                                      width: `${arraywidth[colIndex]}'%'`,
                                      fontSize: "8px",
                                      borderBottom: "1px solid #000000",
                                      borderRight: "1px solid #000000",
                                      padding: 5,
                                      textAlign:
                                        props.heading[colIndex].typeDt === "sno"
                                          ? "center"
                                          : props.heading[colIndex].typeDt ===
                                            "str"
                                          ? "left"
                                          : props.heading[colIndex].typeDt ===
                                              "float" ||
                                            props.heading[colIndex].typeDt ===
                                              "amt"
                                          ? "right"
                                          : "center",
                                      Overflow: "break-word",
                                    }}
                                  >
                                    {props.heading[colIndex].typeDt === "sno"
                                      ? rowIndex + startIdx + 1
                                      : props.heading[colIndex].typeDt === "str"
                                      ? rowData[header.id]
                                      : props.heading[colIndex].typeDt ===
                                          "date" ||
                                        props.heading[colIndex].type === "date"
                                      ? moment(rowData[header.id]).format(
                                          "DD-MMM-YY"
                                        )
                                      : // typeof rowData[header.id] !== 'string' &&
                                      props.heading[colIndex].typeDt ===
                                          "float" ||
                                        props.heading[colIndex].typeDt === "amt"
                                      ? rowData[header.id] == null
                                        ? "-"
                                        : rowData[header.id] == "0.000"
                                        ? "-"
                                        : formatMoney(rowData[header.id])
                                      : rowData[header.id]}
                                  </Text>
                                );
                              })}
                            </View>
                          ))}
                      </View>
                    </View>
                  </View>
                  {/* Footer */}
                  <View style={styles.footer}>
                    <Text>
                      Page {rowIndex + 1} of{" "}
                      {Math.floor(props.rowdata.length / 20) == 0
                        ? 1
                        : Math.floor(props.rowdata.length / 20)}
                    </Text>
                    {/* You can add more footer content here */}
                  </View>
                </Page>
              );
            }
          })}
        </Document>
      )}
      {props.type === "print" && (
        <div
          ref={ref}
          style={{ width: "297mm", height: "180mm", margin: "0 auto" }}
        >
          {props.heading.map((rowData, rowIndex) => {
            const startIdx = rowIndex * 30;
            const endIdx = startIdx + 30;
            const totalPages = Math.ceil(props.heading.length / 30);
            const isLastPage = rowIndex === totalPages - 1;
            if (startIdx <= endIdx && startIdx < props.heading.length) {
              const pageHeight = isLastPage ? "96vh" : "97vh";
              return (
                <div style={{ margin: "50px", height: pageHeight }}>
                  <div style={{}}>
                    <img
                      src={logo}
                      className="logo"
                      style={{ width: "100px", height: "60px" }}
                    ></img>
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "22px",
                      margin: "0px auto 10px auto",
                    }}
                  >{`${props.title?.props?.children[0]}`}</p>
                  <p
                    style={{
                      textAlign: "right",
                      fontSize: "15px",
                      margin: "0 auto 0 auto",
                    }}
                  >{`All values in KWD`}</p>
                  <table
                    style={{
                      width: "100%",
                      border: "1px solid black",
                      margin: "0 auto",
                    }}
                  >
                    <thead>
                      <tr>
                        {props.heading.map((item, index) => {
                          if (item.label.includes("Tom")) {
                            const parts = item.label.split("Tom");
                            return (
                              <>
                                {widthAdjustDt == "A" ? (
                                  <th
                                    key={item.id}
                                    style={{
                                      width: `${arraywidth[index]}'%'`,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      border: "1px solid black",
                                      backgroundColor: "#510F30",
                                      color: "#ffffff",
                                      textAlign: "center",
                                    }}
                                  >
                                    <div>
                                      <img
                                        src={tomimg}
                                        style={{ height: "8px", width: "12px" }}
                                      ></img>
                                    </div>
                                    {parts[0]?.replace("Tom", "")}
                                    <span style={{ fontSize: "10px" }}>
                                      {parts.length == 2 && parts[1]}
                                    </span>
                                  </th>
                                ) : (
                                  <th
                                    key={item.id}
                                    style={{
                                      width: `${arraywidth[index]}'%'`,
                                      border: "1px solid black",
                                      backgroundColor: "#510F30",
                                      color: "#ffffff",
                                      textAlign: "center",
                                    }}
                                  >
                                    <img
                                      src={tomimg}
                                      style={{ height: "12px", width: "12px" }}
                                    ></img>
                                    {parts[0]?.replace("Tom", "")}
                                    <span style={{ fontSize: "10px" }}>
                                      {parts.length == 2 && parts[1]}
                                    </span>
                                  </th>
                                )}
                              </>
                            );
                          } else if (item.label.includes("(KWD)")) {
                            const parts = item.label.split("(");
                            return (
                              <th
                                key={item.id}
                                style={{
                                  border: "1px solid black",
                                  backgroundColor: "#510F30",
                                  color: "#ffffff",
                                  textAlign: "center",
                                }}
                              >
                                {parts[0]}
                                <span style={{ fontSize: "7px" }}>
                                  {" (" + parts[1]}
                                </span>
                              </th>
                            );
                          } else {
                            const parts = item.label.split(" ");
                            return (
                              <>
                                {!item.label.includes("Name") &&
                                widthAdjustDt == "A" ? (
                                  <th
                                    key={item.id}
                                    style={{
                                      width: `${arraywidth[index]}'%'`,
                                      border: "1px solid black",
                                      backgroundColor: "#510F30",
                                      color: "#ffffff",
                                      textAlign: "center",
                                    }}
                                  >
                                    {/* {item.label} */}
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <span style={{ fontSize: "10px" }}>
                                        {parts[0]}
                                      </span>
                                      <span style={{ fontSize: "10px" }}>
                                        {parts.length == 2 && parts[1]}
                                      </span>
                                    </div>
                                  </th>
                                ) : (
                                  <th
                                    key={item.id}
                                    style={{
                                      width: `${arraywidth[index]}'%'`,
                                      fontSize: "10px",
                                      border: "1px solid black",
                                      backgroundColor: "#510F30",
                                      color: "#ffffff",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item.label}
                                  </th>
                                )}
                              </>
                            );
                          }
                        })}
                      </tr>
                    </thead>

                    <tbody>
                      {/************** total calculateD value *****************/}
                      <tr>
                        {props?.totalcal &&
                          rowIndex + 1 == 1 &&
                          props.totalcal.map((item, ind) => {
                            return (
                              <th
                                style={{
                                  width: `${arraywidth[ind]}'%'`,
                                  backgroundColor:
                                    item.income == "" ? "" : "#f8e0d8",
                                  border: "1px solid black",
                                  padding: "2px 5px",
                                  textAlign:
                                    item.income == "Total" ? "center" : "end",
                                  color: "#510F30",
                                }}
                              >
                                {item.income == "Total" || item.income == ""
                                  ? item.income
                                  : formatMoney(item.income)}
                              </th>
                            );
                          })}
                      </tr>
                      {props.rowdata
                        .slice([startIdx], [endIdx])
                        .map((item, index) => (
                          <tr key={index}>
                            {props.heading.map((header, ind) => {
                              //
                              if (
                                props.heading[ind].typeDt === "float" ||
                                props.heading[ind].typeDt === "amt"
                              ) {
                                //                         const checkData=["Income","income","DoctorName","vendor_contact","payableAmount","tomShare","totalRevenue",
                                // "doctorRevenue","insuranceDeduction","referralIncentive","doctorIncome","book_amount","DoctorCommissionPer",
                                // "pharmacyRevenue","sumTotal","rate","amount","tomCommission","tomTotalShare"];

                                return (
                                  <td
                                    key={header.id}
                                    style={{
                                      border: "1px solid black",
                                      padding: "2px 5px",
                                      textAlign: "end",
                                    }}
                                  >
                                    {/* {ind==0?index+1 :item[header.id]} */}
                                    {item[header.id] !== undefined
                                      ? item[header.id] == null
                                        ? "-"
                                        : item[header.id] == "0.000"
                                        ? "-"
                                        : formatMoney(item[header.id])
                                      : item[header.id]}
                                  </td>
                                );
                              } else if (
                                props.heading[ind].typeDt === "date" ||
                                props.heading[ind].type === "date"
                              ) {
                                return (
                                  <td
                                    key={header.id}
                                    style={{
                                      border: "1px solid black",
                                      padding: "2px 5px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {/* {ind==0?index+1 :item[header.id]} */}
                                    {moment(item[header.id]).format(
                                      "DD-MMM-YY"
                                    )}
                                  </td>
                                );
                              } else if (props.heading[ind].typeDt === "str") {
                                return (
                                  <td
                                    key={header.id}
                                    style={{
                                      border: "1px solid black",
                                      padding: "2px 5px",
                                      textAlign: "start",
                                    }}
                                  >
                                    {ind == 0 ? index + 1 : item[header.id]}
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    key={header.id}
                                    style={{
                                      border: "1px solid black",
                                      padding: "2px 5px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {props.heading[ind].typeDt === "sno"
                                      ? index + startIdx + 1
                                      : item[header.id] == "-"
                                      ? ""
                                      : item[header.id]}
                                  </td>
                                );
                              }
                            })}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div style={{ width: "100%", marginTop: "3rem" }}>
                    <p
                      style={{
                        textAlign: "right",
                        fontSize: "15px",
                        margin: "0 auto 10px auto",
                      }}
                    >{`Page ${rowIndex + 1} of ${
                      Math.ceil(props.rowdata.length / 30) == 0
                        ? 1
                        : Math.ceil(props.rowdata.length / 30)
                    }`}</p>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      )}
    </>
  );
});
export default PdfDownload;
