// AUTHOR : Dinesh
// CR-DATE: 03-July-2023
// MOD-DATE: 05-August-2023
// DESCRIPTION: post Update work

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import useStateRef from "react-usestateref";
import dateformat from "dateformat";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";
// Mui meterial component import
import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';


import "./PostMaster.css";
import IOSSwitch from "../../helpers/Switch/Switch";
import FooterMaster from '../Dashboard/FooterMaster'


import successModelTickImg from "../../assets/Web_Doctor_Icon_new_theme/Success.svg";
import closeicon from '../../assets/Web_Doctor_Icon_new_theme/close.svg';
import alertModelTickImg from "../../assets/Web_Doctor_Icon_new_theme/alert.svg";
import uploadimage from "../../assets/icon/uploading-archive-1.svg";
import dragDropImage from "../../assets/Images/nine-dots.png";
import vector from '../../assets/Doctor_web_Icon/Vector.png'
import I from '../../assets/Web_Doctor_Icon_new_theme/ex.svg';
import faildIcon from "../../assets/Images/failedIcon.svg";



function PostComponent() {
  let Var_History = useHistory();
  var Var_Dates = new Date().toLocaleDateString('fr-CA');
  let interval;

  const [Var_ActiveChecked, setVar_ActiveChecked, Var_ActiveChecked_Ref] = useStateRef(false);
  const [Var_File, setVar_File, Var_File_Ref] = useStateRef("");
  const [Var_FileName, setVar_FileName, Var_FileName_Ref] = useStateRef("");
  const [, setVar_FileType, Var_FileType_Ref] = useStateRef("");
  const [Var_MediaTitle, setVar_MediaTitle, Var_MediaTitle_Ref] = useStateRef("");
  const [Var_FileSize, setVar_FileSize, Var_FileSize_Ref] = useStateRef("");
  const [, setVar_PostId, Var_PostId_Ref] = useStateRef("");
  const [, setVar_CreatedOn, Var_CreatedOn_Ref] = useStateRef("");
  const [, setVar_MediaSortOrder, Var_MediaSortOrder_Ref] = useStateRef("");
  const [Var_MediaTitleError, setVar_MediaTitleError,] = useStateRef(false);
  const [, setVar_PostDetailsData, Var_PostDetailsData_Ref] = useStateRef([]);
  const [Var_PostTitleValue, setVar_PostTitleValue,] = useStateRef("ADD POST");
  const [Var_PostButtonValue, setVar_PostButtonValue, setVar_PostButtonValue_Ref] = useStateRef("Upload");
  const [loading, setLoading] = useState(true);
  const [isOpenimageModel, setIsOpenimageModel] = React.useState(false);
  const [, setprivatePathUrl, privatePathUrl_Ref] = useStateRef("");
  const [Var_buttoncolor, setVar_buttoncolor] = React.useState("#510F30");
  const [Var_fileprocess, setVar_fileprocess,] = React.useState(false);
  const [Var_progress, setVar_Progress] = useState(0);
  const [isOpenUploadInstructions, setisOpenUploadInstructions] = useState(false);


  const [Var_PostSuccessfully, setVar_PostSuccessfully] = useStateRef([]);
  const [Var_IsShowAddPostModel, setVar_IsShowAddPostModel] = React.useState(false);
  const [Var_IsShowDeletePostModel, setVar_IsShowDeletePostModel] = React.useState(false);
  const [Var_IsShowSuccess, setVar_IsShowSuccess] = React.useState(false);
  const [Var_IsShowFailed, setVar_IsShowFailed] = React.useState(false);
  const [, setVarimagepopup, Varimagepopup_Ref] = useStateRef("");
  const [, setVar_ThumbnailPath, Var_ThumbnailPath_Ref] = useStateRef("");
  const [, setVar_File_Detials, Var_File_Detials_Ref] = useStateRef("");
  const [, setVar_disablebtn, Var_disablebtn_Ref] = useStateRef(false);
  const [, setVar_mediaType, Var_mediaType_Ref] = useStateRef("");
  // Check for 3 step verification
  const [verification, setVerification] = useState(Var_History.location.state ? (Var_History.location.state.verification ? Var_History.location.state.verification : false) : false);

  // initial function call start
  useEffect(() => {
    getPostDetails();
    dataClear();
    setVar_ActiveChecked(true);
    setVar_fileprocess(false);
    getFileSize();
    setVar_disablebtn(false);
    return () => {
      clearInterval(interval); // Cleanup the interval on component unmount
    };

  }, []);
  // initial function call end


  // navigation to back start
  const handleOnClick = (path) => {
    Var_History.push("/dashboard" + path)
  };
  // navigation to back end
  const isOpenimage = (value) => {
    setVar_mediaType(value.media_type);
    setVarimagepopup(value.media_filepath);
    setIsOpenimageModel(true);
  };


  const isCloseimageModel = () => {
    setIsOpenimageModel(false);
  }

  //header field doctor name start
  //header field doctor name end

  // post model controls start
  const isClosePostSuccessModel = () => {
    if (verification) {
      Var_History.push("/dashboard/post");
    }
    setVar_IsShowSuccess(!Var_IsShowSuccess);
  }
  const isClosePostfailedModel = () => {
    setVar_IsShowFailed(!Var_IsShowFailed);
  }
  const isCloseAddPostModel = () => {
    setVar_fileprocess(false)
    setVar_Progress(0)
    setVar_IsShowAddPostModel(!Var_IsShowAddPostModel);
  }
  const noDeletePostModel = (data) => {
    setVar_IsShowDeletePostModel(!Var_IsShowDeletePostModel);
    setVar_PostId(data.id);
    setVar_MediaSortOrder(data.media_sortorder);
  }
  const addPostDetails = () => {
    dataClear();
    setVar_fileprocess(false)
    setVar_Progress(0)
    setVar_IsShowAddPostModel(!Var_IsShowAddPostModel);
    setVar_PostTitleValue("ADD POST");
    setVar_PostButtonValue("Upload");
  }
  const dataClear = () => {
    setVar_MediaTitle("");
    setVar_File("");
    setVar_FileName("");
    setVar_FileSize("");
    setVar_ActiveChecked(true);
    setVar_MediaTitleError(false);
    setVar_disablebtn(false);
  }
  const uploadPostDetails = () => {

    if (setVar_PostButtonValue_Ref.current == "Upload") {
      uploadAddPostDetails();
    } else {
      updateEditPostDetails();
    }
  }
  const editPostDetails = (data) => {
    setVar_fileprocess(false);
    setVar_IsShowAddPostModel(!Var_IsShowAddPostModel);
    setVar_PostTitleValue("EDIT POST");
    setVar_PostButtonValue('Update');
    setVar_MediaTitle(data.media_title);
    setVar_PostId(data.id);
    setVar_ActiveChecked(data.is_active);
    setVar_CreatedOn(data.created_on);
    setVar_MediaSortOrder(data.media_sortorder);
    setVar_FileType(data.media_type);
    setVar_FileName(data.media_filename);
    setVar_FileSize(data.media_filesize);
    setVar_File(data.media_filepath);
    setVar_ThumbnailPath(data.media_thumbnail.split('?')[0]);
  }
  const validation = () => {
    setVar_MediaTitleError(false);
    if (Var_MediaTitle === "" && Var_File === "") {
      setVar_MediaTitleError(true);
      return false;
    }
    else if (Var_MediaTitle == "") {
      setVar_MediaTitleError(true);
      return false;
    }
    else if (Var_FileName == "") {
      setVar_MediaTitleError(true);
      return false;
    }
    return true;
  }
  // post model controls end


  //Get Post Details List start
  const getPostDetails = () => {
    setVar_PostDetailsData([]);
    setLoading(true);

    let postDetailsData = {
      doctorid: localStorage.getItem("Doctor_id"),
      limit: 1000,
      pageno: 1,
    }

    Axios({
      method: "POST",
      url: "doctor/mediauploaddetails",
      data: postDetailsData,
    })
      .then((response) => {
        setVar_PostDetailsData(response.data.data[0].details);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    clearInterval(interval);
    interval = setInterval(() => {
      getPostDetails();
    }, 120000); // 1000ms interval
  }
  //Get Post Details List end


  //Add Post Details List start
  const uploadAddPostDetails = async () => {

    const isVaild = await validation();
    if (isVaild) {
      if (Var_File_Ref.current != "") {
        setVar_IsShowAddPostModel(!Var_IsShowAddPostModel);
        let active = document.getElementById("is_active").checked;
        if (active == false) {
          setVar_ActiveChecked(0);
        } else {
          setVar_ActiveChecked(1);
        }
        setLoading(true);
        let addPostDetailsData = {
          mediatitle: Var_MediaTitle_Ref.current,
          mediatype: Var_FileType_Ref.current,
          mediasortorder: 1,
          mediadescription: "",
          mediavendorId: localStorage.getItem("Doctor_id"),
          isactive: Var_ActiveChecked_Ref.current,
          activeflag: 1,
          createdon: new Date().getFullYear() + "-" + (new Date().getUTCMonth() + 1) + "-" + new Date().getDate() + " " + new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds(),
          createdby: localStorage.getItem("Doctor_id"),
          modifiedby: "",
          modifiedon: "",
          ipaddress: "192.168.2.1",
          file_name: Var_FileName_Ref.current,
          file_size: Var_FileSize_Ref.current,
          file_path: privatePathUrl_Ref.current,
          file_thumbnail_path: Var_ThumbnailPath_Ref.current
        }
        Axios({
          method: "POST",
          url: "doctor/insertMediaUpload",
          data: addPostDetailsData,
        })
          .then((response) => {
            setLoading(false);
            setVar_IsShowSuccess(!Var_IsShowSuccess);
            setVar_PostSuccessfully(response.data.msg);
            getPostDetails();
            dataClear();
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    }
  }
  //Add Post Details List end


  //Edit Post Details List start
  const updateEditPostDetails = async () => {
    debugger;
    const isVaild = await validation();
    if (isVaild) {
      setVar_IsShowAddPostModel(!Var_IsShowAddPostModel);
      let active = document.getElementById("is_active").checked;
      if (active == false) {
        setVar_ActiveChecked(0);
      } else {
        setVar_ActiveChecked(1);
      }
      setLoading(true);
      let editPostDetailsData = {
        mediatitle: Var_MediaTitle_Ref.current,
        mediatype: Var_FileType_Ref.current,
        mediasortorder: Var_MediaSortOrder_Ref.current,
        mediadescription: "",
        mediavendorId: localStorage.getItem("Doctor_id"),
        isactive: Var_ActiveChecked_Ref.current,
        activeflag: 1,
        createdby: localStorage.getItem("Doctor_id"),
        createdon: dateformat(Var_CreatedOn_Ref.current, "yyyy-mm-dd"),
        modifiedby: localStorage.getItem("Doctor_id"),
        modifiedon: Var_Dates,
        ipaddress: "192.168.2.1",
        id: Var_PostId_Ref.current,
        file_name: Var_FileName_Ref.current,
        file_path: Var_File_Ref.current.split('?')[0],
        file_thumbnail_path: Var_ThumbnailPath_Ref.current,
        file_size: Var_FileSize_Ref.current,
      }

      Axios({
        method: "PUT",
        url: "doctor/editMediaUpload",
        data: editPostDetailsData,
      })
        .then((response) => {
          setLoading(false);
          setVar_IsShowSuccess(!Var_IsShowSuccess);
          setVar_PostSuccessfully(response.data.msg);
          getPostDetails();
          dataClear();
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }
  //Edit Post Details List end


  //Delete Post Details List start
  const yesDeletePostModel = () => {
    setVar_IsShowDeletePostModel(!Var_IsShowDeletePostModel);
    setLoading(true);
    debugger;
    let deletePostDetailsData = {
      id: Var_PostId_Ref.current,
      doctorId: localStorage.getItem("Doctor_id"),
      index: Var_MediaSortOrder_Ref.current,
    }
    Axios({
      method: "DELETE",
      url: "doctor/deleteMediaUpload",
      data: deletePostDetailsData,
    })
      .then((response) => {
        setLoading(false);
        setVar_IsShowSuccess(!Var_IsShowSuccess);
        setVar_PostSuccessfully(response.data.msg);
        getPostDetails();
        dataClear();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }
  //Delete Post Details List end

  //Delete Post No Details List start
  const NoDeletePostModelDetail = () => {
    setVar_IsShowDeletePostModel(!Var_IsShowDeletePostModel);
  }
  //Delete Post No Details List end


  //File upload methods start
  const uploadDocument = async (e) => {
    var fileSize = e.target.files[0].size;
    if (fileSize >= Number(Var_File_Detials_Ref.current.size_in_byts)) {
      setVar_IsShowFailed(!Var_IsShowFailed);
    }
    else {
      setVar_disablebtn(true);
      setVar_MediaTitleError(false);
      setVar_fileprocess(true)
      setVar_Progress(0)
      setVar_buttoncolor("#D4C3CC")
      setVar_FileName(e.target.files[0].name);
      setVar_FileSize((e.target.files[0].size / (1024 * 1024)).toFixed(2));
      setVar_FileType((e.target.files[0].type).slice(0, 5));

      const formData = new FormData();
      formData.append('module_id', "1");
      formData.append('file', e.target.files[0]);
      formData.append('pathLocation', "POST/");
      Axios({
        method: "POST",
        url: "admin/awsS3FileUpload",
        data: formData,
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setVar_Progress(percentage);
        },
      })
        .then(async (response) => {
          setVar_disablebtn(false);
          setVar_File(response.data.filepath.Location);
          setprivatePathUrl(response.data.filepath.privatePathUrl);
          setVar_buttoncolor("#510F30");
          const file = e.target.files[0];
          if (file.type == 'video/mp4') {
            setVar_disablebtn(true);
            const Thumbnail = await generateThumbnail(file);
            const formData = new FormData();
            formData.append('module_id', "1");
            formData.append('file', Thumbnail.file);
            formData.append('pathLocation', "POST/");
            Axios({
              method: "POST",
              url: "admin/awsS3FileUpload",
              data: formData,
            })
              .then((response) => {
                setVar_disablebtn(false);
                setVar_ThumbnailPath(response.data.filepath.privatePathUrl);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          setVar_fileprocess(false);
          setVar_buttoncolor("#510F30")


        });
    }
  };
  //File upload methods end
  const generateThumbnail = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = URL.createObjectURL(file);
      video.load();
      video.muted = true;

      video.onloadeddata = () => {
        video.currentTime = 0.1; // Seek to a time to ensure the frame is loaded
      };

      video.onseeked = () => {
        const obj = captureFrame(video);
        resolve(obj);
      };

      video.onerror = (error) => {
        console.error('Error loading video', error);
        reject(error);
      };
    });
  };

  const captureFrame = (video) => {
    let canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    let dataURI = canvas.toDataURL('image/jpeg');
    let obj = {
      canvas: canvas,
      file: dataURLtoFile(dataURI, `${+new Date()}.jpg`),
      base64: dataURI
    };
    console.log("// Output object to console", obj); // Output object to console
    return obj;
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  //Dynamic file size changes
  const getFileSize = () => {
    const payloaddata = {
      "contenttype": "post",
      "module_id": 1
    }

    Axios({
      method: "POST",
      url: "admin/getFileSizeByModule",
      data: payloaddata
    }).then((response) => {
      debugger
      setVar_File_Detials(response.data.data[0]);
    })
      .catch((error) => {
        console.log(error);
      })
  }

  const openInstruction = () => {
    debugger
    setisOpenUploadInstructions(true);
  }
  const closeInstructionModel = () => {
    setisOpenUploadInstructions(false);
  }
  //Drag Drop Methods start
  const handleDragDrop = (result) => {
    debugger

    if (!result.destination) return;
    const items = Array.from(Var_PostDetailsData_Ref.current);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setVar_PostDetailsData(items);
    setLoading(true);
    let updatePostDetailsData = {
      id: result.draggableId,
      current_index: result.destination.index + 1,
      previous_index: result.source.index + 1,
      doctorId: localStorage.getItem("Doctor_id"),
    }
    debugger
    Axios({
      method: "POST",
      url: "doctor/updateMediaOrder",
      data: updatePostDetailsData,
    })
      .then((response) => {
        setLoading(false);

      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }


  const getListStyle = (isDraggingOver) => ({
    padding: 2,
  });
  //Drag Drop Methods end

  return (
    <div>

      {/* ================= Post sub header start ================= */}
      {loading &&
        <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
      }
      <div className="sub_header">
        <div className="sub_header_body">
          <div className="back_navigation" onClick={() => handleOnClick("/")}>
            <i className="fa fa-angle-left" aria-hidden="true"></i>
            <span>POST</span>
          </div>
          <div className="flex_grow"></div>
          <div className="header_right_content">
            <div>
              <button className="add_btn_post" onClick={() => addPostDetails()}>
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
                <span>Add Post</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ================= Post sub header end ================= */}

      {/* ================= Post list View  start ================= */}
      <div className="content_view post-content-view">
          <div className="post_title_header">
            <Grid container>
              <Grid item xs={1} md={1}><label>Order</label></Grid>
              <Grid item xs={2} md={2} className="text-center"><label>Media Title</label></Grid>
              <Grid item xs={2} md={2} className="text-center"><label>Uploaded Date</label></Grid>
              <Grid item xs={2} md={2} className="text-center"><label>Uploaded Time</label></Grid>
              <Grid item xs={1} md={1} className="text-center"><label>Thumbnail</label></Grid>
              <Grid item xs={1} md={1} className="text-center"><label>File Size</label></Grid>
              <Grid item xs={2} md={2} className="text-center"><label>Action</label></Grid>
              <Grid item xs={1} md={1} className="status_title"><label>Status</label></Grid>
            </Grid>
          </div>
          <DragDropContext onDragEnd={handleDragDrop}>
            <Droppable droppableId="Var_PostDetailsData_Ref.current">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {Var_PostDetailsData_Ref.current?.map((responseData, index) => (
                    <Draggable draggableId={responseData.id.toString()} key={responseData.id} index={index}>
                      {(provided) => (
                        <div {...provided.draggableProps} ref={provided.innerRef}>
                          <Card className="card_content_one">
                            <Grid container className="content_row_post">
                              <Grid item xs={1} md={1}>
                                <img src={dragDropImage} className="drag_drop_img" {...provided.dragHandleProps} alt="" />
                              </Grid>
                              <Grid item xs={2} md={2} className="text-center media_title">
                                <h6 className="post_content">{responseData.media_title}</h6>
                              </Grid>
                              <Grid item xs={2} md={2} className="text-center create_date">
                                <h6 className="post_content">{moment(responseData.created_on).format("DD-MMM-YY")}</h6>
                              </Grid>
                              <Grid item xs={2} md={2} className="text-center create_time">
                                <h6 className="post_content" style={{ textTransform: 'lowercase' }}>
                                  {responseData.createdTime ? responseData.createdTime.slice(0, 5) : '-'}
                                  {responseData.createdTime ? ` ${responseData.createdTime.slice(8, 11)}` : ''}
                                </h6>
                              </Grid>
                              <Grid item xs={1} md={1} className="text-end thumbnil">
                                <img
                                  src={responseData.media_type?.toLowerCase() === "video" ?
                                    (responseData.media_filepath ? responseData.media_thumbnail : vector) :
                                    (responseData.media_filepath || vector)}
                                  className="card_row_img_post"
                                  onClick={() => isOpenimage(responseData)}
                                  alt=""
                                />
                              </Grid>
                              <Grid item xs={1} md={1} className="text-center media_file_size">
                                <h6 className="post_content">{responseData.media_filesize ? `${responseData.media_filesize} MB` : '-'}</h6>
                              </Grid>
                              <Grid item xs={2} md={2} className="post_edit_del_btn action_btn">
                                <Button className="edit_icon_post" onClick={() => editPostDetails(responseData)}>Edit</Button>
                                <Button className="delete_icon_post" onClick={() => noDeletePostModel(responseData)}>Delete</Button>
                              </Grid>
                              <Grid item xs={1} md={1} className="post_active_status">
                                <div className="post_active">Active</div>
                                <div style={{ paddingLeft: '0px' }}><Checkbox checked={responseData.is_active === 1} className="post_active_btn" /></div>
                              </Grid>
                            </Grid>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        {/* ======================== Post Drag Drop end=========================== */}

        {/* ======================== Add Post Model start=========================== */}
        <Dialog open={Var_IsShowAddPostModel} onClose={setVar_IsShowAddPostModel} sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%", maxWidth: "300px", borderRadius: "20px"
            },
          },
        }}>
          <div className="dialog_header">
            <div className="dialog_title">{Var_PostTitleValue}</div>
            <div className="flex_grow"></div>
            <div onClick={() => isCloseAddPostModel()}>
              <img src={closeicon} alt="" />
            </div>
          </div>
          <DialogContent>
            <div>
              <Grid container>
                <Grid item xs={12}>
                  <div className="input_file">
                    <p className="input_span">
                      <span>File<img onClick={() => openInstruction()} src={I} className="file_alert_icon" aria-hidden="true" alt="" /></span></p>
                    <div className="fileUpload">
                      <input type="file" className="upload" onChange={(e) => uploadDocument(e)}
                        sx={{ minWidth: '-webkit-fill-available' }} />
                      <span><img src={uploadimage} alt="Upload Icon" /></span>
                      <p className="fileName">{Var_FileName}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="error">
                    {Var_MediaTitleError && Var_File == "" && "Field Required"}
                  </div>
                </Grid>
                <Grid item xs={12} className="fileshow">
                  {Var_fileprocess &&
                    <div>
                      <div className="upload-container" style={{ paddingTop: '15px' }}>
                        {/* Your other UI elements */}
                        <LinearProgress
                          variant="determinate"
                          value={Var_progress}
                          color="secondary"
                          className="progressBar"
                          sx={{ zIndex: '1', width: '100%', margin: '0px 3px' }}
                        />
                        {/* Display progress percentage */}
                        <div className="progress-text">{`${Var_progress}%`}</div>
                      </div>
                    </div>
                  }
                </Grid>
                <Grid item xs={12}>
                  <div style={{ paddingTop: '20px' }}>
                    <span className="input_span">Media Title</span>
                    <TextField
                      className="input_field_services"
                      value={Var_MediaTitle}
                      onChange={(e) => { setVar_MediaTitle(e.target.value); setVar_MediaTitleError(false) }}
                      variant="outlined"
                      placeholder="Type Media Title"
                      sx={{ minWidth: '-webkit-fill-available' }}
                    />
                  </div>
                  <div className="error" style={{ marginTop: "-6px" }}>
                    {Var_MediaTitleError && Var_MediaTitle == "" && "Field Required"}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="post_toggle">
                    <p className="pt-2 m-0">Active</p>
                    <FormControlLabel className='every_day' control={
                      <IOSSwitch sx={{ m: 1 }} checked={Var_ActiveChecked} id="is_active" onChange={() => setVar_ActiveChecked(!Var_ActiveChecked)} />
                    } />
                  </div>
                </Grid>
                <Grid item xs={12} className="upload_add_btn">
                  <div>
                    <Button
                      variant="contained"
                      className="success_post_add_btn"
                      onClick={() => uploadPostDetails()}
                      disabled={Var_disablebtn_Ref.current}>
                      {Var_PostButtonValue}
                    </Button>

                  </div>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
        {/* ======================== Add Post Model end=========================== */}

        {/* ==========================================Upload Instructions start======================= */}
        <Dialog
          open={isOpenUploadInstructions}
          onClose={closeInstructionModel}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "400px",
                borderRadius: "20px"
              },
            },
          }}
        >
          <DialogTitle sx={{ padding: '0px !important' }}>
            <div className="upload_instruction_full">
              <h4 className="uploadins_head">UPLOAD INSTRUCTIONS</h4>
              <img className="upload_ins_close" onClick={() => closeInstructionModel()} src={closeicon} alt=""></img>
            </div>
          </DialogTitle>
          <DialogContent>
            <div style={{ paddingTop: '20px' }}>
              <div className="uploaddIns_div">
                <label className="upldinsicon"></label><label className="ins_content">Please upload image in JPG or PNG format.</label>
              </div>
              <div className="uploaddIns_div">
                <label className="upldinsicon"></label><label className="ins_content">Image size should be 1080 px X 566 px. </label>
              </div>
              <div className="uploaddIns_div">
                <label className="upldinsicon"></label><label className="ins_content">Image size should be less than {Var_File_Detials_Ref.current.size_in_mb} MB.</label>
              </div>
              <div className="uploaddIns_div">
                <label className="upldinsicon"></label><label className="ins_content">Video size should be less than {Var_File_Detials_Ref.current.size_in_mb} MB.</label>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/* ==========================================Upload Instructions end======================= */}


        {/* ========================  Post Alert Model start======================================= */}
        <Dialog open={Var_IsShowDeletePostModel} onClose={setVar_IsShowDeletePostModel}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%", maxWidth: "300px", borderRadius: "20px"
              },
            },
          }}>
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={alertModelTickImg} alt="" />
              </div>
              <div className="success_body_size_alert">alert!</div>
              <div className="content">Do you want to delete this post?</div>
              <div className="alert_btn_center">
                <button className="alert_no_btn" onClick={() => NoDeletePostModelDetail()}>No</button>
                <button className="alert_yes_btn" onClick={() => yesDeletePostModel()}>Yes</button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/* ========================  Post Alert Model  end======================================= */}

        {/* ======================== Post Success Model start======================================= */}
        <Dialog fullWidth={true} open={Var_IsShowSuccess} onClose={setVar_IsShowSuccess}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={successModelTickImg} alt="" />
              </div>
              <div className="title">success!</div>
              <div className="content">{Var_PostSuccessfully}</div>
              <div className="segment_center">
                <button className="botton_Cart_Create" onClick={() => isClosePostSuccessModel()}>OK</button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/* ======================== Post Success Model end===================================== */}
        <Dialog fullWidth={true} open={Var_IsShowFailed} onClose={setVar_IsShowFailed}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={faildIcon} alt="" />
              </div>
              <div className="failed">alert!</div>
              <div className="content">File size should be less than {Var_File_Detials_Ref.current.size_in_mb} MB</div>
              <div className="segment_center">
                <button className="botton_Cart_Create" onClick={() => isClosePostfailedModel()}>OK</button>
              </div>
            </div>
          </DialogContent>
        </Dialog>


        <Dialog
          fullWidth={true}
          open={isOpenimageModel}
          onClose={isCloseimageModel}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "340px",
                borderRadius: "20px",
                height: "51%"
              },
            },
          }}
        >

          <div className="dialog_header">
            <div className="dialog_title">VIEW POST</div>
            <div className="flex_grow"></div>
            <div onClick={() => isCloseimageModel()}>
              <img src={closeicon} alt="" />
            </div>
          </div>
          <DialogContent className="view_post_cls" >
            {Var_mediaType_Ref.current?.toLowerCase() == "video" ?
              (<video src={Varimagepopup_Ref.current !== "" ? Varimagepopup_Ref.current : vector} autoPlay muted loop controls className="image_content_manage_servive" />)
              : (<img src={Varimagepopup_Ref.current !== "" ? Varimagepopup_Ref.current : vector} className="image_content_manage_servive" alt="" />)
            }
          </DialogContent>
        </Dialog>



        {/* ==========================================jhgfds============================= */}


      </div>
      {/* ================= Post list View  end ================= */}

      {/* ================= Post footer start ================= */}
      {localStorage.getItem("FooterStatus") == 'A' && <div className="footer">
        <FooterMaster />
      </div>}
      {/* ================= Post footer end ================= */}
    </div>
  );
};
export default PostComponent;
