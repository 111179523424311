import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { notification, Spin } from "antd";
import axios from "axios";
import useStateRef from "react-usestateref";
import moment from "moment";
import LinearProgress from '@mui/material/LinearProgress';


// Mui meterial component import
import { InputLabel, FormControlLabel, Select, MenuItem, Container, Card, Grid, TextField, Button, FormControl, DialogContent, Dialog, Radio } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import Success from '../../assets/Doctor_web_Icon/success.png'
import './Prescription.css'
import Report from '../../assets/icon/Group 19092.svg'
import profile from '../../assets/Images/TOMLogo.jpg'
import FooterMaster from '../Dashboard/FooterMaster'
import Member from '../../assets/Web_Doctor_Icon_new_theme/Member.svg'
import Fasttrack from '../../assets/Web_Doctor_Icon_new_theme/fast_track.svg'
import Online from '../../assets/Web_Doctor_Icon_new_theme/Online.svg'
import Walkin from '../../assets/Web_Doctor_Icon_new_theme/walkin.svg'
import closeicon from '../../assets/Web_Doctor_Icon_new_theme/close.svg';
import successModelTickImg from '../../assets/Web_Doctor_Icon_new_theme/Success.svg'
import Reportimage from '../../assets/Doctor_web_Icon/IMG_20200803_125505 (1).png';
import Icon from "../../assets/Web_Doctor_Icon_new_theme/perscription_upload.svg"
import vector from '../../assets/Doctor_web_Icon/Vector.png'
import faildIcon from "../../assets/Images/failedIcon.svg";

const PrescriptionComponent = () => {

    let Var_History = useHistory();
    let { patientId } = useParams();
    let { bookingId } = useParams();
    let { date } = useParams();
    let { is_send_to_pharam } = useParams();


    const [fullWidth, setFullWidth] = React.useState(true);
    var [Var_DateApi, setVar_DateApi, dateRefapi] = useStateRef("")
    var [Var_AppointmentDetail, setVar_AppointmentDetail] = useState([]);
    var [Var_prescriptionDetails, setVar_prescriptionDetails, Var_prescriptionDetails_Ref] = useStateRef([]);
    var [Var_TotalCuont, setVar_TotalCuont] = useState("");
    var [Var_NoData, setVar_NoData] = useStateRef("none")
    var [Var_HaveData, setVar_HaveData] = useStateRef("block")
    var [Var_FileName, setVar_FileName, Var_FileName_Ref] = useStateRef("")
    var [Var_FilePath, setVar_FilePath, Var_FilePath_Ref] = useStateRef("")
    var [Var_FileDate, setVar_FileDate] = useStateRef("")
    var [Var_Id, SetVar_Id, Var_Id_Ref] = useStateRef("")
    var [Var_Pharamacy_Id, SetVar_Pharamacy_Id, Var_Pharamacy_Id_Ref] = useStateRef("")
    const [Var_AdvertisementMsg, setVar_AdvertisementMsg, Var_AdvertisementMsg_Ref] = useStateRef("");
    const [Var_AdvertisementMsgHead, setVar_AdvertisementMsgHead, Var_AdvertisementMsgHead_Ref] = useStateRef("");
    const [Var_PopupModelIcon, setVar_PopupModelIcon, Var_PopupModelIcon_Ref] = useStateRef("");



    const [isOpenSuccessModel, setisOpenSuccessModel] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isDisable, setisisDisable] = useState(false);

    // initial function call start
    useEffect(() => {
        setVar_DateApi(new Date().toLocaleDateString('fr-CA'));
        getAllAppointment()
        prescriptionImage();
        setisisDisable(false);
    }, []);
    // initial function call End

    // navigattion to back start
    const navigateTo = (path) => {
        Var_History.push("/dashboard" + path)
    };
    // navigattion to back end

    // model controls start

    // =============== Get All Appointment Start ==========================================
    const getAllAppointment = () => {
        // var date = dateRefapi.current

        setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("Clinic_id"),
            doctor_id: localStorage.getItem("Doctor_id"),
            patient_type: "",
            period: "day",
            search_date: date, //"2023-08-14",
            search_date_to: date //"2023-08-14",
        }
        axios
            .post("doctor/getAppointmentList", data)
            .then((response) => {
                console.log("Appoinment", response.data.data[0].result)
                var filtereddata = response.data.data[0].result.filter(x => x.patient_id == patientId && x.booking_id == bookingId);
                setVar_AppointmentDetail(filtereddata)
                setVar_TotalCuont(response.data.data[0].result.length)
                console.log("appointment Result", filtereddata)
                setLoading(false);

            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            })

    }
    // =============== Get All Appointment End ==========================================

    // ====================get prescription details start==============
    const prescriptionImage = () => {

        setLoading(true);
        var data = {
            doctorId: localStorage.getItem("Doctor_id"),
            patientId: patientId
        }
        axios
            .post("doctor/getPrescriptionImage", data)
            .then((response) => {

                if (response.data.data == 0) {
                    console.log("data", response.data.data);
                    setVar_NoData("block")
                    setVar_HaveData("none")
                    setVar_FileName(response.data.data.prescript_file_name)
                    setVar_FilePath(response.data.data.prescript_file_path)
                    setVar_FileDate(response.data.data.prescript_date)
                    setLoading(false);
                }
                else {
                    setVar_NoData("none")
                    setVar_HaveData("block")
                    setVar_prescriptionDetails(response.data.data)
                    SetVar_Id(response.data.data[0].id)
                    SetVar_Pharamacy_Id(response.data.data[0].pharamacy_id)
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }

    // ====================get prescription details end==============





    const sendRoport = () => {
        setisisDisable(true);
        setLoading(true);
        var data = {
            is_send_to_pharam: 1,
            is_prescrp_sts: "P",
            pharm_id: Var_Pharamacy_Id_Ref.current,
            id: Var_Id_Ref.current,
        }
        axios
            .post("doctor/updatePrescription", data)
            .then((response) => {
                setisisDisable(false);
                setLoading(false);
                if (response.data.status == 1) {
                    setVar_PopupModelIcon(successModelTickImg);
                    setVar_AdvertisementMsgHead("success!");
                    setVar_AdvertisementMsg("Prescription sent to pharmacy!");
                    setisOpenSuccessModel(!isOpenSuccessModel);
                }
                else {
                    setVar_PopupModelIcon(faildIcon);
                    setVar_AdvertisementMsgHead("failed!");
                    setVar_AdvertisementMsg(response.data.msg + "!");
                    setisOpenSuccessModel(!isOpenSuccessModel);
                }
            })
            .catch((error) => {
                setisisDisable(false);
                setLoading(false);
                console.log(error);
            });
    }


    const okClose = () => {
        setisOpenSuccessModel(!isOpenSuccessModel)
        navigateTo("/appointments");
    }

    // model controls end

    // Time conversion start

    const convertTo12HrFormat = (time24) => {
        var timeArr = time24.split(':');
        var hours = parseInt(timeArr[0]);
        var minutes = parseInt(timeArr[1]);
        var suffix = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12 || 12;
        var time12 = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + suffix;

        return time12;
    }
    const timeFormat = (time) => {
        let datetime = new Date('1970-01-01T' + time + 'Z');
        return datetime.toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' });
    }

    // Time conversion End
    return (

        <div>
            {/* {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            } */}
            {loading &&
                <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
            }

            <div>
                <div>
                    <div className="sub_header">
                        <div className="sub_header_body">
                            <div className="back_navigation" onClick={() => navigateTo("/appointments")}>
                                <i className="fa fa-angle-left" aria-hidden="true"></i>
                                <span>PRESCRIPTION</span>
                            </div>
                            <div className="flex_grow"></div>
                        </div>
                    </div>
                    <div className='content_view'>
                        <div>
                            <div>
                                {Var_AppointmentDetail.map((item) => (

                                    <div className='profile_details_prescription'>
                                        <div className='profile_detail_container'>
                                            <img src={item.file_name == "" ? vector : item.file_name == " " ? vector : item.file_name == null ? vector : item.file_name} className='profile_Details' style={{ border: '1px solid var(--app-theme)' }} />
                                            <div className='detailsprofile'>
                                                <p className='profile_name'>{item.name}</p>
                                                {/* <p className='profile_number'>{item.mobile_no}</p> */}
                                                <p className='profile_age'>{item.age > 0 ? item.age + " " + "yrs," : ""} <span> {item.gender}</span></p>
                                            </div>
                                        </div>
                                        <div className="flex_grow"></div>
                                        <div className='profile_detail_type'>
                                            <img src={item.appointment_type_id == 1
                                                ? Member
                                                : item.appointment_type_id == 3
                                                    ? Fasttrack
                                                    : item.appointment_type_id == 2
                                                        ? Online
                                                        : item.appointment_type_id == 0
                                                            ? Walkin
                                                            : vector} alt='pro' className='Profoile_Type' />
                                            <p className='profile_time'>{item.appointment_time == null ? 0 : convertTo12HrFormat(item.appointment_time)}</p>
                                            <p className='report_date'>{moment(item.appointment_date).format("DD-MMM-YY")}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className='prescription_contnt' style={{ display: Var_NoData }}>
                                <div className='segment_center'>
                                    <div className='alert_image_container'>
                                        {/* <img src={Icon} className='alert_image' /> */}
                                    </div>
                                </div>
                                <div className='segment_center'>
                                    <p className='note'>NOTE!</p>
                                </div>
                                <div className='segment_center'>
                                    {is_send_to_pharam == "1" ?
                                        <p className='alert_text'>Prescription already sent</p>
                                        : <p className='alert_text'>Please upload prescription from doctor app</p>
                                    }
                                </div>
                            </div>

                            <div className='prescription_contnt' style={{ display: Var_HaveData }}>

                                <div className="prescription_image_align">
                                    {Var_prescriptionDetails.map((value) => (
                                        <div>
                                            <div className='segment_center'>
                                                <Grid container className='report_image'>
                                                    <Grid item xs={3} align="center">
                                                        <img className="presription_image" src={value.prescript_file_path} alt="" />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className='segment_center'>
                                                <div className='button_container'>
                                                    <Grid container className='cancel_button'>
                                                        <Grid item xs={3} align="center">
                                                            <div className='report_cancel_button'>
                                                                <div className='button_text'><p>{value.prescript_file_name} <span>X</span></p></div>
                                                                {/* Prescription_0012 */}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='segment_center mt-4'>
                                    <div className="segment_center">
                                        <button className="botton_Cart_pharm" disabled={isDisable} onClick={() => sendRoport()}>Send to Pharmacy</button>
                                    </div>
                                    {/* <Grid container className='send_button'>
                                        <Grid item align='center'>
                                            <Button
                                                className={"botton_Cart_Create success_button"}
                                                onClick={() => {
                                                    sendRoport();
                                                }}
                                            >
                                                Send to Pharmacy
                                            </Button>
                                        </Grid>
                                    </Grid> */}
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="layout_footer footer">
                        <FooterMaster />
                    </div>

                    {/* ================= Prescription Success Model Start ================= */}
                    <Dialog
                        fullWidth={fullWidth}
                        open={isOpenSuccessModel}
                        onClose={setisOpenSuccessModel}
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "300px",
                                    borderRadius: "20px",
                                },
                            },
                        }}
                    >
                        <DialogContent>
                            <div className="success_model_body">
                                <div className="icon">
                                    <img src={Var_PopupModelIcon_Ref.current} />
                                </div>
                                <div className="title">{Var_AdvertisementMsgHead_Ref.current}</div>
                                <div className="content">{Var_AdvertisementMsg_Ref.current}</div>
                                <div className="segment_center">
                                    <button className="botton_Cart_Create" onClick={() => okClose()}>OK</button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                    {/* ================= Prescription Success Model End ================= */}
                </div>
            </div>

        </div>
    )
}
export default PrescriptionComponent;