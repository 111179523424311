import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import React, { useEffect, useState } from "react";
import Axios from "axios";
import dateformat from "dateformat";
import { notification} from "antd";
import LinearProgress from "@mui/material/LinearProgress";

// mui material imports
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FooterMaster from "../Dashboard/FooterMaster";
import "./AdvertisementBookingMaster.css";
import Calendar from "./Calendar";
import DialogTitle from '@mui/material/DialogTitle';

// import onNext from './Calendar'

import "bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";
import Datedata from '../Revenue_settle/mothpicker/Datedata';


import full from "../../../src/images/Full.png";
import half from "../../../src/images/Half.png";
import uploadalert from "../../assets/Web_Doctor_Icon_new_theme/info.svg";
import uploadIcon1 from "../../assets/Web_Doctor_Icon_new_theme/uploading-archive-1.svg";
import successModelTickImg from "../../assets/Web_Doctor_Icon_new_theme/Success.svg";
import faildIcon from "../../assets/Images/failedIcon.svg";
import closeicon from "../../assets/Web_Doctor_Icon_new_theme/close.svg";

import { paymentsuccessurl, kneturl } from '../../App'
import mastercard from "../../../src/images/masterCardImage.png";
import knetImage from "../../../src/images/knetImage.png";

function AdvertisementBookingComponent() {
  let Var_History = useHistory();

  const [
    Var_PlacementLocationId,
    setVar_PlacementLocationId,
    Var_PlacementLocationId_Ref,
  ] = useStateRef("");
  const [, setVar_StartDate, Var_StartDate_Ref] =
    useStateRef("");
  const [, setVar_EndDate, Var_EndDate_Ref] = useStateRef("");
  const [Var_ExcludedDays, setVar_ExcludedDays, Var_ExcludedDays_Ref] =
    useStateRef([]);
  const [, setVar_ADImgUpload, Var_ADImgUpload_Ref] =
    useStateRef(null);
  const [Var_ADImgName, setVar_ADImgName, ] =
    useStateRef(null);
  const [Var_ImageSize, setVar_ImageSize, Var_ImageSize_Ref] = useStateRef("");
  const [,
    setVar_AdvertisementSize,
    Var_AdvertisementSize_Ref,
  ] = useStateRef(null);
  const [, setVar_FeeKWD, Var_FeeKWD_Ref] = useStateRef(0);
  const [, setVar_ToTalCost, Var_ToTalCost_Ref] = useStateRef(0);
  const [, setVar_Advetisedays, Var_AdvetisedaysRef] = useStateRef(0);
  const [Var_Pre_Month_ExcludedDays, setVar_Pre_Month_ExcludedDays] =
    useStateRef([]);
  const [
    isOpenAddedAdvertisementSuccessModel,
    setisOpenAddedAdvertisementSuccessModel,
  ] = useState(false);
  const [loading, setLoading] = useState(true);
  const [Var_fileprocess, setVar_fileprocess, ] =
    React.useState(false);
  const [Var_progress, setVar_Progress] = useState(0);
  const [, setVar_AdvertisementMsg, Var_AdvertisementMsg_Ref] = useStateRef("");
  const [, setVar_AdvertisementMsgHead, Var_AdvertisementMsgHead_Ref] = useStateRef("");
  const [, setVar_PopupModelIcon, Var_PopupModelIcon_Ref] = useStateRef("");
  const [isOpenUploadInstructions, setisOpenUploadInstructions] = useState(false);
  const [, setVar_ClassName, Var_ClassName_Ref] = useStateRef("");
  const [isOpenViewMore, setisOpenViewMore] = useState(false);
  const [, setVar_ExcludedDaysPayload, Var_ExcludedDaysPayload_Ref] = useStateRef([]);
  var [showEnterValue, setshowEnterValue] = useState(false);
  const [isOpenPayment, setisOpenPayment] = useState(false);

  const [paymentDataObj, setPaymentDataObj] = useState(null);
  const [isShowPaymentModel, setIsShowPaymentModel] = useState(false);
  const [, setVar_File_Detials, Var_File_Detials_Ref] = useStateRef("");
  const [Var_IsShowFailed, setVar_IsShowFailed] = React.useState(false);
  const [isDisable, setisisDisable] = useState(false);
  // initial function call start
  useEffect(() => {
    setisisDisable(false);
    getPlaceMentLocation();
    getAdvertisementSize();
    getFileSize();
    if (localStorage.getItem("paymentstatus") == "cancel") {
      setVar_ClassName("failedtxt");
      setVar_PopupModelIcon(faildIcon);
      setVar_AdvertisementMsgHead("failed!");
      setVar_AdvertisementMsg("Payment failed!");
      setisOpenAddedAdvertisementSuccessModel(true);
    } else if (localStorage.getItem("paymentstatus") == "completed") {
      let payloaddata = JSON.parse(localStorage.getItem('payloaddata'));
      payloaddata['paymentId'] = localStorage.getItem('resultindicator');
      payloaddata['sessionid'] = localStorage.getItem('session');
      payloaddata["referrence"] = localStorage.getItem("referenceid");
      payloaddata["orderId"] = localStorage.getItem("order_on");
      payloaddata["paymentGatewayName"] = localStorage.getItem("paymentGatewayName");
      payloaddata["module_name"] = "Doctor";
      debugger;
      var data = JSON.parse(localStorage.getItem("paymentObject"));
      setPaymentDataObj(data);
      if (data != null && localStorage.getItem("paymentGatewayName") == "KNET") {
        setIsShowPaymentModel(true);
      }
      else {
        setIsShowPaymentModel(false);
      }

      insertAddvertisement(payloaddata);
    } else if (localStorage.getItem("paymentstatus") == "failed") {
      setVar_ClassName("failedtxt");
      setVar_PopupModelIcon(faildIcon);
      setVar_AdvertisementMsgHead("failed!");
      setVar_AdvertisementMsg("Payment failed!");
      setisOpenAddedAdvertisementSuccessModel(true);
    }

    localStorage.setItem("paymentstatus", "");
    localStorage.setItem("resultindicator", "");
    localStorage.setItem("session", "");
    localStorage.setItem("order_on", "");
    localStorage.setItem("paymentGatewayName", "")
  }, []);
  // initial function call end
  // naviagte back start
  const navigateToBack = (path) => {
    Var_History.push("/dashboard" + path);
  };
  // naviagte back end

  const openInstruction = () => {
    setisOpenUploadInstructions(true);
  }
  const closeInstructionModel = () => {
    setisOpenUploadInstructions(false);
  }
  const closePaymentModel = () => {
    setisOpenPayment(false);
  }
  const isClosePostfailedModel = () => {
    setVar_IsShowFailed(!Var_IsShowFailed);
  }
  // ======================== Get Date From Calender =======================
  const getRangeDate = (data) => {
    if (data.enddate === null) {
      let pre_month_excludedDays = [];
      let TotalslotsAvailable = data.TotalslotsAvailable.filter((object) => {
        return object.availableSlots == 0 || object.availableSlots <= 0;
      });
      var excludedDays = [];
      TotalslotsAvailable.map((obj) => {
        var date = moment(obj["selected_date"], "YYYY-MM-DD");
        if (date.diff(moment(data.startdate), "days") > 0) {
          pre_month_excludedDays.push({
            display_date: moment(obj["selected_date"], "YYYY-MM-DD").format(
              "DD-MMM-YY"
            ),
            date: obj["selected_date"],
          });
        }
      });
      setVar_StartDate(moment(data.startdate).format("DD-MMM-YY"));
      setVar_Pre_Month_ExcludedDays(pre_month_excludedDays);
      compareDate();
    } else {
      {
        var TotalslotsAvailable = data.TotalslotsAvailable.filter((object) => {
          return object.availableSlots == 0 || object.availableSlots <= 0;
        });
        var excludedDays = [];
        if (
          moment(data.startdate).format("MM") !=
          moment(data.enddate).format("MM")
        ) {
          Var_Pre_Month_ExcludedDays.map((obj) => {
            excludedDays.push(obj);
            setVar_ExcludedDays(excludedDays);
          });
        }
        TotalslotsAvailable.map((obj) => {
          if (data.startdate > data.enddate) {
            if (moment(obj["selected_date"]).isBetween(moment(data.enddate), moment(data.startdate))) {
                excludedDays.push({ "display_date": moment(obj["selected_date"], "YYYY-MM-DD").format("DD-MMM-YY"), "date": obj["selected_date"] })
                setVar_ExcludedDays(excludedDays);
            }
        }
        else {
        if (moment(obj["selected_date"]).isBetween(moment(data.startdate), moment(data.enddate))) {
            excludedDays.push({ "display_date": moment(obj["selected_date"], "YYYY-MM-DD").format("DD-MMM-YY"), "date": obj["selected_date"] })
            setVar_ExcludedDays(excludedDays);
        }
    }
        });
        if (data.startdate < data.enddate) {
          setVar_StartDate(moment(data.startdate).format("DD-MMM-YY"));
          setVar_EndDate(moment(data.enddate).format("DD-MMM-YY"));
          setVar_ExcludedDays(excludedDays);
          compareDate();
        } else if (data.startdate > data.enddate) {
          setVar_StartDate(moment(data.enddate).format("DD-MMM-YY"));
          setVar_EndDate(moment(data.startdate).format("DD-MMM-YY"));
          setVar_ExcludedDays(excludedDays);
          compareDate();
        } else {
          setVar_StartDate(moment(data.startdate).format("DD-MMM-YY"));
          setVar_EndDate(moment(data.enddate).format("DD-MMM-YY"));
          setVar_ExcludedDays(excludedDays);
          compareDate();
        }
      }
      var payloadExcluededDate = [];
      Var_ExcludedDays_Ref.current.forEach(item => {
        payloadExcluededDate.push(moment(item.date).format("YYYY-MM-DD"));
      });
      setVar_ExcludedDaysPayload(payloadExcluededDate);
    }
  };
  const checkHours = () => {
    let startDate = moment(Var_EndDate_Ref.current).format("DD");
    let endDate = moment(Var_StartDate_Ref.current).format("DD");
    let fromMonth = moment(Var_StartDate_Ref.current).format("MM");
    let toMonth = moment(Var_EndDate_Ref.current).format("MM");
    let current_year = moment().year();
    let to_year = moment(Var_EndDate_Ref.current).year();

    if (parseInt(fromMonth) < parseInt(toMonth)) {
      var monthDiff =
        moment(Var_EndDate_Ref.current).format("MM") -
        moment(Var_StartDate_Ref.current).format("MM");
    } else if (parseInt(toMonth) < parseInt(fromMonth)) {
      var monthabs =
        moment(Var_StartDate_Ref.current).format("MM") -
        moment(Var_EndDate_Ref.current).format("MM") -
        12 * (to_year - current_year);

      var monthDiff = Math.abs(monthabs);
    } else if (parseInt(fromMonth) == parseInt(toMonth)) {
      var monthDiff = 0;
    }

    var daysInMonth = 0;
    for (let i = 0; i < monthDiff; i++) {
      let filteredMonth = parseInt(fromMonth) + parseInt(i);
      daysInMonth += new Date(current_year, filteredMonth, 0).getDate();
    }

    if (parseInt(endDate) < parseInt(startDate)) {
      let totalDays =
        moment(Var_EndDate_Ref.current).format("DD") -
        moment(Var_StartDate_Ref.current).format("DD") +
        daysInMonth +
        1;
      setVar_Advetisedays(totalDays);
    } else {
      let totalDays =
        daysInMonth +
        1 -
        (moment(Var_StartDate_Ref.current).format("DD") -
          moment(Var_EndDate_Ref.current).format("DD"));
      setVar_Advetisedays(totalDays);
    }
    var totalcost =
      (Var_AdvetisedaysRef.current - Var_ExcludedDays_Ref.current.length) *
      Var_FeeKWD_Ref.current;
    setVar_ToTalCost(totalcost);
  };
  const compareDate = () => {
    if (
      dateformat(Var_StartDate_Ref.current, "mm-dd-yyyy") <=
      dateformat(Var_EndDate_Ref.current, "mm-dd-yyyy")
    ) {
      checkHours();
    } else {
    }
  };


  const getImgeSizeValue = (event, action) => {
    setVar_ImageSize(action);
    getAdvertisementSizeCost();
  };

  const isCloseAdvertisementSuccessModel = () => {
    setisOpenAddedAdvertisementSuccessModel(false);
    navigateToBack("/advertisementbooking/advertisementbookinglist");
  };
  // ======================== Advertisement s3 file Upload start ========================

  const addvertisementFileUpload = (event) => {
    if (Var_StartDate_Ref.current != "" && Var_EndDate_Ref.current != "") {
      let fileSize = event.target.files[0].size;
      if (fileSize >= Number(Var_File_Detials_Ref.current.size_in_byts)) {
        setVar_IsShowFailed(!Var_IsShowFailed);
      }
      else {
        setisisDisable(true);
        setVar_fileprocess(true);
        setVar_Progress(0);
        setVar_ADImgUpload("");
        setVar_ADImgName(event.target.files[0].name);
        const formData = new FormData();
        formData.append('module_id', "1");
        formData.append("file", event.target.files[0]);
        formData.append('pathLocation', "ADV/");
        Axios({
          method: "POST",
          url: "admin/awsS3FileUpload",
          data: formData,
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setVar_Progress(percentage);
          },
        })
          .then((response) => {
            setVar_ADImgUpload(response.data.filepath.privatePathUrl);
            setisisDisable(false);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            setVar_fileprocess(false);
            setisisDisable(false);
          });
      }
    } else {
      if (Var_StartDate_Ref.current == "" && Var_EndDate_Ref.current != "") {
        notification.error({ message: " Please choose start date first" });
      }
      else if (Var_StartDate_Ref.current != "" && Var_EndDate_Ref.current == "") {
        notification.error({ message: " Please choose end date" });
      }
      else {
        notification.error({ message: " Please choose start date and end date first" });
      }
    }
  };
  // ======================== Advertisement s3 file Upload end ========================

  // ======================== Get PlaceMent Location from Api  ========================
  const getPlaceMentLocation = () => {
    setLoading(true);
    Axios({
      method: "GET",
      url: "doctor/get_mas_placement_location",
    })
      .then((response) => {
        setVar_PlacementLocationId(response.data.data[0].id);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  // ========================Get Advertisement Size ========================
  const getAdvertisementSize = () => {
    setLoading(true);
    Axios({
      method: "GET",
      url: "clinic/get_mas_size_master",
    })
      .then((response) => {
        console.log("adverisementSize", response.data.data[0].id);
        setVar_AdvertisementSize(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  // ========================Get Advertisement Size End ========================
  // ======================================Get Advertisement Size Cost=============================================
  const getAdvertisementSizeCost = () => {
    setLoading(true);
    const Var_RequestData = {
      vendor_type_id: localStorage.getItem("Doctor_vendor_type_id"),
      placement_location_id: Var_PlacementLocationId_Ref.current,
      size_id: Var_ImageSize_Ref.current == "Half" ? 1 : 2,
    };
    Axios({
      method: "POST",
      url: "clinic/get_ad_rate_vendor",
      data: Var_RequestData,
    })
      .then((response) => {
        setVar_FeeKWD(response.data.data[0].rate);
        checkHours();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  //=====================================paymentgateway================================
  const generatePaymentOrderNo = () => {
    let first_segment = "tom";
    let second_segment = (Math.random() + 1).toString(36).substring(2);
    return first_segment + second_segment;
  }


  const selectPayment = () => {
    if (moment(Var_StartDate_Ref.current).format("YYYY-MM-DD") >= moment().add(2, 'days').format("YYYY-MM-DD")
      && Var_AdvetisedaysRef.current != 0 && Var_FeeKWD_Ref.current != 0 && Var_ToTalCost_Ref.current != 0
      && Var_StartDate_Ref.current != "" && Var_EndDate_Ref.current != "" && Var_ADImgUpload_Ref.current != null && Var_ImageSize_Ref.current != "") {
      setisOpenPayment(true);
    }
    else {
      if (Var_StartDate_Ref.current == "" ||Var_EndDate_Ref.current == ""||Var_ADImgUpload_Ref.current == null 
        ||Var_ImageSize_Ref.current == "" ||Var_FeeKWD_Ref.current == null) {
        setshowEnterValue(true);
      }
    }
  }

  const advertisementSave = (type) => {
    let order_on = generatePaymentOrderNo();

    const payload = {
      adtitle: "DoctorWeb",
      startdate: moment(Var_StartDate_Ref.current).format("YYYY-MM-DD"),
      enddate: moment(Var_EndDate_Ref.current).format("YYYY-MM-DD"),
      adtotaldays: Var_ExcludedDays_Ref.current != "" ? Number(Var_AdvetisedaysRef.current) - Number(Var_ExcludedDays_Ref.current.length) : Var_AdvetisedaysRef.current,
      adsize: Var_ImageSize == "Half" ? 1 : 2,
      adlocationId: Var_PlacementLocationId,
      adfeeperday: Var_FeeKWD_Ref.current,
      adtotalcost: Var_ToTalCost_Ref.current,
      advendorId: localStorage.getItem("Doctor_id"),
      activeflag: 1,
      createdby: localStorage.getItem("Doctor_id"),
      modifiedby: localStorage.getItem("Doctor_id"),
      ipaddress: " 126.183.0.1",
      filename: "",
      filepath: Var_ADImgUpload_Ref.current,
      advertisement_name: " ",
      excludedDays: Var_ExcludedDaysPayload_Ref.current == "" ? "" : Var_ExcludedDaysPayload_Ref.current.join(","),
      paymentStatus: 1,
      paymentId: localStorage.getItem("resultindicator"),
      referrence: "",
      OrderId: order_on,
      created_on: new Date().getFullYear() + "-" + (new Date().getUTCMonth() + 1) + "-" + new Date().getDate() + " " + new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds(),
    };

    let payloadstr = JSON.stringify(payload);
    localStorage.setItem('payloaddata', payloadstr);
    localStorage.setItem('order_on', order_on)
    if (type == "mastercard") {
      getInitiatePayment()
    }
    else if (type == "knet") {
      let url = kneturl +
        "price=" +
        Var_ToTalCost_Ref.current +
        "&name=" +
        JSON.parse(localStorage.getItem("LoginData")).user_name +
        "&email=" +
        JSON.parse(localStorage.getItem("LoginData")).email +
        "&phone=" +
        JSON.parse(localStorage.getItem("LoginData")).phone +
        "&return_url=" +
        paymentsuccessurl;
      window.open(url, '_self');
    }
  }

  const getInitiatePayment = () => {

    let obj = {
      vendor_name: JSON.parse(localStorage.getItem("LoginData")).user_name,
      phone: JSON.parse(localStorage.getItem("LoginData")).phone,
      vendor_email: JSON.parse(localStorage.getItem("LoginData")).email,
      amount: Var_ToTalCost_Ref.current,
      OrderId: localStorage.getItem("order_on"),
    };

    Axios({
      method: "POST",
      url: "admin/createSessionId",
      data: obj,
    }).then((res) => {
      console.log(res.data.data.session.id);
      window.Checkout.configure({
        session: {
          id: res.data.data.session.id,
        },
      });
      window.Checkout.showPaymentPage();
    });
  };

  // ======================================Get Advertisement Size Cost End =============================================
  const insertAddvertisement = (data) => {
    setLoading(true);
    console.log(data);
    Axios({ method: "POST", url: "diet/insertAdBooking", data: data })
      .then((response) => {
        if (response.data.status == 1) {
          setVar_ClassName("title");
          setVar_PopupModelIcon(successModelTickImg);
          setVar_AdvertisementMsgHead("success!");
          setVar_AdvertisementMsg("Advertisement added successfully!");
          setisOpenAddedAdvertisementSuccessModel(true);
          setLoading(false);
        } else {
          setVar_ClassName("failedtxt");
          setVar_PopupModelIcon(faildIcon);
          setVar_AdvertisementMsgHead("failed!");
          setVar_AdvertisementMsg(response.data.data[0].p_return_msg + "!");
          setisOpenAddedAdvertisementSuccessModel(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const openViewMore = () => {
    setisOpenViewMore(!isOpenViewMore);
  }

  const componentWillUnmount = () => {
    // Knet payment Data null
    setPaymentDataObj(null);
    setIsShowPaymentModel(false);
    localStorage.setItem("paymentObject", null);
  };

  const handleClosePaymentFailedTypeModel = () => {
    debugger
    // Knet payment Data null
    setPaymentDataObj(null);
    setIsShowPaymentModel(false);
    localStorage.setItem("paymentObject", null);
  };

  const handleClosePaymentTypeModel = () => {
    // Knet payment success mail 
    var logindata = JSON.parse(localStorage.getItem("LoginData"));
    var Object = {
      email: logindata.email,
      username: logindata.user_name,
      amount: paymentDataObj.amt,
      paymentid: paymentDataObj.paymentid,
      transactionid: paymentDataObj.tranid,
      dateTime: moment(new Date()).format('DD-MMM-YY, hh:mm a'),
      title: "Knet Payment Success Details"
    }
    Axios({ method: "post", url: "admin/knetPaymentSuccessDetailsMail", data: Object }).then((response) => {
      console.log(response);
      // Knet payment Data null
      setPaymentDataObj(null);
      setIsShowPaymentModel(false);
      localStorage.setItem("paymentObject", null);
    })
      .catch((error) => {
        console.log(JSON.stringify(error));
        // Knet payment Data null
        setPaymentDataObj(null);
        setIsShowPaymentModel(false);
        localStorage.setItem("paymentObject", null);
      });
  };
//Dynamic file size changes
const getFileSize = () =>{
  debugger
  const payloaddata = {
      "contenttype": "Advertisement",
      "module_id": 1
  }

  Axios({
    method:"POST",
    url : "admin/getFileSizeByModule",
    data : payloaddata
  }).then((response)=>{
setVar_File_Detials(response.data.data[0]);
})
  .catch((error)=>{
    console.log(error);
  })
}
  return (
    <div>
      {loading && (
        <LinearProgress
          color="secondary"
          className="progressBar"
          sx={{ zIndex: "9999" }}
        />
      )}
      <div className="sub_header">
        <div className="sub_header_body">
          <div className="back_navigation" onClick={() => navigateToBack("/")}>
            <i className="fa fa-angle-left" aria-hidden="true"></i>
            <span>ADVERTISEMENT</span>
          </div>
          <div className="flex_grow"></div>
          <div className="header_right_content">
            <div>
              <button className="add_btn_post" onClick={() => navigateToBack("/advertisementbooking/advertisementbookinglist")}>
                <span>View AD</span>
              </button>
            </div>

          </div>
        </div>
      </div>

      <div className="content_view">
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="calendar_full">
                <Calendar
                  useRef={"calendar"}
                  getDate={(data) => getRangeDate(data)}
                  aftertwodays={true}
                  showSlots={true}
                  clinicId={null}
                ></Calendar>
                <div>
                  <div className="textCalLen12">
                    Note : Booking can be done 2 days from the current date only
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={6}>
                  <InputLabel>
                    Start Date
                  </InputLabel>
                  <TextField
                    type="text"
                    size="small"
                    value={Var_StartDate_Ref.current}
                    sx={{ minWidth: "-webkit-fill-available" }}
                  />
                  <label style={{ display: (showEnterValue == true && Var_StartDate_Ref.current == "") ? 'block' : 'none' }} className='value_lbl'>Field Required</label>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <InputLabel >End Date</InputLabel>
                  <TextField
                    type="text"
                    size="small"
                    value={Var_EndDate_Ref.current}
                    sx={{ minWidth: "-webkit-fill-available" }}
                  />
                  <label style={{ display: (showEnterValue == true && Var_EndDate_Ref.current == "") ? 'block' : 'none' }} className='value_lbl'>Field Required</label>
                </Grid>
                <Grid item xs={12} md={12} lg={12} style={{ display: "flex" }}>
                  <div id="demo-select-small-label" className="exclude_days">Excluded Dates :&nbsp;
                    {Var_ExcludedDays.map((data, i, array) => (
                      <label> {dateformat(data.date, "dd-mmm-yy")}{i < array.length - 1 ? "," : ""}&nbsp;</label>
                    ))}
                  </div>{Var_ExcludedDays.length > 3 && <label className="view_more" onClick={() => openViewMore()} >view more</label>}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="upload_title">
                    <span>Upload Advertisement</span>
                    <img onClick={() => openInstruction()} src={uploadalert} alt=""></img>
                  </div>
                  <div class="fileUpload">
                    <input
                      type="file"
                      class="upload"
                      onChange={(e) => addvertisementFileUpload(e)}
                      onClick={(e) => (e.target.value = null)}
                    />
                    <span>
                      <img src={uploadIcon1} alt="Upload Icon" />
                    </span>
                    <p>{Var_ADImgName}</p>
                  </div>
                  <label style={{ display: (showEnterValue == true && Var_ADImgUpload_Ref.current == null) ? 'block' : 'none' }} className='value_lbl'>Field Required</label>
                  {Var_fileprocess && (
                    <div>
                      <div>
                        <div className="upload-container" style={{ paddingTop: '15px' }}>
                          {/* Your other UI elements */}

                          <LinearProgress
                            variant="determinate"
                            value={Var_progress}
                            color="secondary"
                            className="progressBar"
                            sx={{ zIndex: "1", width: '100%', margin: '0px 3px' }}
                          />
                          <div className="progress-text">
                            {`${Var_progress}%`}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Grid container spacing={2}>
                    <Grid item sm={3} className="checkbox_full">
                      <Checkbox
                        className="post_active_btn_ad"
                        checked={Var_ImageSize == "Half" ? true : false}
                        onChange={(e) => getImgeSizeValue(e, "Half")}
                      />
                      <label className="fess_cost">Half</label>
                      <img src={half} className="select_Icon" alt=""></img>
                      <label style={{ display: (showEnterValue == true && Var_ImageSize_Ref.current == "") ? 'block' : 'none' }} className='value_lbl'>Field Required</label>
                    </Grid>
                    <Grid item sm={3} className="checkbox_full">
                      <Checkbox
                        className="post_active_btn_ad"
                        checked={Var_ImageSize == "Full" ? true : false}
                        onChange={(e) => getImgeSizeValue(e, "Full")}
                      />
                      <label className="fess_cost">Full</label>
                      <img src={full} className="select_Icon" alt=""></img>
                      <label style={{ display: (showEnterValue == true && Var_ImageSize_Ref.current == "") ? 'block' : 'none' }} className='value_lbl'>Field Required</label>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                  <p className="content_SubHeadings">
                    Fee / Day <span style={{ fontSize: "10px" }}> (KWD)</span>
                  </p>
                  {Var_FeeKWD_Ref.current != 0 &&
                    <p style={{ color: '#aaaaaa' }}>{Datedata.formatMoney(Var_FeeKWD_Ref.current)}</p>}
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                  <p className="content_SubHeadings">
                    Total Cost <span style={{ fontSize: "10px" }}> (KWD)</span>
                  </p>
                  {Var_ToTalCost_Ref.current != 0 &&
                    <p style={{ color: '#aaaaaa' }}>{Datedata.formatMoney(Var_ToTalCost_Ref.current)}</p>} 
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="segment_center">
                    <button
                      className="submit_Button"
                      disabled={isDisable}
                      onClick={() => selectPayment()}
                    >
                      Book
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div >
      <div className="layout_footer footer">
        <FooterMaster />
      </div>
      {/* ================= Add Advertisement  Success Model Start ================= */}
      <Dialog
        open={isOpenAddedAdvertisementSuccessModel}
        onClose={isCloseAdvertisementSuccessModel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "300px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogContent>
          <div className="success_model_body">
            <div className="icon">
              <img className="image_body_sizes" src={Var_PopupModelIcon_Ref.current} alt=""/>
            </div>
            <div className={Var_ClassName_Ref.current}>{Var_AdvertisementMsgHead_Ref.current}</div>
            <div className="content">{Var_AdvertisementMsg_Ref.current}</div>
            <div className="segment_center">
              <button
                className="botton_Cart_Create"
                onClick={() => isCloseAdvertisementSuccessModel()}
              >
                OK
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* ==========================================Upload Instructions ======================= */}
      <Dialog
        open={isOpenUploadInstructions}
        onClose={closeInstructionModel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogTitle sx={{ padding: '0px !important' }}>
          <div className="upload_instruction_full">
            <h4 className="uploadins_head">UPLOAD INSTRUCTIONS</h4>
            <img className="upload_ins_close" onClick={() => closeInstructionModel()} src={closeicon} alt=""></img>
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{ paddingTop: '15px' }}>
            <div className="uploaddIns_div">
              <label className="upldinsicon"></label><label className="ins_content">Please upload image in JPG or PNG format.</label>
            </div>
            <div className="uploaddIns_div">
              <label className="upldinsicon"></label><label className="ins_content">Image size should be 1080 px X 566 px. </label>
            </div>
            <div className="uploaddIns_div">
              <label className="upldinsicon"></label><label className="ins_content">Image size should be less than {Var_File_Detials_Ref.current.size_in_mb} MB.</label>
            </div>
            {/* <div className="uploaddIns_div">
            <label className="upldinsicon"></label><label className="ins_content">Video size should be less than {Var_File_Detials_Ref.current.size_in_mb} MB.</label>
            </div> */}
          </div>
        </DialogContent>
      </Dialog>
      {/* ==========================================date view more ======================= */}
      <Dialog
        open={isOpenViewMore}
        onClose={openViewMore}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogTitle className="excluded-date">
          <div className="upload_instruction_full">
            <h4 className="uploadins_head">Excluded Dates</h4>
            <img className="upload_ins_close" onClick={() => openViewMore()} src={closeicon} alt=""></img>
          </div>
        </DialogTitle>
        <DialogContent className="excluded-padding">
          <div>
            {Var_ExcludedDays.map((data, i, array) => (
              <label className="view_more_label"> {dateformat(data.date, "dd-mmm-yy")}&nbsp;</label>
            ))}
          </div>
        </DialogContent>
      </Dialog>

      {/* ==========================================Payment Popup ======================= */}
      <Dialog
        open={isOpenPayment}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogTitle>
          <div className="upload_instruction_full">
            <h4 className="uploadins_head">Select a payment type</h4>
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="payment_type_body">
              <img
                onClick={(e) => advertisementSave("mastercard")}
                className="payment_img_mastercard"
                src={mastercard}
                alt="mastercard"
              />
              <img
                onClick={(e) => advertisementSave("knet")}
                className="payment_img_knet"
                src={knetImage}
                alt="knetImage"
              />
            </div>
            <div className="segment_center">
              <button className="botton_Cart_Create" onClick={() => closePaymentModel()}>Cancel</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>


      <Dialog
        className="Dialogmodal ticket_table_success"
        open={isShowPaymentModel}
        // aria-labelledby="customized-dialog-title"
        // maxWidth={"sm"}
        // fullWidth={true}
        disableBackdropClick={true}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <div>
          <div className="payment_type_header">Payment {paymentDataObj?.result == "CAPTURED" ? "Success" : "Failed"} Details</div>
          <div className="popup_content">
            <ul style={{marginBottom:"0px"}}>
              <li><b>Payment Amount : </b> KWD  {paymentDataObj?.amt}</li>
              <li><b>Payment Id: </b> {paymentDataObj?.paymentid}</li>
              <li><b>Transaction Id: </b> {paymentDataObj?.tranid}</li>
              <li><b>Date & Time: </b> {moment(new Date()).format('DD-MMM-YY, hh:mm a')}</li>
              <li><b>Payment Status : </b><span className="payment_status"> {paymentDataObj?.result == "CAPTURED" ? "Success" : "Failed"}</span></li>
            </ul>
          </div>

          <div
            className="contract_approve_btn_div"
            style={{paddingBottom:"1rem"}}
          >
            <Button
              className="ticketSubmit"
              onClick={() => {
                paymentDataObj?.result == "CAPTURED" ? handleClosePaymentTypeModel() : handleClosePaymentFailedTypeModel();
              }}
            >
              {" "}
              Ok{" "}
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog fullWidth= {true} open={Var_IsShowFailed} onClose={setVar_IsShowFailed}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={faildIcon} alt="" />
              </div>
              <div className="failed">alert!</div>
              <div className="content">File size should be less than {Var_File_Detials_Ref.current.size_in_mb} MB</div>
              <div className="segment_center">
                <button className="botton_Cart_Create" onClick={() => isClosePostfailedModel()}>OK</button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
    </div >
  );
}
export default AdvertisementBookingComponent;
