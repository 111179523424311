import Appointment from "../component/Appointments/AppointmentsMaster"

var service = {
    check: "testing data",
    start_date: "",
    end_date: "",
    conert_12Hto_24HTime: (timeStr) => {
        try {
            const [time, modifier] = timeStr.split(' ');
            let [hours, minutes] = time.split(':');
            if (hours === '12') {
                hours = '00';
            }
            if (modifier === 'PM') {
                hours = parseInt(hours, 10) + 12;
            }
            return `${hours}:${minutes}`;
        } catch (e) {
            return "-1";
        }
    },
    conert_24Hto_12HTime: (timeStr) => {
        try {
            var hoursfrom = parseInt(timeStr.split(":")[0], 10);
            var minutesfrom = parseInt(timeStr.split(":")[1], 10);
            // Check if it's AM or PM
            var periodfrom = hoursfrom >= 12 ? "PM" : "AM";
            // Convert to 12-hour format
            var hourIn12HrFormatfrom = hoursfrom % 12 || 12;
            // Format the time with "am" or "pm" indicator
            return hourIn12HrFormatfrom + ":" + minutesfrom.toString().padStart(2, '0') + " " + periodfrom;
        } catch (e) {
            return "-1";
        }
    }
}

export default service;