// AUTHOR : Dinesh
// CR-DATE: 17-July-2023
// MOD-DATE: 01-August-2023
// DESCRIPTION: Notification Update work

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import useStateRef from "react-usestateref";
import axios from "axios";
import moment from "moment";


// Mui meterial component import
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import LinearProgress from '@mui/material/LinearProgress';
import NotFound from "../../assets/Web_Doctor_Icon_new_theme/NotFound.svg"


import './Notification.css'
import FooterMaster from '../Dashboard/FooterMaster'


function NotificationComponent() {
    let Var_History = useHistory();
    const [Var_NotificationData, setVar_NotificationData] = React.useState([]);
    const [Var_NoData, setVar_NoData, Var_NoData_Ref] = useStateRef([]);
    const [loading, setLoading] = useState(true);
    const [Var_DoctorDetailShow, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] = useStateRef(null);

    // var Var_Dates = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
    var Var_Dates = new Date().toLocaleDateString('fr-CA');
    const [Var_getClinicWiseDoctor, setVar_getClinicWiseDoctor] = React.useState([]);
    var [Var_doctorSpecialitySelect, setVar_doctorSpecialitySelect] = useState("");
    const [Var_specialityid, setVar_specialityid, Var_specialityid_Ref] = useStateRef(null);


    // initial function call start
    useEffect(() => {
        // getClinicWiseDoctor();
        // getNotifications();
        newNotification();
    }, []);
    // initial function call end


    // navigation to back start
    const handleOnClick = (path) => {
        Var_History.push("/dashboard" + path)
    };
    // navigation to back end


    //Get Notifications Details List start
    // const getNotifications = () => {
    //     setLoading(true);
    //     var notificationGetData = {
    //         // vendor_type_id: localStorage.getItem("doctor_id"),
    //         doctor_id: localStorage.getItem("doctor_id")
    //         // date_value: Var_Dates,
    //     };
    //     Axios({
    //         method: "get",
    //         url: "doctor/doctorwebnotification",
    //         data: notificationGetData,
    //     })
    //         .then((response) => {

    //             setVar_NotificationData([]);
    //             setVar_NoData(response.data.msg);
    //             setLoading(false);
    //             if (response.data.data.length > 0) {
    //                 setVar_NotificationData(response.data.data);
    //                 setLoading(false);
    //             }
    //         })
    //         .catch((error) => {
    //             setLoading(false);
    //             console.log(error);
    //         });
    // }


    const convertTo12HrFormat = (time24) => {
        var timeArr = time24.split(':');
        var hours = parseInt(timeArr[0]);
        var minutes = parseInt(timeArr[1]);
        var suffix = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12 || 12;
        var time12 = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + suffix;

        return time12;
    }




    //Get Notifications Details List end


    // const getClinicWiseDoctor = () => {
    //     setLoading(true);
    //     debugger
    //     var data = {
    //         clinic_id: localStorage.getItem("clinic_id")
    //     };
    //     axios
    //         .post("clinic/getClinicWiseDoctor", data)
    //         .then((response) => {
    //             console.log('test', response.data.data)
    //             setVar_getClinicWiseDoctor(response.data.data);
    //             setVar_doctorSpecialitySelect(response.data.data[0].specialityid);
    //             var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id"));
    //             console.log(filteredData);
    //             setVar_DoctorDetailShow(filteredData[0].doctorName + ' ' + filteredData[0].specialityname);
    //             setVar_specialityid(filteredData[0].specialityid);
    //             console.log("praveen123456789",Var_specialityid)
    //             localStorage.setItem("")
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }



    // const getClinicWiseDoctor = () => {
    //     setLoading(true);
    //     debugger
    //     var data = {
    //       clinic_id: localStorage.getItem("clinic_id")
    //     };
    //     axios
    //       .post("clinic/getClinicWiseDoctor", data)
    //       .then((response) => {
    //         console.log('test', response.data.data)
    //         setVar_getClinicWiseDoctor(response.data.data);
    //         setVar_doctorSpecialitySelect(response.data.data[0].specialityid);
    //         var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id"));
    //         console.log(filteredData);
    //         setVar_DoctorDetailShow(filteredData[0].doctorName + ' ' + filteredData[0].specialityname);

    //         console.log("poiuytrdcvhgfcvygfvc",filteredData[0].specialityid)
    //         localStorage.setItem("")
    //         setLoading(false);
    //         setVar_specialityid(filteredData[0].specialityid);

    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }

    const getClinicWiseDoctor = () => {
        debugger;
        setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("Clinic_id")
        };
        axios
            .post("clinic/getClinicWiseDoctor", data)
            .then((response) => {
                debugger;
                var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("Doctor_id"));
                setVar_DoctorDetailShow(filteredData[0].doctorName + ' ' + filteredData[0].specialityname);
                setVar_specialityid(filteredData[0].specialityid);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }



    const getNotifications = () => {
        setLoading(true);
        debugger
        var data = {
            // doctor_id: localStorage.getItem("doctor_id"),
            // clinic_id: localStorage.getItem("clinic_id"),
            // speciality_id: localStorage.getItem("speciality_Id")
            clinic_id: 0,
            doctor_id: localStorage.getItem("Doctor_id"),
            patient_type: "",
            period: "day",
            search_date: (moment(new Date().toLocaleDateString('fr-CA')).format("YYYY-MM-DD")),
            search_date_to: (moment(new Date().toLocaleDateString('fr-CA')).format("YYYY-MM-DD"))
        };

        {

        }
        axios
            .post("doctor/getAppointmentList", data)
            .then((response) => {
                debugger;
                setVar_NotificationData(response.data.data[0].result);
                console.log("lenth", response.data.data[0].result.length);
                console.log("data", response.data.data[0].result);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const newNotification = () => {
        setLoading(true);
        var NotificationData = {
            "vendor_id": localStorage.getItem("Doctor_id")
        }
        axios
            .post("doctor/notification_doc_list", NotificationData)
            .then((response) => {
                debugger;
                if (response.data.length != 0) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        response.data.data[i].notification_date = (response.data.data[i].notification_date) ? formatDate(response.data.data[i].notification_date) : response.data.data[i].notification_date;
                        response.data.data[i].time = (response.data.data[i].time != null) ? formatTime(response.data.data[i].time) : response.data.data[i].time;
                    }
                    setVar_NotificationData(response.data.data);
                } else {
                    setVar_NotificationData(response.data.data.length);
                }
                console.log("notification_doc_list", response.data.data.length);
                console.log("notification_doc_list", response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function formatDate(inputDate) {
        let dateParts = inputDate.split("-");
        let year = dateParts[0].substring(2);
        let month = parseInt(dateParts[1], 10);
        let day = parseInt(dateParts[2], 10);
        let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let formattedDate = day.toString().padStart(2, '0') + "-" + monthNames[month - 1] + "-" + year;
        return formattedDate;
    }

    function formatTime(inputTime) {
        let timeParts = inputTime.split(":");
        let hours = parseInt(timeParts[0], 10);
        let minutes = timeParts[1];
        let amOrPm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12 || 12;
        let formattedTime = hours.toString().padStart(2, '0') + ":" + minutes + " " + amOrPm;
        return formattedTime;
    }

    function notificationNavLink(notificationLink) {
        debugger
        const datePattern = /\d{2}\/\d{2}\/\d{2}/;
        const match = notificationLink.notification_content.match(datePattern);
        const dateRegex = /\b\d{2}\/\d{2}\/\d{2}\b/g;
        const dates = notificationLink.notification_content.match(dateRegex);
        const referredPatient = notificationLink.notification_content.includes("referred a patient to you");
        switch (notificationLink.content_type) {
            case "Ticket":
                handleOnClick("/support");
                break;
            case "Advertisement reviewed":
                handleOnClick("/advertisementbooking/advertisementbookinglist");
                break;
            case "Advertisement published":
                handleOnClick("/advertisementbooking/advertisementbookinglist");
                break;
            case "Deal":
                handleOnClick("/deals");
                break;
            case "Booking":
                if (notificationLink.active_show == 1) {
                localStorage.setItem('contentTypeData', "Booking")
                if (match) {
                    const sessionDateOriginal = match[0];
                    localStorage.setItem('formatDateToDayMonthYear', formatDateToDayMonthYear(sessionDateOriginal))
                    localStorage.setItem('formatDateToISO', formatDateToISO(sessionDateOriginal))
                }
                handleOnClick("/appointments");
                }
                break;
            case "Rescheduled":
                if (notificationLink.active_show == 1) {
                localStorage.setItem('contentTypeData', "RescheduledSec")
                if (dates) {
                    const sessionDateOriginal = dates[1];
                    localStorage.setItem('formatDateToDayMonthYear', formatDateToDayMonthYear(sessionDateOriginal))
                    localStorage.setItem('formatDateToISO', formatDateToISO(sessionDateOriginal))
                }
                handleOnClick("/appointments");
                }
                break;
            case "Cancel":
                handleOnClick("/cancelledappointments");
                break;
            case "ReferralLab":
                localStorage.setItem('contentTypeData', "Referral")
                handleOnClick("/referral");
                break;
            case "Referral":
                if (referredPatient) {
                    handleOnClick("/referral");
                } else {
                    localStorage.setItem('contentTypeData', "Referral")
                    handleOnClick("/referral");
                }
                break;
            case "ReferralPharmacy":
                localStorage.setItem('contentTypeData', "Referral")
                handleOnClick("/referral");
                break;
            case "Revenue":
                handleOnClick("/revenue");
                break;
            case "DealBooking":
                Var_NotificationData.forEach((element, index) => {
                    if (notificationLink.id == element.id) {
                        var nextObjects = Var_NotificationData[index + 1];
                        if (nextObjects != undefined) {
                            const match1 = nextObjects.notification_content.match(datePattern);
                            if (match1) {
                                localStorage.setItem('contentTypeData', "Booking")
                                const sessionDateOriginal = match1[0];
                                localStorage.setItem('formatDateToDayMonthYear', formatDateToDayMonthYear(sessionDateOriginal))
                                localStorage.setItem('formatDateToISO', formatDateToISO(sessionDateOriginal))
                                handleOnClick("/appointments");
                            }
                        }
                    }
                });
                break;
        }
    }

    function formatDateToDayMonthYear(dateStr) {
        const [day, month, year] = dateStr.split('/').map(part => parseInt(part, 10));
        const date = new Date(2000 + year, month - 1, day);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear().toString().slice(-2)}`;
    }

    function formatDateToISO(dateStr) {
        const [day, month, year] = dateStr.split('/').map(part => parseInt(part, 10));
        const date = new Date(2000 + year, month - 1, day + 1);
        return date.toISOString().split('T')[0];
    }

    const currentDate = new Date();

    // Get the year, month, and day
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because January is 0
    const day = ('0' + currentDate.getDate()).slice(-2);

    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    console.log("dateme", formattedDate);
    // console.log("praveen", formattedDate)


    return (
        <div>
            {/* {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            } */}
            {loading &&
                <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
            }

            {/* ================= Notification sub header start ================= */}
            <div className="sub_header">
                <div className="sub_header_body">
                    <div className="back_navigation" onClick={() => handleOnClick("/")}>
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                        <span>NOTIFICATION</span>
                    </div>
                    <div className="flex_grow"></div>
                    <div className="header_right_content"></div>
                    {/* <div className='dropdown_button'>
                        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                SelectProps={{
                                    renderValue: (p) => p
                                }}
                                value={Var_DoctorDetailShow_Ref.current}
                                readOnly={true}
                                renderValue={(p) => p}
                            >
                            </Select>
                        </FormControl>
                    </div> */}
                </div>

            </div>
            {/* ================= Notification sub header end ================= */}


            {/* ================= Notification list View  start ================= */}
            <div className='content_view' style={{ boxShadow: 'none !important' }}>
                {/* {(Var_NotificationData.todate ==formattedDate)  &&  */}

                {Var_NotificationData.length > 0 &&
                    Var_NotificationData?.map((item, i) => (
                        <>
                            {
                                <>
                                    <Card className='notification_content_card' onClick={() => notificationNavLink(item)} key={i}>
                                        <div className="d_flex">
                                            <div className="notification_content">
                                                <p className='email_id content'>{item.notification_content}</p>
                                            </div>
                                            <div className="email_id content_time">{item.notification_date + ', ' + item.time}</div>
                                        </div>
                                    </Card>
                                </>
                            }
                            {/* {moment(item.appointment_date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") == formattedDate && item.doctor_id == localStorage.getItem("Doctor_id") &&
                                <>

                                    <Card className='notification_content_card' key={i}>

                                        <div className="notification_content">
                                            <div>
                                                <p className='clinic_doctor_name'>{item.member_name}</p>
                                                <p className='clinic_name'>{item.doctor_name}</p>
                                            </div>
                                            <p className='clinic_time'>{moment(item.appointment_date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")}</p>

                                        </div>
                                        <hr className='underline_status'></hr>
                                        <p className='email_id'>Todays appointment with {item.member_name} at {convertTo12HrFormat(item.appointment_time)}</p>

                                    </Card>
                                </>
                            } */}


                        </>
                    ))
                }


                {
                    Var_NotificationData == 0 &&

                    <div style={{ display: Var_NoData, height: "70vh" }}>
                        <p className="cancel_no_data"> <img src={NotFound} className="cancel_no_data_image" />No Data Found</p>
                    </div>
                }

            </div>
            {/* ================= Notification list View  end ================= */}


            {/* ================= Notification footer start ================= */}
            <div className="footer">
                <FooterMaster />
            </div>
            {/* ================= Notification footer end ================= */}
        </div>
    )
}
export default NotificationComponent;
