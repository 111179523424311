// AUTHOR : PRAVEEN.P
// CR-DATE: 17-july-2023
// MOD-DATE: 20-july-2023
// DESCRIPTION: WALK IN BOOKING work

import axios from "axios";
import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { notification, Spin } from "antd";
import LinearProgress from '@mui/material/LinearProgress';

// Mui meterial component import
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { MenuItem } from '@mui/material';
import FormControl from "@mui/material/FormControl";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { red } from "@mui/material/colors";
import CircularProgress from '@mui/material/CircularProgress';


import FooterMaster from '../Dashboard/FooterMaster'

import "./WalkinBookingMaster.css";
import Success from "../../assets/Web_Doctor_Icon_new_theme/Success.svg";
import TOMLogo from '../../assets/Images/TOMLogo.jpg';
import vector from '../../assets/Doctor_web_Icon/Vector.png'
import internet1 from '../../assets/Doctor_web_Icon/internet1.svg'
import royalcrown from '../../assets/Doctor_web_Icon/royal-crown.svg'
import internet2 from '../../assets/Doctor_web_Icon/internet2.svg'

function WalkInBookingComponent() {
  let Var_History = useHistory();

  const [Var_getClinicWiseDoctor, setVar_getClinicWiseDoctor] = React.useState([]);
  var [Var_doctorSpecialitySelect, setVar_doctorSpecialitySelect] = useState("");
  const [Var_GetServiceList, setVar_GetServiceList, Var_GetServiceList_Ref] = useStateRef([]);
  const [Var_WalkInGetSlots, setVar_WalkInGetSlots, Var_WalkInGetSlots_Ref] = useStateRef([]);
  const [Var_WalkInbooked, setVar_WalkInbooked, Var_WalkInbooked_Ref] = useStateRef([]);
  const [Var_serviceTypeId, setVar_serviceTypeId, Var_serviceTypeId_Ref] = useStateRef('');
  const [Var_ServiceWalkin, setVar_ServiceWalkin, Var_ServiceWalkin_Ref] = useStateRef("");
  const [Var_SlotDuration, setVar_SlotDuration, Var_SlotDuration_Ref] = useStateRef("");
  const [Var_CostValue, setVar_CostValue, Var_CostValue_Ref] = useStateRef("");
  const [Var_ProfileName, setVar_ProfileName, Var_ProfileName_Ref] = useStateRef('');
  const [Var_ProfileAge, setVar_ProfileAge, Var_ProfileAge_Ref] = useStateRef('');
  const [Var_ProfilePhone, setVar_ProfilePhone, Var_ProfilePhone_Ref] = useStateRef('');
  const [Var_ProfileGender, setVar_ProfileGender, Var_ProfileGender_Ref] = useStateRef('');
  const [Var_SelectTimeSlots, setVar_SelectTimeSlots, Var_SelectTimeSlots_Ref] = useStateRef('');
  const [Var_SelectToTimeSlots, setVar_SelectToTimeSlots, Var_SelectToTimeSlots_Ref] = useStateRef('');
  const [Var_SlotsLength, setVar_SlotsLength, Var_SlotsLength_Ref] = useStateRef('');
  const [Var_arrivedstatus, setVar_arrivedstatus, Var_arrivedstatus_Ref] = useStateRef('');
  const [Var_SpecialityId, setSpecialityId, Var_SpecialityId_Ref] = useStateRef('');
  const [Var_DoctorDetailShow, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] = useStateRef(null);
  const [Var_gendervalue, setVar_gendervalue, Var_gendervalue_Ref] = useStateRef("Gender");
  const [loading, setLoading] = useState(true);

  const [Var_gender, setVar_gender, Var_gender_Ref] = useStateRef("");
  var [Var_date, setVar_Date, Var_date_Ref] = useStateRef(new Date().toLocaleDateString('fr-CA'))
  const [fullWidth, setFullWidth] = React.useState(true);
  var [dateapi, setDateapi, dateRefapi] = useStateRef("")
  var [Var_NoData, setVar_NoData] = useStateRef("block")
  var [Var_HaveData, setVar_HaveData] = useStateRef("none")

  const [Var_IsShowSuccess, setVar_IsShowSuccess] = React.useState(false);

  // initial function call start
  useEffect(() => {
    // getSlotsService();
    setVar_Date(moment(new Date().toLocaleDateString('fr-CA')).format("DD-MMM-YY"));
    setDateapi(new Date().toLocaleDateString('fr-CA'));
    getClinicWiseDoctor();
    // getservice();



    // getSlotsService();
  }, []);
  // initial function call end

  // navigation to back start
  const handleOnClick = (path) => {
    Var_History.push("/dashboard" + path)
  };
  // navigattion to back end

  //Date secssion for back start
  const previousdate = () => {

    if (new Date(Var_date_Ref.current) > new Date()) {
      let date = new Date(Var_date_Ref.current);
      let date2 = date.setDate(date.getDate() - 1);
      let options = {
        day: '2-digit',
        month: 'short',
        year: '2-digit'
      };
      let formattedDate = date.toLocaleDateString('en-US', options);
      var monthsegment = moment(formattedDate).format("DD-MMM-YY")
      setVar_Date(monthsegment);
      setDateapi(date.toLocaleDateString('fr-CA'));
      getSlotsService();
    }

  }
  //Date secssion for back End

  //Date secssion for forward start
  const nextdate = () => {

    let date = new Date(Var_date_Ref.current);
    let date2 = date.setDate(date.getDate() + 1);
    let options = {
      day: '2-digit',
      month: 'short',
      year: '2-digit'
    };
    let formattedDate = date.toLocaleDateString('en-US', options);
    var monthsegment = moment(formattedDate).format("DD-MMM-YY")
    setVar_Date(monthsegment);
    setDateapi(date.toLocaleDateString('fr-CA'));
    getSlotsService();
  }
  //Date secssion for forward End

  //sucess modal closing start
  const oKClose = () => {
    setVar_IsShowSuccess(!Var_IsShowSuccess);
    getservice();
    dataClear();

  }
  //sucess modal closing end

  // validation for not booked before enter all inputs start
  const validation = () => {
    if (Var_serviceTypeId_Ref.current == "") {
      notification.error({ message: "Please fill all the data" });
      return false;
    }
    else if (Var_SelectTimeSlots_Ref.current == '') {
      notification.error({ message: "Please fill all the data" });
      return false;
    }
    else if (Var_ProfileName_Ref.current == '') {
      notification.error({ message: "Please fill all the data" });
      return false;
    }
    else if (Var_gendervalue_Ref.current.current == '') {
      notification.error({ message: "Please fill all the data" });
      return false;
    }
    else if (Var_ProfilePhone_Ref.current == '') {
      notification.error({ message: "Please fill all the data" });
      return false;
    }

    else {
      return true;
    }
  }
  //validation for not booked before enter all inputs end

  //  validation for clear data after booked start
  const dataClear = () => {
    setVar_GetServiceList([]);
    let date = new Date();
    setVar_Date(moment(new Date().toLocaleDateString('fr-CA')).format("DD-MMM-YY"));
    setDateapi(date.toLocaleDateString('fr-CA'));
    setVar_serviceTypeId("");
    setVar_SlotDuration("");
    setVar_CostValue("");
    setVar_WalkInGetSlots([]);
    setVar_ProfileName("");
    setVar_ProfilePhone("");
    setVar_gender("");
  }
  // validation for clear data after booked end

  // book function start
  const addBook = () => {
    bookedSuccessfully();
  }
  // book function end

  // select slots from time function start
  const selectSlots = (value) => {

    Var_WalkInGetSlots_Ref.current.forEach(element => {
      element.is_clicked = 0;
    });
    setVar_SelectTimeSlots(value.from_time);
    value.is_clicked = 1;
    setVar_SelectToTimeSlots(value.to_time);
  }
  // select slots from time function end

  //get doctor list API start
  const getClinicWiseDoctor = () => {
    setLoading(true);
    var data = {
      clinic_id: localStorage.getItem("Clinic_id")
    };
    axios
      .post("clinic/getClinicWiseDoctor", data)
      .then((response) => {
        debugger;
        console.log('test', response.data.data)
        setVar_getClinicWiseDoctor(response.data.data);
        setVar_doctorSpecialitySelect(response.data.data[0].specialityid);
        var filteredData = response.data.data.filter(x => x.doctor_id == localStorage.getItem("Doctor_id"));
        console.log(filteredData);
        setSpecialityId(filteredData[0].specialityid);
        setVar_DoctorDetailShow(filteredData[0].doctorName + ',' + " " + filteredData[0].specialityname);
        getservice();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //get doctor list API end

  //get service list API start
  const getservice = () => {
    setLoading(true);
    var data = {
      doctor_id: localStorage.getItem("Doctor_id"),
      speciality_id: Var_SpecialityId_Ref.current
    }
    axios
      .post("doctor/getDoctorServiceBySpecilaity", data)
      .then((response) => {
        {
          console.log("slot response", response.data.data);
          setVar_GetServiceList(response.data.data)
          // setVar_serviceTypeId(response.data.data[0].id);
          getSlotsService();
          setLoading(false);

        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

  }
  //get service list API end


  // current time function start
  // const currentDate = new Date();
  // const options = {
  //   hour12: false,
  //   hour: 'numeric',
  //   minute: 'numeric',
  // }
  // const formattedDate = currentDate.toLocaleString('en-US', options);
  // current time function start 

  // get slots details API start
  const getSlotsService = () => {

    setVar_WalkInGetSlots([])
    setVar_NoData("block")
    setVar_HaveData("none")
    setLoading(true);
    var data = {

      search_date: dateRefapi.current,
      clinic_id: localStorage.getItem("Clinic_id"),
      doctor_id: localStorage.getItem("Doctor_id"),
      service_id: Var_serviceTypeId_Ref.current,
      type: "",
      nxtdate: "true"
    }

    axios
      .post("doctor/getslotsbyservice", data)
      .then((response) => {
        {
          console.log("slot", response.data.data[0])
          debugger;
          if (response.data.data[0].date_slots.length == 0) {
            setLoading(true);
            setVar_NoData("block")
            setVar_HaveData("none")
            setVar_WalkInGetSlots(response.data.data[0].date_slots)
            setVar_SlotsLength(response.data.data[0].date_slots.length)
            setLoading(false);
          }
          else if (response.data.data[0].date_slots.length > 0) {
            setLoading(true);
            setVar_NoData("none")
            setVar_HaveData("block")
            var filteredData = response.data.data[0].date_slots.filter(x => x.appointtype_id == "1" && x.is_blocked != "1");
            setVar_WalkInGetSlots(filteredData)
            // setVar_WalkInGetSlots(response.data.data[0].date_slots)
            // let avalilable_slot = response.data.data[0].date_slots.filter(item => item.from_time > formattedDate);
            // setVar_WalkInGetSlots(avalilable_slot)
            setVar_SlotsLength(response.data.data[0].date_slots.length)
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }
  // get slots details API end

  // walk in booking API start
  const bookedSuccessfully = () => {

    let Var_Validate = validation();
    setLoading(true);
    if (Var_Validate) {
      var date = dateRefapi.current
      // if(date==new Date().getFullYear() + "-" + new Date().getUTCMonth() + "-" + new Date().getDate() + " "){
      //   setVar_arrivedstatus("1")
      // }
      // else{
      //   setVar_arrivedstatus("0")
      // }

      // setLoading();
      debugger
      var data = {
        mobile_no: Var_ProfilePhone_Ref.current,
        name: Var_ProfileName_Ref.current,
        age: 0,
        gender: Var_gendervalue_Ref.current,
        book_date: date.toString(),
        from_time: Var_SelectTimeSlots_Ref.current,
        to_time: Var_SelectToTimeSlots_Ref.current,
        service_type_id: Var_serviceTypeId_Ref.current,
        book_amount: Var_CostValue_Ref.current,
        total_slots: Var_SlotsLength_Ref.current,
        payment_status: 0,
        clinic_id: localStorage.getItem("Clinic_id"),
        doctor_id: localStorage.getItem("Doctor_id"),
        created_by: localStorage.getItem("Doctor_id"),
        arrived_status: Var_date == moment(new Date()).format("DD-MMM-YY") ? "1" : "0",
        arrived_order_list: Var_date == moment(new Date()).format("DD-MMM-YY") ? new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds() : "NULL",
      };

      axios
        .post("clinic/insert_patient_walkin", data)
        .then((response) => {

          {
            if (response.data.status == 1) {
              setLoading(false);
              setVar_IsShowSuccess(!Var_IsShowSuccess);
              setVar_WalkInbooked(response.data.data)
              setVar_gendervalue("Gender")
              setLoading(false);

            }
            else {
              notification.error({ message: response.data.msg })
              setLoading(false);
            }

          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }
  // walk in booking API end

  // time format for changing start
  const timeFormat = (time) => {
    debugger;
    let datetime = new Date('1970-01-01T' + time + 'Z');
    return datetime.toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' });
  }
  // time format for changing end

  const getGender = (e) => {
    setVar_gendervalue(e.target.value)
    if (e.target.value == "M") {
      setVar_gender("Male")
    } else {
      setVar_gender("Female")
    }
  }

  const handleInputChange = (event) => {
    const inputText = event.target.value;
    const numericOnly = inputText.replace(/\D/g, '');
    console.log("inputText", inputText)
    console.log("numericOnly", numericOnly)

    setVar_ProfilePhone(numericOnly);
  };

  return (
    <div>
      {/* ================================================ Walk In sub header start ============================================== */}
      {/* {loading &&
        <div className="loader_body">
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        </div>
      } */}
      {loading &&
        <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
      }

      <div className="sub_header">
        <div className="sub_header_body walkin_header_body">
          <div className="back_navigation" onClick={() => handleOnClick("/")}>
            <i class="fa fa-angle-left" aria-hidden="true"></i>
            <span>WALK-IN BOOKING</span>
          </div>
          <div className="flex_grow"></div>
          <div className="header_right_content">
            {/* <div className='dropdown_button'>
              <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  SelectProps={{
                    renderValue: (p) => p
                  }}
                  value={Var_DoctorDetailShow_Ref.current}
                  readOnly={true}
                  renderValue={(p) => p}
                >
                </Select>
              </FormControl>
            </div> */}
            <div className="page_head_date">
              <Button className='date_left_button'
                onClick={() => {
                  previousdate();
                }}
              >
                <i className="fa fa-chevron-left date_left_button_icon" aria-hidden="true" />
              </Button>
              <Button className='date_button'>{Var_date}</Button>
              <span><Button onClick={() => {
                nextdate();
              }} className='date_right_button'>
                <i className="fa fa-chevron-right date_right_button_icon" aria-hidden="true" />
              </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* ================================================ Walk In sub header end ================================================= */}


      {/* ================================================ Walk In booking  start ================================================= */}
      <div className="content_view">
        <Grid container item className="total_walkin_service">
          {/* =====================Service Drop Down  start======================== */}
          <Grid item xs={4}>
            <div className="service_consulting_walkin">
              <FormControl>
                <span className="input_span walkin_input_header">Select Service</span>
                <Select size="small" className="selectoption" sx={{ m: 1, minHeight: 15, minWidth: 250 }}
                  id="demo-simple-select"
                  value={Var_serviceTypeId_Ref.current}
                  onChange={e => {
                    Var_GetServiceList.forEach((item) => {
                      if (item.id == e.target.value) setVar_SlotDuration(item.slot_duration)
                      if (item.id == e.target.value) setVar_CostValue(item.cost)
                      setVar_serviceTypeId(e.target.value)
                      if (item.id == e.target.value) {
                        setVar_ServiceWalkin(item.service)
                      }
                    });
                    getSlotsService()
                  }}
                  renderValue={() => {
                    //   
                    // Var_GetServiceList
                    //  let serviceName = Var_GetServiceList.filter(member => member.id == Var_serviceTypeId)
                    return Var_ServiceWalkin
                  }}
                >
                  {Var_GetServiceList.map((item) => (
                    <MenuItem
                      key={item.service}
                      value={item.id}
                    >
                      {item.is_active == 1 &&
                        <Card className='specialtyimgRow'>
                          <Grid container item>
                            <Grid xs={1} align="start">
                              <img src={item.file_path == " " ? vector : item.file_path == "[object Object]" ? vector : item.file_path == "" ? vector : item.file_path} alt='a' className='specialtyimg' />
                            </Grid><Grid xs={1}></Grid>
                            <Grid xs={9} className='ref_lab' align="start">{item.service}</Grid>
                          </Grid>
                        </Card>
                      }
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid>

          {/* ========================Service Drop Down  end======================== */}

          {/* ========================duration and cost start======================== */}
          <Grid xs={4}>
            <div>
              <span className="input_span walkin_input_header">Duration <span style={{ fontSize: "10px" }}>(hrs)</span></span>
              <p className="input_duration">{Var_SlotDuration}</p>
            </div>
          </Grid>
          <Grid xs={4}>
            <div>
              <span className="input_span walkin_input_header">Fee <span style={{ fontSize: "10px" }}>(KWD)</span></span>
              <p className="input_duration">{Var_CostValue}</p>
            </div>
          </Grid>
          {/* ========================duration and cost end======================== */}
        </Grid>
        {/* ========================total slots and input fields start======================== */}

        <Grid container >
          {/* =================get all slots start================== */}
          <Grid item xs={6} style={{ justifyContent: "space-evenly" }}>
            <Grid container className="total_walkin_slots">
              <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                <Grid container item className="walkin_booking_select_time">
                  <Grid xs={3}>
                    <span className="walkin_time_slots" >Select Time</span>
                  </Grid>
                  <Grid xs={3}>
                    <Grid container xs={12}>
                      <Grid item xs={2}>
                        <div className="square_slots_booked">  </div>
                      </Grid>
                      <Grid item xs={2}>
                        <span className="walkin_booked">Booked</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={3}>
                    <Grid container xs={12}>
                      <Grid item xs={2}>
                        <div className="square_slots_available">  </div>
                      </Grid>
                      <Grid item xs={2}>
                        <span className="walkin_available">Available</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={3}>
                    <Grid container xs={12}>
                      <Grid item xs={2}>
                        <div className="square_slots_selected">  </div>
                      </Grid>
                      <Grid item xs={2}>
                        <span className="walkin_selected">Selected</span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* ======================== */}
            <div className="segment_center slot_unavailable" style={{ display: Var_NoData }}>
              <p>Slots unavailable</p>
            </div>

            <div style={{ display: Var_HaveData }}>
              <Grid container spacing={2} className="walkin_total_slots" >
                {Var_WalkInGetSlots.map((value) => (
                  <Grid item xs={2}>
                    <div className="slot_container_walkin">
                      {((value.date > moment(new Date()).format("YYYY-MM-DD") == true) || (value.from_time > new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds())) &&
                        <>
                          <div className="time_sloat"><p className="time_show" onClick={() => {
                            if (value.is_booked == 0) {
                              selectSlots(value);
                            }
                          }} disabled={value.is_booked == 1 ? true : false}
                            style={{
                              backgroundColor: value.is_booked == 0 ? value.is_clicked == 1 ? "var(--app-theme)" : "#510F3040" : "#FBEAE5", color: value.is_booked == 0 ? value.is_clicked == 1 ? "white" : "var(--app-theme) " : "#E12F2F",
                              border: value.is_booked == 0 ? "1px solid var(--app-theme) " : " 1px solid #E12F2F"
                            }}>{timeFormat(value.from_time)}</p></div>
                          <img className="slot_type_walkin" src={value.appointtype_id == 2 ? internet2 : value.appointtype_id == 3 ? royalcrown : internet1} alt="error"
                            style={{
                              display: value.appointtype_id == 1 ? "none" : "block",
                              backgroundColor: value.is_booked == 0 ? "var(--app-theme)" : "#E12F2F",
                              border: value.is_booked == 0 ? "1px solid var(--app-theme) " : "#E12F2F"
                            }}
                          />
                        </>
                      }

                    </div>


                  </Grid>
                ))}
              </Grid>
            </div>
            {/* ======================== */}

          </Grid>
          {/* =================get all slots end================== */}

          {/* =====================all walkin patient details start============ */}
          <Grid item xs={5} className="total_input_profile_header">
            <Grid container >
              <Grid item xs={6}>
                <span className="input_span walkin_input_header_profile">Name</span>
                <TextField
                  className="input_field_walk"
                  placeholder="Abdul"
                  value={Var_ProfileName}
                  onChange={event => setVar_ProfileName(event.target.value)}
                />
              </Grid>
              <Grid item xs={6} className="gender_details">
                {/* <span className="input_span walkin_input_header_profile">Gender</span> */}
                <div className="flex_grow"></div>
                <div className="header_right_content gender_dropdown_align">
                  <div className='dropdown_button'></div>
                  <p className="patient_gender">Gender</p>
                  <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={Var_gender}
                      renderValue={(p) => p}
                      onChange={(e) => getGender(e)}
                    >
                      {/* <MenuItem valu></MenuItem> */}
                      <MenuItem value={"M"}>Male</MenuItem>
                      <MenuItem value={"F"}>Female</MenuItem>
                      {/* <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>

                  </FormControl>
                </div>

              </Grid>
              {/* <Grid item xs={6}>
                <span className="input_span walkin_input_header_profile">Age</span>
                <TextField
                  className="input_field_walk"
                  placeholder="30"
                  value={Var_ProfileAge}
                  onChange={event => setVar_ProfileAge(event.target.value)}
                />
              </Grid> */}
              <Grid item xs={6}>
                <span className="input_span walkin_input_header_profile">Mobile Number</span>
                <TextField
                  className="input_field_walk"
                  placeholder="12345677"
                  inputProps={{
                    maxLength: 8,
                  }}
                  value={Var_ProfilePhone}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* =====================all walkin patient input fields start============ */}
        </Grid>
        {/* ========================total slots and input fields end======================== */}

        <Grid xs={12} className="walk_book_btn">
          <Button variant="contained" color="success" className="book_btn" onClick={() => addBook()}>
            Book
          </Button>
        </Grid>
      </div>
      {/* ===============================================   Walk In booking  end ============================================================= */}
      <div className="footer">
        <FooterMaster />
      </div>

      {/* ================================================== AddBook Success Model start========================================== */}
      <Dialog
        fullWidth={fullWidth}
        open={Var_IsShowSuccess}
        onClose={setVar_IsShowSuccess}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "300px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogContent>
          <div className="success_model_body">
            <div className="icon">
              <img src={Success} />
            </div>
            <div className="title">success!</div>
            <div className="content">Walk-in Booking added successfully!</div>
            <div className="segment_center">
              <button className="botton_Cart_Create" onClick={() => oKClose()}>OK</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* ================================================== AddBook Success Model end============================================= */}
    </div>
  );
};
export default WalkInBookingComponent;
