// AUTHOR : Dinesh
// CR-DATE: 20-July-2023
// MOD-DATE: 21-July-2023
// DESCRIPTION: Support Update work

import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import LinearProgress from '@mui/material/LinearProgress';

// Mui meterial component import
import { InputLabel, FormControlLabel, Select, MenuItem, Container, Card, Grid, TextField, Button, FormControl, DialogContent, Dialog, Radio } from '@mui/material';


import './SupportMaster.css'
import FooterMaster from '../Dashboard/FooterMaster'
import status from "../../assets/Web_Doctor_Icon_new_theme/status.svg"
import closeicon from '../../assets/Web_Doctor_Icon_new_theme/close.svg';
import successModelTickImg from '../../assets/Web_Doctor_Icon_new_theme/Success.svg'
import play from '../../assets/Web_Doctor_Icon_new_theme/Play.png'
import pass from '../../assets/Web_Doctor_Icon_new_theme/Pause.png'
import Eye from '../../assets/Web_Doctor_Icon_new_theme/Eye.svg'
import { SpatialAudioOffSharp, TramRounded } from "@mui/icons-material";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


function SupportMasterComponent() {
    let Var_History = useHistory();

    const [Var_SupportTicketList, setVar_SupportTicketList, Var_SupportTicketList_Ref] = useStateRef([]);
    const [Var_SupportTicketID, setVar_SupportTicketID, Var_SupportTicketID_Ref] = useStateRef([]);
    const [Var_statusvalue, setVar_statusvalue, Var_statusvalue_Ref] = useStateRef([]);
    const [isshowsuccess, setishiddensuccess] = useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [Var_FileView, setVar_FileView] = React.useState("");
    const [Var_audioState, setVar_audioState] = React.useState(false);
    const [Var_audioIndex, setVar_audioIndex] = React.useState(0);
    const [loading, setLoading] = useState(true);
    const [isshowsuccessmodal, setishiddensuccessmodal] = useState(false);
    const [isshowsuccessmodal_text, setishiddensuccessmodal_text, isshowsuccessmodal_text_ref] = useStateRef('');



    // initial function call start
    useEffect(() => {
        getSupportticketsList();
    }, [])
    // initial function call End


    // navigation to back start
    const handleOnClick = (path) => {
        Var_History.push("/dashboard" + path)
    };
    // navigation to back end


    {/* ================= get Support tickets List Api Start ================= */ }

    const getSupportticketsList = () => {

        setLoading(true);
        var data = {
            vendor_id: localStorage.getItem("Doctor_id"),
            limit: 1000,
            pageno: 1
        };

        axios({ method: "POST", url: "admin/get_vendor_ticket", data: data, }).then((response) => {
            setLoading(false);
            setVar_SupportTicketList(response.data.data);
            setVar_SupportTicketID(response.data.data.ticket_id);

        })
            .catch((error) => {
                console.log(error);
            });
    }

    // =================praveen================

    // video upload check fn 
    const isValidVideo = (url) => {
        const allowedExtensions = ['.mp4', '.avi', '.mkv']; // Add more if needed
        const extension = url.substring(url.lastIndexOf('.')).toLowerCase();
        return allowedExtensions.includes(extension);
    };



    const supportstatus = (item, data) => {
        setLoading(true);
        if (item == "close") {
            setVar_statusvalue("RE");
            setishiddensuccessmodal(true);
            setishiddensuccessmodal_text('Ticket Resolved Successfully!');
        }
        else {
            setVar_statusvalue("RO");
            setishiddensuccessmodal(true);
            setishiddensuccessmodal_text('Ticket Reopened Successfully!');
        }
        var data = {
            TicketId: data,
            Status: Var_statusvalue_Ref.current,
            Comments: ""
        };
        axios
            .put("admin/updatesupportstatus", data)
            .then((response) => {

                getSupportticketsList();
                setLoading(false);

            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });

    }


    {/* ================= get Support tickets List Api End ================= */ }

    const timeFormate = (time) => {
        const timeShow = moment(time).format("DD-MMM-YY")
        return timeShow

    }

    const viewreport = (value, status) => {
        console.log(value, status, "sai")
        setVar_FileView(value)

        setishiddensuccess(!isshowsuccess)
    }

    const isCloseModel = () => {
        setishiddensuccess(!isshowsuccess)

    }

    const okClose = () => {
        setishiddensuccessmodal(false);

    }

    // var audio = new Audio();
    const playAudio = (url, index, type) => {

        const audio = document.getElementById("PlayAudio" + index);

        // Pause the previously playing audio if it's not the same as the current one
        if (Var_audioState && Var_audioIndex !== index) {
            const prevAudio = document.getElementById("PlayAudio" + Var_audioIndex);
            if (prevAudio) {
                prevAudio.pause();
                setVar_audioState(false);
            }
        }

        if (type === "play") {
            audio.play();
            setVar_audioState(true);
            setVar_audioIndex(index);

            // Listen for the 'ended' event to change play state and icon when audio completes
            audio.addEventListener("ended", () => {
                setVar_audioState(false);
                setVar_audioIndex(null);
                setVar_SupportTicketList((prevState) => {
                    return prevState.map((ticket, i) => {
                        if (i === index) {
                            return {
                                ...ticket,
                                Var_audioState: false,
                            };
                        }
                        return ticket;
                    });
                });
            });
        } else {
            audio.pause();
            setVar_audioState(false);
            setVar_audioIndex(null);
        }
        setVar_SupportTicketList((prevState) => {
            return prevState.map((ticket, i) => {
                if (i === index) {
                    return {
                        ...ticket,
                        Var_audioState: type === "play",
                    };
                }
                return ticket;
            });
        });
    }


    const find = () => {
        var audio = new Audio('https://docterweb-ticketing-audio.s3.amazonaws.com/TOM-Suport-2-8-2023-11-25-4.mp3');
        // Wait for the audio to be loaded
        audio.addEventListener('loadedmetadata', function () {
            // Get the duration in seconds
            var duration = audio.duration;
            // Convert the duration to hours, minutes, and seconds
            var hours = Math.floor(duration / 3600);
            var minutes = Math.floor((duration % 3600) / 60);
            var seconds = Math.floor(duration % 60);
            // Format the time with leading zeros
            var formattedDuration = (hours < 10 ? '0' : '') + hours + ':' +
                (minutes < 10 ? '0' : '') + minutes + ':' +
                (seconds < 10 ? '0' : '') + seconds;
            console.log('Audio duration: ' + formattedDuration);
        });
        // Start loading the audioaudio.load();
    }


    const isAudioPlaying = (index) => {
        return Var_audioState && Var_audioIndex === index;
    };

    return (
        <div>
            {/* {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            } */}
            {loading &&
                <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
            }
            {/* ================= Support sub header start ================= */}
            <div className="sub_header">
                <div className="sub_header_body">
                    <div className="back_navigation" onClick={() => handleOnClick("/")}>
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                        <span>SUPPORT</span>
                    </div>
                    <div className="flex_grow"></div>
                    <div className="header_right_content">
                        <i className="fa fa-plus support_add_circle" aria-hidden="true" onClick={() => handleOnClick("/support/addsupport")}></i>
                    </div>
                </div>
            </div>
            {/* ================= Support sub header end ================= */}


            {/* ================= Support list View  start ================= */}
            <div className='content_view'>
                {Var_SupportTicketList?.map((responseData, i) => (
                    <Card className='support_content_card' key={i} >
                        <Grid container className="support_card">
                            <Grid item xs={6} md={6} lg={6} >
                                <div className="support_card_name">
                                    <p className="title">Ticket ID</p>
                                    <p> :&nbsp;&nbsp;</p>
                                    <p>{responseData.ticket_no}</p>
                                </div>
                                <div className="support_card_name">
                                    <p className="title">Remarks</p>
                                    <p> :&nbsp;&nbsp;</p>
                                    <p className="Remark_Content">{responseData.remarks}</p>
                                </div>
                                {/* <div className="close_complaint_button"> */}
                                <div className="support_card_name">
                                    <p className="title">Status</p>
                                    <p> :&nbsp;&nbsp;</p>
                                    <p className="support_open">
                                        {
                                            responseData.status == "P" && <p><span style={{ color: "#FF0068", fontWeight: "bold" }}>Pending</span></p>
                                        }
                                        {
                                            responseData.status == "RO" && <p><span style={{ color: "#FF0068", fontWeight: "bold" }}>Reopen</span></p>
                                        }
                                        {
                                            responseData.status == "RE" &&
                                            <>
                                                <div style={{ display: "flex" }}>
                                                    <p><span style={{ color: "var(--app-theme)", fontWeight: "bold" }}>Resolved</span></p>
                                                    <img src={status} alt='a' className='specialtyimg' />
                                                </div>
                                            </>
                                        }
                                        {
                                            responseData.status == "C" && <p><span style={{ color: "var(--app-theme)", fontWeight: "bold" }}>Closed</span></p>
                                        }
                                    </p>
                                </div>

                                {/* </div> */}
                            </Grid>
                            <Grid className="icons_end" item xs={6} md={6} lg={6}>
                                {console.log(responseData, "ajithkumar")}
                                {responseData.status == "P" &&
                                    <>
                                        <p className="support_card_date">  {timeFormate(responseData.ticket_date)}</p>
                                        <div className="support_icons">
                                            <div className="titless">
                                                {
                                                    responseData.audio_path &&
                                                    <img src={(Var_audioState == true && Var_audioIndex == i) ? pass : play} className="play"
                                                        onClick={() => { !Var_audioState ? playAudio(responseData.audio_path, i, "play") : playAudio(responseData.audio_path, i, "stop") }}
                                                    ></img>
                                                }
                                            </div>
                                            <div className="titless">
                                                {responseData.media_filepath &&
                                                    <span>
                                                        <a href={responseData.media_filepath} target="_blank" >
                                                        <img src={Eye} className="eye"
                                                            // onClick={() => viewreport(responseData.media_filepath, responseData.status)}
                                                        />
                                                        </a>
                                                    </span>
                                                }
                                                <audio className="audio_tag" src={responseData.audio_path} controls id={"PlayAudio" + i} style={{ visibility: "hidden" }}></audio>
                                            </div>
                                        </div>
                                        <div>
                                            <Button variant="contained" color="success" className="close_complaint" onClick={() => {
                                                supportstatus("close", responseData.ticket_id)
                                            }}>
                                                Resolve
                                            </Button>
                                        </div>
                                    </>
                                }
                                {responseData.status == "RO" &&
                                    <>
                                        <p className="support_card_date">  {timeFormate(responseData.ticket_date)}</p>
                                        <div className="support_icons">
                                            <div className="titless">
                                                {responseData.audio_path &&
                                                    <img src={(Var_audioState == true && Var_audioIndex == i) ? pass : play} className="play"
                                                        onClick={() => { !Var_audioState ? playAudio(responseData.audio_path, i, "play") : playAudio(responseData.audio_path, i, "stop") }}
                                                    ></img>}
                                            </div>
                                            <div className="titless">
                                                {responseData.media_filepath &&
                                                    <a href={responseData.media_filepath} target="_blank">
                                                        <img src={Eye} className="eye"
                                                        // onClick={() => viewreport(responseData.media_filepath ? responseData.media_filepath : "")}
                                                        ></img>
                                                    </a>}
                                                <audio className="audio_tag" src={responseData.audio_path} controls id={"PlayAudio" + i} style={{ visibility: "hidden" }}></audio>
                                            </div>
                                        </div>
                                        <div>
                                            <Button variant="contained" color="success" className="close_complaint" onClick={() => supportstatus("close", responseData.ticket_id)}>
                                                Resolve
                                            </Button>
                                        </div>
                                    </>
                                }
                                {responseData.status == "C" &&
                                    <>
                                        <p className="support_card_date_reopen">  {timeFormate(responseData.ticket_date)}</p>
                                        <div className="support_icons_reopen">
                                            <div className="titless">
                                                {responseData.audio_path &&
                                                    <img src={(Var_audioState == true && Var_audioIndex == i) ? pass : play} className="play"
                                                        onClick={() => { !Var_audioState ? playAudio(responseData.audio_path, i, "play") : playAudio(responseData.audio_path, i, "stop") }}
                                                    ></img>}
                                            </div>
                                            <div className="titless">
                                                {responseData.media_filepath &&
                                                    <a href={responseData.media_filepath} target="_blank">
                                                        <img src={Eye} className="eye"
                                                        // onClick={() => viewreport(responseData.media_filepath ? responseData.media_filepath : "")}
                                                        ></img>
                                                    </a>}
                                                <audio className="audio_tag" src={responseData.audio_path} controls id={"PlayAudio" + i} style={{ visibility: "hidden" }}></audio>
                                            </div>
                                        </div>
                                        <div className="Reopen_Resolve_btn">
                                            <Button variant="contained" color="success" className="close_complaint" onClick={() => supportstatus("close", responseData.ticket_id)}>
                                                Resolve
                                            </Button>

                                            <Button className="Reopen_btn" onClick={() => supportstatus("open", responseData.ticket_id)} >
                                                Reopen
                                            </Button>
                                        </div>

                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Card>
                ))}
            </div>
            {/* ================= Support list View  end ================= */}


            {/* ================= Support footer start ================= */}
            {localStorage.getItem("FooterStatus") == 'A' &&
                <div className="layout_footer footer">
                    <FooterMaster />
                </div>
            }
            {/* ================= Support footer end ================= */}

            {/* =================  Support Document View Model Start =============== */}
            <Dialog
                fullWidth={fullWidth}
                open={isshowsuccess}
                onClose={setishiddensuccess}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "340px",
                            borderRadius: "20px",
                            height: "51%"
                        },
                    },
                }}
            >

                <div className="dialog_header">
                    <div className="dialog_title" style={{ textTransform: 'uppercase' }}>View Document</div>
                    <div className="flex_grow"></div>
                    <div onClick={() => isCloseModel()}>
                        <img src={closeicon} />
                    </div>
                </div>
                <DialogContent className="view_post_cls">
                    {/* <div className="success_model_body"> */}
                    {/* <img src={Var_FileView} className="view_imag" /> */}
                    {isValidVideo(Var_FileView) ? (
                        <video controls className="video_cls_width">
                            <source src={Var_FileView} type="video/mp4" />
                        </video>
                    ) : (
                        <img src={Var_FileView} className="view_imag" />

                    )}
                    {/* </div> */}
                </DialogContent>
            </Dialog>
            {/* ================= Support Document View Model Start  ================= */}
            <Dialog
                fullWidth={fullWidth}
                open={isshowsuccessmodal}
                onClose={setishiddensuccessmodal}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "300px",
                            borderRadius: "20px",
                        },
                    },
                }}
            >
                <DialogContent>
                    <div className="success_model_body">
                        <div className="icon">
                            <img src={successModelTickImg} />
                        </div>
                        <div className="title">SUCCESS!</div>
                        <div className="content">{isshowsuccessmodal_text_ref.current}</div>
                        <div className="segment_center">
                            <button className="botton_Cart_Create" onClick={() => okClose()}>OK</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )

}
export default SupportMasterComponent;







